import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { heightToDp } from "../utils/Responsive";
import { IMAGE_WARNING_SIGN, IMAGE_BTN_OK } from "../constants/ImageConst";
import { String } from "../constants/String";
import ImagePress from "./ImagePress";

const ToastCmp = ({ message, onPress }) => {
  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View style={styles.mainContainer}>
        <Image
          style={[styles.imageContainer, { marginBottom: "2%" }]}
          source={IMAGE_WARNING_SIGN}
        />
        <Text style={styles.textSubTitleContainer}>
          {message}
        </Text>
        <ImagePress
          style={styles.imageButtonContainer}
          source={IMAGE_BTN_OK}
          onPress={onPress}
        />
      </View>
    </View>
  );
};

export default ToastCmp;

const styles = StyleSheet.create({
  mainContainer: {
    // flex: 1,
    width: "30%",
    height: "40%",
    borderWidth: 10,
    borderRadius: 10,
    borderColor: "white",
    backgroundColor: "#FFBC49",
    justifyContent: "center",
  },

  imageContainer: {
    width: "20%",
    height: "20%",
    alignSelf: "center",
    resizeMode: "contain",
  },

  imageButtonContainer: {
    marginTop: "6%",
    marginBottom: "2%",
    width: "18%",
    height: "16%",
    resizeMode: "contain",
    alignSelf: "center",
  },
 
  textSubTitleContainer: {
    fontSize: heightToDp(3),
    marginTop:'4%',
    color: "black",
    marginTop: "2%",
    textAlign: "center",
  },
 
});

import React, { useRef, useState, useEffect } from "react";
import { View, Text, ActivityIndicator, StyleSheet } from "react-native";
import { Video, AVPlaybackStatus, Audio } from "expo-av";

import ImagePress from "../../components/ImagePress";
import QuestionScreen from "./QuestionScreen";
import { IMAGE_QT_CLOSE } from "../../constants/ImageConst";
import { URL_VIDEO } from "../../constants/ApiConst";
var subtitleResp = require("../../../assets/subtitle.json");
import videoListData from "../../../assets/videoList.json";

var subtitleIndex = 0;
const IntroVideonScreenFull = ({
  onPress,
  urlSource,
  onPressClose,
  hideclose,
}) => {
  const videoRef = useRef();
  const [videoplayFlag, setVideoPlayFlag] = useState(true);
  const [videoLoader, setVideoLoader] = useState(false);
  const [questionDispFlag, setQuestionDispFlag] = useState(false);

  //===== Video State ==========
  const [currentTimeInDeciSeconds, setCurrentTimeInDeciSeconds] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [videopauseFlag, setVideoPauseFlag] = useState(false);

  const loader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          position: "absolute",
        }}
      >
        <ActivityIndicator size="large" color="white" />
      </View>
    );
  };

  return (
    <View
      style={
        hideclose ? styles.mainContainerNoBorder : styles.mainContainerBorder
      }
    >
      <Video
        videoRef={(ref) => {
          videoRef = ref;
        }}
        shouldPlay={videoplayFlag}
        isLooping={false}
        useNativeControls
      
        source={{ uri: urlSource }}
        style={{ width: "100%", height: "100%", position: "absolute"}}
        resizeMode={"stretch"}
        onLoad={() => {
          setVideoLoader(false);
        }}
        onLoadStart={() => {
          setVideoLoader(true);
        }}
        onPlaybackStatusUpdate={(e) => {
          if (e.didJustFinish) {
            onPress();
          }
        }}
      />
      {hideclose ? null : (
        <ImagePress
          onPress={onPressClose}
          style={{
            position: "absolute",
            height: "8%",
            width: "4%",
            right: 0,
            margin: "0.2%",
            resizeMode: "contain",
          }}
          source={IMAGE_QT_CLOSE}
        />
      )}

      {videoLoader ? loader() : null}
    </View>
  );
};

export default IntroVideonScreenFull;

const styles = StyleSheet.create({
  mainContainerBorder: {
    width: "100%",
    height: "100%",
    alignSelf: "center",
    position: "absolute",
    backgroundColor: "rgba(94, 490, 243, 1)",
    borderColor: "white",
    borderWidth: 4,
    borderRadius: 10,
    overflow: "hidden",
  },
  mainContainerNoBorder: {
    width: "100%",
    height: "100%",
    alignSelf: "center",
    position: "absolute",
    justifyContent:'center',
    backgroundColor: "rgba(94, 490, 243, 1)",
    // borderColor: "white",
    // borderWidth: 4,
    // borderRadius: 10,
    overflow: "hidden",
  },
});

import React, { useEffect, useRef,useState } from "react";
import { View, Text, StyleSheet, Image, FlatList } from "react-native";
import CustomViewBack from "../../../components/CustomViewBack";
import { String } from "../../../constants/String";
import {
  IMAGE_AVTAR,
  IMAGE_HOWITS_CHILD,
  IMAGE_HOWITS_ABOUT_2,
  IMAGE_BTN_SIGNUP,
  IMAGE_BTN_BACK,
  IMAGE_RIGHT_ARROW,
  IMAGE_LEFT_ARROW,
  IMAGE_QUOTE
} from "../../../constants/ImageConst";
import { heightToDp, widthToDp } from "../../../utils/Responsive";
import ImagePress from "../../../components/ImagePress";
import AboutCmp from "../../../components/AboutCmp";
import CustomViewBackTrans from "../../../components/CustomViewBackTrans";
import TestimonialData from "../../../../assets/testimonal.json";
import ImagePressNoAnim from "../../../components/ImagePressNoAnim";
import ReadmoreCmp from "../../../components/ReadmoreCmp";

const sections = [
  {
    title: "Made for you",
    horizontal: true,
  },
];

const data = TestimonialData;
var totalLength = data.length -1;

const TestimonialModal = ({ displayFlag, closeModal, onPressSignup }) => {

  const [currentIndex,setCurrentIndex] = useState(0)
  const [readmoreFlag,setReadMoreFlag] = useState(false)

  useEffect(() => {
   if(currentIndex > totalLength){
     setCurrentIndex(0)
   }else if(currentIndex <0){
     setCurrentIndex(totalLength)
   }
  }, [currentIndex]);
  
  const leftScroll = ()=>{

    var templeft = currentIndex;
    templeft  = templeft -1;
    if(templeft < 0){
      setCurrentIndex(totalLength)
    }else{
      setCurrentIndex(templeft)
    }
  }

  const rightScroll = ()=>{
    var tempright = currentIndex;
    tempright  = tempright +1;
    if(tempright > totalLength){
      setCurrentIndex(0)
    }else{
      setCurrentIndex(tempright)
    }
    
  }

  useEffect(() => {
   setReadMoreFlag(readmoreFlag)
  }, [readmoreFlag]);

  const readmoreView =()=>{
    return(
      <View
      style={{
        backgroundColor: "white",
        marginTop: "3%",
        borderRadius: 10,
        width: "20%",
        justifyContent: "center",
      }}
    >
      <Text
        onPress={onPressReadMore}
        style={{
          padding: "4%",
          fontSize: heightToDp(3),
          textAlign: "center",
          color: "black",
        }}
      >
        {"READ MORE"}
      </Text>
    </View>
    );
  }

  const postItemView = (item, index) => {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#FFE7F1",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          alignContent: "center",
          padding: "2%",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            width: "96%",
            height: "100%",
            // backgroundColor: "#FFE7F1",
          }}
        >
          <View
            style={{ width: "20%", height: "100%", justifyContent: "center" }}
          >
            <Image
              source={IMAGE_AVTAR}
              style={{
                width: "60%",
                height: "25%",
                borderRadius: 180,
                overflow: "hidden",
              }}
            />
          </View>
          <View
            style={{
              width: "80%",
              height: "100%",
              alignItems: "flex-start",

              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Image source={IMAGE_QUOTE} style={{alignSelf:'center',width:'10%',height:'10%',resizeMode:'contain'}}/>
            <Text style={styles.titleContainer}>
              {" "}
              {item.title.toString().trim()}
            </Text>
            <Text style={[styles.descriptionContainer, { marginLeft: "1%" }]}>
              {" "}
              {item.description}
            </Text>

            <Text
              style={[
                styles.descriptionContainer,
                { marginTop: "3%", marginBottom: "1%", fontWeight: "bold" },
              ]}
            >
              {" "}
              {item.name}
            </Text>
            <Text style={styles.descriptionContainer}> {item.address}</Text>
            <Text style={styles.descriptionContainer}> {item.date}</Text>

            {item.readmore.toString().length >0? readmoreView():null}
           
          </View>
        </View>
      </View>
    );
  };

  const onPressReadMore = ()=>{
    console.log("REad more click")
    setReadMoreFlag(true)
  }
  const onPressReadMoreClose = ()=>{
    setReadMoreFlag(false)
  }
  
  return (
    <CustomViewBack borderColor="white" onPress={closeModal} bgColor="#A2D9F8">
      {postItemView(data[currentIndex], currentIndex)}

      <View style={[styles.arrowMainContainer, { left: 0 }]}>
        <ImagePressNoAnim
        onPress={leftScroll}
          style={styles.arrowContainer}
          source={IMAGE_LEFT_ARROW}
        />
      </View>
      <View style={[styles.arrowMainContainer, { right: 0 }]}>
        <ImagePressNoAnim
          onPress={rightScroll}
          style={styles.arrowContainer}
          source={IMAGE_RIGHT_ARROW}
        />
      </View>
      <ImagePress
        onPress={closeModal}
        source={IMAGE_BTN_BACK}
        style={{
          resizeMode: "contain",
          position: "absolute",
          height: "8%",
          width: "5%",
          margin: "1%",
        }}
      />
      {readmoreFlag?<ReadmoreCmp moreText={data[currentIndex].readmore} onPressClose={onPressReadMoreClose}/>:null}
    </CustomViewBack>
  );
};
export default TestimonialModal;

const styles = StyleSheet.create({
  titleContainer: {
    fontSize: heightToDp(3),
    fontWeight: "bold",
    textAlign: "left",
    color: "black",
  },
  descriptionContainer: {
    fontSize: heightToDp(3),
    textAlign: "left",
    color: "black",
    letterSpacing: 1,
  },
  arrowMainContainer: {
    width: "5%",
    height: "100%",
    position: "absolute",
    justifyContent: "center",
  },
  arrowContainer: {
    height: "8%",
    width: "100%",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    resizeMode: "contain",
  },
});

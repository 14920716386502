const TAG = 'Utility ';

export const validateObject = (obj) => {
    try {
      if (Object.keys(obj).length !== 0 && obj !== null) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
  
  export const validateArray = (obj) => {
    try {
      if (Array.isArray(obj)) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
  export const checkKeyAssign = (key, obj) => {
    try {
      if (key in obj) {
        if (obj[key] !== undefined && obj[key] !== null) {
          return obj[key].toString();
        } else {
          return '';
        }
      } else {
        return '';
      }
    } catch (error) {
      return '';
    }
  };
  
  export const checkStringToBool = (myValue)=>{
    try {
      if(!isBlank(myValue)){
        if(myValue === 'true'){
          return true;
        }else{
          return true ;
        }
      }else{
        return false;
      }
    } catch (error) {
      return false;
    }
  }
  
export const isBlank = (str) => {
  // console.log('Recieve : ' + str);
  return !str || /^\s*$/.test(str);
};


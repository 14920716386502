import React, { useState } from "react";
import { View, Text, FlatList, StyleSheet } from "react-native";
import CustomViewBack from "../../../components/CustomViewBack";
import ImagePress from "../../../components/ImagePress";
import { String } from "../../../constants/String";
import {
  IMAGE_REASEARCH_BROODY,
  IMAGE_REASEARCH_CHILD,
  IMAGE_BTN_SIGNUP,
  IMAGE_BTN_BACK,
} from "../../../constants/ImageConst";
import { useEffect } from "react";
import DescriptionRowCmp from "../../../components/DescriptionRowCmp";
import { heightToDp } from "../../../utils/Responsive";
const sections = [
  {
    title: "Made for you",
    horizontal: true,
  },
];

const ResearchModal = ({
  displayFlag,
  closeModal,
  onPressSignup,
  mydeviceType,
}) => {
  const mainView = () => {
    return (
      <View style={{ width: "100%", alignItems: "center", paddingTop: "6%" }}>
        <View style={{ flexDirection: "row", width: "100%" }}>
          <View style={styles.leftContainer}>
            <ImagePress
              source={IMAGE_REASEARCH_BROODY}
              style={styles.sideImageContainer}
            />
          </View>

          <View style={styles.rightConatainer}>
            <Text style={styles.titleContainer}>
              {String.research_hg_eresearch}
            </Text>
            <Text style={styles.descriptionContainer}>
              {String.research_hg_eresearch_1}
            </Text>
          </View>
        </View>
        <Text
          style={[
            styles.titleContainer,
            { textAlign: "center", marginBottom: "2%", color: "black" },
          ]}
        >
          {String.research_hg_list_of_studies}
        </Text>
        <DescriptionRowCmp
          leftViewColor="#FEE7F1"
          leftTextTitle={String.research_split_left_title_5}
          leftTextDescription={String.research_split_left_description_5}
          rightText={String.research_split_right_description_5}
        />
        <DescriptionRowCmp
          leftViewColor="#FDE453"
          leftTextTitle={String.research_split_left_title_4}
          leftTextDescription={String.research_split_left_description_4}
          rightText={String.research_split_right_description_4}
        />
        <DescriptionRowCmp
          leftViewColor="#FEE7F1"
          leftTextTitle={String.research_split_left_title_3}
          leftTextDescription={String.research_split_left_description_3}
          rightText={String.research_split_right_description_3}
        />
        <DescriptionRowCmp
          leftViewColor="#FDE453"
          leftTextTitle={String.research_split_left_title_2}
          leftTextDescription={String.research_split_left_description_2}
          rightText={String.research_split_right_description_2}
        />
        <DescriptionRowCmp
          leftViewColor="#FEE7F1"
          leftTextTitle={String.research_split_left_title_1}
          leftTextDescription={String.research_split_left_description_1}
          rightText={String.research_split_right_description_1}
        />
        <View
          style={{
            width: "100%",
            height: heightToDp(80),
            bottom: 0,
            flexDirection: "row",
            marginLeft: "5%",
          }}
        >
          <ImagePress
            onPress={onPressSignup}
            source={IMAGE_BTN_SIGNUP}
            style={{
              height: "15%",
              width: "30%",
              marginTop: "5%",
              resizeMode: "contain",
            }}
          />
          <View style={{ width: "70%", height: heightToDp(90) }}>
            <ImagePress
              source={IMAGE_REASEARCH_CHILD}
              style={{
                resizeMode: "contain",
                width: "100%",
                height: "100%",
                right: 0,
                // backgroundColor:'white',
                justifyContent: "flex-end",
                alignContent: "flex-end",
                alignSelf: "flex-end",
                alignItems: "flex-end",
              }}
            />
          </View>
        </View>
      </View>
    );
  };

  return (
    <CustomViewBack bgColor="#A2D9F8" borderColor="white" onPress={closeModal}>
      <FlatList
        data={sections}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item }) => mainView()}
        showsVerticalScrollIndicator={false}
      />
      <ImagePress
        onPress={closeModal}
        source={IMAGE_BTN_BACK}
        style={{
          resizeMode: "contain",
          position: "absolute",
          height: "8%",
          width: "5%",
          margin: "1%",
        }}
      />
    </CustomViewBack>
  );
};
export default ResearchModal;
const styles = StyleSheet.create({
  titleContainer: {
    fontSize: heightToDp(4.8),
    fontWeight: "bold",
    color: "black",
  },
  descriptionContainer: {
    fontSize: heightToDp(3.5),
    lineHeight: heightToDp(5.5),
    color: "black",
  },
  sideImageContainer: {
    resizeMode: "contain",
    height: 250,
    width: "80%",
    alignSelf: "center",
  },
  rightConatainer: {
    width: "68%",
    paddingLeft: "1%",
    paddingRight: "1%",
  },
  leftContainer: {
    width: "32%",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
});

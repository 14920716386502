import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import RootReducer from '../reducers/root/RootReducer';
const store = configureStore({
  reducer: RootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
  // middleware: [
  //   ...getDefaultMiddleware({immutableCheck: false, serializableCheck: false, }),
  // ],

});

export default store;

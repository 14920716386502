import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { heightToDp } from "../utils/Responsive";

const DescriptionRowCmp = ({
  leftViewColor,
  leftTextTitle,
  leftTextDescription,
  rightText,
}) => {
  return (
    <View
      style={{
        backgroundColor: "white",
        borderRadius: 12,
        flexDirection: "row",
        width: "95%",
        height:'15%',
        marginBottom: "1%",
        marginTop: "1%",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <View style={[styles.leftContainer, { backgroundColor: leftViewColor }]}>
        <Text style={[styles.titleContainer,{marginBottom:'2%'}]}>{leftTextTitle}</Text>
        <Text style={styles.descriptionContainer}>{leftTextDescription}</Text>
      </View>
      <View style={[styles.rightContainer]}>
        <Text style={styles.descriptionContainer2}>{rightText}</Text>
      </View>
    </View>
  );
};
export default DescriptionRowCmp;

const styles = StyleSheet.create({
  leftContainer: {
    width: "49%",
    borderRadius: 12,
    height:'90%',
    justifyContent:'center',
    margin: "0.5%",
    padding: "2%",
  },
  rightContainer: {
    width: "49%",
    margin: "0.5%",
    paddingRight: "0.5%",
    paddingTop:'2%',
    paddingBottom:'2%'
  },
  titleContainer: {
    fontSize: heightToDp(3.8),
    fontWeight: "bold",
    marginBottom: "1%",
    lineHeight: heightToDp(5),
    color:'black'
  },
  descriptionContainer: {
    fontSize: heightToDp(3.3),
    lineHeight: heightToDp(5),
    color:'black'
  },
  descriptionContainer2: {
    fontSize: heightToDp(3.2),
    lineHeight: heightToDp(5),
    color:'black'
  },
});

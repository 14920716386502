import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, Image, Animated } from "react-native";
import { heightToDp } from "../utils/Responsive";
import TypeWriter from "react-native-typewriter";
import { BUILD_TYPE_WEB, DEFAUL_BUILD_TYPE } from "../constants/DefaultConst";

const BroodyMsgBox = ({ message, boxwidth, animFlag }) => {
  const textArr = message.trim().split(" ");
  // const animatedValue = new Animated.Value(0);
  var animatedValue =[];
  const startValue = new Animated.Value(0);
  const endValue = 1;
  const duration = 400;

  // useEffect(()=>{
  //   textArr.map((_,i)=>{
  //     animatedValue[i]= new  Animated.Value(0)
  //   })
  //   animated()
  // },[])
  useEffect(() => {
      
    // Animated.timing(startValue, {
    //   toValue: endValue,
    //   duration: duration,
    //   useNativeDriver: true,
    // }).start();
    // animated();
    let animations =  textArr.map((_,i)=>{
      return Animated.timing(startValue,{
        toValue: endValue,
        duration: duration,
        useNativeDriver: true,
      })

    });
    Animated.stagger(100,animations).start();

  }, [duration, endValue, startValue]);



  const animated = ()=>{
    let animations =  textArr.map((_,i)=>{
      return Animated.timing(startValue,{
        toValue: endValue,
        duration: duration,
        useNativeDriver: true,
      })

    });
    Animated.stagger(100,animations).start();
  }

  const TypeAnimText_ = () => {
    return (
      <TypeWriter
        maxDelay={200}
        typing={1}
        style={{
          fontSize: heightToDp(2.5),
          textAlignVertical: "center",
          width: "90%",
          alignSelf: "center",
          color: "black",
          marginLeft: "5%",
          padding: "4%",
        }}
      >
        {message}
      </TypeWriter>
    );
  };

  const TypeAnimText = () => {
    return (
      <View
        style={{
          width: "92%",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          marginLeft: "7.5%",
          padding: "4%",
        }}
      >
        {textArr.map((word, index) => {
          // console.log("Words : ", word);
          return (
            <Animated.Text
              key={`${word}-${index}`}
              style={{
                fontSize: heightToDp(2.5),
                color: "black",
                textAlignVertical:'center',
                // opacity: startValue,
                transform:[{translateY:Animated.multiply(startValue, new Animated.Value(-5))}],
                opacity: startValue,
              }}
            >
              {word}{`${index < textArr.length?' ':''}`}
            </Animated.Text>
          );
        })}
      </View>
    );
  };
  return (
    <View
      style={
        DEFAUL_BUILD_TYPE === BUILD_TYPE_WEB
          ? {
              position: "absolute",
              maxWidth: boxwidth,
              width: boxwidth,
              backgroundColor: "#ffffff",
              padding: "0.2%",
              // padding: "4%",
              // left: "1.5%",
              left: "5%",
              marginLeft: "12%",
              // bottom: "40%",
              bottom: "35%",
              borderRadius: 70,
            }
          : styles.appContainer
      }
    >
      <Image
        source={require("../../assets/others/nanoarrow.png")}
        style={
          DEFAUL_BUILD_TYPE === BUILD_TYPE_WEB
            ? styles.imageArrowWeb
            : styles.imageArrowApp
        }
      />
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
        }}
      >
        {animFlag ? (
          TypeAnimText()
        ) : (
          <Text
            style={{
              fontSize: heightToDp(2.5),
              textAlignVertical: "center",
              width: "92%",
              alignSelf: "center",
              textAlignVertical:'center',
              color: "black",
              marginLeft: "5%",
              padding: "4%",
            }}
          >
            {message}
          </Text>
        )}

        {/*<View style={styles.leftArrow} />*/}
      </View>
    </View>
  );
};
export default BroodyMsgBox;

const styles = StyleSheet.create({
  leftArrow: {
    width: 0,
    height: 0,
    backgroundColor: "transparent",
    borderStyle: "solid",
    borderLeftWidth: 30,
    borderRightWidth: 30,
    borderBottomWidth: 60,
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    zIndex: -10,
    bottom: 5,
    left: -40,
    borderBottomColor: "#ffffff",
    transform: [{ rotate: "-110deg" }],
  },
  imageArrowApp: {
    width: "15%",
    height: "60%",
    resizeMode: "contain",
    marginLeft: "-5.4%",
    position: "absolute",
    bottom: "10%",
    transform: [{ rotate: "10deg" }],
  },
  imageArrowWeb: {
    width: "15%",
    height: "60%",
    resizeMode: "contain",
    marginLeft: "-8%",
    position: "absolute",
    bottom: "10%",
    transform: [{ rotate: "10deg" }],
  },
  appContainer: {
    position: "absolute",
    // maxWidth:boxwidth,
    // width: boxwidth,
    maxWidth: "60%",
    width: "60%",
    backgroundColor: "#ffffff",
    padding: "0.2%",
    // left: "1.5%",
    left: "3.5%",
    marginLeft: "10%",
    // bottom: "40%",
    bottom: "35%",
    borderRadius: 70,
  },
  textWrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
});

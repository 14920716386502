import axios from "axios";
import { BASE_URL } from "../constants/ApiConst";


  export default axios.create({
    
    baseURL: BASE_URL,
    responseType: "json",
    transformRequest: [function (data, headers) {
      // Do whatever you want to transform the data
      // console.log("Header Axios Api Param : ",data);
      // console.log("Header Axios Api : ",headers);
      return data;
    }],
    responseType:'json',
    responseEncoding: 'utf8',
    // timeout: 5000,
    // headers: {'X-Custom-Header': 'foobar'}
  });


import React, { useEffect } from "react";
import { View, Text, StyleSheet } from "react-native";
import ImagePress from "./ImagePress";
import { IMAGE_STAR, IMAGE_BTN_OK } from "../constants/ImageConst";
import { String } from "../constants/String";
import { heightToDp } from "../utils/Responsive";

const CollectStarCmp = ({ onPress, message }) => {

  // useEffect(()=>{
  //   console.log("Messsage is : "+message)
  // },[])

  const messageCondtion = () => {
    if (message === "") {
      return (
        <Text
          style={{
            fontSize: heightToDp(4),
            color: "black",

            textAlign: "center",
            marginTop: "2%",
          }}
        >
          {String.congratulation_star}
          {"\n\n"}
        </Text>
      );
    } else {
      return (
        <Text
          style={{
            fontSize: heightToDp(4),
            color: "black",

            textAlign: "center",
            marginTop: "2%",
          }}
        >
          {/**{String.congratulation_star}
          {"\n\n"}
          {String.congratulation_next_video}
          {"\n"}
          {String.con_left_quat}
          {message}
        {String.con_left_quat}*/}
        {message}
        </Text>
      );
    }
  };
  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View style={styles.mainContainer}>
        <ImagePress
          source={IMAGE_STAR}
          style={styles.imageContainerLarge}
        />
        {messageCondtion()}
        <ImagePress
          onPress={onPress}
          source={IMAGE_BTN_OK}
          style={styles.imageButtonContainer}
        />
      </View>
    </View>
  );
};

export default CollectStarCmp;
const styles = StyleSheet.create({
  mainContainer: {
    width: "85%",
    height: "85%",
    // margin: "2%",
    borderWidth: 10,
    borderRadius: 10,
    justifyContent: "center",
    borderColor: "white",
    backgroundColor: "#a3daf9",
  },
  closeButtonView: {
    position: "absolute",
    height: "4%",
    width: "2%",
    // margin: "10%",
    right: "4.5%",
    top: "4.5%",
    resizeMode: "contain",
  },
  imageContainerLarge: {
    width: "25%",
    height: "25%",
    resizeMode: "contain",
    alignSelf: "center",
  },
  imageButtonContainer: {
    marginTop: "2%",
    marginBottom: "1%",
    width: "26%",
    height: "12%",
    resizeMode: "contain",
    alignSelf: "center",
  },
  textTitleContainer: {
    fontSize: heightToDp(3),
    color: "black",
    padding: "2%",
    textAlign: "center",
    fontWeight: "bold",
  },

  textSubTitleContainer: {
    fontSize: heightToDp(3),
    color: "black",
    marginTop: "2%",
    textAlign: "center",
  },
});

import React, { Children } from "react";
import { View, StyleSheet } from "react-native";
import SideMenu from "../screens/startscreens/SideMenu";

// handleMenu={onPressSideMenuButton}

const CustomView = ({
  bgColor,
  borderColor,
  children,
  menuDisPlayFlag,
  handleMenu,
  onPressHowWork,
  onPressResearch,
  onPressTheBook,
  onPressInsightTeacher,
  onPressTestimonials,
  onPressNotifications,
  onPressButtonWatchLogins,
  onPressPrivacyPolicy,
  onPressTermsOfUSes
}) => {
  return (
    <View
      style={[
        styles.mainContainer,
        { backgroundColor: bgColor, borderColor: borderColor },
      ]}
    >
      {children}
      {menuDisPlayFlag ? <SideMenu handleMenu={handleMenu} 
      onPressHowWork={onPressHowWork}
      onPressResearch={onPressResearch}
      onPressTheBook={onPressTheBook}
      onPressInsightTeacher={onPressInsightTeacher}
      onPressTestimonials={onPressTestimonials}
      onPressButtonWatchLogins={onPressButtonWatchLogins}
      onPressPrivacyPolicy={onPressPrivacyPolicy}
      onPressTermsOfUSes={onPressTermsOfUSes}
      onPressNotifications={onPressNotifications}/> : null}
    </View>
  );
};

export default CustomView;

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
    height: "100%",
    borderWidth: 12,
    position:'absolute'
  },
});

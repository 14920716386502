import React from "react";
import { Text, View, StyleSheet, ScrollView } from "react-native";
import ImagePress from "../../components/ImagePress";
import SideMenuText from "../../components/SideMenuText";
import {
  IMAGE_BTN_MENU_CLOSE,
  IMAGE_BTN_NOTIFICATION,
  IMAGE_BTN_BEL,
} from "../../constants/ImageConst";
import { String } from "../../constants/String";
const SideMenu = ({
  menuDisplayFlag,
  handleMenu,
  onPressHowWork,
  onPressResearch,
  onPressTheBook,
  onPressInsightTeacher,
  onPressTestimonials,
  onPressNotifications,
  onPressButtonWatchLogins,
  onPressPrivacyPolicy,
  onPressTermsOfUSes,
}) => {
  return (
    <View style={styles.mainContainer}>
      <ImagePress
        source={IMAGE_BTN_MENU_CLOSE}
        onPress={handleMenu}
        style={{
          alignSelf: "flex-end",
          height: "10%",
          width: "20%",
          marginTop: "6%",
          resizeMode: "contain",
        }}
      />
      <ScrollView>
        <View style={{ padding: "8%" }}>
          <SideMenuText
            menuName={String.sidemenu_how_does_work}
            cstStyle={styles.menuItemStyle}
            onPress={onPressHowWork}
          />
          <SideMenuText
            menuName={String.sidemenu_research}
            cstStyle={styles.menuItemStyle}
            onPress={onPressResearch}
          />
          <SideMenuText
            menuName={String.sidemenu_the_book}
            cstStyle={styles.menuItemStyle}
            onPress={onPressTheBook}
          />
          <SideMenuText
            menuName={String.sidemenu_insight_teacher}
            cstStyle={styles.menuItemStyle}
            onPress={onPressInsightTeacher}
          />
          <SideMenuText
            menuName={String.sidemenu_login}
            cstStyle={styles.menuItemStyle}
            onPress={onPressButtonWatchLogins}
          />
          <SideMenuText
            menuName={String.sidemenu_testimonials}
            cstStyle={styles.menuItemStyle}
            onPress={onPressTestimonials}
          />

          <SideMenuText
            menuName={String.sidemenu_terms_of_uses}
            cstStyle={styles.menuItemStyle}
            onPress={onPressTermsOfUSes}
          />

          <SideMenuText
            menuName={String.sidemenu_privacy_policy}
            cstStyle={styles.menuItemStyle}
            onPress={onPressPrivacyPolicy}
          />

          {/**<View
          style={{
            backgroundColor: "#FFE353",
            marginTop: "12%",
            flexDirection: "row",
          }}
        >
          <Text
            onPress={onPressNotifications}
            style={[
              styles.menuItemStyle,
              { paddingLeft: "6%", paddingRight: "6%" },
            ]}
          >
            {String.sidemenu_notification}
          </Text>
          <ImagePress
            onPress={onPressNotifications}
            source={IMAGE_BTN_BEL}
            style={{
              width: "20%",
              height: "80%",
              resizeMode: "contain",
              alignSelf: "center",
            }}
          />
          </View>*/}
        </View>
      </ScrollView>
    </View>
  );
};

export default SideMenu;

const styles = StyleSheet.create({
  mainContainer: {
    width: "28%",
    height: "100%",
    padding: "4%",
    position: "absolute",
    backgroundColor: "white",
    alignSelf: "flex-end",
  },
  menuItemStyle: {
    fontSize: 16,
    fontWeight: "bold",
    paddingTop: "4.5%",
    paddingBottom: "4.5%",
  },
});

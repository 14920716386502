import React from "react";
import { Platform } from "react-native";
import Constants from "expo-constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isBlank, validateObject,checkKeyAssign } from "../../utils/Utility";
import { API_QUESTION_LIST_GET, URL_VIDEO} from "../../constants/ApiConst";
import { String } from "../../constants/String";
import axios from "axios";
import { HEADER } from "../../constants/DefaultConst";
const TAG = "Rq_QuestionList ";
export const request_QuestionList = createAsyncThunk(
  "Rq_QuestionList/request_QuestionList",
  async (payloadCreator, thunkAPI) => {
    const data = {};
    var config = {
      // '/' +
      // payloadCreator.extraParams.from +
      // '/' +
      // payloadCreator.extraParams.to 
      method: 'get',
      url: API_QUESTION_LIST_GET +'?vid='+payloadCreator.extraParams.vid+ '&'+'uid='+payloadCreator.extraParams.uid,
      
    };
    
   const response = axios(config)
    .then(function (response) {
      console.log("Rq_QuestionList Response : "+JSON.stringify(response.data));
      return response;
    })
    .catch(function (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // Logger(TAG, 'error data1 : ' + JSON.stringify(error.response.data));
        // Logger(TAG, 'error status2 : ' + error.response.status);
        // Logger(
        //   TAG,
        //   'error headers3 : ' + JSON.stringify(error.response.headers),
        // );
        return error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // Logger(TAG, 'error request4 : ' + JSON.stringify(error.request));
      } else {
        // Something happened in setting up the request that triggered an Error
        // Logger(TAG, 'error message5 : ' + error.message);
      }
      // Logger(TAG, 'error config6 : ' + JSON.stringify(error.config));
    });
    return response;
  }
);

const setResponsedata = (state, response) => {
  // console.log(TAG, "data response infun :" + JSON.stringify(response.status));
  if (validateObject(response.data)) {
    state.levelQuestionDataArray = [];
    response.data.forEach(item => {
      let dataitem = null
      try {
       dataitem = setSingleItemData(state,item)  
      return state.levelQuestionDataArray.push(dataitem);
      } catch (error) {
      }
    });
    // console.log("My array is : "+ JSON.stringify(state.levelDataArray));
  }
};

const setSingleItemData =(state,item)=>{
  let itemobject = {
    'qid':'',
    'question':'',
    'vid':'',
    'start_time':'',
    'end_time':'',
    'option1':'',
    'option1_answer_falg':'',
    'option2':'',
    'option2_answer_falg':'',
    'option3':'',
    'option3_answer_falg':'',
    'option4':'',
    'option4_answer_falg':'',
    'created_at':'',
    'updated_at':'',
  }
  itemobject.qid= checkKeyAssign('qid',item);
  itemobject.question= checkKeyAssign('question',item);
  itemobject.vid= checkKeyAssign('vid',item);

  itemobject.start_time= checkKeyAssign('start_time',item);
  itemobject.end_time= checkKeyAssign('end_time',item);
  itemobject.option1= checkKeyAssign('option1',item);
  itemobject.option1_answer_falg= checkKeyAssign('option1_answer_falg',item);
  itemobject.option2= checkKeyAssign('option2',item);
  itemobject.option2_answer_falg= checkKeyAssign('option2_answer_falg',item);
  itemobject.option3= checkKeyAssign('option3',item);
  itemobject.option3_answer_falg= checkKeyAssign('option3_answer_falg',item);
  itemobject.option4= checkKeyAssign('option4',item);
  itemobject.option4_answer_falg= checkKeyAssign('option4_answer_falg',item);  
  itemobject.created_at= checkKeyAssign('created_at',item);
  itemobject.updated_at= checkKeyAssign('updated_at',item);

  return itemobject;
}

const Rq_QuestionList = createSlice({
  name: "Rq_QuestionList",
  initialState: {
    loading: "",
    responseFlag: "",

    levelQuestionDataArray: [],
    errorCode: "",
    errorType: "",
    errorMessage: "",
  },
  reducers: {},
  extraReducers: {
    [request_QuestionList.pending]: (state, action) => {
      state.loading = "yes";
    },
    [request_QuestionList.fulfilled]: (state, action) => {
      
      if (validateObject(action.payload.data)) {
        if (validateObject(action.payload.data.result)) {
          state.responseFlag = checkKeyAssign("status", action.payload.data.result);
          if (state.responseFlag === "true") {
             setResponsedata(state, action.payload.data.result);
          } else if (state.responseFlag === "false") {
            if (validateObject(action.payload.data.result)) {
                state.errorCode= checkKeyAssign('statusCode',action.payload.data.result);
                state.errorMessage= checkKeyAssign('statusMessage',action.payload.data.result);
              // setErrorData(state, action.payload.result.error);
            } else {
              state.responseFlag = "false";
              state.responseMessage = String.could_not_get_reponse;
            }
          } else {
            state.responseFlag = "false";
            state.responseMessage = String.could_not_get_reponse;
          }
        }
      }
      state.loading = "no";
    },
    [request_QuestionList.rejected]: (state, action) => {
      state.loading = "no";
      state.responseFlag = "false";
      state.responseMessage = String.could_not_get_reponse;
    },
  },
});
export default Rq_QuestionList;

import React,{useEffect} from "react";
import { View, Image, TouchableWithoutFeedback, Animated } from "react-native";
import { Video, AVPlaybackStatus, Audio } from "expo-av";
import { useState } from "react";

const soundClickObject = new Audio.Sound();
const ImagePressNoAnim = ({ source, style, onPress }) => {
const [clickSound,setClickSound] = useState(false);

  const loadClickSound = async () => {
    await soundClickObject.loadAsync(require("../../assets/mp3/click.mp3"));
    await soundClickObject.setIsLoopingAsync(false);
    await soundClickObject.getStatusAsync().then((soundRes) => {
      setClickSound(soundRes.isLoaded);
    });
    // console.log(
    //   "SOUND loading status : " +
    //     JSON.stringify(await soundClickObject.getStatusAsync())
    // );
  };
  const pauseClickSound = async () => {
    soundClickObject.pauseAsync();
  };
  const playClickSound = async () => {
    soundClickObject.playAsync();
    unloadClickSound();
  };
  const unloadClickSound = async () => {
    soundClickObject.unloadAsync();
  };

  useEffect(()=>{
    if(clickSound===false || clickSound === null){
      // loadClickSound();
    }
    
  },[])

  return (
    <TouchableWithoutFeedback
      onPress={onPress}
      style={style}
    >
      <Animated.Image source={source} style={[style]} resizeMethod="resize" />
    </TouchableWithoutFeedback>
  );
};
export default ImagePressNoAnim;

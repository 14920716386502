import React from "react";
import { FlatList, Image, Linking, StyleSheet, Text, View } from "react-native";
import * as WebBrowser from "expo-web-browser";
import CustomViewBack from "../../../components/CustomViewBack";
import ImagePress from "../../../components/ImagePress";
import { String } from "../../../constants/String";
import { heightToDp } from "../../../utils/Responsive";
import {
  IMAGE_SIDE_TEACHER,
  IMAGE_SIDE_TEACHER_CHILD,
  IMAGE_BTN_BACK,
  IMAGE_GET_THE_BOOK,
} from "../../../constants/ImageConst";
import TeacherCmp from "../../../components/TeacherCmp";
import {
  BUILD_TYPE_WEB,
  DEFAUL_BUILD_TYPE,
} from "../../../constants/DefaultConst";
const sections = [
  {
    title: "Made for you",
    horizontal: true,
  },
];

const TheBookModal = ({ displayFlag, closeModal, onPressSignup }) => {
  const View_1 = () => {
    return (
      <View
        style={{
          width: "100%",
          alignItems: "center",
        }}
      >
        <Text style={styles.titleContainer}>
          {String.insight_teacher_first_selmatters}
        </Text>
        <Text style={[styles.descriptionContainer, { marginTop: "2%" }]}>
          {String.insight_teacher_second_selmatters}
        </Text>
        <ImagePress
          source={IMAGE_SIDE_TEACHER}
          style={{
            width: "85%",
            marginTop: "2%",
            height: heightToDp(45),
            resizeMode: "contain",
          }}
        />
        <Text style={[styles.titleContainer, { marginTop: "4%" }]}>
          {String.insight_teacher_third_selteacher}
        </Text>
      </View>
    );
  };

  const View_2 = () => {
    return (
      <View
        style={{
          width: "100%",
          alignItems: "center",
          marginTop: "4%",
        }}
      >
        <Text
          style={
            (styles.descriptionContainer,
            { textAlign: "center", color: "black" })
          }
        >
          {String.insight_teacher_src2_desc1}
        </Text>
        <View
          style={{
            width: "8%",
            backgroundColor: "white",
            justifyContent: "center",
            height: "0.5%",
            marginTop: "2%",
          }}
        ></View>
        <Text
          style={
            (styles.descriptionContainer,
            { textAlign: "center", color: "black", marginTop: "2%" })
          }
        >
          {String.insight_teacher_src2_desc2}
        </Text>
        <View
          style={{
            width: "8%",
            backgroundColor: "white",
            justifyContent: "center",
            height: "0.5%",
            marginTop: "2%",
          }}
        ></View>
        <Text
          style={
            (styles.descriptionContainer,
            { textAlign: "center", color: "black", marginTop: "2%" })
          }
        >
          {String.insight_teacher_src2_desc3}
        </Text>

        <Text
          style={{
            marginTop: "4%",
            paddingTop: "8%",
            paddingBottom: "8%",
            width: "100%",
            letterSpacing: 1,
            textAlign: "center",
            color: "black",
            fontSize: heightToDp(4.2),
            fontWeight: "bold",
            backgroundColor: "#A2D9F8",
          }}
        >
          {String.insight_teacher_src2_desc4}
        </Text>

        <View style={{ width: "100%", backgroundColor: "#A2D9F8" }}>
          <TeacherCmp
            margineFlag={false}
            textCount="01"
            textLable={String.insight_teacher_src3_desc1}
            textSubLable={String.insight_teacher_src3_desc1_sub}
          />
          <TeacherCmp
            margineFlag={false}
            textCount="02"
            textLable={String.insight_teacher_src3_desc2}
            textSubLable={String.insight_teacher_src3_desc2_sub}
          />
          <TeacherCmp
            margineFlag={false}
            textCount="03"
            textLable={String.insight_teacher_src3_desc3}
            textSubLable={String.insight_teacher_src3_desc3_sub}
          />
          <TeacherCmp
            margineFlag={false}
            textCount="04"
            textLable={String.insight_teacher_src3_desc4}
            textSubLable={String.insight_teacher_src3_desc4_sub}
          />
          <TeacherCmp
            margineFlag={false}
            textCount="05"
            textLable={String.insight_teacher_src3_desc5}
            textSubLable={String.insight_teacher_src3_desc5_sub}
          />
          <TeacherCmp
            margineFlag={false}
            textCount="06"
            textLable={String.insight_teacher_src3_desc6}
            textSubLable={String.insight_teacher_src3_desc6_sub}
          />
          <TeacherCmp
            margineFlag={false}
            textCount="07"
            textLable={String.insight_teacher_src3_desc7}
            textSubLable={String.insight_teacher_src3_desc7_sub}
          />
          <TeacherCmp
            margineFlag={false}
            textCount="08"
            textLable={String.insight_teacher_src3_desc8}
            textSubLable={String.insight_teacher_src3_desc8_sub}
          />
          <TeacherCmp
            margineFlag={false}
            textCount="09"
            textLable={String.insight_teacher_src3_desc9}
            textSubLable={String.insight_teacher_src3_desc9_sub}
          />
          <TeacherCmp
            margineFlag={true}
            textCount="10"
            textLable={String.insight_teacher_src3_desc10}
            textSubLable={String.insight_teacher_src3_desc10_sub}
          />
        </View>
        <Image
          source={IMAGE_SIDE_TEACHER_CHILD}
          style={{
            resizeMode: "contain",
            justifyContent: "center",
            backgroundColor: "#A2D9F8",
            marginTop: "-2%",
            height: heightToDp(80),
            width: "100%",
          }}
        />
      </View>
    );
  };

  const _handlePressButtonAsync = async () => {
    if (DEFAUL_BUILD_TYPE === BUILD_TYPE_WEB) {
      let result = await WebBrowser.openBrowserAsync(
        String.thebook_happigenius_url
      );
    } else {
      Linking.openURL(String.thebook_happigenius_url);
    }
  };

  const openBookUrl = async() => {
    if (DEFAUL_BUILD_TYPE === BUILD_TYPE_WEB) {
      let result = await WebBrowser.openBrowserAsync(String.thebook_amazon_url);
    } else {
      Linking.openURL(String.thebook_amazon_url);
    }
  };
  const mainView = () => {
    return (
      <View
        style={{
          width: "100%",
          alignItems: "center",
          paddingTop: "6%",
          overflow: "hidden",
          paddingLeft: "4%",
          paddingRight: "4%",
          paddingBottom: "4%",
        }}
      >
        <Text style={styles.descriptionContainer}>
          {String.thebook_desc}
          <Text
            style={styles.hyperlinkStyle}
            onPress={() => {
              _handlePressButtonAsync();
            }}
          >
            {String.thebook_desc_happigenius}
          </Text>
        </Text>

        <ImagePress
          source={IMAGE_GET_THE_BOOK}
          onPress={openBookUrl}
          style={{
            resizeMode: "contain",
            height: "20%",
            width: "20%",
            margin: "3%",
          }}
        />
      </View>
    );
  };

  return (
    <CustomViewBack bgColor="#FDE453" borderColor="white" onPress={closeModal}>
      <FlatList
        data={sections}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item }) => mainView()}
        showsVerticalScrollIndicator={false}
      />
      <ImagePress
        onPress={closeModal}
        source={IMAGE_BTN_BACK}
        style={{
          resizeMode: "contain",
          position: "absolute",
          height: "8%",
          width: "5%",
          margin: "1%",
        }}
      />
    </CustomViewBack>
  );
};

export default TheBookModal;

const styles = StyleSheet.create({
  titleContainer: {
    fontSize: heightToDp(4.8),
    fontWeight: "bold",
    color: "black",
    letterSpacing: 1.2,
  },
  descriptionContainer: {
    fontSize: heightToDp(3),
    lineHeight: heightToDp(6),

    color: "black",
  },
  hyperlinkStyle: {
    color: "blue",
  },
});

import React from "react";
import { View, Text, StyleSheet, Image } from "react-native";
import { String } from "../constants/String";
import {
  IMAGE_BROODY_FUNFACT,
  IMAGE_BTN_CONTINUE,
} from "../constants/ImageConst";
import ImagePress from "./ImagePress";
import { heightToDp } from "../utils/Responsive";

const FunFactCmp = ({ onPressContinue, message }) => {
  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View style={styles.mainContainer}>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            padding: "2%",
            height: "100%",
          }}
        >
          <View
            style={{
              width: "40%",
              height: "100%",
            }}
          >
            <Image
              source={IMAGE_BROODY_FUNFACT}
              style={[
                styles.imageContainerLarge,
                { bottom: "10%", position: "absolute", alignSelf: "center" },
              ]}
            />
          </View>
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              width: "60%",
              height: "100%",
            }}
          >
            <Text style={styles.textTitleContainer}>
              {String.funfacts_title}
            </Text>
            <Text style={styles.textSubTitleContainer}>
              {message}
            </Text>
            <ImagePress
              source={IMAGE_BTN_CONTINUE}
              onPress={onPressContinue}
              style={[styles.imageButtonContainer, { marginTop: "6%" }]}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default FunFactCmp;

const styles = StyleSheet.create({
  mainContainer: {
    width: "80%",
    height: "80%",
    borderWidth: 10,
    borderRadius: 10,
    borderColor: "white",
    justifyContent: "center",
    backgroundColor: "#A0EDF5",
  },
  imageContainer: {
    width: "35%",
    height: "35%",
    alignSelf: "center",
    resizeMode: "contain",
  },
  imageButtonContainer: {
    marginTop: "4%",
    marginBottom: "2%",
    width: "30%",
    height: "14%",
    resizeMode: "contain",
    alignSelf: "center",
  },
  imageContainerLarge: {
    width: "65%",
    height: "60%",
    resizeMode: "contain",
  },
  textTitleContainer: {
    fontSize: heightToDp(3),
    color: "black",
    padding: "2%",
    marginBottom:'2%',
    textAlign: "center",
    fontWeight: "bold",
  },
  textSubTitleContainer: {
    fontSize: heightToDp(3),
    color: "black",
    marginTop: "2%",
    textAlign: "left",
  },
});

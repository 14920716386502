import React, { useEffect, useState, useRef } from "react";
import { View, Text, StyleSheet, Image } from "react-native";
import { useForm, Controller } from "react-hook-form";
import ImagePress from "./ImagePress";
import {
  IMAGE_QT_CLOSE,
  IMAGE_REASEARCH_BROODY,
  IMAGE_BTN_SUBMIT,
} from "../constants/ImageConst";
import { heightToDp } from "../utils/Responsive";
import CmpTextInput from "./CmpTextInput";
import { String } from "../constants/String";
import InputSuccessCmp from "./InputSuccessCmp";
import ToastCmp from "./ToastCmp";

const ActiFunFactCmp = ({ message, onPressSubmit,onPressClose }) => {
  const ref_UInpuet1 = useRef();
  const ref_UInpuet2 = useRef();
  const ref_UInpuetDone = useRef();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [multiFlag, setMultiFlag] = useState(false);
  const [userInput1, setUserInput1] = useState("");
  const [userInput2, setUserInput2] = useState("");
  const [userInputDone, setUserInputDone] = useState("");
  const [toastFlag,setToastFlag] = useState(false);
  const [toastMessage,setToastMessage] = useState("");

  useEffect(() => {
    const temp = message;
    if (temp.toLowerCase().includes("three")) {
      setMultiFlag(true);
    } else {
      setMultiFlag(false);
    }
  }, []);
  useEffect(() => {
    setUserInput1(userInput1);
    setUserInput2(userInput2);
    setUserInputDone(userInputDone);
  }, [userInput1, userInput2, userInputDone]);



  const onSubmit = () => {
    if(multiFlag){
        if(userInput1 !== "" &&userInput2 !== "" && userInputDone !== ""){
          onPressSubmit()
        }else{
            setToastMessage("You missed the things to write!")
            setToastFlag(true)
        }
    }else{
      if(userInputDone !== ""){
        onPressSubmit()
      }else{
        setToastMessage("You must enter the something in box!")
        setToastFlag(true)
      }
    }
  };

  
  const toastOnPressClose = ()=>{
    setToastFlag(false)
  }


  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View style={styles.mainContainer}>
        <View style={{ flexDirection: "row", width: "100%", height: "100%" }}>
          <View
            style={{ width: "30%", height: "100%", justifyContent: "center" }}
          >
            <Image
              source={IMAGE_REASEARCH_BROODY}
              style={styles.imageContainerLarge}
            />
          </View>
          <View
            style={{
              width: "70%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Text
              style={[styles.textSubTitleContainer, { marginBottom: "2%" ,paddingRight:'4%',paddingLeft:'2%'}]}
            >
              {message}
            </Text>
            {multiFlag ? (
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "",
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CmpTextInput
                    style={styles.inputContainer}
                    autoCapitalize="none"
                    keyboardType="default"
                    returnKeyType="done"
                    secureTextEntry={false}
                    refrensKey={ref_UInpuet1}
                    onSubmitEditing={() => ref_UInpuet2.current.focus()}
                    onBlur={onBlur}
                    onChangeText={(text) => {
                      onChange;
                      setUserInput1(text);
                    }}
                    value={value}
                  />
                )}
                name="userInput1"
                defaultValue=""
              />
            ) : null}

            {multiFlag ? (
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "",
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <CmpTextInput
                    style={styles.inputContainer}
                    autoCapitalize="none"
                    keyboardType="default"
                    returnKeyType="done"
                    secureTextEntry={false}
                    refrensKey={ref_UInpuet2}
                    onSubmitEditing={() => ref_UInpuetDone.current.focus()}
                    onBlur={onBlur}
                    onChangeText={(text) => {
                      onChange;
                      setUserInput2(text);
                    }}
                    value={value}
                  />
                )}
                name="userInput2"
                defaultValue=""
              />
            ) : null}

            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "",
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <CmpTextInput
                  style={styles.inputContainer}
                  autoCapitalize="none"
                  keyboardType="default"
                  returnKeyType="done"
                  secureTextEntry={false}
                  refrensKey={ref_UInpuetDone}
                  onSubmitEditing={onSubmit}
                  onBlur={onBlur}
                  onChangeText={(text) => {
                    onChange;
                    setUserInputDone(text);
                  }}
                  value={value}
                />
              )}
              name="userInputDone"
              defaultValue=""
            />
            <ImagePress
             onPress={onSubmit}
              source={IMAGE_BTN_SUBMIT}
              style={styles.imageButtonContainer}
            />
          </View>
        </View>
   
        {toastFlag?<ToastCmp message={toastMessage} onPress={toastOnPressClose}/>:null}
   
        </View>

      <ImagePress
        onPress={onPressClose}
        style={styles.closeButtonView}
        source={IMAGE_QT_CLOSE}
      />

    </View>
  );
};

export default ActiFunFactCmp;

const styles = StyleSheet.create({
  mainContainer: {
    width: "90%",
    height: "90%",
    // margin: "2%",
    borderWidth: 10,
    borderRadius: 10,
    justifyContent: "center",
    borderColor: "white",
    backgroundColor: "#a3daf9",
  },
  closeButtonView: {
    position: "absolute",
    height: "4%",
    width: "2%",
    // margin: "10%",
    right: "4.5%",
    top: "4.5%",
    resizeMode: "contain",
  },
  imageContainerLarge: {
    width: "45%",
    height: "45%",
    resizeMode: "contain",
    alignSelf: "center",
  },
  imageButtonContainer: {
    marginTop: "4%",
    marginBottom: "1%",
    width: "26%",
    height: "12%",
    resizeMode: "contain",
    alignSelf: "center",
  },
  textTitleContainer: {
    fontSize: heightToDp(3),
    color: "black",
    padding: "2%",
    textAlign: "center",
    fontWeight: "bold",
  },
  
  textSubTitleContainer: {
    fontSize: heightToDp(3),
    color: "black",
    marginTop: "2%",
    textAlign: "center",
  },
  inputContainer: {
    fontSize: heightToDp(2),
    justifyContent: "center",
    color: "black",
    height: "10%",
    backgroundColor: "white",
    width: "90%",
    marginTop: "1.5%",
    borderRadius: 10,
    paddingHorizontal: 10,
  },
});

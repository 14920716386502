export const LS_ENVIRONMENT = "https://apils.happigenius.com/";
export const LIVE_ENVIRONMENT = "https://api.happigenius.com/";
export const VERSION = "v1.0/";
export const CURRENT_ENVIRONMENT = LIVE_ENVIRONMENT;

export const URL_BGIMAGE = CURRENT_ENVIRONMENT + "uploads/levels/bg/";
export const URL_CHARIMAGE = CURRENT_ENVIRONMENT + "uploads/levels/characters/";
export const URL_VIDEO = CURRENT_ENVIRONMENT + "uploads/levels/videos/";
export const URL_MUSIC = CURRENT_ENVIRONMENT + "uploads/levels/music/";

export const BASE_URL = CURRENT_ENVIRONMENT + VERSION;


//============== GET CALL ===================
export const API_CREATE_TOKEN_GET =BASE_URL+"token/createtoken";
export const API_LEVEL_LIST_GET =BASE_URL+"levels/levellist";
export const API_APP_CONFIG_GET =BASE_URL+"appconfig";
export const API_LEVEL_VIDEO_LIST_GET =BASE_URL+"contain/videolist";
export const API_USER_INFO_GET =BASE_URL+"user/userinfo";
export const API_QUESTION_LIST_GET =BASE_URL+"questions/questionlist";
export const API_USER_AWARD_LIST_GET =BASE_URL+"award/getuseraward";
export const API_GET_PAYMENT_ENTRY =BASE_URL+"stripe/getpaymententry";

// "https://apils.happigenius.com/v1.0/pymt/create-checkout-session.php?pymtkey=";
export const API_PYMT = BASE_URL+"pymt/create-checkout-session.php?pymtkey=";




//============== POST CALL ===================
export const API_USER_CREATE_POST =BASE_URL+"user/createuser";
export const API_USER_LOGIN_POST =BASE_URL+"user/loginuser";
export const API_USER_UPDATE_POST =BASE_URL+"user/updateuser";

// User Coupon
export const API_USER_COUPON_CREATE_POST =BASE_URL+"coupon/createcouponentry";
export const API_USER_COUPON_UPDATE_POST =BASE_URL+"coupon/createcouponentry";

// USer Input
export const API_USER_INPUT_POST =BASE_URL+"input/userinput";
// USer Award
export const API_USER_AWARD_POST =BASE_URL+"award/userawardinsert";
// Stripe
export const API_PAYMENT_ENTRY_TEMP =BASE_URL+"stripe/createpaymententry";

// 060720210236162306940467139341460be12dc5d698.mp4
import React from "react";
import { View, Text } from "react-native";
import { String } from "../constants/String";
import { heightToDp } from "../utils/Responsive";

const VideoEndMessage = ({ onPress, message, collectionFlag }) => {
  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        // margin:'1%',
        // justifyContent:'center',
        alignSelf: "center",
        // alignItems:'center',
        position: "absolute",
        backgroundColor: "#5EC8F3",
        borderColor: "white",
        borderWidth: 10,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10,
        // backgroundColor: "black",
      }}
    >
      <Text
        style={{
          fontSize: heightToDp(4.5),
          lineHeight: 30,
          padding: "8%",
          textAlign: "center",
          color: "white",
          fontWeight: "bold",
        }}
      >
        {message}
      </Text>
      {collectionFlag ? (
        <Text
          style={{
            fontSize: heightToDp(4.5),
            textAlign: "center",
            color: "#F4943E",
            fontWeight: "bold",
          }}
          onPress={onPress}
        >
          {String.award_message}
        </Text>
      ) : null}
    </View>
  );
};

export default VideoEndMessage;

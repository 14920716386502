import Rq_Levellist from '../Rq_Levellist';
import Rq_Appconfig from '../Rq_Appconfig';
import Rq_Level_videolist from '../Rq_Level_videolist';
import Rq_UserInfo from '../Rq_UserInfo';
import Rq_CreateUser from '../Rq_CreateUser';
import Rq_CreateToken from '../Rq_CreateToken';
import Rq_UpdateUser from '../Rq_UpdateUser';
import Rq_QuestionList from '../Rq_QuestionList';
import Rq_UserInputInsert from '../Rq_UserInputInsert';
import Rq_UserAwardInsert from '../Rq_UserAwardInsert';
import Rq_LoginUser from '../Rq_LoginUser';
import Rq_UserAwardList from '../Rq_UserAwardList';
import Rq_StripeYearly from '../Rq_StripeYearly';
import Rq_PaymentEntry from '../Rq_PaymentEntry';
import Rq_Create_PaymentEntry from '../Rq_Create_PaymentEntry';
import Rq_UserCouponEntry from '../Rq_UserCouponEntry';


const RootReducers = {
  Rq_Levellist: Rq_Levellist.reducer,
  Rq_Appconfig: Rq_Appconfig.reducer,
  Rq_Level_videolist: Rq_Level_videolist.reducer,
  Rq_UserInfo:Rq_UserInfo.reducer,
  Rq_CreateUser:Rq_CreateUser.reducer,
  Rq_CreateToken:Rq_CreateToken.reducer,
  Rq_UpdateUser:Rq_UpdateUser.reducer,
  Rq_QuestionList:Rq_QuestionList.reducer,
  Rq_UserInputInsert:Rq_UserInputInsert.reducer,
  Rq_UserAwardInsert:Rq_UserAwardInsert.reducer,
  Rq_LoginUser:Rq_LoginUser.reducer,
  Rq_UserAwardList:Rq_UserAwardList.reducer,
  Rq_StripeYearly:Rq_StripeYearly.reducer,
  Rq_PaymentEntry:Rq_PaymentEntry.reducer,
  Rq_Create_PaymentEntry:Rq_Create_PaymentEntry.reducer,
  Rq_UserCouponEntry:Rq_UserCouponEntry.reducer
  
};
export default RootReducers;

import React from "react";
import { Platform } from "react-native";
import Constants from "expo-constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isBlank, validateObject, checkKeyAssign } from "../../utils/Utility";
import {
  API_GET_PAYMENT_ENTRY,
  API_LEVEL_LIST_GET,
  BASE_URL,
  URLs,
  URL_BGIMAGE,
  URL_CHARIMAGE,
} from "../../constants/ApiConst";
import { String } from "../../constants/String";
import Api from "../../utils/Api";
import axios from "axios";
import { HEADER } from "../../constants/DefaultConst";
const TAG = "Rq_PaymentEntry ";
export const request_PaymentEntry = createAsyncThunk(
  "Rq_PaymentEntry/request_PaymentEntry",
  async (payloadCreator, thunkAPI) => {
    const data = {};
    var config = {
      method: "get",
      url: API_GET_PAYMENT_ENTRY+"?request_key="+payloadCreator.extraParams.request_key ,
      // headers: HEADER,
    };

     const response = axios(config)
      .then(function (response) {
        console.log(TAG+" Response : "+JSON.stringify(response.data));
        return response;
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // Logger(TAG, 'error data1 : ' + JSON.stringify(error.response.data));
          // Logger(TAG, 'error status2 : ' + error.response.status);
          // Logger(
          //   TAG,
          //   'error headers3 : ' + JSON.stringify(error.response.headers),
          // );
          return error.response.data;
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // Logger(TAG, 'error request4 : ' + JSON.stringify(error.request));
        } else {
          // Something happened in setting up the request that triggered an Error
          // Logger(TAG, 'error message5 : ' + error.message);
        }
        // Logger(TAG, 'error config6 : ' + JSON.stringify(error.config));
      });

    // let response = await fetch(API_LEVEL_LIST_GET, {
    //   method:'GET',
    //   mode: 'no-cors',
    //   headers: {
    //     platform: Platform.OS,
    //     version: Constants.manifest.version,
    //     "Access-Control-Allow-Origin": "*",
    //   },
    // }).then((resp)=>{
    //   console.log(TAG + " Response is : " + JSON.stringify(resp.json()));
    // });
    
    return response;
  }
);

const setResponsedata = (state, response) => {
  // console.log(TAG, "data response infun :" + JSON.stringify(response.status));
  if (validateObject(response.data)) {
    state.secure_payment_key = checkKeyAssign("secure_payment_key", response.data);
    state.subscrib_type = checkKeyAssign("subscrib_type", response.data);
    state.payment_status = checkKeyAssign("payment_status", response.data);
  }
};



const Rq_PaymentEntry = createSlice({
  name: "Rq_PaymentEntry",
  initialState: {
    loading: "",
    responseFlag: "",

    secure_payment_key:"",
    subscrib_type:"",
    payment_status:"",


    errorCode: "",
    errorType: "",
    errorMessage: "",
  },
  reducers: {},
  extraReducers: {
    [request_PaymentEntry.pending]: (state, action) => {
      state.loading = "yes";
    },
    [request_PaymentEntry.fulfilled]: (state, action) => {
      if (validateObject(action.payload.data)) {
        if (validateObject(action.payload.data.result)) {
          state.responseFlag = checkKeyAssign(
            "status",
            action.payload.data.result
          );
          if (state.responseFlag === "true") {
            setResponsedata(state, action.payload.data.result);
          } else if (state.responseFlag === "false") {
            if (validateObject(action.payload.data.result)) {
              // setErrorData(state, action.payload.result.error);
              state.errorCode = checkKeyAssign(
                "statusCode",
                action.payload.data.result
              );
              state.errorMessage = checkKeyAssign(
                "statusMessage",
                action.payload.data.result
              );
            } else {
              state.responseFlag = "false";
              state.responseMessage = String.could_not_get_reponse;
            }
          } else {
            state.responseFlag = "false";
            state.responseMessage = String.could_not_get_reponse;
          }
        }
      }
      state.loading = "no";
    },
    [request_PaymentEntry.rejected]: (state, action) => {
      state.loading = "no";
      state.responseFlag = "false";
      state.responseMessage = String.could_not_get_reponse;
    },
  },
});
export default Rq_PaymentEntry;

// Right Panel Images
export const IMAGE_RIGHT_PANEL_BG = require("../../assets/rightpanel/right_panel_bg.png");
export const IMAGE_RP_MUSIC_ON = require("../../assets/rightpanel/music_on.png");
export const IMAGE_RP_MUSIC_OFF = require("../../assets/rightpanel/music_off.png");
export const IMAGE_RP_HELP = require("../../assets/rightpanel/help.png");
export const IMAGE_RP_AWARD = require("../../assets/rightpanel/award.png");
export const IMAGE_RP_AWARD_ANIM = require("../../assets/rightpanel/award_anim.gif");
export const IMAGE_RP_SETTING = require("../../assets/rightpanel/setting.png");

// Bottom Panel Images
export const IMAGE_BT_PAUSE = require("../../assets/bottompanel/pause.png");
export const IMAGE_BT_PLAY = require("../../assets/bottompanel/play.png");
export const IMAGE_BT_REWIND = require("../../assets/bottompanel/rewind.png");

// Questions Dialog
export const IMAGE_QT_CORRECT = require("../../assets/others/correct.png");
export const IMAGE_QT_INCORRECT = require("../../assets/others/incorrect.png");
export const IMAGE_QT_CLOSE = require("../../assets/others/close.png");
export const IMAGE_QT_OPTION_BOX = require("../../assets/others/option_box.png");

// Levels Background
// export const IMAGE_LV_1 = require("../../assets/levels/lv_one.jpg");
export const IMAGE_LV_1 = require("../../assets/levels/SCENE_LEVEL_1.jpg");

//Character
export const IMAGE_CHARACTER = require("../../assets/others/character.png");

// Speech boxs
export const IMAGE_SPEECH_BOX_START = require("../../assets/others/speechbox_start.png");
export const IMAGE_SPEECH_BOX_ACTIVITY = require("../../assets/others/speech_activity.png");

// Level Stand
export const IMAGE_LEVEL_STAND = require("../../assets/others/player_stand_new.png");
export const IMAGE_WATCHED_TICK = require("../../assets/others/watched_tick.png");
export const IMAGE_SEVENDAY_TRIAL = require("../../assets/others/sevenday_trial.png");
export const IMAGE_PAYNOW = require("../../assets/others/paynow.png");
export const IMAGE_SINGLE_CHAR = require("../../assets/others/singlechar.png");
export const IMAGE_RADIO_ACTIVE = require("../../assets/others/radio_active.png");
export const IMAGE_RADIO_INACTIVE = require("../../assets/others/radio_inactive.png");
export const IMAGE_START_NOW = require("../../assets/others/btn_startnow.png");
export const IMAGE_BTN_CANCEL = require("../../assets/others/btn_cancel.png");
export const IMAGE_BTN_RENEW = require("../../assets/others/btn_renew.png");
export const IMAGE_BTN_NO = require("../../assets/others/btn_no.png");
export const IMAGE_BTN_YES = require("../../assets/others/btn_yes.png");
export const IMAGE_BTN_SUBMIT = require("../../assets/others/btn_submit.png");

// reward Stand
export const IMAGE_REWARD_STAND = require("../../assets/others/rewardstop.png");
// export const IMAGE_REWARD_STAND = require("../../assets/others/rewardstop_new.gif");
// export const IMAGE_REWARD_STAND_NEW = require("../../assets/others/rewardstop_new.gif");


// Welcome Screen
export const IMAGE_CHILD = require("../../assets/others/child.png");
export const IMAGE_BTN_LOADING = require("../../assets/others/loading.png");
export const IMAGE_BTN_BACK = require("../../assets/others/back.png");
export const IMAGE_BTN_BEL = require("../../assets/others/bel.png");
export const IMAGE_BTN_NOTIFICATION = require("../../assets/others/btn_notification.png");
export const IMAGE_BTN_MENU_CLOSE = require("../../assets/others/close_transparent.png");
export const IMAGE_BTN_MENU = require("../../assets/others/menu.png");
export const IMAGE_BTN_SIGNUP = require("../../assets/others/btn_signup.png");
export const IMAGE_BTN_WATCHINTRO = require("../../assets/others/btn_watchintro.png");
export const IMAGE_BTN_WATCHSAMPLE = require("../../assets/others/btn_watcsample.png");
export const IMAGE_BROODY_DIALOG = require("../../assets/others/welcome_broody.png");

// How it works
export const IMAGE_HOWITS_CHILD = require("../../assets/others/howit_child.png");
export const IMAGE_HOWITS_ABOUT_2 = require("../../assets/others/abt_2.png");
export const IMAGE_BTN_LOGIN = require("../../assets/others/btn_login.png");


// Insight Teacher
export const IMAGE_SIDE_TEACHER = require("../../assets/others/side_teacher.png");
export const IMAGE_SIDE_TEACHER_CHILD = require("../../assets/others/side_teacher_child.png");

// research
export const IMAGE_REASEARCH_BROODY = require("../../assets/others/research_broony.png");
export const IMAGE_REASEARCH_CHILD = require("../../assets/others/research_child.png");
export const IMAGE_BTN_PLAY = require("../../assets/others/btn_play.png");
export const IMAGE_BTN_CONTINUE = require("../../assets/others/btn_continue.png");

// New UI
export const IMAGE_BTN_OK = require("../../assets/others/btn_ok.png");
export const IMAGE_TROPHY = require("../../assets/others/trophy.png");
export const IMAGE_STAR = require("../../assets/others/star.png");
export const IMAGE_BLAST = require("../../assets/others/blast.gif");
export const IMAGE_BROODY_LESSONS = require("../../assets/others/broody_lesson.png");
export const IMAGE_PLAY_VIDEO= require("../../assets/others/btn_play_video.png");
export const IMAGE_START_SESSONS= require("../../assets/others/btn_start_sessons.png");
export const IMAGE_WARNING_SIGN= require("../../assets/others/warning_sign.png");
export const IMAGE_BROODY_ACTIVITY= require("../../assets/others/broody_activity.png");
export const IMAGE_BROODY_FUNFACT= require("../../assets/others/broody_funfact.png");
export const IMAGE_BROODY_HEART= require("../../assets/others/broody_heart.png");
export const IMAGE_STAND_FUNFACT_PIN= require("../../assets/others/stand_funfact_pin.png");
export const IMAGE_STAND_ACTIVITY_PIN= require("../../assets/others/stand_activity_pin.png");
export const IMAGE_LOCK= require("../../assets/others/lock.png");
export const IMAGE_END_FLAG= require("../../assets/others/end_flag.png");
export const IMAGE_AVTAR= require("../../assets/others/avtar.png");


export const IMAGE_RIGHT_ARROW= require("../../assets/others/rightArrow.png");
export const IMAGE_LEFT_ARROW= require("../../assets/others/leftArrow.png");
export const IMAGE_QUOTE = require("../../assets/others/quote.png");

export const IMAGE_GET_THE_BOOK = require("../../assets/others/btn_getthebook.png");













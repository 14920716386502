import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { Video, AVPlaybackStatus, Audio } from "expo-av";
import { heightToDp } from "../utils/Responsive";

const SampleVideoCmp = ({ videoSource, bottomText }) => {
  return (
    <View style={styles.mainContainer}>
      <Video
        // videoRef={(ref) => {
        //   videoRef = ref;
        // }}
        shouldPlay={false}
        isLooping={false}
        resizeMode="stretch"
        usePoster={false}
        useNativeControls={true}
        style={styles.videoContainer}
        source={{ uri: videoSource }}
        onPlaybackStatusUpdate={(e) => {}}
      />
      <Text style={styles.descriptionContainer}>{bottomText}</Text>
    </View>
  );
};
export default SampleVideoCmp;

const styles = StyleSheet.create({
  mainContainer: {
    width: "28%",
    height: "95%",
    // flex: 0.33,
    marginLeft: "1%",
    marginRight: "1%",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
  },
  videoContainer: { width: "100%", height: "100%", backgroundColor: "gray" },
  descriptionContainer: {
    fontSize: heightToDp(2.5),
    textAlign:'center',
    color: "black",
    marginTop:'1%'
  },
});

import React from "react";
import { View, Text, StyleSheet, Image, FlatList } from "react-native";
import CustomViewBack from "../../../components/CustomViewBack";
import { String } from "../../../constants/String";
import {
  IMAGE_CHILD,
  IMAGE_HOWITS_CHILD,
  IMAGE_HOWITS_ABOUT_2,
  IMAGE_BTN_SIGNUP,
  IMAGE_BTN_BACK,
} from "../../../constants/ImageConst";
import { heightToDp, widthToDp } from "../../../utils/Responsive";
import ImagePress from "../../../components/ImagePress";
import AboutCmp from "../../../components/AboutCmp";
import CustomViewBackTrans from "../../../components/CustomViewBackTrans";
import { WebView } from 'react-native-webview';

const sections = [
  {
    title: "Made for you",
    horizontal: true,
  },
];

const WebViewModal = ({ displayFlag, closeModal }) => {
  const mainView = () => {
    return (
      <WebView style={styles.container} source={{ uri: "https://www.happigenius.com/" }} />
    );
  };
  return (
    <CustomViewBack bgColor="white" borderColor="white" onPress={closeModal}>
    <WebView style={{marginTop:'4%'}} source={{ uri: "https://www.happigenius.com/" }} />
    <ImagePress
    onPress={closeModal}
    source={IMAGE_BTN_BACK}
    style={{
      resizeMode: "contain",
      position:'absolute',
      height: "8%",
      width: "5%",
      margin: "1%",
    }}
  />
    </CustomViewBack>
  );
};
export default WebViewModal;

const styles = StyleSheet.create({
  titleContainer: {
    fontSize: heightToDp(4.8),
    fontWeight: "bold",
    color: "black",
    letterSpacing: 1.2,
  },
  descriptionContainer: {
    fontSize: heightToDp(3.5),
    lineHeight: heightToDp(6),
    color: "black",
  },
});

export const MUSIC_ON_OFF = "music_on_off";

// Login related
export const PREF_LOGIN = "pref_login";
export const PREF_USER_TOKEN = "pref_user_token";
export const PREF_USER_UID = "pref_uid";
export const PREF_CURRENT_LEVEL = "pref_current_level";
export const PREF_USER_POINT = "pref_user_point";

export const PREF_USER_FIRST_NAME = "pref_user_first_name";
export const PREF_EMAIL = "pref_email";

export const PREF_STORE_PREF_NAME = "Happi_Pref";

export const PREF_FIRST_VISIT = "pref_first_visit";

export const PREF_INTERVAL_SECONDS = "pref_interval_seconds";

// Level particular user
export const PREF_LESSON1 = "pref_lesson1";
export const PREF_LESSON2 = "pref_lesson2";
export const PREF_LESSON3 = "pref_lesson3";
export const PREF_LESSON4 = "pref_lesson4";
export const PREF_LESSON5 = "pref_lesson5";
export const PREF_LESSON6 = "pref_lesson6";
export const PREF_LESSON7 = "pref_lesson7";
export const PREF_LESSON8 = "pref_lesson8";
export const PREF_LESSON9 = "pref_lesson9";
export const PREF_LESSON10 = "pref_lesson10";
export const PREF_LESSON11 = "pref_lesson11";
export const PREF_LESSON12 = "pref_lesson12";

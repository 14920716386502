import React, { useRef, useState, useEffect } from "react";
import { View, Text, ActivityIndicator, Dimensions } from "react-native";
import { Video, AVPlaybackStatus, Audio } from "expo-av";
import { useDispatch, useSelector } from "react-redux";
import { request_QuestionList } from "../../redux/reducers/Rq_QuestionList";

import ImagePress from "../../components/ImagePress";
import QuestionScreen from "../childscreens/QuestionScreen";
import { IMAGE_QT_CLOSE } from "../../constants/ImageConst";
import { URL_VIDEO } from "../../constants/ApiConst";
var subtitleResp = require("../../../assets/subtitle.json");
import videoListData from "../../../assets/videoList.json";
import QuestionScreenVideo from "./QuestionScreenVideo";

var subtitleIndex = 0;
var mycount =0;
var PreviousText="";
const VideonScreen = ({ onPress, finishCall, count, videoData, uid }) => {
  const dispatch = useDispatch();
  const videoRef = useRef();
  const [millisecond, setmilliseconds] = useState(0);
  const [videoplayFlag, setVideoPlayFlag] = useState(true);
  const [videoLoader, setVideoLoader] = useState(false);
  const [questionDispFlag, setQuestionDispFlag] = useState(false);
  const [tempCount, setTempCount] = useState(count);

  //===== Video State ==========
  const [currentTimeInDeciSeconds, setCurrentTimeInDeciSeconds] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [videopauseFlag, setVideoPauseFlag] = useState(false);
  const [aletFlag, setAlertFlag] = useState(false);
  //===== End Video State ==========

  // Question state
  const [questionType, setQuestionType] = useState("");
  const [questionTitle, setQuestionTitle] = useState("");
  const [optionA_origAnsFlag, setOptionA_origAnsFlag] = useState("");
  const [optionB_origAnsFlag, setOptionB_origAnsFlag] = useState("");
  const [optionC_origAnsFlag, setOptionC_origAnsFlag] = useState("");
  const [optionD_origAnsFlag, setOptionD_origAnsFlag] = useState("");

  const [optionAData, setOptionAData] = useState("");
  const [optionBData, setOptionBData] = useState("");
  const [optionCData, setOptionCData] = useState("");
  const [optionDData, setOptionDData] = useState("");
  // End Question state

  const [videoFullWatch,setVideoFullWatch] = useState(false);

  const [currentVideoUrl, setCurrentVideoUrl] = useState(
    videoListData[count - 1]
  );
  const [subTitleAvailableFlag, setSubTitleAvailableFlag] = useState(false);

  //  =============== Quetion details ===============
  const [questionList, setQuestionList] = useState([]);

  //End Question Data
  const responseQuestionState = useSelector((state) => {
    // console.log(" -------Level List -------------  "+ JSON.stringify(state.Rq_Levellist));
    return state.Rq_QuestionList;
  });

  useEffect(() => {
    setCurrentVideoUrl(currentVideoUrl);
    console.log("Recive UId :  " + uid);
    console.log("Video data props : " + JSON.stringify(videoData));

    // if (count === 1) {
    //   setSubTitleAvailableFlag(true);
    //   console.log("=== title flag inside===");
    // } else {
    //   setSubTitleAvailableFlag(false);
    // }
    setTempCount(count);
    console.log("Video data props :" + JSON.stringify(videoData));
    console.log("Count : " + count);

    var payloadCreator = new Object();
    payloadCreator["extraParams"] = {
      vid: videoData.vid,
      uid: "36921c0c19",
    };
    dispatch(request_QuestionList(payloadCreator));
  }, [currentVideoUrl]);

  // useEffect(() => {
  //   // if (tempCount === 2) {
  //   //   setSubTitleAvailableFlag(true);
  //   // } else {
  //   //   setSubTitleAvailableFlag(false);
  //   // }
  // }, [tempCount]);

  useEffect(() => {
    if (responseQuestionState.levelQuestionDataArray.length > 0) {
      setQuestionList([...responseQuestionState.levelQuestionDataArray]);
      // setSubTitleAvailableFlag(true);
    } else {
      setQuestionList([]);
      // setSubTitleAvailableFlag(false);
    }
  }, [responseQuestionState.levelQuestionDataArray]);

  useEffect(() => {
    setQuestionList(questionList);
    if (questionList.length > 0) {
      setSubTitleAvailableFlag(true);
    } else {
      setSubTitleAvailableFlag(false);
    }
    // clearData();
    console.log("Question List : " + JSON.stringify(questionList));
  }, [questionList]);

  useEffect(()=>{
    setVideoFullWatch(videoFullWatch)
    if(videoFullWatch === true){
      setTimeout(() => {
        finishCall();
      }, 3000);
    }else{
      console.log("video not finish")
    }
  },[videoFullWatch])

  useEffect(() => {
    setmilliseconds(millisecond);
  }, [millisecond]);

  useEffect(() => {
    
    videoplayFlag
      ? videoRef.current.playAsync()
      : videoRef.current.pauseAsync();
  }, [videoplayFlag]);

  useEffect(() => {
    // setQuestionDispFlag(questionDispFlag);
    if(questionDispFlag){
      mycount = mycount +1;
      console.log("Display count : "+ mycount)
    }
  }, [questionDispFlag]);

 

  useEffect(() => {
   
  }, [
    questionType,
    questionTitle,
    optionA_origAnsFlag,
    optionB_origAnsFlag,
    optionC_origAnsFlag,
    optionD_origAnsFlag,
    optionAData,
    optionBData,
    optionCData,
    optionDData,
  ]);
  useEffect(() => {
    // setSubTitleAvailableFlag(subTitleAvailableFlag);
  }, [subTitleAvailableFlag]);

  const loader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          position: "absolute",
        }}
      >
        <ActivityIndicator size="large" color="white" />
      </View>
    );
  };

  const onPressClickClose = () => {
    console.log("click to close");
    videoRef.current.setPositionAsync(millisecond + 1000);
    setQuestionDispFlag(false);
    setVideoPlayFlag(true);
  };
  const onPressClose = () => {
    videoRef.current.setPositionAsync(millisecond + 1000);
    console.log("Close milliseconds : "+millisecond);
    setQuestionDispFlag(false);
    setVideoPlayFlag(true);
    console.log("click on question close");
  };

  // =================== Subtitle Process ===============


  const updateIndex = (currentTime, starting) => {
    let index = starting,
      startTime,
      endTime,
      nextStartTime,
      prevEndTime;

    while (index >= 0) {
      const curSub = subtitleResp[index];
      const prevSub = subtitleResp[index - 1];
      const nextSub = subtitleResp[index + 1];

      startTime = parseTimeStringToDeciSecond(curSub.startTime);
      endTime = parseTimeStringToDeciSecond(curSub.endTime);
      prevEndTime = prevSub ? parseTimeStringToDeciSecond(prevSub.endTime) : 0;

      console.log(
        "startTime second : " +
          startTime +
          " Original start time : " +
          curSub.startTime
      );
      console.log(
        "endTime second : " + endTime + " Original endTime : " + curSub.endTime
      );
      console.log("prevEndTime second : " + prevEndTime);

      nextStartTime = nextSub
        ? parseTimeStringToDeciSecond(nextSub.startTime)
        : Number.MAX_SAFE_INTEGER;
      console.log("Next start time : " + nextStartTime);
      if (currentTime <= startTime && currentTime <= prevEndTime) {
        index--;
        console.log("Inside minus and index value is : " + index);
      } else if (currentTime >= endTime && currentTime >= nextStartTime) {
        index++;
        console.log("Inside plus and index value is : " + index);
      } else {
        console.log("Inside breack and index value is : " + index);
        break;
      }
    }
    return index < 0 ? 0 : index;
  };

  const parseTimeStringToDeciSecond = (str) => {
    let splitByComma = str.split(",");
    let result = 0.0;
    result = Math.round(parseInt(splitByComma[1]) / 100.0) / 10.0;
    let splitByColon = splitByComma[0].split(":");
    for (let i = 0; i < 3; i++) {
      result += splitByColon[i] * Math.pow(60, 2 - i);
    }
    return Math.floor(result * 10) / 10.0;
  };

  const showSubtitle = () => {
    let currentTime = currentTimeInDeciSeconds;
    let subtitles = questionList;
    if (!subtitles[subtitleIndex]) return null;
    let startTime = parseTimeStringToDeciSecond(
      subtitles[subtitleIndex].start_time
    );
    let endTime = parseTimeStringToDeciSecond(
      subtitles[subtitleIndex].end_time
    );

    console.log("startTime second Sub : " + startTime);
    console.log("endTime second Sub : " + endTime);

    if (currentTime < startTime || currentTime > endTime) {
      subtitleIndex = updateIndex(currentTime, subtitleIndex);
      console.log("Subtitle Index : " + subtitleIndex);
    }
    // subtitleIndex = updateIndex(currentTime, subtitleIndex);

    if (currentTime <= endTime && currentTime >= startTime) {
      // return subtitles[subtitleIndex].text;
      setQuestionTitle(subtitles[subtitleIndex].question);
      setOptionA_origAnsFlag(subtitles[subtitleIndex].option1_answer_falg);
      setOptionB_origAnsFlag(subtitles[subtitleIndex].option2_answer_falg);
      setOptionC_origAnsFlag(subtitles[subtitleIndex].option3_answer_falg);
      setOptionD_origAnsFlag(subtitles[subtitleIndex].option4_answer_falg);
      setOptionAData(subtitles[subtitleIndex].option1);
      setOptionBData(subtitles[subtitleIndex].option2);
      setOptionCData(subtitles[subtitleIndex].option3);
      setOptionDData(subtitles[subtitleIndex].option4);

      console.log("Question : " + subtitles[subtitleIndex].question);
      console.log(
        "option1 Ans : " + subtitles[subtitleIndex].option1_answer_falg
      );
      console.log(
        "option2 Ans : " + subtitles[subtitleIndex].option2_answer_falg
      );
      console.log(
        "option3 Ans : " + subtitles[subtitleIndex].option3_answer_falg
      );
      console.log(
        "option4 Ans : " + subtitles[subtitleIndex].option4_answer_falg
      );

      console.log("option1  : " + subtitles[subtitleIndex].option1);
      console.log("option2 : " + subtitles[subtitleIndex].option2);
      console.log("option3 : " + subtitles[subtitleIndex].option3);
      console.log("option4 : " + subtitles[subtitleIndex].option4);


      return subtitles[subtitleIndex].question;
    } else {
      return null;
    }
  };
  const mytitle = () => {
    const text = showSubtitle();
    if (text !== null) {
      console.log("subtile : " + text);
      if(text !== "" && text !== null){
        if(text !== PreviousText){
          PreviousText = text;
          setQuestionTitle(text);
          setVideoPlayFlag(false);
          setQuestionDispFlag(true)
        }
      }
      // setQuestionTitle(text);
      // setVideoPlayFlag(false);
      // setQuestionDispFlag(true)
    } else {
    }
  };
 
  const callProgress = (data) => {
    console.log("new seconds  : " + (data / 1000).toFixed(0));
    setCurrentTimeInDeciSeconds((data / 1000).toFixed(0));
    mytitle();
  };

  const videoClickOnClose = ()=>{
    console.log("click")
    if(videoFullWatch === true){
      finishCall();
    }else{
      console.log("click onpress")
      onPress();
    }
  }
  // =================== Subtitle Process ===============
  
  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        // backgroundColor: "rgba(94, 490, 243, 1)",
        backgroundColor: "black",
        overflow: "hidden",
      }}
    >
      <Video
        ref={videoRef}
        style={{
          width:'100%',
          height:'100%',
          alignSelf: "center",
          position: "absolute",
          alignItems:'center',
          justifyContent:'center',
          borderColor: "white",
          
          // borderWidth: 4,
          // borderRadius: 10,
          // overflow: "hidden",
        }}

        shouldPlay={videoplayFlag}
        // usePoster={false}
        isLooping={false}
        resizeMode={Video.RESIZE_MODE_CONTAIN}
        useNativeControls={false}
        // source={require("../../../assets/videos/Lets_Play_Pool.mp4")}
        // source={{uri:URL_VIDEO+'060720210236162306940467139341460be12dc5d698.mp4'}}
        // source={{uri:'https://apicdn1.edivo.io/happigenius/Lesson%203_In%20a%20Nutshell.mp4'}}
        // source={{uri:'https://apicdn1.b-cdn.net/happigenius/lesson_3_In_a_nutshell.mp4'}}
        source={{ uri: videoData.video_url }}
        onLoad={() => {
          setVideoLoader(false);
        }}
        onLoadStart={() => {
          setVideoLoader(true);
        }}
        onPlaybackStatusUpdate={(e) => {
          if (e.didJustFinish) {
            // finishCall();
            setVideoFullWatch(true)
          }
          if (subTitleAvailableFlag) {
            setmilliseconds(e.positionMillis);
            console.log("time : " + e.positionMillis);
            callProgress(e.positionMillis);
          }
        }}
      />
      <ImagePress
        // onPress={onPress}
        onPress={videoClickOnClose}
        style={{
          position: "absolute",
          height: "4%",
          width: "2%",
          right: 0,
          // margin: "1%",
          resizeMode: "contain",
        }}
        source={IMAGE_QT_CLOSE}
      />
      {questionDispFlag ? (
        <QuestionScreenVideo
          onPressClose={onPressClose}
          onPressClickClose={onPressClickClose}
          questionTitle={questionTitle}
          optionA_origAnsFlag={optionA_origAnsFlag}
          optionB_origAnsFlag={optionB_origAnsFlag}
          optionC_origAnsFlag={optionC_origAnsFlag}
          optionD_origAnsFlag={optionD_origAnsFlag}
          option1={optionAData}
          option2={optionBData}
          option3={optionCData}
          option4={optionDData}
        />
      ) : null}
      {videoLoader ? loader() : null}
    </View>
  );
};

export default VideonScreen;

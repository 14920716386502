import React from "react";
import { Image, StyleSheet, Text, View, ImageBackground } from "react-native";
import ImagePress from "../../components/ImagePress";
import {
  IMAGE_QT_CLOSE,
  IMAGE_STAR,
  IMAGE_TROPHY,
  IMAGE_SPEECH_BOX_START,
} from "../../constants/ImageConst";
import { String } from "../../constants/String";
import { heightToDp } from "../../utils/Responsive";
import { useDispatch, useSelector } from "react-redux";
import ImagePressOnLoadAnim from "../../components/ImagePressOnLoadAnim";

const AwardScreen = ({ onPress, dispUserName, dispAward, dispAwardPoint }) => {
  const responseUserAwardListDataState = useSelector((state) => {
    // console.log(
    //   " -------Create User Award List -------------  " +
    //     JSON.stringify(state.Rq_UserAwardList)
    // );
    return state.Rq_UserAwardList;
  });

 
  return (
    <View style={{ width: "100%", height: "100%" }}>
      <View style={styles.mainContainer}>
        <Text style={styles.textTitleContainer}>
          {String.award_great_goin}
          {dispUserName}
          {"!"}
        </Text>
        <View style={styles.rowContainer}>
          <View style={styles.splitRawContainer}>
            <ImagePressOnLoadAnim source={IMAGE_STAR} style={styles.imageContainer} />
            <Text style={styles.textSubTitleContainer}>
              {String.award_total_star_collected}
            </Text>
            <Text style={styles.textSubBoldTitleContainer}>
              {responseUserAwardListDataState.point}
            </Text>
          </View>
          <View style={styles.splitRawContainer}>
            <ImagePressOnLoadAnim source={IMAGE_TROPHY} style={styles.imageContainer} />
            <Text style={styles.textSubTitleContainer}>
              {String.award_total_trophies_collected}
            </Text>
            <Text style={styles.textSubBoldTitleContainer}>
              {responseUserAwardListDataState.award}
            </Text>
          </View>
        </View>
      </View>
      <ImagePress
        onPress={onPress}
        style={styles.closeButtonView}
        source={IMAGE_QT_CLOSE}
      />
    </View>
  );
};

export default AwardScreen;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    width: "96%",
    height: "96%",
    margin: "2%",
    borderWidth: 10,
    borderRadius: 10,
    borderColor: "white",
    backgroundColor: "rgba(238, 227, 106, 0.9)",
  },
  rowContainer: {
    width: "100%",
    height: "100%",
    flexDirection: "row",
  },
  splitRawContainer: {
    width: "50%",
    height: "100%",
  },
  closeButtonView: {
    position: "absolute",
    height: "4%",
    width: "2%",
    margin: "1.7%",
    right: "0%",
    resizeMode: "contain",
  },
  imageContainer: {
    width: "35%",
    height: "35%",
    alignSelf: "center",
    resizeMode: "contain",
  },
  textTitleContainer: {
    fontSize: heightToDp(4),
    color: "black",
    padding: "4%",
    textAlign: "center",
    fontWeight: "bold",
  },
  textSubTitleContainer: {
    fontSize: heightToDp(4),
    color: "black",
    marginTop: "2%",
    textAlign: "center",
  },
  textSubBoldTitleContainer: {
    fontSize: heightToDp(4.5),
    color: "black",
    marginTop: "2%",
    textAlign: "center",
    fontWeight: "bold",
  },

  rightArrow: {
    position: "absolute",
    backgroundColor: "#0078fe",
    //backgroundColor:"red",
    width: 20,
    height: 25,
    bottom: 0,
    borderBottomLeftRadius: 25,
    right: -10,
  },

  rightArrowOverlap: {
    position: "absolute",
    backgroundColor: "#eeeeee",
    backgroundColor:"rgba(238, 227, 106, 0.9)",
    width: 20,
    height: 35,
    bottom: -6,
    borderBottomLeftRadius: 18,
    right: -20,
  },

  /*Arrow head for recevied messages*/
  leftArrow: {
    position: "absolute",
    backgroundColor: "#dedede",
    //backgroundColor:"red",
    width: 20,
    height: 25,
    bottom: 0,
    borderBottomRightRadius: 25,
    left: -10,
  },

  leftArrowOverlap: {
    position: "absolute",
    backgroundColor: "#eeeeee",
    //backgroundColor:"green",
    width: 20,
    height: 35,
    bottom: -6,
    borderBottomRightRadius: 18,
    left: -20,
  },
});

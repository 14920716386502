import React from "react";
import { Platform } from "react-native";
import Constants from "expo-constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isBlank, validateObject,checkKeyAssign } from "../../utils/Utility";
import { API_USER_AWARD_LIST_GET, URL_VIDEO} from "../../constants/ApiConst";
import { String } from "../../constants/String";
import axios from "axios";
import { HEADER } from "../../constants/DefaultConst";
const TAG = "Rq_UserAwardList ";
export const request_UserAward_List = createAsyncThunk(
  "Rq_UserAwardList/request_UserAward_List",
  async (payloadCreator, thunkAPI) => {
    const data = {};
    console.log(TAG+" Payload uid : "+payloadCreator.extraParams.uid)
    var config = {
      // '/' +
      // payloadCreator.extraParams.from +
      // '/' +
      // payloadCreator.extraParams.to 
      method: 'get',
      url: API_USER_AWARD_LIST_GET +'?uid='+payloadCreator.extraParams.uid,
      // headers: HEADER,
    };
    
   const response = axios(config)
    .then(function (response) {
      console.log("Rq_Level_videolist Response : "+JSON.stringify(response.data));
      return response;
    })
    .catch(function (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // Logger(TAG, 'error data1 : ' + JSON.stringify(error.response.data));
        // Logger(TAG, 'error status2 : ' + error.response.status);
        // Logger(
        //   TAG,
        //   'error headers3 : ' + JSON.stringify(error.response.headers),
        // );
        return error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // Logger(TAG, 'error request4 : ' + JSON.stringify(error.request));
      } else {
        // Something happened in setting up the request that triggered an Error
        // Logger(TAG, 'error message5 : ' + error.message);
      }
      // Logger(TAG, 'error config6 : ' + JSON.stringify(error.config));
    });
    return response;
  }
);

const setResponsedata = (state, response) => {
  if (validateObject(response.data)) {

    state.award= checkKeyAssign('award',response.data);
    state.point= checkKeyAssign('point',response.data);
    return state;
  }
};



const Rq_UserAwardList = createSlice({
  name: "Rq_UserAwardList",
  initialState: {
    loading: "",
    responseFlag: "",

    award:"",
    point:"",

    errorCode: "",
    errorType: "",
    errorMessage: "",
  },
  reducers: {},
  extraReducers: {
    [request_UserAward_List.pending]: (state, action) => {
      state.loading = "yes";
    },
    [request_UserAward_List.fulfilled]: (state, action) => {
      
      if (validateObject(action.payload.data)) {
        if (validateObject(action.payload.data.result)) {
          state.responseFlag = checkKeyAssign("status", action.payload.data.result);
          
          if (state.responseFlag === "true") {
             setResponsedata(state, action.payload.data.result);
          } else if (state.responseFlag === "false") {
            if (validateObject(action.payload.data.result)) {
                state.errorCode= checkKeyAssign('statusCode',action.payload.data.result);
                state.errorMessage= checkKeyAssign('statusMessage',action.payload.data.result);
              // setErrorData(state, action.payload.result.error);
            } else {
              state.responseFlag = "false";
              state.responseMessage = String.could_not_get_reponse;
            }
          } else {
            state.responseFlag = "false";
            state.responseMessage = String.could_not_get_reponse;
          }
        }
      }
      state.loading = "no";
    },
    [request_UserAward_List.rejected]: (state, action) => {
      state.loading = "no";
      state.responseFlag = "false";
      state.responseMessage = String.could_not_get_reponse;
    },
  },
});
export default Rq_UserAwardList;

import React, { useRef, useEffect, useState } from "react";
import { View, Text, StyleSheet, Alert } from "react-native";
import { String } from "../constants/String";
import {
  IMAGE_BTN_NO,
  IMAGE_BTN_YES,
  IMAGE_BTN_PLAY,
} from "../constants/ImageConst";
import ImagePress from "./ImagePress";
import { useForm, Controller } from "react-hook-form";
import CmpTextInput from "./CmpTextInput";
import { IMAGE_BTN_SUBMIT } from "../constants/ImageConst";
import { heightToDp } from "../utils/Responsive";

const VideoEndMessageInput = ({
  onPressYes,
  onPressNo,
  message,
  layoutHandleFlag,
  onPressSubmitUserInput,
  detectDeviceType,
  isActivity,
}) => {
  const ref_UserInpuet = useRef();
  const [userDataInput, setUserDataInput] = useState("");
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("click");
    if (userDataInput !== "") {
      onPressSubmitUserInput(userDataInput);
    } else {
      if (detectDeviceType === 3 || detectDeviceType === "3") {
        alert(String.user_input_alert_enter_data);
      } else {
        Alert.alert(
          "Ohh!",
          String.user_input_alert_enter_data,
          [{ text: "OK", onPress: () => console.log("OK Pressed") }],
          { cancelable: false }
        );
      }
    }
  };

  const layoutHandle = (handleDataFlag) => {
    if (handleDataFlag === "false") {
      return (
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            height: "20%",
            justifyContent: "center",
          }}
        >
          <ImagePress
            source={IMAGE_BTN_PLAY}
            onPress={onPressYes}
            style={{
              width: "25%",
              height: "100%",
              marginRight: "5%",
              resizeMode: "contain",
            }}
          />
        </View>
      );
    } else if (handleDataFlag === "true") {
      return (
        <View
          style={{
            width: "90%",
            justifyContent: "center",
            marginTop: "-6%",
            height: "50%",
            alignItems: "center",
            marginBottom: "2%",
          }}
        >
          <Controller
            control={control}
            rules={{
              required: {
                value: true,
                message: String.user_input_alert_enter_data,
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <CmpTextInput
                style={{
                  fontSize: heightToDp(4),
                  // justifyContent: "center",
                  backgroundColor: "orange",
                  color: "black",
                  height: "25%",
                  textAlignVertical: "top",
                  backgroundColor: "white",
                  width: "80%",
                  borderRadius: 6,
                  paddingHorizontal: heightToDp(3),
                  // height: heightToDp(5),
                }}
                placeholder={String.user_input_writehere}
                autoCapitalize="none"
                keyboardType="default"
                returnKeyType="done"
                multiline={true}
                secureTextEntry={false}
                refrensKey={ref_UserInpuet}
                onSubmitEditing={onSubmit}
                onBlur={onBlur}
                onChangeText={(text) => {
                  onChange;
                  setUserDataInput(text);
                }}
                value={value}
              />
            )}
            name="userinput"
            defaultValue=""
          />
          {errors.userinput && (
            <Text style={styles.errorTextStyle}>
              {errors.userinput.message}
            </Text>
          )}

          <ImagePress
            onPress={onSubmit}
            source={IMAGE_BTN_SUBMIT}
            style={{
              width: "30%",
              height: "45%",
              resizeMode: "contain",
            }}
          />
        </View>
      );
    }
  };

  return (
    <View
      style={{
        width: "96%",
        height: "96%",
        margin: "1%",
        justifyContent: "center",
        alignSelf: "center",
        // alignItems:'center',
        position: "absolute",
        backgroundColor: "#5EC8F3",
        borderColor: "white",
        position:'absolute',
        borderWidth: 10,
        // justifyContent: "center",
        alignItems: "center",
        borderRadius: 4,
        // backgroundColor: "black",
      }}
    >
      <Text
        style={{
          fontSize: heightToDp(4.5),
          // lineHeight: 30,
          padding: "8%",
          textAlign: "center",
          marginTop: "6%",
          color: "black",
          // fontWeight: "bold",
        }}
      >
        {isActivity?<Text style={{ fontSize: heightToDp(5), fontWeight: "bold" }}>
          {"Suggested Activity\n"}
        </Text>:null}
        {message}
      </Text>
      {layoutHandle(layoutHandleFlag)}
    </View>
  );
};

export default VideoEndMessageInput;

const styles = StyleSheet.create({
  errorTextStyle: {
    color: "red",
    justifyContent: "center",
    width: "80%",
    paddingHorizontal: 10,
    fontSize: 10,
  },
});

const String = {
  question_one: "Select 3 things that are round and always moving?",
  internet_connection_not_avaialable:'Please check your internet connection!',
// start_text:'Hit the PLAY button to start the HappiGenius ',
  // start_text: "Hi ",
  start_text: "Hit the PLAY button to start Lesson ",
  start_text_1: "! I'm, your new friend.\n",
  start_play_text:'Hit the PLAY button to start an exciting journey with me!',

  start_play_text1: "Hit the ",
  start_play_text2:"PLAY ",
  start_play_text3:"button to start \nthe HappiGenius journey. \nI’ll listen with you as ",
  start_play_text4:"Gauri",
  start_play_text5:"\nintroduces the program. ",
  
  start_activity_text: "It's time for our \n",
  start_activity_text1: " activity!\nWatch this video.",
  start_activity_text2: "Watch this video.",
  start_activity_text_welcome:"Here comes the Welcome video for Lesson ",

  // register Welcome screen
  register_wcm_happygen: "Welcome to HappiGenius!",
  register_get_started: "Let's get you started.",
  register_plc_name: "Enter your name",
  register_plc_email: "Enter your email address",
  register_plc_password: "Enter your password",
  register_plc_coupon: "Enter your coupon code (if available)",
  alert_ohh:'Ohh!',
  alert_register_name_email_passwrod:'Name, Email and Password must be required!',
  alert_register_invalid_email:'Please enter a valid email id!',
  alert_register_only_text_allowed:'Only Characters should be allowed!',
  alert_login_invalid_email:'Please enter a valid email id!',
  alert_login_email_must_required:'Email must be required!',
  alert_login_email_password_required:'Email & Password must be required!',

  alert_coupon_code_invalid:'Please enter a valid coupon code!',
  
  // Login welcome screen
  login_wcm_happygen: "Welcome back to HappiGenius!",
  login_plc_email_title: "Email address",

  // register with subscription related
  register_choose_subscription: "Choose subscription",
  register_cancel_anytime_subscription: "Cancel Anytime",
  register_choose_subscription_provide: "\nFor both monthly and yearly subscription, user will receive continued access to the 12-lesson core HappiGenius program." 
  +"In addition, the user will receive new games, videos, fun facts and quizzes that will be regularly added to the program content.",
  register_subscription_monthly: "Monthly",
  register_subscription_yearly: "Yearly",
  register_subscription_price_monthly: "$4.95",
  register_subscription_price_yearly: "$29.95",
  register_sub_cancel_anytime: "CANCEL ANYTIME",
  register_sub_access_all_levels: "ACCESS ALL LEVELS",
  register_subscribe_enjoy: "Subscribe \n and enjoy",
  register_sevenday_free: "7 DAYS FREE!",
  register_btn_paynow: "PAY NOW!",
  register_alert_enter_firstname: "Please enter Your name",
  register_alert_enter_email: "Please enter email",

  // Start now screen
  startnow_allset: "You're all set!",
  startnow_enjoy_sevenday_free: "All levels unlocked! Enjoy 7 days free.",
  startnow_other_description:
    "You will be charged automatically after 7 days.\nYou can choose to cancel your subscription anytime\n from the HELP section.",

  // Unsubscrie screen
  unsubscribe_endson: "Your subscription ends on",
  unsubscribe_endson_date: "October 15, 2021",
  unsubscribe_are_cancel_subscription:
    "Are you sure you want to cancel your subscription?",

  // Side menu
  sidemenu_how_does_work: "How does it work?",
  sidemenu_research: "Research",
  sidemenu_the_book: "The Book",
  sidemenu_insight_teacher: "Insight for Teachers",
  sidemenu_testimonials: "Testimonials",
  sidemenu_privacy_policy: "Privacy Policy",
  sidemenu_terms_of_uses: "Terms of Use",
  sidemenu_login: "Login",
  sidemenu_notification: "Notifications",

  sidemenu_containt_title: "Welcome to HappiGenius!",
  sidemenu_containt_description:
    "\nMeet Broody, an extraordinary brain living an ordinary life.\n\n" +
    "Do you know why that is?\n\n" +
    "Because he struggles with focus and self-kindness, and easily " +
    "gets sad and irritable. But it doesn't have to be this way.\n\n" +
    "Watch the lessons Broody learned that helped him get past his struggles.\n\n"+ 
    "HappiGenius is the story of Broody, and of many children who struggle like him.",

  sidemenu_scr_how_work_abouttitle: "About HappiGenius!",
  sidemenu_scr_how_work_aboutdescription:
    "HappiGenius (HG) is an evidence-based, attention-centric, resilience-powered social and emotional learning solution that is designed to help your child become happier, smarter, calmer, more creative, well-behaved, and better connected.",
    sidemenu_scr_how_work_help_yourchild:'HappiGenius will help your child learn',
    sidemenu_scr_abt_title_1:'BREATHING',
    sidemenu_scr_abt_subtitle_1:'Taking deep relaxing \nbreaths',
    sidemenu_scr_abt_title_2:'FOCUSING',
    sidemenu_scr_abt_subtitle_2:'Building strong \nattention',
    sidemenu_scr_abt_title_3:'FEELINGS',
    sidemenu_scr_abt_subtitle_3:'Accessing uplifting \nemotions',
    sidemenu_scr_abt_title_4:'RESILIENCE',
    sidemenu_scr_abt_subtitle_4:'Reframing negative \nexperiences',
    sidemenu_scr_abt_title_5:'BONDING',
    sidemenu_scr_abt_subtitle_5:'Developing positive \nrelationships',

    sidemenu_scr_how_work_tool: "What tools and practices are part of HappiGenius?",

  sidemenu_scr_how_work_title: "How does HappiGenius work?",
  sidemenu_scr_how_work_description:
    "HG is designed for both classroom and in-home experience.\n" +
    "- The program is divided in 12 sessions.\n" +
    "- Each session takes about 30 to 45 minutes.\n" +
    "- A typical session includes several activities - attention challenges,\n" +
    'breathing practices, social and emotional skills, fun facts, quizzes, and "fun at home" practices.\n' +
    "- Most activities start with a short video.\n" +
    "- Earn stars and trophies as you cruise along.\n" +
    "- The program offers plenty of interaction opportunities. Many activities\n" +
    "can be helpful for adults as well as children. Please participate with your\n" +
    "child (or students) as they progress through the program.",

  congratulation_levelfinsih: "Congratulation\nYou finished your ",
  congratulation_levelfinsih_other: " level",

  // Common message
  could_not_get_reponse:
    "Our Server is not responding. Please try again later.",

  award_message: "\n\n⭐ Click To Collect Your Star ⭐",
  trophy_message: "\n\n🏆 Click To Collect Your Trophy 🏆",
  // trophy_message: "\n\n🏆 Click To Collect Your Trophy 🏆",
  // congratulation_lession:'Congratulations!\nYour just completed a lesson\nto earn your trophy!',
  congratulation_lession:'Congratulations!\nYour just earned a trophy!',
  congratulation_star:'Congratulations!\nYour just earned a star',
  con_left_quat:'"',
  congratulation_next_video:'Next, let\'s watch the video',
  congratulation_next_lesson:'Next, lesson is\n',
  // cometofar_collect_star:'You\'ve come so far!\nCOLLECT A STAR!',
  cometofar_collect_star:'Collect your star and then click on the PLAY button to continue.',
  tropty_collected:'Your 🏆 Trophy has been colleccted!',
  fun_activity: "Did you do at least one fun at home activity?",
  user_input_alert_enter_data: "Please share your thoughts",
  user_input_writehere: "Share your thoughts here",

  // research model
  research_hg_eresearch: "HG-e Research",
  research_hg_eresearch_1:
    "\nHG-e has been piloted and offered at four public schools and one " +
    "private school. We are collaborating with a Fortune 500 company to bring the program to its employees’ families.\n\n" +
    "In collaboration with InSciEd out, Mayo Clinic, and Rochester public School District, we have recently completed the first clinical trial of HG-e among third-grade students at the Gage Elementary Public School, Rochester, MN.",

  research_hg_list_of_studies: "\nList of studies:",

  research_split_left_title_1: "March-June 2021",
  research_split_left_description_1:
    "Pilot Study of HappiGenius Social-Emotional Learning Curriculum in Rochester Public Schools.",
  research_split_right_description_1:
    "This was the first IRB approved clinical trial of HappiGenius-Elementary program in third-grade students at the Gage Elementary School, Rochester MN. The study was conducted in collaboration with Mayo Clinic and InSciEd Out.  The intervention was delivered in person. The study has shown promising efficacy data that is being analyzed and prepared for publication.",

  research_split_left_title_2: "March-June 2021",
  research_split_left_description_2:
    "Pilot evaluation to assess feasibility of HG-e delivery via interactive live video sessions at Frankfurt International School, Germany.",
  research_split_right_description_2:
    "This study enrolled 30 participants (15 parent-child dyads). A total of 11 dyads completed > 80% of the sessions. Qualitative feedback showed promising results with respect to efficacy. The study showed high engagement and feasibility of delivery internationally, along with promising efficacy signal.",

  research_split_left_title_3: "Jan-March 2021",
  research_split_left_description_3:
    "Pilot evaluation to assess feasibility of HG-e delivery using a hybrid approach (online and in-person) at Long fellow Elementary School, Rochester, MN.",
  research_split_right_description_3:
    "This study enrolled 25 third-grade students and was conducted in partnership with a school counselor. The study showed high engagement and feasibility of delivery in classroom setting, both virtually and in person.",

  research_split_left_title_4: "October 2020-January 2021",
  research_split_left_description_4:
    "Pilot evaluation to assess feasibility of HG-e delivery via interactive live video sessions.",
  research_split_right_description_4:
    "This study enrolled 18 participants with 15 participants completing >80% of the sessions. The pilot showed high engagement across a broader age range.\n\n" +
    "Based on the feedback from the above two pilots the program was modified, and two additional pilots were run before launching the first IRB approved clinical trial.",

  research_split_left_title_5: "July-October 2020",
  research_split_left_description_5:
    "Pilot evaluation to assess feasibility of HG-e delivery via interactive live video sessions.",
  research_split_right_description_5:
    "This study enrolled 7 elementary school children in the 12-week program delivered via Zoom. Six participants completed > 80% of the sessions. The study showed high engagement with the program via synchronous live video sessions.",

  thebook_desc:'Read how Broody, an unhappy brain, learns new tips and tricks to become happy. This book is an interactive immersion that will help your child cultivate two essential skills: a strong focus and abundant uplifting emotions. Moody Broody is a poem, a story, and an experience, to help increase curiosity, notice and memorize details, take deep breaths, and access positive feelings. It offers you a fun and simple way to build formative mindfulness and resilience skills.\n\n'+
'Moody Broody is part of HappiGenius, a program co-created by its author, Gauri, to help elementary-school children enhance attention, positive emotions, resilience, social connections, and learning. For more information, please visit ',
thebook_happigenius_url:'https://www.happigenius.com/',
  thebook_desc_happigenius:'happigenius.com.',
  thebook_amazon_url:'https://www.amazon.com/Moody-Broody-learns-how-happy/dp/0999552597/ref=sr_1_1?dchild=1&keywords=gauri+sood+moody+broody&qid=1632768304&s=books&sr=1-1',
  
  privacy_policy_url:"https://app.happigenius.com/privacypolicy",
  terms_of_uses_url:"https://app.happigenius.com/termsofuse",

  insight_teacher_first_selmatters:'SEL (Social and Emotional Learning) Matters',
  insight_teacher_second_selmatters:'Hundreds of rigorous studies show SEL matters. SEL\n'+
  'interventions improve academic outcomes, and are useful for:',
  insight_teacher_third_selteacher:'SEL Insights for Teachers',

  insight_teacher_src2_desc1:'SEL skills can help children with better attitude and behaviors, closer friends, and superior\nacademic performance (by up to 11 percentile points).',
  insight_teacher_src2_desc2:'SEL can have positive impact up to 18-years later on several important outcomes: academics, \nemotional distress, conduct problems, drug use.',
  insight_teacher_src2_desc3:'SEL skills in kindergarten are associated with lower law and order problems, lesser need for \npublic assistance or spending time in a detention facility.',

  insight_teacher_src2_desc4:'We see SEL as not a practice or a program, \n but instead as a way of being.\n\nHere are ten perspectives on how we think about SEL:',

  insight_teacher_src3_desc1:'SEL skills are as important as academic skills, for success at school, in relationships, at the workplace, and beyond.',
  insight_teacher_src3_desc1_sub:'Research shows many students enter and exit school without the social and behavioral skills they need to succeed in life.',

  insight_teacher_src3_desc2:'SEL skills impact every aspect and every stage of a student\'s life.',
  insight_teacher_src3_desc2_sub:'Physical health, mental health, behavior, academic achivement, relationships, problem solving abilities - these are all affected by SEL skills.',

  insight_teacher_src3_desc3:'SEL is a combined effort of students, teachers, staff, counselors, parents, and the community.',
  insight_teacher_src3_desc3_sub:'SEL is best seen as part of an ecosystem that supports the child, rather than as a focused responsibility of SEL teachers to deliver the content and skills.',

  insight_teacher_src3_desc4:'SEL approaches should be inexpensive, time-efficient, and integrated with academic curriculum.',
  insight_teacher_src3_desc4_sub:'SEL approaches that are integrated with academic curriculum, are affordable, and not time intensive will be the most scalable and impactful.',

  insight_teacher_src3_desc5:'Attention and resilience are essential building blocks of SEL.',
  insight_teacher_src3_desc5_sub:'Attention powerfully influences emotions, behavior, relationships, problem solving, and learning; weak attention interferes with improving most other skills.',

  insight_teacher_src3_desc6:'SEL approaches aren\'t just for the classroom; they should be a part of the entire school experience for everyone, everywhere, and every day (in the classroom, cafeteria, play ground, and more).',
  insight_teacher_src3_desc6_sub:'SEL programs offered as a once-a-week standalone classroom "interventions" show only modest positive results in research studies.',

  insight_teacher_src3_desc7:'Implementation quality significantly affects benefits of SEL training.',
  insight_teacher_src3_desc7_sub:'Training staff in implementation of the SEL approaches can improve its impact. SEL approaches should offer equitable and culturally responsive solutions, and involve active family and community participation.',

  insight_teacher_src3_desc8:'Quality SEL approaches should promote positive relationships in multicultural societies, among peers, between teachers and students, and between families and the community.',
  insight_teacher_src3_desc8_sub:'SEL approaches that emphasize relationships are particularly beneficial for self-regulation.',
  
  insight_teacher_src3_desc9:'The most effective SEL approaches are well-designed, evidence-based, follow a developmental and sequential approach, are continuously improved through data driven feedback, and help foster positive habits through creating routines and supporting teachers, staff, parents and other members in their own SEL journey.',
  insight_teacher_src3_desc9_sub:'',

  insight_teacher_src3_desc10:'Eventually, SEL isn\'t just a commitment of the school or district administration. It is engagement of the school, district, state and country\'s leadership at the highest level. ',
  insight_teacher_src3_desc10_sub:'',



  error_not_email:'Please enter a valid email id or password!',
  question_try_again:'Try Again!',

  // First viewtext
  
  firstview_text:'Hi, I am your new friend Broody. Hit the PLAY button to start the HappiGenius journey. I’ll listen with you as Gauri introduces the program.',
  gauri_is_right:'Gauri is right. That sub was really awesome. Let’s get going with lesson 1.',
  click_to_start_lession_1:'Click to Start Lesson 1',

  click_tocollect_simple_star :'Click to collect your star',
  alert_open_funfact_time :'Open and check a FUN-FACT!',
  alert_open_activity_time :'Uh-oh! You missed an activity.',
  alert_click_play_to_continue:'Click on the PLAY button to continue.',
  alert_hope_you_enjoy_activity:'Hope you enjoyed the activity. Click on the PLAY button to continue.',

// Sample Content
  sample_content:'Sample content',
  sample_content_offers_short:'HG-e offers short, engaging videos as starting point for most lessons.',
  sample_content_example:'Here are a few examples:',
  sample_content_integrated:'The skills learned through these videos translate into practices that integrate throughout the day.',
  sample_video_title_1:'BREATHING: Take five deep breaths as you\nbuild your start',
  sample_video_title_2:'FOCUSING: Train your focus with fun\nengaging videos',
  sample_video_title_3:'FEELING: Access uplifting feelings in short\nincremental steps',

  // Award Screen
  award_great_goin:'Great going, ',
  award_total_star_collected:'Total Stars collected',
  award_total_trophies_collected:'Total Trophies collected',

  // Signup
  alert_signup_yourname_max_char:'Your Name Only 50 Characters should be allowed!',
  alert_signup_email_must_required:'Email id must be required!',
  alert_signup_name_must_required:'Name must be required!',
  alert_signup_password_must_required:'Password must be required!',

  // Alert Message
  alert_incomplete_activity:'To complete this activity, \nPlease watch the full Video.',

  // Start new screen msg
  dialog_welcome_tohappygenius : 'Welcome to HappiGenius',
  dialog_welcome_desc : 'Hello, I am Broody! \nWatch this video to get started',
  dialog_gouri_desc:'Gauri',
  dialog_gouri_desc1:' is right!\n That sub was really awesome.\nLet\'s get going with Lesson 1.',

  // funfacts
  funfacts_title:'Fun facts with Broody!',
  // activity
  activity_suggest_title:'Suggested Activity',
  input_succesmsg:'Thanks for sharing!\n\nNow let\'s relax with\n\'Five Deep Breaths.\'',

  toast_message_funfact:'Time for a few FUN FACTS!',
  toast_message_activity:'It\'s ACTIVITY TIME!',
  toast_message_level_change:'ohh! First complete your current level!',
  payment_waiting_msg:'please wait while you are redirected to make payment\n if not redirect after 5 minute then click on continue\n(don\'t press close, otherwise your payment hasbeen cancel)',
  payment_waiting_false_msg:'ohh! Your payment failed.\nPlease try again!',
  payment_pls_wait_msg:'Please wait...',

  start_quote:"\"",

};
export { String };

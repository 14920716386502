import React, { useRef, useEffect, useState } from "react";
import { View, Image, Text, StyleSheet, Alert } from "react-native";
import { useForm, Controller } from "react-hook-form";
import CmpTextInput from "../../components/CmpTextInput";
import RoundBackground from "../../components/RoundBackground";
import { String } from "../../constants/String";
import {
  IMAGE_SEVENDAY_TRIAL,
  IMAGE_PAYNOW,
  IMAGE_SINGLE_CHAR,
  IMAGE_RADIO_ACTIVE,
  IMAGE_RADIO_INACTIVE,
  IMAGE_BTN_LOGIN,
} from "../../constants/ImageConst";
import ImagePress from "../../components/ImagePress";
import RadioView from "../../components/RadioView";
import * as Device from "expo-device";
import {
  BUILD_TYPE_WEB,
  DEFAUL_BUILD_TYPE,
} from "../../constants/DefaultConst";
import { heightToDp } from "../../utils/Responsive";
import ToastCmp from "../../components/ToastCmp";
import NetInfo from "@react-native-community/netinfo";

const LoginWelcome = ({ onPressLogin, onPress }) => {
  const ref_Register_password = useRef();
  const ref_Register_email = useRef();
  const [detectDeviceType, setDetectDeviceType] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [toastFlag, setToastFlag] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [internetFlag, setInternetFlag] = useState("");

  useEffect(() => {
    Device.getDeviceTypeAsync().then((deviceType) => {
      setDetectDeviceType(deviceType);
      if (Device.DeviceType.PHONE === deviceType) {
        console.log("Device Type Phone");
      } else if (Device.DeviceType.TABLET === deviceType) {
        console.log("Device Type TABLET");
      } else if (Device.DeviceType.DESKTOP === deviceType) {
        console.log("Device Type DESKTOP");
      } else if (Device.DeviceType.TV === deviceType) {
        console.log("Device Type TV");
      } else {
        console.log("Device Type Unknown");
      }
    });
  }, []);

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      console.log("Connection type", state.type);
      console.log("Is connected?", state.isConnected);
      setInternetFlag(state.isConnected.toString());
    });
    return () => {
      // Unsubscribe to network state updates
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    setDetectDeviceType(detectDeviceType);
  }, [detectDeviceType]);

  useEffect(() => {
    setEmail(email);
    setPassword(password);
  }, [email, password]);

  useEffect(() => {
    setToastFlag(toastFlag);
  }, [toastFlag]);
  useEffect(() => {
    setToastMessage(toastMessage);
  }, [toastMessage]);

  // const { control, handleSubmit, errors, getValues, reset } = useForm();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (internetFlag === "true") {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if (email !== "" && reg.test(email) !== false && password !== "") {
        if (reg.test(email) !== false) {
          onPressLogin(email, password);
          // onPressLogin(email);
        } else {
          setToastMessage(
            String.alert_ohh + "\n" + String.alert_login_invalid_email
          );
          setToastFlag(true);
        }
      } else {
        if (email === "" && password === "") {
          setToastMessage(
            String.alert_ohh + "\n" + String.alert_login_email_password_required
          );
          setToastFlag(true);
        } else if (email === "") {
          setToastMessage(String.alert_signup_email_must_required);
          setToastFlag(true);
        } else if (reg.test(email) === false) {
          setToastMessage(String.alert_register_invalid_email);
          setToastFlag(true);
        } else if (password === "") {
          setToastMessage(String.alert_signup_password_must_required);
          setToastFlag(true);
        }
      }
    } else {
      setToastFlag(true);
      setToastMessage(String.internet_connection_not_avaialable);
    }
  };

  const onChangeSet = () => {
    return (
      <View
        style={{
          position: "absolute",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Text
          style={{
            color: "black",
            fontWeight: "bold",
            fontSize: heightToDp(2.5),
          }}
        >
          {String.login_wcm_happygen}
        </Text>

        <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: String.register_alert_enter_email,
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "invalid email address",
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <CmpTextInput
              style={{
                fontSize: heightToDp(2),
                justifyContent: "center",
                color: "black",
                height: "10%",
                backgroundColor: "white",
                width: "55%",
                // textAlignVertical: "top",
                borderRadius: 6,
                marginTop: "3%",
                paddingHorizontal: 10,
              }}
              placeholder={String.register_plc_email}
              autoCapitalize="none"
              keyboardType="email-address"
              returnKeyType="next"
              secureTextEntry={false}
              refrensKey={ref_Register_email}
              onSubmitEditing={() => ref_Register_password.current.focus()}
              // onSubmitEditing={handleSubmit(onPressPayNow("hitesh","hitesh@sociosquares.com"))}
              // onSubmitEditing={onSubmit}
              onBlur={onBlur}
              onChangeText={(text) => {
                onChange;
                setEmail(text);
              }}
              value={value}
            />
          )}
          name="email"
          defaultValue=""
        />

        <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: String.register_plc_password,
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <CmpTextInput
              style={{
                fontSize: heightToDp(2),
                justifyContent: "center",
                color: "black",
                height: "10%",
                backgroundColor: "white",
                width: "55%",
                marginTop: "1%",
                borderRadius: 6,
                paddingHorizontal: 10,
                // height: heightToDp(5),
              }}
              placeholder={String.register_plc_password}
              autoCapitalize="none"
              keyboardType="default"
              returnKeyType="done"
              maxLength={15}
              secureTextEntry={true}
              refrensKey={ref_Register_password}
              onSubmitEditing={onSubmit}
              onBlur={onBlur}
              onChangeText={(text) => {
                onChange;
                setPassword(text);
              }}
              value={value}
            />
          )}
          name="password"
          defaultValue=""
        />

        <ImagePress
          source={IMAGE_BTN_LOGIN}
          // onPress={()=>{onPressPayNow("hitesh","hitesh@sociosquares.com")}}
          onPress={onSubmit}
          style={{
            marginTop: "3%",
            width: "27%",
            height: "12%",
            resizeMode: "contain",
          }}
        />
      </View>
    );
  };

  const toastOnPressClose = () => {
    setToastFlag(false);
  };

  return (
    <RoundBackground
      bgcolor="rgba(241, 221, 106, 0.9)"
      onPress={onPress}
      closeDisplay={true}
      borderColor="white"
    >
      {onChangeSet()}
      {toastFlag ? (
        <ToastCmp message={toastMessage} onPress={toastOnPressClose} />
      ) : null}
    </RoundBackground>
  );
};
export default LoginWelcome;

const styles = StyleSheet.create({
  errorTextStyle: {
    color: "red",
    justifyContent: "center",
    width: "55%",
    paddingHorizontal: 10,
    fontSize: 10,
  },
});

import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  Image,
  BackHandler,
  Alert,
  ActivityIndicator,
  FlatList,
  Linking,
  Platform,
  ScrollView,
} from "react-native";
import CustomView from "../../components/CustomView";
import ImagePress from "../../components/ImagePress";
import * as WebBrowser from "expo-web-browser";
import { WebView } from "react-native-webview";
import {
  IMAGE_BTN_SIGNUP,
  IMAGE_BTN_WATCHINTRO,
  IMAGE_BTN_WATCHSAMPLE,
  IMAGE_BROODY_DIALOG,
  IMAGE_BTN_MENU,
  IMAGE_SINGLE_CHAR,
  IMAGE_BTN_LOGIN,
} from "../../constants/ImageConst";
import { String } from "../../constants/String";
import HowDoesWorkModal from "./Models/HowDoesWorkModal";
import SideMenu from "./SideMenu";
import { widthToDp, heightToDp } from "../../utils/Responsive";
import RegisterWelcome from "../childscreens/RegisterWelcome";
import StartNowScreen from "../childscreens/StartNowScreen";
import AllReadySubscribed from "../childscreens/AllReadySubscribed";
import { useDispatch, useSelector } from "react-redux";
import { request_CreateUser } from "../../redux/reducers/Rq_CreateUser";
import { request_CreateUserCouponEntry } from "../../redux/reducers/Rq_UserCouponEntry";
import { request_CreateToken } from "../../redux/reducers/Rq_CreateToken";
import { request_PaymentEntry } from "../../redux/reducers/Rq_PaymentEntry";
import { request_StripeYearly } from "../../redux/reducers/Rq_StripeYearly";
import { request_UserInfo } from "../../redux/reducers/Rq_UserInfo";
import { request_CreatePaymentTempEntry } from "../../redux/reducers/Rq_Create_PaymentEntry";

import { request_LoginUser } from "../../redux/reducers/Rq_LoginUser";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  PREF_EMAIL,
  PREF_LOGIN,
  PREF_USER_FIRST_NAME,
  PREF_USER_POINT,
  PREF_USER_TOKEN,
  PREF_USER_UID,
} from "../../constants/PrefConst";
import IAP from "react-native-iap";
import ResearchModal from "./Models/ResearchModal";
import * as Device from "expo-device";
import InsightsForTeachersModal from "./Models/InsightsForTeachersModal";
import LoginWelcome from "../childscreens/LoginWelcome";
import IntroVideonScreen from "../childscreens/IntroVideonScreen";
import IntroVideonScreenFull from "../childscreens/IntroVideonScreenFull";
import WebViewModal from "./Models/WebViewModal";
import TheBookModal from "./Models/TheBookModal";
import * as font from "expo-font";
import {
  BUILD_TYPE_WEB,
  DEFAUL_BUILD_TYPE,
} from "../../constants/DefaultConst";
import WatchSampleModal from "./Models/WatchSampleModal";
import * as Crypto from "expo-crypto";
import WaitingCmp from "../../components/WaitingCmp";
import TestimonialModal from "./Models/TestimonialModal";
import ToastCmp from "../../components/ToastCmp";
import NetInfo from "@react-native-community/netinfo";
import ImagePressNoAnim from "../../components/ImagePressNoAnim";
import { API_PYMT } from "../../constants/ApiConst";

const items = Platform.select({
  ios: ["com.akg.happigenius.monthly", "com.akg.happigenius.yearly"],
  android: ["com.akg.happigenius.monthlys", "com.akg.happigenius.yearlys"],
});
// android: [
//   // "com.akg.happigenius.weeklys",
//   "com.akg.happigenius.monthlys",
//   "com.akg.happigenius.yearlys",
// ],

let purchaseUpdateSubscription;
let purchaseErrorSubscription;

const sections = [
  {
    title: "Made for you",
    horizontal: true,
  },
];

var tempSubmitUserName = "";
var tempSubmitUserEmail = "";
var tempSubmitUSerPass = "";
var tempSubmitUSerCoupon = "";
var tempSubmitUSerActivationDate = "";
var tempSubmitUserRequestKey = "";

const WelcomeScreen = ({ loginState, token }) => {
  const dispatch = useDispatch();

  const [introvideoDispFlag, setIntroVideoDispFlag] = useState(false);
  const [sampleScreenDispFlag, setSampleScreenDispFlag] = useState(false);

  const [detectDeviceType, setDetectDeviceType] = useState();
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [sidemenuDisplayFlag, setSideMenuDispFlag] = useState(false);
  const [howDoesWorkModalFlag, setHowDoesWorkModalFlag] = useState(false);
  const [researchModalFlag, setResearchModalFlag] = useState(false);
  const [teacherModalFlag, setTeacherModalFlag] = useState(false);
  const [testiMonialModalFlag, setTestiMonialModalFlag] = useState(false);
  const [bookModalFlag, setBookModalFlag] = useState(false);

  // Functionality display flag
  const [signupDisplayFlag, setSignUpDisplayFlag] = useState(false);
  const [loginDisplayFlag, setLoginDisplayFlag] = useState(false);
  // End Functionality display flag

  // ===== Subscription ==================
  const [startNowFlag, setStartNowflag] = useState(false);
  const [subscriptionMonthly, setSubscriptionMonthly] = useState(true);
  const [subscriptionYearly, setSubscriptionYearly] = useState(false);

  const [userSubscribed, setUserSubscribed] = useState(false);

  const [unSubscribedView, setUnSubscribedView] = useState(false);
  const [renewPressFlag, setRenewPressFlag] = useState(false);
  const [cancelPressFlag, setCancelPressFlag] = useState(false);

  const [iapResponse, setIapResponse] = useState([]);
  const [monthlyPrice, setmonthlyPrice] = useState("");
  const [yearlyPrice, setyearlyPrice] = useState("");
  const [monthlyProductId, setMonthlyProductId] = useState("");
  const [yearlyProductId, setYearlyProductId] = useState("");
  const [purchased, setPurchased] = useState(false);

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [coupon, setCoupon] = useState("");

  const [paymentSecureKeyis, setPaymentSecureKeyis] = useState("");
  const [startPaymentStatus, setStartPaymentStatus] = useState(false);
  const [paymentMessage, setPaymentMessage] = useState(
    String.payment_waiting_msg
  );

  const [internetFlag, setInternetFlag] = useState("");
  const [toastFlag, setToastFlag] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [waitingUIFlag, setWaitingUIFlag] = useState(false);
  // ===== End Subscription ==================

  const responseCreateTokenState = useSelector((state) => {
    // console.log(" -------Token create -------------  "+ JSON.stringify(state.Rq_CreateToken));
    return state.Rq_CreateToken;
  });
  const responseCreateUserState = useSelector((state) => {
    // console.log(" -------User create -------------  "+ JSON.stringify(state.Rq_CreateUser));
    return state.Rq_CreateUser;
  });

  const responseUserInfoState = useSelector((state) => {
    // console.log(" -------User Info -------------  "+ JSON.stringify(state.Rq_UserInfo));
    return state.Rq_UserInfo;
  });

  const responseUserLoginState = useSelector((state) => {
    // console.log(" -------User Login -------------  "+ JSON.stringify(state.Rq_LoginUser));
    return state.Rq_LoginUser;
  });

  const responsePaymentEntryState = useSelector((state) => {
    // console.log(" -------Payment entry -------------  "+ JSON.stringify(state.Rq_LoginUser));
    return state.Rq_PaymentEntry;
  });

  // var payloadCreator = new Object();
  // payloadCreator["extraParams"] = {
  //   request_key: "write your request key",
  // };
  // dispatch(request_PaymentEntry(payloadCreator));

  useEffect(() => {
    font.loadAsync({
      "BalooTammudu2-Regular": require("../../../assets/fonts/BalooTammudu2-Regular.ttf"),
      "BalooTammudu2-Medium": require("../../../assets/fonts/BalooTammudu2-Medium.ttf"),
      "BalooTammudu2-SemiBold": require("../../../assets/fonts/BalooTammudu2-SemiBold.ttf"),
      "BalooTammudu2-Bold": require("../../../assets/fonts/BalooTammudu2-Bold.ttf"),
      "BalooTammudu2-ExtraBold": require("../../../assets/fonts/BalooTammudu2-ExtraBold.ttf"),
    });
  }, []);

  useEffect(() => {
    if (purchased === true) {
      callAPI_Signup(
        tempSubmitUserName,
        tempSubmitUserEmail,
        tempSubmitUSerPass
      );
    }
  }, [purchased]);

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      console.log("Connection type", state.type);
      console.log("Is connected?", state.isConnected);
      setInternetFlag(state.isConnected.toString());
    });
    return () => {
      // Unsubscribe to network state updates
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    setToastFlag(toastFlag);
  }, [toastFlag]);
  useEffect(() => {
    setToastMessage(toastMessage);
  }, [toastMessage]);
  const toastOnPressClose = () => {
    setToastFlag(false);
  };

  useEffect(() => {
    Device.getDeviceTypeAsync().then((deviceType) => {
      setDetectDeviceType(deviceType);
      if (Device.DeviceType.PHONE === deviceType) {
        console.log("Device Type Phone");
      } else if (Device.DeviceType.TABLET === deviceType) {
        console.log("Device Type TABLET");
      } else if (Device.DeviceType.DESKTOP === deviceType) {
        console.log("Device Type DESKTOP");
      } else if (Device.DeviceType.TV === deviceType) {
        console.log("Device Type TV");
      } else {
        console.log("Device Type Unknown");
      }
    });
  }, []);

  useEffect(() => {
    console.log("welcome Token : " + token);
    console.log(
      "welcome Token response : " + responseCreateTokenState.usertoken
    );
    if (token === "" && responseCreateTokenState.usertoken === "") {
      console.log(
        "welcome Token response1 : " + responseCreateTokenState.usertoken
      );
      dispatch(request_CreateToken());
    }
  }, []);

  useEffect(() => {
    if (detectDeviceType !== 3 || detectDeviceType !== "3") {
      try {
        IAP.initConnection()
          .catch(() => {
            console.log("error connecting to store...");
          })
          .then(() => {
            IAP.getSubscriptions(items)
              .catch(() => {
                console.log("error finding items");
              })
              .then((res) => {
                if (res !== null && res.length > 0) {
                  let tempResponse = [];
                  tempResponse.push(...res);
                  console.log("response : " + JSON.stringify(tempResponse));
                  setIapResponse(tempResponse);
                  setmonthlyPrice(tempResponse[0].localizedPrice);
                  setyearlyPrice(tempResponse[1].localizedPrice);
                  setMonthlyProductId(tempResponse[0].productId);
                  setYearlyProductId(tempResponse[1].productId);
                  console.log("IAP data : " + tempResponse[0].productId);
                  console.log("IAP data : " + tempResponse[1].productId);
                  console.log(
                    "IAP Monthly Price : " + tempResponse[0].localizedPrice
                  );
                  console.log(
                    "IAP Yearly Price : " + tempResponse[1].localizedPrice
                  );
                }
                console.log("response : " + JSON.stringify(res));
              });
          });

        purchaseErrorSubscription = IAP.purchaseErrorListener((error) => {
          if (!(error["responseCode"] === "2")) {
            Alert.alert(
              "You cancel payment.",
              "Ohh!" + error["code"] + "error : " + JSON.stringify(error)
            );
          }
        });
        purchaseUpdateSubscription = IAP.purchaseUpdatedListener((purchase) => {
          const receipt = purchase.transactionReceipt;
          Alert.alert("Receipt data : " + JSON.stringify(receipt));
          if (receipt) {
            if (Platform.OS === "ios") {
              // Alert.alert("Receipt data : " + JSON.stringify(receipt));
              try {
                if (receipt !== null && receipt !== "") {
                  // validate(receipt, purchase);
                  setLoadingFlag(true);
                  const params = {
                    "receipt-data": receipt,
                    password: "fd3c0e1dc2e140a38ff4b0401c330beb",
                    "exclude-old-transactions": false,
                  };
                  const options = {
                    method: "POST",
                    body: JSON.stringify(params),
                  };

                  try {
                    fetch(
                      "https://buy.itunes.apple.com/verifyReceipt",
                      options
                    ).then((response) => {
                      const respjson = response.json();

                      if (
                        respjson.status === "21007" ||
                        respjson.status === 21007
                      ) {
                        try {
                          fetch(
                            "https://sandbox.itunes.apple.com/verifyReceipt",
                            options
                          ).then((responseSand) => {
                            const respSandjson = responseSand.json();
                            if (respSandjson !== null && respSandjson !== "") {
                              setLoadingFlag(false);
                              IAP.finishTransaction(purchase, false);
                              setPurchased(true);
                            } else {
                              setLoadingFlag(false);
                            }
                          });
                        } catch (error) {
                          setLoadingFlag(false);
                          console.error(error);
                        }
                      } else {
                        setLoadingFlag(false);
                        IAP.finishTransaction(purchase, false);
                        setPurchased(true);
                      }
                    });

                    // if we get 21007 stus then validate reciept with sandbox
                  } catch (error) {
                    setLoadingFlag(false);
                    console.error(error);
                  }
                }
                // IAP.validateReceiptIos(
                //   {
                //     "receipt-data": receipt,
                //     password: "fd3c0e1dc2e140a38ff4b0401c330beb",
                //     "exclude-old-transactions": false,
                //   },
                //   true
                // );

                // IAP.finishTransaction(purchase, false);
                // setPurchased(true);
              } catch (error) {}
            } else {
              IAP.finishTransaction(purchase, false);
              setPurchased(true);

              // callAPI_Signup(
              //   tempSubmitUserName,
              //   tempSubmitUserEmail,
              //   tempSubmitUSerPass
              // );
            }
          }
        });

        return () => {
          try {
            purchaseUpdateSubscription.remove();
          } catch (error) {}
          try {
            purchaseErrorSubscription.remove();
          } catch (error) {}
          try {
            IAP.endConnection();
          } catch (error) {}
        };
      } catch (error) {
        console.log("error connecting to store..." + JSON.stringify(error));
      }
    }
  }, []);

  const validate = async (receipt, purchase) => {
    setLoadingFlag(true);
    await Crypto.digestStringAsync(Crypto.CryptoEncoding.BASE64, receipt).then(
      (resp) => {
        const params = {
          "receipt-data": resp,
          password: "fd3c0e1dc2e140a38ff4b0401c330beb",
          "exclude-old-transactions": false,
        };
        const options = {
          method: "POST",
          body: JSON.stringify(params),
        };

        try {
          fetch("https://buy.itunes.apple.com/verifyReceipt", options).then(
            (response) => {
              const respjson = response.json();

              if (respjson.status === "21007" || respjson.status === 21007) {
                try {
                  fetch(
                    "https://sandbox.itunes.apple.com/verifyReceipt",
                    options
                  ).then((responseSand) => {
                    const respSandjson = responseSand.json();
                    if (respSandjson !== null && respSandjson !== "") {
                      setLoadingFlag(false);
                      IAP.finishTransaction(purchase, false);
                      setPurchased(true);
                    } else {
                      setLoadingFlag(false);
                    }
                  });
                } catch (error) {
                  setLoadingFlag(false);
                  console.error(error);
                }
              } else {
                setLoadingFlag(false);
                IAP.finishTransaction(purchase, false);
                setPurchased(true);
              }
            }
          );

          // if we get 21007 stus then validate reciept with sandbox
        } catch (error) {
          setLoadingFlag(false);
          console.error(error);
        }
      }
    );
  };

  useEffect(() => {
    setIapResponse(iapResponse);
    console.log("IAP Response : " + JSON.stringify(iapResponse));
  }, [iapResponse]);

  useEffect(() => {
    setmonthlyPrice(monthlyPrice);
  }, [monthlyPrice]);

  useEffect(() => {
    setyearlyPrice(yearlyPrice);
  }, [yearlyPrice]);

  useEffect(() => {
    setUserEmail(userEmail);
    setUserName(userName);
  }, [userName, userEmail]);

  useEffect(() => {
    const backAction = () => {
      setHowDoesWorkModalFlag(false);
      setResearchModalFlag(false);
      setTeacherModalFlag(false);
      return true;
    };
    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backAction
    );
    return () => backHandler.remove();
  }, []);

  useEffect(() => {
    if (responseCreateUserState.responseFlag === "true") {
      // displayAlert("signup Response RECEIVE ","Reponse name : "+responseCreateUserState.first_name)
      setSignUpDisplayFlag(false);
      setStartNowflag(false);

      console.log("First user Login : " + responseCreateUserState.first_name);

      savePref(PREF_USER_UID, responseCreateUserState.uid);
      savePref(PREF_USER_TOKEN, responseCreateUserState.usertoken);
      savePref(PREF_USER_FIRST_NAME, responseCreateUserState.first_name);
      savePref(PREF_EMAIL, responseCreateUserState.email);
      savePref(PREF_USER_POINT, responseCreateUserState.point);
      savePref(PREF_LOGIN, "true");
      loginState();

      callCouponCreateEntry(responseCreateUserState.uid);
      // console.log("user created ");

      // var payloadCreator = new Object();
      // payloadCreator["extraParams"] = {
      //   usertoken: responseCreateUserState.usertoken,
      //   uid: responseCreateUserState.uid,
      // };
      // dispatch(request_UserInfo(payloadCreator));
      // loginState();
    } else if (responseCreateUserState.responseFlag === "false") {
      setLoadingFlag(false);
      // alert(String.error_not_email);
    }
  }, [
    responseCreateUserState.responseFlag === "true" ||
      responseCreateUserState.responseFlag === "false",
  ]);

  const displayAlert = (title, msg) =>
    Alert.alert(title, msg, [
      { text: "OK", onPress: () => console.log("OK Pressed") },
    ]);

  useEffect(() => {
    if (responseUserLoginState.responseFlag === "true") {
      setLoginDisplayFlag(false);
      setStartNowflag(false);
      savePref(PREF_USER_UID, responseUserLoginState.uid);
      savePref(PREF_USER_TOKEN, responseUserLoginState.usertoken);
      savePref(PREF_USER_FIRST_NAME, responseUserLoginState.first_name);
      savePref(PREF_EMAIL, responseUserLoginState.email);
      savePref(PREF_USER_POINT, responseUserLoginState.point);
      savePref(PREF_LOGIN, "true");
      setLoadingFlag(false);
      loginState();
      // if((responseUserLoginState.monthly_subscribe_end_date !=="")||(responseUserLoginState.yearly_subscribe_end_date !=="")){
      //   setLoginDisplayFlag(false);
      //   setStartNowflag(true);
      //   savePref(PREF_USER_UID, responseUserLoginState.uid);
      //   savePref(PREF_USER_TOKEN, responseUserLoginState.usertoken);
      //   savePref(PREF_USER_FIRST_NAME, responseUserLoginState.first_name);
      //   savePref(PREF_EMAIL, responseUserLoginState.email);
      //   savePref(PREF_USER_POINT, responseUserLoginState.point);
      //   setLoadingFlag(false);
      //   loginState();
      // }else if((responseUserLoginState.monthly_subscribe_end_date ==="")&&(responseUserLoginState.yearly_subscribe_end_date ==="")){
      //   console.log("not subscribe");
      //   setLoadingFlag(false);
      // }else{
      //   console.log("not subscribe");
      //   setLoadingFlag(false);
      // }
    } else if (responseUserLoginState.responseFlag === "false") {
      setLoadingFlag(false);
      setToastFlag(true);
      setToastMessage(String.error_not_email);
      // alert(String.error_not_email);
    }
  }, [responseUserLoginState.responseFlag]);

  useEffect(() => {
    if (responseUserInfoState.responseFlag === "true") {
      console.log(
        "user info welcome status : " + responseUserInfoState.responseFlag
      );
      console.log("user info welcome ");
      setLoadingFlag(false);
    }
  }, [
    responseUserInfoState.responseFlag === "true" ||
      responseUserInfoState.responseFlag === "false",
  ]);

  useEffect(() => {
    if (startPaymentStatus === true) {
      const timer = setTimeout(() => {
        callAPI_PaymentStatus();
      }, 40000);
      return () => clearTimeout(timer);
    }
  }, [startPaymentStatus]);

  useEffect(() => {
    if (responsePaymentEntryState.responseFlag === "true") {
      if (responsePaymentEntryState.payment_status === "unknown") {
        if (startPaymentStatus) {
          callAPI_PaymentStatus();
        }
      } else if (responsePaymentEntryState.payment_status === "true") {
        setWaitingUIFlag(false);
        setStartPaymentStatus(false);
        callAPI_Signup(
          tempSubmitUserName,
          tempSubmitUserEmail,
          tempSubmitUSerPass
        );
      } else if (responsePaymentEntryState.payment_status === "false") {
        setPaymentMessage(String.payment_waiting_false_msg);
      }
    } else {
      if (startPaymentStatus) {
        callAPI_PaymentStatus();
      }
    }
  }, [responsePaymentEntryState]);

  const callAPI_PaymentStatus = () => {
    if (internetFlag === "true") {
      var payloadCreator = new Object();
      payloadCreator["extraParams"] = {
        request_key: tempSubmitUserRequestKey,
      };
      dispatch(request_PaymentEntry(payloadCreator));
    } else {
      setToastMessage(String.internet_connection_not_avaialable);
      setToastFlag(true);
    }
  };

  async function savePref(key, value) {
    try {
      await AsyncStorage.setItem(key, value);
    } catch (error) {}
  }

  const onPressSideMenuButton = () => {
    if (sidemenuDisplayFlag) {
      setSideMenuDispFlag(false);
    } else {
      setSideMenuDispFlag(true);
    }
  };

  const closeSideMenu = () => {
    setSideMenuDispFlag(false);
  };

  const onPressHowDoesItWork = () => {
    console.log("onPress How Does It Work");
    if (!howDoesWorkModalFlag) {
      setHowDoesWorkModalFlag(true);
    }
    closeSideMenu();
  };
  const onPressResearch = () => {
    console.log("onPress Research");
    if (!researchModalFlag) {
      setResearchModalFlag(true);
    }
    closeSideMenu();
  };
  const [result, setResult] = useState(null);
  const _handlePressButtonAsync = async () => {
    if (detectDeviceType === 3 || detectDeviceType === "3") {
      let result = await WebBrowser.openBrowserAsync(
        "https://www.happigenius.com/"
      );
      setResult(result);
    } else {
    }
  };

  const _handlePressToOpenWindow = async (
    name,
    email,
    password,
    secureKey,
    stype
  ) => {
    setWaitingUIFlag(true);
    let result = await WebBrowser.openBrowserAsync(
      API_PYMT + secureKey + "&subscrib_type=" + stype
    );
    setResult(result);
    console.log("Browser Response : " + JSON.stringify(result));
  };

  const _handlePressButtonPAYMENT = async (name, email, password) => {
    if (detectDeviceType === 3 || detectDeviceType === "3") {
      await Crypto.digestStringAsync(
        Crypto.CryptoDigestAlgorithm.SHA256,
        name + email + password + Date().toLocaleString()
      ).then((resp) => {
        console.log("response is : " + resp);
        setPaymentSecureKeyis(resp);
        var temptype = "";
        if (subscriptionYearly) {
          temptype = "yearly";
        } else if (subscriptionMonthly) {
          temptype = "monthly";
        }
        tempSubmitUserName = name;
        tempSubmitUserEmail = email;
        tempSubmitUSerPass = password;
        tempSubmitUserRequestKey = resp;

        var payloadCreator = new Object();
        payloadCreator["params"] = {
          secure_payment_key: resp,
          subscrib_type: temptype,
        };

        dispatch(request_CreatePaymentTempEntry(payloadCreator));
        setStartPaymentStatus(true);
        _handlePressToOpenWindow(name, email, password, resp, temptype);
      });
      console.log("Recieve Name Browser : " + name);
      console.log("Recieve Email Browser: " + email);
      console.log("Recieve Password Browser: " + password);

      // cookie read
      // if cookie exist and it's value is according to our cookie those are set in callback.php file.

      // Method 2
      // var timestamp = define current timestamp
      // https://apils.happigenius.com/v1.0/pymt/create-checkout-session.php?timestamp
      // append timestamp in create-checkout-session.php file at the end of success url
      // https://apils.happigenius.com/v1.0/pymt/checkpytmstatus.php?timestamp this api call in timer till the respons get success or false

      // let result = await WebBrowser.openBrowserAsync("https://apils.happigenius.com/v1.0/pymt/create-checkout-session.php?");
      // setResult(result);
      // console.log("Browser Response : "+ JSON.stringify(result));
    } else {
    }
  };

  const onPressTheBook = () => {
    console.log("onPress The Book");
    // if (detectDeviceType === 3 || detectDeviceType === "3") {
    //   _handlePressButtonAsync();
    // } else {
    //   setBookModalFlag(true);
    // }
    // closeSideMenu();

    if (!bookModalFlag) {
      setBookModalFlag(true);
    }
    closeSideMenu();
  };

  const onPressInsightTeacher = () => {
    console.log("onPress Insight Teacher");
    if (!teacherModalFlag) {
      setTeacherModalFlag(true);
    }
    closeSideMenu();
  };
  const onPressTestimonials = () => {
    console.log("onPress Testimonials");
    if (!testiMonialModalFlag) {
      setTestiMonialModalFlag(true);
    }
    closeSideMenu();
  };
  const onPressNotification = () => {
    console.log("onPress Notification");
    closeSideMenu();
  };

  const onPressButtonWatchIntro = () => {
    console.log("onPress Button WatchIntro");
    setIntroVideoDispFlag(true);
  };

  const onPressCloseButtonIntroVideoView = () => {
    setIntroVideoDispFlag(false);
  };

  const onPressButtonWatcSample = () => {
    setSampleScreenDispFlag(true);
    console.log("onPress Button WatcSample");
    // alert("Sample video not available");
  };
  const onPressCloseButtonWatchSample = () => {
    setSampleScreenDispFlag(false);
  };

  const onPressButtonWatchSignUp = () => {
    console.log("Signup press");
    // _handlePressButtonPAYMENT();
    // dispatch(request_StripeYearly());
    closeSideMenu();
    if (userSubscribed) {
      if (!unSubscribedView) {
        setUnSubscribedView(true);
      }
    } else {
      if (signupDisplayFlag === false) {
        console.log("Recive user info token " + token);
        var g1 = new Date();
        var g2 = new Date("2021-10-29");
        var g3 = new Date("2021-9-28");
        console.log("Date 1 : ", g1);
        console.log("Date 2 : ", g2);
        console.log("Date 3 : ", g3);

        if (g1.getTime() > g2.getTime()) {
          console.log(`Date g1  ${g1} is greater then Date g2 ${g2}`);
        } else {
          console.log(`Date g2  ${g2} is greater then Date g1 ${g1}`);
        }

        if (g1.getTime() > g3.getTime()) {
          console.log(`Date g1  ${g1} is greater then Date g3 ${g3}`);
        } else {
          console.log(`Date g3  ${g3} is greater then Date g1 ${g1}`);
        }
        setSignUpDisplayFlag(true);
      }
    }
  };
  const onPressButtonWatchLogin = () => {
    console.log("Login press");
    if (!loginDisplayFlag) {
      setLoginDisplayFlag(true);
    }
    closeSideMenu();
  };
  const onPressPrivacyPolicy = async () => {
    console.log("Privacy Policy");
    if (DEFAUL_BUILD_TYPE === BUILD_TYPE_WEB) {
      let result = await WebBrowser.openBrowserAsync(String.privacy_policy_url);
    } else {
      Linking.openURL(String.privacy_policy_url);
    }
    closeSideMenu();
  };

  const onPressTermsOfUSes = async () => {
    console.log("Terms of Uses");
    if (DEFAUL_BUILD_TYPE === BUILD_TYPE_WEB) {
      let result = await WebBrowser.openBrowserAsync(String.terms_of_uses_url);
    } else {
      Linking.openURL(String.terms_of_uses_url);
    }
    closeSideMenu();
  };

  const closeHowDoesWorkModal = () => {
    setHowDoesWorkModalFlag(false);
  };
  const closeResearchModal = () => {
    setResearchModalFlag(false);
  };
  const closeTeacherModal = () => {
    setTeacherModalFlag(false);
  };
  const closeWebViewModal = () => {
    setBookModalFlag(false);
  };
  const closeTestiMonialModal = () => {
    setTestiMonialModalFlag(false);
  };
  const closeLoginModal = () => {
    setLoginDisplayFlag(false);
  };
  const closeTheBookModal = () => {
    setBookModalFlag(false);
  };

  //====== Signup and subscription

  const callCouponCreateEntry = (tempuid) => {
    if (coupon.length !== 0) {
      var payloadCreatorCoupon = new Object();
      payloadCreatorCoupon["params"] = {
        uid: tempuid,
        coupon: coupon,
      };
      dispatch(request_CreateUserCouponEntry(payloadCreatorCoupon));
    }
  };

  const callAPI_Signup = (tname, temail, tpassword) => {
    // displayAlert("signup API RECEIVE ","Name : "+tname+" email: "+temail+" passowrd: "+tpassword)
    let today = new Date();
    let dates =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    console.log("Current date is : " + dates);
    if (
      tname !== null &&
      tname !== undefined &&
      tname !== "" &&
      temail !== null &&
      temail !== undefined &&
      temail !== "" &&
      tpassword !== null &&
      tpassword !== undefined &&
      tpassword !== ""
    ) {
      setLoadingFlag(true);

      var payloadCreator = new Object();
      // if (subscriptionMonthly) {
      //   payloadCreator["params"] = {
      //     usertoken: token,
      //     email: temail,
      //     first_name: tname,
      //     password: tpassword,
      //     user_type: "general",
      //     monthly_subscribe_date: dates.toString(),
      //   };
      // } else {
      //   payloadCreator["params"] = {
      //     usertoken: token,
      //     email: temail,
      //     first_name: tname,
      //     password: tpassword,
      //     user_type: "general",
      //     yearly_subscribe_date: dates.toString(),
      //   };
      // }
      payloadCreator["params"] = {
        usertoken: token,
        email: temail,
        first_name: tname,
        password: tpassword,
        user_type: "general",
        monthly_subscribe_date: dates.toString(),
      };

      dispatch(request_CreateUser(payloadCreator));
      console.log("monthly select flag  : " + subscriptionMonthly.toString());
      console.log("yearly select flag  : " + subscriptionYearly.toString());
    } else {
      AlertDisplay();
      console.log("name and email is required");
    }
  };

  const callAPI_LogIn = (email, password) => {
    console.log("Call APi login");
    if (email !== "") {
      setLoadingFlag(true);
      var payloadCreator = new Object();
      payloadCreator["params"] = {
        email: email,
        password: password,
        user_type: "general",
      };
      dispatch(request_LoginUser(payloadCreator));
    }
  };

  const onPressPayNow = (name, email, password, coupon) => {
    setUserName(name);
    setUserEmail(email);
    setPassword(password);
    setCoupon(coupon);

    tempSubmitUserName = name;
    tempSubmitUserEmail = email;
    tempSubmitUSerPass = password;
    tempSubmitUSerCoupon = coupon;

    if (internetFlag === "true") {
      if (DEFAUL_BUILD_TYPE === BUILD_TYPE_WEB) {
        console.log("Web call");

        if (coupon.length !== 0) {
          // When we received coupon from developer side then we directly call signup api
          callAPI_Signup(name, email, password);
        } else {
          // Handle payment using stripe payment gatway
          _handlePressButtonPAYMENT(name, email, password);
        }
        // callAPI_Signup(name,email,password)
      } else {
        if (coupon.length !== 0) {
          callAPI_Signup(name, email, password);
        } else {
          if (subscriptionMonthly) {
            IAP.requestSubscription(monthlyProductId);
          } else {
            IAP.requestSubscription(yearlyProductId);
          }
        }
      }
      // callAPI_Signup(name,email)

      console.log("on Press PayNow");
      console.log(
        "Recive user info : " +
          name +
          " email : " +
          email +
          " password : " +
          password
      );
      console.log("Recive user info token " + token);
    } else if (internetFlag === "false") {
      setToastMessage(String.internet_connection_not_avaialable);
      setToastFlag(true);
    }
  };

  const onPressLogin = (email, password) => {
    console.log("On Press Login : " + email);
    console.log("On Press Login password : " + password);

    setWaitingUIFlag(false);
    setUserEmail(email);
    setPassword(password);
    callAPI_LogIn(email, password);
  };
  const onPressPayNowRenew = () => {
    console.log("on Press PayNow Renew");
    setRenewPressFlag(false);
    setCancelPressFlag(false);
    setUnSubscribedView(false);
  };
  const onPressStartNow = () => {
    setStartNowflag(false);
    loginState();
  };
  const onPressCloseHelpDialog = () => {
    setSignUpDisplayFlag(false);
    setLoginDisplayFlag(false);
  };
  const onPressSubscriptionMonthly = () => {
    setSubscriptionMonthly(true);
    setSubscriptionYearly(false);
    console.log("click on onPressSubscription Monthly");
  };
  const onPressSubscriptionYearly = () => {
    setSubscriptionMonthly(false);
    setSubscriptionYearly(true);
    console.log("click on onPressSubscription Yearly");
  };
  const onPressCloseAllReadySubscribed = () => {
    setUnSubscribedView(false);
    setRenewPressFlag(false);
    setCancelPressFlag(false);
  };
  const onPressCancelSubscription = () => {
    console.log("on Press Cancel subscription");
    setCancelPressFlag(true);
  };
  const onPressRenewSubscription = () => {
    console.log("on Press Renew subscription");
    setRenewPressFlag(true);
  };
  const onPressCloseCancelView = () => {
    setCancelPressFlag(false);
  };
  //====== End Signup and subscription

  const onPressClosePaymentRedirect = () => {
    setWaitingUIFlag(false);
    setStartPaymentStatus(false);
  };
  const onPressCloseSuccessPaymentRedirect = () => {
    setStartPaymentStatus(false);
    setPaymentMessage(String.payment_pls_wait_msg);
    callAPI_PaymentStatus();
  };

  const AlertDisplay = () =>
    Alert.alert(
      "Ohh!",
      "Name and Email must be required!",
      [{ text: "OK", onPress: () => console.log("OK Pressed") }],
      { cancelable: false }
    );

  const loader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          position: "absolute",
          backgroundColor: "white",
        }}
      >
        <ActivityIndicator size="large" color="orange" />
      </View>
    );
  };

  const mainView = () => {
    return (
      <View style={styles.mainContainer}>
        <ImagePress
          source={IMAGE_BTN_MENU}
          onPress={onPressSideMenuButton}
          style={{
            alignSelf: "flex-end",
            height: "10%",
            width: "6%",
            resizeMode: "contain",
          }}
        />
        <View style={{ width: "100%", height: "100%" }}>
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              height: "66%",
              marginBottom: "4%",
            }}
          >
            <View
              style={{
                flexDirection: "column",
                width: "40%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                source={IMAGE_BROODY_DIALOG}
                style={{ resizeMode: "contain", width: "80%", height: "50%" }}
              />
              <Image
                source={IMAGE_SINGLE_CHAR}
                style={{
                  resizeMode: "contain",
                  width: "70%",
                  height: "50%",
                }}
              />
            </View>

            <View
              style={{
                justifyContent: "center",
                width: "60%",
                height: "100%",
                marginLeft: "5%",
              }}
            >
              <Text style={styles.titleContainer}>
                {String.sidemenu_containt_title}
              </Text>
              <Text style={styles.descriptionContainer}>
                {String.sidemenu_containt_description}
              </Text>
            </View>
          </View>

          <View style={styles.rowButtonContainer}>
            <ImagePressNoAnim
              onPress={onPressButtonWatchIntro}
              source={IMAGE_BTN_WATCHINTRO}
              style={styles.buttonContainer}
            />
            <ImagePressNoAnim
              onPress={onPressButtonWatcSample}
              source={IMAGE_BTN_WATCHSAMPLE}
              style={styles.buttonContainer}
            />
            <ImagePressNoAnim
              onPress={onPressButtonWatchSignUp}
              source={IMAGE_BTN_SIGNUP}
              style={styles.buttonContainer}
            />
          </View>
        </View>
      </View>
    );
  };

  return (
    <CustomView
      bgColor="#FFE353"
      borderColor="white"
      menuDisPlayFlag={sidemenuDisplayFlag}
      handleMenu={onPressSideMenuButton}
      onPressHowWork={onPressHowDoesItWork}
      onPressResearch={onPressResearch}
      onPressTheBook={onPressTheBook}
      onPressInsightTeacher={onPressInsightTeacher}
      onPressTestimonials={onPressTestimonials}
      onPressNotifications={onPressNotification}
      onPressButtonWatchLogins={onPressButtonWatchLogin}
      onPressPrivacyPolicy={onPressPrivacyPolicy}
      onPressTermsOfUSes={onPressTermsOfUSes}
    >
      {mainView()}

      {howDoesWorkModalFlag ? (
        <HowDoesWorkModal
          displayFlag={howDoesWorkModalFlag}
          closeModal={closeHowDoesWorkModal}
          onPressSignup={onPressButtonWatchSignUp}
          dispSignup={true}
        />
      ) : null}
      {researchModalFlag ? (
        <ResearchModal
          displayFlag={researchModalFlag}
          closeModal={closeResearchModal}
          mydeviceType={detectDeviceType}
          onPressSignup={onPressButtonWatchSignUp}
        />
      ) : null}

      {teacherModalFlag ? (
        <InsightsForTeachersModal
          displayFlag={teacherModalFlag}
          closeModal={closeTeacherModal}
          mydeviceType={detectDeviceType}
          onPressSignup={onPressButtonWatchSignUp}
        />
      ) : null}
      {/** bookModalFlag ? (
          <WebViewModal
            displayFlag={bookModalFlag}
            closeModal={closeWebViewModal}
          />
        ) : null*/}
      {bookModalFlag ? (
        <TheBookModal
          displayFlag={bookModalFlag}
          closeModal={closeTheBookModal}
        />
      ) : null}
      {testiMonialModalFlag ? (
        <TestimonialModal
          displayFlag={howDoesWorkModalFlag}
          closeModal={closeTestiMonialModal}
        />
      ) : null}

      {loginDisplayFlag ? (
        <LoginWelcome
          displayFlag={loginDisplayFlag}
          closeModal={closeTeacherModal}
          onPressLogin={onPressLogin}
          onPress={onPressCloseHelpDialog}
        />
      ) : null}

      {signupDisplayFlag ? (
        <RegisterWelcome
          onPressPayNow={onPressPayNow}
          onPress={onPressCloseHelpDialog}
          monthlySubscribeFlag={subscriptionMonthly}
          yearlySubscribeFlag={subscriptionYearly}
          onPressSubscriptionMonthly={onPressSubscriptionMonthly}
          onPressSubscriptionYearly={onPressSubscriptionYearly}
          token={token}
          monthPrince={monthlyPrice}
          yearPrice={yearlyPrice}
          monthProductId={monthlyProductId}
          yearProductId={yearlyProductId}
        />
      ) : null}

      {startNowFlag ? <StartNowScreen onPressStart={onPressStartNow} /> : null}

      {unSubscribedView ? (
        <AllReadySubscribed
          onPressPayNow={onPressPayNowRenew}
          onPress={onPressCloseAllReadySubscribed}
          cancelPress={onPressCancelSubscription}
          renewPress={onPressRenewSubscription}
          closeCancelView={onPressCloseCancelView}
          cancelFlag={cancelPressFlag}
          renewFlag={renewPressFlag}
          monthlySubscribeFlag={subscriptionMonthly}
          yearlySubscribeFlag={subscriptionYearly}
          onPressSubscriptionMonthly={onPressSubscriptionMonthly}
          onPressSubscriptionYearly={onPressSubscriptionYearly}
        />
      ) : null}

      {introvideoDispFlag ? (
        <IntroVideonScreenFull
          onPressClose={onPressCloseButtonIntroVideoView}
          onPress={onPressCloseButtonIntroVideoView}
          urlSource="https://happigenius.b-cdn.net/Intro_to_happigenius.mp4"
        />
      ) : null}

      {sampleScreenDispFlag ? (
        <WatchSampleModal closeModal={onPressCloseButtonWatchSample} />
      ) : null}

      {waitingUIFlag ? (
        <WaitingCmp
          waitingMessage={paymentMessage}
          waitingMinute={5}
          waitingSeconds={0}
          onPressContinue={onPressCloseSuccessPaymentRedirect}
          onPressClose={onPressClosePaymentRedirect}
        />
      ) : null}
      {toastFlag ? (
        <ToastCmp message={toastMessage} onPress={toastOnPressClose} />
      ) : null}
      {loadingFlag ? loader() : null}
    </CustomView>
  );
};
export default WelcomeScreen;

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
    height: "100%",
    paddingTop: "2%",
    paddingBottom: "2%",
    paddingLeft: "2%",
    paddingRight: "6%",
    overflow: "hidden",
  },
  rowButtonContainer: {
    flexDirection: "row",
    width: "100%",
    height: "12%",
    justifyContent: "center",
    alignSelf: "baseline",
  },
  buttonContainer: {
    height: "100%",
    width: "30%",
    resizeMode: "contain",
  },
  titleContainer: {
    fontSize: heightToDp(4.8),
    fontWeight: "bold",
    color: "black",
    letterSpacing: 1.2,
  },
  descriptionContainer: {
    fontSize: heightToDp(2.3),
    lineHeight: heightToDp(2.6),
    color: "black",
  },
});

import React from "react";
import { View, Text, FlatList, StyleSheet, ScrollView } from "react-native";
import CustomViewBack from "../../../components/CustomViewBack";
import ImagePress from "../../../components/ImagePress";
import SampleVideoCmp from "../../../components/SampleVideoCmp";
import { BUILD_TYPE_WEB, DEFAUL_BUILD_TYPE } from "../../../constants/DefaultConst";
import { IMAGE_BTN_BACK } from "../../../constants/ImageConst";
import { String } from "../../../constants/String";
import { heightToDp } from "../../../utils/Responsive";

const sections = [
  {
    title: "Made for you",
    horizontal: true,
  },
];

const WatchSampleModal = ({ closeModal }) => {
  const mainView = () => {
    return (
      <View
        style={{
          width: "100%",
          alignItems: "center",
          paddingTop: "4%",
          overflow: "hidden",
        }}
      >
        <Text style={styles.titleContainer}>{String.sample_content}</Text>
        <Text style={[styles.descriptionContainer, { marginTop: "2%" }]}>
          {String.sample_content_offers_short}
        </Text>
        <Text style={[styles.descriptionContainer]}>
          {String.sample_content_example}
        </Text>
        <View
          style= {DEFAUL_BUILD_TYPE === BUILD_TYPE_WEB? styles.videoWebContainer:styles.videoAppContainer}
        >
          <SampleVideoCmp
            videoSource="https://happigenius.b-cdn.net/star_breath_90_seconds.mp4"
            bottomText={String.sample_video_title_1}
          />
          <SampleVideoCmp
            videoSource="https://happigenius.b-cdn.net/lesson_5_sammy_the_squirrel_is_hungry.mp4"
            bottomText={String.sample_video_title_2}
          />
          <SampleVideoCmp
            videoSource="https://happigenius.b-cdn.net/lesson_5_kind_words.mp4"
            bottomText={String.sample_video_title_3}
          />
        </View>

        <Text style={[styles.descriptionContainer, { marginTop: "2%",padding:'2%' }]}>
          {String.sample_content_integrated}
        </Text>
      </View>
    );
  };

  return (
    <CustomViewBack borderColor="white" onPress={closeModal} bgColor="#FDE453">
      <ScrollView>{mainView()}</ScrollView>
      <ImagePress
        onPress={closeModal}
        source={IMAGE_BTN_BACK}
        style={{
          resizeMode: "contain",
          position: "absolute",
          height: "8%",
          width: "5%",
          margin: "1%",
        }}
      />
    </CustomViewBack>
  );
};

export default WatchSampleModal;

const styles = StyleSheet.create({
  titleContainer: {
    fontSize: heightToDp(4.8),
    fontWeight: "bold",
    color: "black",
    letterSpacing: 1.2,
  },
  descriptionContainer: {
    fontSize: heightToDp(3.5),
    lineHeight: heightToDp(6),
    color: "black",
  },
  videoWebContainer:{
      width: "100%",
      height:'50%',
      flexDirection: "row",
      marginTop: "4%",
      flex: 1,
      justifyContent: "center",
  },
  videoAppContainer:{
    width: "100%",
    height:160,
    flexDirection: "row",
    marginTop: "4%",
    flex: 1,
    justifyContent: "center",
}
});

import React, { useState } from "react";
import { useEffect } from "react";
import { View, Text, FlatList } from "react-native";

import ImagePress from "../../components/ImagePress";
import OptionView from "../../components/OptionView";
import { IMAGE_QT_CLOSE } from "../../constants/ImageConst";
import { String } from "../../constants/String";
import { heightToDp } from "../../utils/Responsive";


const QuestionScreenVideo = ({
  onPressClickClose,
  onPressClose,
  questionTitle,
  optionA_origAnsFlag,
  optionB_origAnsFlag,
  optionC_origAnsFlag,
  optionD_origAnsFlag,
  option1,
  option2,
  option3,
  option4,
}) => {
  const [optionA_givenAnsFlag, setOptionA_givenAnsFlag] = useState("");
  const [optionB_givenAnsFlag, setOptionB_givenAnsFlag] = useState("");
  const [optionC_givenAnsFlag, setOptionC_givenAnsFlag] = useState("");
  const [optionD_givenAnsFlag, setOptionD_givenAnsFlag] = useState("");

  const [questionTitledata, setQuestionTitleData] = useState(questionTitle);
  const [optionA_originalAnsFlag, setOptionA_originalAnsFlag] =
    useState(optionA_origAnsFlag);
  const [optionB_originalAnsFlag, setOptionB_originalAnsFlag] =
    useState(optionB_origAnsFlag);
  const [optionC_originalAnsFlag, setOptionC_originalAnsFlag] =
    useState(optionC_origAnsFlag);
  const [optionD_originalAnsFlag, setOptionD_originalAnsFlag] =
    useState(optionD_origAnsFlag);

  const [tryAgainFlag, setTryAgainFlag] = useState(false);

  useEffect(()=>{
    console.log("option1 : "+option1);
    console.log("option2 : "+option2);
    console.log("option3 : "+option3);
    console.log("option4 : "+option4);

    console.log("option1 Ans : "+optionA_origAnsFlag);
    console.log("option2 Ans : "+optionB_origAnsFlag);
    console.log("option3 Ans : "+optionC_origAnsFlag);
    console.log("option4 Ans : "+optionD_origAnsFlag);
  },[])

  useEffect(() => {
    if (tryAgainFlag) {
      setTimeout(() => {
        setTryAgainFlag(false);
      }, 1000);
    }
  }, [tryAgainFlag]);

  const closeQuestionWindow = () => {
    setTimeout(() => {
      onPressClose();
    }, 2000);
  };
  const onPressOption_A = () => {
    console.log("onPressOption_A");

    if (optionA_originalAnsFlag === "true") {
      setOptionA_givenAnsFlag("true");
      closeQuestionWindow();
    } else {
      setTryAgainFlag(true);
      setOptionA_givenAnsFlag("false");
    }
  };
  const onPressOption_B = () => {
    console.log("onPressOption_B");
    if (optionB_originalAnsFlag === "true") {
      setOptionB_givenAnsFlag("true");
      closeQuestionWindow();
    } else {
      setTryAgainFlag(true);
      setOptionB_givenAnsFlag("false");
    }
  };
  const onPressOption_C = () => {
    console.log("onPressOption_C");
    if (optionC_originalAnsFlag === "true") {
      setOptionC_givenAnsFlag("true");
      closeQuestionWindow();
    } else {
      setTryAgainFlag(true);
      setOptionC_givenAnsFlag("false");
    }
  };
  const onPressOption_D = () => {
    console.log("onPressOption_D");
    if (optionD_originalAnsFlag === "true") {
      setOptionD_givenAnsFlag("true");
      closeQuestionWindow();
    } else {
      setTryAgainFlag(true);
      setOptionD_givenAnsFlag("false");
    }
  };

  const questionView = () => {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontWeight: "bold",
            fontSize: heightToDp(5.5),
            margin: "6%",
          }}
          numberOfLines={2}
        >
          {questionTitledata}
        </Text>
        
        <View style={{ width: "90%", height: "60%" }}>
          <View
            style={{
              width: "100%",
              height: "50%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <OptionView
              onPress={onPressOption_A}
              optionAns={option1}
              userClickOn={optionA_givenAnsFlag}
              optionNumber="A. "
            />
            <OptionView
              onPress={onPressOption_B}
              optionAns={option2}
              userClickOn={optionB_givenAnsFlag}
              optionNumber="B. "
            />
          </View>
          {option3 !== ""?<View
            style={{
              width: "100%",
              height: "50%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <OptionView
              onPress={onPressOption_C}
              optionAns={option3}
              userClickOn={optionC_givenAnsFlag}
              optionNumber="C. "
            />
            <OptionView
              onPress={onPressOption_D}
              optionAns={option4}
              userClickOn={optionD_givenAnsFlag}
              optionNumber="D. "
            />
          
          </View>:null}
        </View>
        {tryAgainFlag ? (
          <Text style={{ fontWeight: "bold", fontSize: heightToDp(4),bottom:4,position:'absolute' }}>
            {String.question_try_again}
          </Text>
        ) : null}
      </View>
    );
  };
  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(94, 200, 243, 1)",
        borderRadius: 2,
        borderColor: "white",
        borderWidth: 6,
      }}
    >
   
       {questionView()}

      <ImagePress
        onPress={onPressClickClose}
        style={{
          position: "absolute",
          height: "4%",
          width: "4%",
          right: 0,
          margin: "1%",
          resizeMode: "contain",
        }}
        source={IMAGE_QT_CLOSE}
      />
    </View>
  );
};
export default QuestionScreenVideo;

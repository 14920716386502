import React from "react";
import { StyleSheet, View,Text,ScrollView } from "react-native";
import ImagePress from "./ImagePress";
import { IMAGE_QT_CLOSE } from "../constants/ImageConst";
import { heightToDp } from "../utils/Responsive";
const ReadmoreCmp = ({ onPressClose, moreText }) => {
  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        overflow:'hidden',
        justifyContent: "center",
        alignItems: "center",
      }}
    >
        

      <View style={styles.mainContainer}>
      <ScrollView>  
       <Text style={styles.textSubTitleContainer}>{moreText}</Text>
       </ScrollView>
      </View>
      <ImagePress
      onPress={onPressClose}
      style={styles.closeButtonView}
      source={IMAGE_QT_CLOSE}
    />
    </View>
  );
};

export default ReadmoreCmp;

const styles = StyleSheet.create({
  mainContainer: {
    width: "90%",
    height: "90%",
    // margin: "2%",
    borderWidth: 10,
    borderRadius: 10,
    borderColor: "white",
    backgroundColor: "#a3daf9",
    overflow:'hidden',
  },
  closeButtonView: {
    position: "absolute",
    height: "4%",
    width: "2%",
    // margin: "10%",
    right: "4.5%",
    top: "4.5%",
    resizeMode: "contain",
  },

  imageButtonContainer: {
    marginTop: "4%",
    marginBottom: "1%",
    width: "26%",
    height: "12%",
    resizeMode: "contain",
    alignSelf: "center",
  },

  textSubTitleContainer: {
    fontSize: heightToDp(3),
    color: "black",
    textAlign: "left",
    padding:'2%',
    letterSpacing:1,
    
  },
});

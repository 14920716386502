import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import ImagePress from "./ImagePress";
import {
  IMAGE_QT_CLOSE,
  IMAGE_BROODY_HEART,
  IMAGE_BTN_OK,
} from "../constants/ImageConst";
import { heightToDp } from "../utils/Responsive";
import { String } from "../constants/String";

const InputSuccessCmp = ({ onPressClose, onPressOk }) => {
  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View style={styles.mainContainer}>
        <Image source={IMAGE_BROODY_HEART} style={styles.imageContainerLarge} />
        <Text style={styles.textTitleContainer}>{String.input_succesmsg}</Text>
        <ImagePress
          style={styles.imageButtonContainer}
          source={IMAGE_BTN_OK}
          onPress={onPressOk}
        />
      </View>

      <ImagePress
        onPress={onPressClose}
        style={styles.closeButtonView}
        source={IMAGE_QT_CLOSE}
      />
    </View>
  );
};

export default InputSuccessCmp;

const styles = StyleSheet.create({
  mainContainer: {
    width: "90%",
    height: "90%",
    // margin: "2%",
    borderWidth: 10,
    borderRadius: 10,
    justifyContent: "center",
    borderColor: "white",
    backgroundColor: "#a3daf9",
  },
  closeButtonView: {
    position: "absolute",
    height: "4%",
    width: "2%",
    // margin: "10%",
    right: "4.5%",
    top: "4.5%",
    resizeMode: "contain",
  },
  imageContainerLarge: {
    width: "30%",
    height: "30%",
    resizeMode: "contain",
    alignSelf: "center",
  },
  imageButtonContainer: {
    marginTop: "2%",
    marginBottom: "1%",
    width: "26%",
    height: "12%",
    resizeMode: "contain",
    alignSelf: "center",
  },
  textTitleContainer: {
    fontSize: heightToDp(3),
    color: "black",
    padding: "2%",
    textAlign: "center",
    fontWeight: "bold",
  },

  textSubTitleContainer: {
    fontSize: heightToDp(3),
    color: "black",
    marginTop: "2%",
    textAlign: "center",
  },
});

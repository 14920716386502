import React from "react";
import { Platform } from "react-native";
import FormData from 'form-data';
import Constants from "expo-constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isBlank, validateObject, checkKeyAssign } from "../../utils/Utility";
import { API_USER_AWARD_POST } from "../../constants/ApiConst";
import { String } from "../../constants/String";
import axios from "axios";
import { HEADER } from "../../constants/DefaultConst";
const TAG = "Rq_UserAwardInsert ";
export const request_CreateUserAward = createAsyncThunk(
  "Rq_UserAwardInsert/request_CreateUserAward",
  async (payloadCreator, thunkAPI) => {
    const data = new FormData();

    if (!isBlank(payloadCreator.params['uid'])) {
      data.append('uid', payloadCreator.params['uid']);
    }
    if (!isBlank(payloadCreator.params['level_no'])) {
      data.append('level_no', payloadCreator.params['level_no']);
    }
  
    // const data = {
    //   'first_name': payloadCreator.params.name,
    //   'email': payloadCreator.params.email,
    //   'usertoken': payloadCreator.params.usertoken,
    // };
    
    console.log(TAG + " Payload uid : " + payloadCreator.params.uid);
    console.log(TAG + " Payload level_no : " + payloadCreator.params.level_no);
    var config = {
      // '/' +
      // payloadCreator.extraParams.from +
      // '/' +
      // payloadCreator.extraParams.to
      method: "post",
      url: API_USER_AWARD_POST,
      // headers: HEADER,
      data: data,
    };

    const response = axios(config)
      .then(function (response) {
        console.log(TAG + " : " + JSON.stringify(response.data));
        return response;
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // Logger(TAG, 'error data1 : ' + JSON.stringify(error.response.data));
          // Logger(TAG, 'error status2 : ' + error.response.status);
          // Logger(
          //   TAG,
          //   'error headers3 : ' + JSON.stringify(error.response.headers),
          // );
          return error.response.data;
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // Logger(TAG, 'error request4 : ' + JSON.stringify(error.request));
        } else {
          // Something happened in setting up the request that triggered an Error
          // Logger(TAG, 'error message5 : ' + error.message);
        }
        // Logger(TAG, 'error config6 : ' + JSON.stringify(error.config));
      });
    return response;
  }
);



const Rq_UserAwardInsert = createSlice({
  name: "Rq_UserAwardInsert",
  initialState: {
    loading: "",
    responseFlag: "",

    errorCode: "",
    errorType: "",
    errorMessage: "",
  },
  reducers: {},
  extraReducers: {
    [request_CreateUserAward.pending]: (state, action) => {
      state.loading = "yes";
    },
    [request_CreateUserAward.fulfilled]: (state, action) => {
      if (validateObject(action.payload.data)) {
        if (validateObject(action.payload.data.result)) {
          state.responseFlag = checkKeyAssign(
            "status",
            action.payload.data.result
          );
          if (state.responseFlag === "true") {
          } else if (state.responseFlag === "false") {
            if (validateObject(action.payload.data.result)) {
              state.errorCode = checkKeyAssign(
                "statusCode",
                action.payload.data.result
              );
              state.errorMessage = checkKeyAssign(
                "statusMessage",
                action.payload.data.result
              );
              // setErrorData(state, action.payload.result.error);
            } else {
              state.responseFlag = "false";
              state.responseMessage = String.could_not_get_reponse;
              state.errorMessage = String.could_not_get_reponse;
            }
          } else {
            state.responseFlag = "false";
            state.responseMessage = String.could_not_get_reponse;
            state.errorMessage = String.could_not_get_reponse;
          }
        }
      }
      state.loading = "no";
    },
    [request_CreateUserAward.rejected]: (state, action) => {
      state.loading = "no";
      state.responseFlag = "false";
      state.responseMessage = String.could_not_get_reponse;
    },
  },
});
export default Rq_UserAwardInsert;

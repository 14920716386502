import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  Animated,
  Alert,
  Text,
  Platform,
  ActivityIndicator,
  TouchableWithoutFeedback,
  FlatList,
  ImageBackground,
  Dimensions,
} from "react-native";
import {
  IMAGE_LV_1,
  IMAGE_BT_PAUSE,
  IMAGE_BT_PLAY,
  IMAGE_BT_REWIND,
  IMAGE_RP_MUSIC_ON,
  IMAGE_RP_MUSIC_OFF,
  IMAGE_RP_HELP,
  IMAGE_RP_AWARD,
  IMAGE_RP_AWARD_ANIM,
  IMAGE_RP_SETTING,
  IMAGE_CHARACTER,
  IMAGE_LEVEL_STAND,
  IMAGE_SPEECH_BOX_START,
  IMAGE_SPEECH_BOX_ACTIVITY,
  IMAGE_REWARD_STAND,
  IMAGE_REWARD_STAND_NEW,
  IMAGE_QT_CLOSE,
  IMAGE_BTN_LOADING,
  IMAGE_BTN_OK,
  IMAGE_TROPHY,
  IMAGE_STAR,
  IMAGE_BLAST,
  IMAGE_STAND_ACTIVITY_PIN,
  IMAGE_STAND_FUNFACT_PIN,
  IMAGE_LOCK,
  IMAGE_END_FLAG,
} from "../../constants/ImageConst";
import {
  MUSIC_ON_OFF,
  PREF_STORE_PREF_NAME,
  PREF_USER_POINT,
  PREF_USER_TOKEN,
  PREF_USER_UID,
  PREF_FIRST_VISIT,
  PREF_USER_FIRST_NAME,
  PREF_LESSON1,
  PREF_LESSON2,
  PREF_LESSON12,
  PREF_LESSON11,
  PREF_LESSON10,
  PREF_LESSON9,
  PREF_LESSON8,
  PREF_LESSON7,
  PREF_LESSON6,
  PREF_LESSON5,
  PREF_LESSON4,
  PREF_LESSON3,
  PREF_INTERVAL_SECONDS,
} from "../../constants/PrefConst";
import ImagePress from "../../components/ImagePress";
import {
  API_LEVEL_LIST_GET,
  URLs,
  URL_BGIMAGE,
  VERSION,
} from "../../constants/ApiConst";
import * as Device from "expo-device";
import NetInfo from "@react-native-community/netinfo";

import { StatusBar } from "expo-status-bar";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Video, AVPlaybackStatus, Audio } from "expo-av";
import * as font from "expo-font";
import { String } from "../../constants/String";
import VideonScreen from "../childscreens/VideonScreen";
import Constants from "expo-constants";
import useAxios from "../../customhooks/useAxios";
import axios from "axios";
import { request_LevelList } from "../../redux/reducers/Rq_Levellist";
import { request_CreateUserAward } from "../../redux/reducers/Rq_UserAwardInsert";
import { request_Appconfig } from "../../redux/reducers/Rq_Appconfig";
import { request_Level_VideoList } from "../../redux/reducers/Rq_Level_videolist";
import { request_UserInfo } from "../../redux/reducers/Rq_UserInfo";
import { request_CreateUser } from "../../redux/reducers/Rq_CreateUser";
import { request_CreateToken } from "../../redux/reducers/Rq_CreateToken";
import { request_CreateUserInput } from "../../redux/reducers/Rq_UserInputInsert";
import { request_UserAward_List } from "../../redux/reducers/Rq_UserAwardList";
import {
  request_UpdateUser,
  update_userData,
} from "../../redux/reducers/Rq_UpdateUser";

import { useDispatch, useSelector } from "react-redux";
import IntroVideonScreen from "../childscreens/IntroVideonScreen";
import RoundBackground from "../../components/RoundBackground";
import RegisterWelcome from "../childscreens/RegisterWelcome";
import StartNowScreen from "../childscreens/StartNowScreen";
import AllReadySubscribed from "../childscreens/AllReadySubscribed";
import SummaryVideonScreen from "../childscreens/SummaryVideonScreen";
import VideoEndMessage from "../../components/VideoEndMessage";
import VideoEndMessageButton from "../../components/VideoEndMessageButton";
import VideoEndMessageInput from "../../components/VideoEndMessageInput";
import { heightToDp } from "../../utils/Responsive";
import QuestionScreen from "../childscreens/QuestionScreen";
import FunFactCmp from "../../components/FunFactCmp";
import IntroBanner from "../../components/IntroBanner";
import FirstViewBanner from "../childscreens/FirstViewBanner";
import CollectStarCmp from "../../components/CollectStarCmp";
import AwardScreen from "../childscreens/AwardScreen";
import BroodyMsgBox from "../../components/BroodyMsgBox";
import ToastCmp from "../../components/ToastCmp";
import ActivityCmp from "../../components/ActivityCmp";
import ActiFunFactCmp from "../../components/ActiFunFactCmp";
import InputSuccessCmp from "../../components/InputSuccessCmp";
import ActivityPinBoard from "../../components/ActivityPinBoard";
import ImagePressNoAnim from "../../components/ImagePressNoAnim";
import ImagePressOnLoadAnim from "../../components/ImagePressOnLoadAnim";
import ScrollingBackground from "react-native-scrolling-images";
import HowDoesWorkModal from "../startscreens/Models/HowDoesWorkModal";
import {
  BUILD_TYPE_WEB,
  DEFAUL_BUILD_TYPE,
} from "../../constants/DefaultConst";
import FinishCourseBanner from "../../components/FinishCourseBanner";
// var axios = require("axios");


let interval = 0;
let intervalChar = 0;
let taskList = [];
let rewardList = [];
let myindex = 0;
let tempFuncount = 0;
var secondScreendetectIndicator = 0;

// let soundObject = new Audio.Sound();

const soundStarCollectionObject = new Audio.Sound();
const soundStarCollectionAwardObject = new Audio.Sound();
let g_name = "";
let g_uid = "";
let g_accessToken = "";
let g_point = 0;
let g_interval_second = 3;
var tempTaskCount = 0;

var testHeight = Dimensions.get("window").height;

const GameScen = () => {
  const soundRef = useRef(null);

  const dispatch = useDispatch();
  const imageRef = useRef();
  // Network state
  const [internetFlag, setInternetFlag] = useState("");

  //==== Loading state ========
  const [toastFlag, setToastFlag] = useState(false);
  const [toastText, setToastText] = useState("");
  const [gifPlay, setGifPlay] = useState(false);
  const [animAwardFlag, setAnimAwardFlag] = useState(false);

  const [inputSuccessDispFlag, setInputSuccessDispFlag] = useState(false);

  const [collectStarScreenDispFlag, setCollectStarScreenDispFlag] =
    useState(false);

  const [apploadingFlag, setAppLoadingFlag] = useState(true);
  const [innerLoadingFlag, setInnerLoadingFlag] = useState();
  const [videoloadingFlag, setVideoLoadingFlag] = useState(false);

  const [introBannerDispFlag, setIntroBannerDispFlag] = useState(false);
  const [summaryBannerDispFlag, setSummaryBannerDispFlag] = useState(false);
  const [introBannerLabel, setIntroBannerLabel] = useState("");
  const [firstVistFlag, setFirstVisitFlag] = useState("");
  const [lessionScreenDispFlag, setLessionScreenDispFlag] = useState(false);

  const [courseFinishFlag, setCourseFinishFlag] = useState(false);
  const [introvideoDispFlag, setIntroVideoDispFlag] = useState(false);
  const [introvideoEndMessageDispFlag, setIntroVideoEndMessageDispFlag] =
    useState(false);
  const [summaryvideoDispFlag, setSummaryVideoDispFlag] = useState(false);
  const [summaryvideoEndMessageDispFlag, setSummaryVideoEndMessageDispFlag] =
    useState(false);
  const [funfactFlag, setFunFactFlag] = useState(false);
  const [funfactSingleFlag, setFunFactSingleFlag] = useState(false);
  const [funfactMultipleFlag, setFunFactMultipleFlag] = useState(false);
  const [funfactTaskCount, setFunFactCount] = useState(0);
  const [funfactDispInputFlag, setfunfactDispInputFlag] = useState(false);

  const [activityDispFlag, setActivityDispFlag] = useState(false);
  const [activityDispInputFlag, setActivityDispInputFlag] = useState(false);

  const [currentUrlis, setCurrentUrlis] = useState("");
  const [introVideoUrls, setIntroVideoUrls] = useState("");
  const [summaryVideoUrls, setSummaryVideoUrls] = useState("");
  //==== End Loading state ========

  // ===== Subscription ==================
  const [helpFlag, setHelpFlag] = useState(false);
  const [startNowFlag, setStartNowflag] = useState(false);
  const [subscriptionMonthly, setSubscriptionMonthly] = useState(true);
  const [subscriptionYearly, setSubscriptionYearly] = useState(false);

  const [userSubscribed, setUserSubscribed] = useState(false);

  const [unSubscribedView, setUnSubscribedView] = useState(false);
  const [renewPressFlag, setRenewPressFlag] = useState(false);
  const [cancelPressFlag, setCancelPressFlag] = useState(false);
  // ===== End Subscription ==================

  //==== Device state ========
  const [detectDeviceType, setDetectDeviceType] = useState();
  const [sound, setSound] = useState();
  const [soundFlag, setSoundFlag] = useState("true");
  const [bottomMargine, setBottomMargine] = useState("0%");
  const [bottomCharacterMargine, setBottomCharacterMargine] = useState("0%");
  const [bottomMargineEndStand, setBottomMargineEndStand] = useState("0%");
  const [bottomMargineStand, setBottomMargineStand] = useState("0%");
  const [bottomMargineTaskboardStand, setBottomMargineTaskBoardStand] =
    useState("0%");
  const [bottomMargineActivityPinStand, setBottomMargineActivityPinStand] =
    useState("0%");
  const [bottomMargineFunFactPinStand, setBottomMargineFunFactPinStand] =
    useState("0%");
  const [leftmargine, setLeftMargine] = useState("10%");
  //==== End Device state ========

  // =========== User Input =========
  const [userInputData, setUserInputData] = useState("");
  const [userInputActivityFlag, setUserInputActivityFlag] = useState(false);
  const [userInputMsgFlag, setUserInputMsgFlag] = useState(false);
  // =========== End User Input =========
  //==== Background State ========
  const [imgWidth, setimgWdth] = useState(0);
  const [imgHeight, setimgHeight] = useState(0);
  const [travelEndx, setTravelEndx] = useState(0);
  const [valuex, setValueX] = useState(0);
  const [valueCharx, setValueCharX] = useState(5);
  const [bgPlayFlag, setbgPlayFlag] = useState(false);
  const [startTextFlag, setStartTextFlag] = useState(true);
  //==== End Background State ========

  //==== Task State ========
  const [charReachEndFlag, setCharReachEndFlag] = useState(false);
  const [charReachEndStopFlag, setCharReachEndStopFlag] = useState(false);
  const [charReachEndStopDelayFlag, setCharReachEndStopDelayFlag] =
    useState(false);
  const [levelFinishFlag, setLevelFinishFlag] = useState(false);
  const [taskCountState, setTaskCountState] = useState(4);
  const [currentTaskCount, setCurrentTaskCount] = useState(1);
  const [nextTask, setNextTaskCount] = useState("");
  const [currentTaskCountWords, setCurrentTaskCountWords] = useState("");

  const [taskBoardStandFlag, setTaskBoardStandFlag] = useState(false);
  const [activityStandPinFlag, setActivityStandPinFlag] = useState(false);
  const [funfactStandPinFlag, setFunfactStandPinFlag] = useState(false);

  const [broodyMessageBox, setBroodyMessageBox] = useState(false);
  const [broodyMessageBoxText, setBroodyMessageBoxText] = useState("");
  const [broodyMessageBoxTextAnimFlag, setBroodyMessageBoxTextAnimFlag] =
    useState(true);
  const [inputMessage, setInputMessage] = useState("");

  const [reawardStandFlag, setRewardStandFlag] = useState(false);
  const [reawardCollectMsgFlag, setRewardCollectMsgFlag] = useState(false);
  const [videoDispFlag, setVideoDispFlag] = useState(false);
  const [taskFinishFlag, setTaskFinishFlag] = useState(false);
  //==== end Task State ========

  //==== Api Response State ========
  const [levelExpandFlag, setLevelExpandFlag] = useState(false);
  let responseDAta = null;
  let mydata = [];
  const [levelList, setLevelList] = useState(mydata);
  const [currentLevelVideoList, setCurrentLevelVideoList] = useState([]);
  const [currentLevelDetail, setCurrentLevelDetail] = useState();
  const [currentLevelIndex, setCurrentLevelIndex] = useState(null);
  const [currentLevelIndexWord, setCurrentLevelIndexWord] = useState("");
  const [introMessageData, setIntroMessageData] = useState("");
  const [summaryMessageData, setSummaryMessageData] = useState("");

  //==== End Api Response State ========

  const [points, setPoints] = useState(0);

  // ======= Prefrence data =============
  const [prefToken, setPrefToken] = useState("");
  const [prefUid, setPrefUid] = useState("");
  const [prefUserName, setPrefUserName] = useState("");
  // ======= End Prefrence data =============

  // ========== Question ============
  const [questionTitle, setQuestionTitle] = useState("");
  const [optionA_origAnsFlag, setOptionA_origAnsFlag] = useState("");
  const [optionB_origAnsFlag, setOptionB_origAnsFlag] = useState("");
  const [optionC_origAnsFlag, setOptionC_origAnsFlag] = useState("");
  const [optionD_origAnsFlag, setOptionD_origAnsFlag] = useState("");

  const [optionAData, setOptionAData] = useState("");
  const [optionBData, setOptionBData] = useState("");
  const [optionCData, setOptionCData] = useState("");
  const [optionDData, setOptionDData] = useState("");
  // ========== End Question =========

  //========== Award Screen
  const [awardScreenDisplayFlag, setAwardScreenDisplayFlag] = useState(false);
  const [awardsCount, setAwardsCount] = useState("");
  const [awardsPointCount, setAwardsPointCount] = useState("");
  //======== End Award Screen

  // Sound relate
  const [soundBgPlayFlag, setSoundBgPlayFlag] = useState(true);
  const [soundLoadFlag, setSoundLoadFlag] = useState(false);

  const [seconds, setSeconds] = useState(3);
  // User Lesson
  const [lesson1, setLesson1] = useState("false");
  const [lesson2, setLesson2] = useState("false");
  const [lesson3, setLesson3] = useState("false");
  const [lesson4, setLesson4] = useState("false");
  const [lesson5, setLesson5] = useState("false");
  const [lesson6, setLesson6] = useState("false");
  const [lesson7, setLesson7] = useState("false");
  const [lesson8, setLesson8] = useState("false");
  const [lesson9, setLesson9] = useState("false");
  const [lesson10, setLesson10] = useState("false");
  const [lesson11, setLesson11] = useState("false");
  const [lesson12, setLesson12] = useState("false");
  // End user sesson

  const [secondImageLoadFlag, setSecondImageLoadFlag] = useState(false);
  const tempViews = [];

  // Current Level Data
  //End Current Level Data
  const responseAppConfigState = useSelector((state) => {
    // console.log(" -------Level List -------------  "+ JSON.stringify(state.Rq_Levellist));
    return state.Rq_Appconfig;
  });
  const responseLevelListState = useSelector((state) => {
    // console.log(" -------Level List -------------  "+ JSON.stringify(state.Rq_Levellist));
    return state.Rq_Levellist;
  });

  const responseCreateTokenState = useSelector((state) => {
    // console.log(" -------Create Token -------------  "+ JSON.stringify(state.Rq_CreateToken));
    return state.Rq_CreateToken;
  });
  const responseUserInfoState = useSelector((state) => {
    // console.log(" -------User Info -------------  "+ JSON.stringify(state.Rq_UserInfo));
    return state.Rq_UserInfo;
  });
  const responseUpdateUserState = useSelector((state) => {
    // console.log(" -------Update User Info -------------  "+ JSON.stringify(state.Rq_UpdateUser));
    return state.Rq_UpdateUser;
  });
  const responseVideoDetailsState = useSelector((state) => {
    // console.log(" -------Create Token -------------  "+ JSON.stringify(state.Rq_CreateToken));
    return state.Rq_Level_videolist;
  });
  const responseAwardInsertState = useSelector((state) => {
    // console.log(" -------Create Token -------------  "+ JSON.stringify(state.Rq_CreateToken));
    return state.Rq_UserAwardInsert;
  });
  const responseUserInputInsertState = useSelector((state) => {
    // console.log(" -------Create User Input -------------  "+ JSON.stringify(state.Rq_UserInputInsert));
    return state.Rq_UserInputInsert;
  });
  const responseUserAwardListDataState = useSelector((state) => {
    // console.log(
    //   " -------Create User Award List -------------  " +
    //     JSON.stringify(state.Rq_UserAwardList)
    // );
    return state.Rq_UserAwardList;
  });

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      console.log("Connection type", state.type);
      console.log("Is connected?", state.isConnected);
      setInternetFlag(state.isConnected.toString());
    });
    return () => {
      // Unsubscribe to network state updates
      unsubscribe();
    };
  }, []);

  const loasdBgSound = async (myindex, fromApi) => {
    let soundObject = new Audio.Sound();
    soundRef.current = soundObject;

    if (fromApi === "yes") {
      // console.log("My index is : ", currentLevelIndex);
      if (soundObject._loaded || soundObject._loading) {
        // unloadSound(myindex)
        try {
          await soundObject.loadAsync(
            responseAppConfigState.levelMusic[currentLevelIndex]
          );
        } catch (error) {
          // console.log("unload response : error : ", error);
        }

        // console.log("already : " + soundObject._loaded.toString());
      } else {
        
        if(DEFAUL_BUILD_TYPE === BUILD_TYPE_WEB){
          await soundObject.loadAsync(
            responseAppConfigState.levelMusic[currentLevelIndex]
          );
        }else{
          await soundObject.loadAsync({
            uri:responseAppConfigState.levelMusic[currentLevelIndex]
          });
        }
      
       
      }
    } else {
      await soundObject.loadAsync(
        require("../../../assets/mp3/game_music.mp3")
      );
    }
    await soundObject.setIsLoopingAsync(true);
    await soundObject.setVolumeAsync(0.4);
    await soundObject.getStatusAsync().then((soundRes) => {
      setSoundLoadFlag(soundRes.isLoaded);
    });
    // console.log(
    //   "SOUND loading status : " +
    //     JSON.stringify(await soundObject.getStatusAsync())
    // );
  };

  const loadStarCollectionSound = async (fromApi) => {
    if (fromApi === "yes") {
      if (!soundStarCollectionObject._loaded) {


        if(DEFAUL_BUILD_TYPE === BUILD_TYPE_WEB){
          await soundStarCollectionObject.loadAsync(
            responseAppConfigState.star_collection_mp3
          );
        }else{
          await soundStarCollectionObject.loadAsync({
            uri: responseAppConfigState.star_collection_mp3
          });
        }


       
      }
    } else {
      if (!soundStarCollectionObject._loaded) {
        await soundStarCollectionObject.loadAsync(
          require("../../../assets/mp3/star_collection.mp3")
        );
      }
    }
    await soundStarCollectionObject.setIsLoopingAsync(false);
    await soundStarCollectionObject.getStatusAsync().then((soundRes) => {
      // setSoundLoadFlag(soundRes.isLoaded);
    });
  };

  const loadStarCollectionAwardSound = async (fromApi) => {
    if (fromApi === "yes") {
      if (!soundStarCollectionAwardObject._loaded) {

        if(DEFAUL_BUILD_TYPE === BUILD_TYPE_WEB){
          await soundStarCollectionAwardObject.loadAsync(
            responseAppConfigState.star_collection_award_mp3
          );
        }else{
          await soundStarCollectionAwardObject.loadAsync({
            uri: responseAppConfigState.star_collection_award_mp3
          });
        }
      
      }
    } else {
      if (!soundStarCollectionAwardObject._loaded) {
        await soundStarCollectionAwardObject.loadAsync(
          require("../../../assets/mp3/star_collection_award.mp3")
        );
      }
    }
    await soundStarCollectionAwardObject.setIsLoopingAsync(false);
    await soundStarCollectionAwardObject.getStatusAsync().then((soundRes) => {
      // setSoundLoadFlag(soundRes.isLoaded);
    });
  };

  const pauseBgSound = async () => {
    // soundObject.pauseAsync();
    soundRef.current.pauseAsync();
  };
  const playBgSound = async () => {
    // soundObject.playAsync();
    soundRef.current.playAsync();
  };

  const pauseStarCollectionSound = async () => {
    soundStarCollectionObject.pauseAsync();
  };
  const playStarCollectionSound = async () => {
    soundStarCollectionObject.playAsync();
  };

  const pauseStarCollectionAwardSound = async () => {
    soundStarCollectionAwardObject.pauseAsync();
  };
  const playStarCollectionAwardSound = async () => {
    soundStarCollectionAwardObject.playAsync();
  };

  useEffect(() => {
    font.loadAsync({
      "BalooTammudu2-Regular": require("../../../assets/fonts/BalooTammudu2-Regular.ttf"),
      "BalooTammudu2-Medium": require("../../../assets/fonts/BalooTammudu2-Medium.ttf"),
      "BalooTammudu2-SemiBold": require("../../../assets/fonts/BalooTammudu2-SemiBold.ttf"),
      "BalooTammudu2-Bold": require("../../../assets/fonts/BalooTammudu2-Bold.ttf"),
      "BalooTammudu2-ExtraBold": require("../../../assets/fonts/BalooTammudu2-ExtraBold.ttf"),
    });

    // Image.getSize(
    //   "http://apils.happigenius.com/uploads/levels/bg/060720210233162306923476646323660be12329c8ba.jpg",
    //   (width, height) => {
    //     console.log("Width : " + width + " Height : " + height);
    //     // setImageScaleData({ imgWidth: 15360, imgHeight: height });
    //   },(error)=>{
    //     console.log("Error : "+JSON.stringify(error));
    //   }
    // );
  }, []);

  useEffect(() => {
    // setSoundBgPlayFlag(soundBgPlayFlag)
    if (soundLoadFlag) {
      if (soundBgPlayFlag) {
        playBgSound();
      } else {
        pauseBgSound();
      }
    }
  }, [soundBgPlayFlag]);

  useEffect(() => {
    setSoundLoadFlag(soundLoadFlag);
  }, [soundLoadFlag]);

  useEffect(() => {
    setAppLoadingFlag(true);

    Device.getDeviceTypeAsync().then((deviceType) => {
      if (Device.DeviceType.PHONE === deviceType) {
        callAllApi();

        console.log("Device Type Phone");
      } else if (Device.DeviceType.TABLET === deviceType) {
        callAllApi();

        console.log("Device Type TABLET");
      } else if (Device.DeviceType.DESKTOP === deviceType) {
        callAllApi();
        console.log("Device Type DESKTOP");
      } else if (Device.DeviceType.TV === deviceType) {
        console.log("Device Type TV");
      } else {
        console.log("Device Type Unknown");
      }
    });
  }, []);

  useEffect(() => {
    getValueForPref(PREF_FIRST_VISIT);
    getValueForPref(PREF_USER_TOKEN);
    getValueForPref(PREF_USER_UID);
    getValueForPref(PREF_USER_POINT);
    getValueForPref(PREF_USER_FIRST_NAME);
    getValueForPref(PREF_INTERVAL_SECONDS);

    getValueForPref(PREF_LESSON1);
    getValueForPref(PREF_LESSON2);
    getValueForPref(PREF_LESSON3);
    getValueForPref(PREF_LESSON4);
    getValueForPref(PREF_LESSON5);
    getValueForPref(PREF_LESSON6);
    getValueForPref(PREF_LESSON7);
    getValueForPref(PREF_LESSON8);
    getValueForPref(PREF_LESSON9);
    getValueForPref(PREF_LESSON10);
    getValueForPref(PREF_LESSON11);
    getValueForPref(PREF_LESSON12);
  }, []);

  useEffect(() => {
    setSeconds(seconds);
  }, [seconds]);
  useEffect(() => {
    setLesson1(lesson1);
    setLesson2(lesson2);
    setLesson3(lesson3);
    setLesson4(lesson4);
    setLesson5(lesson5);
    setLesson6(lesson6);
    setLesson7(lesson7);
    setLesson8(lesson8);
    setLesson9(lesson9);
    setLesson10(lesson10);
    setLesson11(lesson11);
    setLesson12(lesson12);
  }, [
    lesson1,
    lesson2,
    lesson3,
    lesson4,
    lesson5,
    lesson6,
    lesson7,
    lesson8,
    lesson10,
    lesson11,
    lesson12,
  ]);

  const callAllApi = async () => {
    setAppLoadingFlag(true);
    dispatch(request_LevelList());
    dispatch(request_Appconfig());

    // dispatch(request_LevelList());

    // dispatch(request_CreateToken());
  };

  useEffect(() => {
    // var payloadCreator = new Object();
    // payloadCreator["extraParams"] = {
    //   usertoken: "1a013f4c4a5478d2bf65a74c9afdd920",
    //   uid: "36921c0c19",
    // };
    // dispatch(request_UserInfo(payloadCreator));

    if (responseAppConfigState.responseFlag === "true") {
      // console.log(
      //   "Appconfig recieve : " + responseAppConfigState.stop_interval
      // );
      // console.log(
      //   "Music list : " + JSON.stringify(responseAppConfigState.levelMusic)
      // );
      setSeconds(parseInt(responseAppConfigState.stop_interval));
      g_interval_second = responseAppConfigState.stop_interval;
      savePref(PREF_INTERVAL_SECONDS, responseAppConfigState.stop_interval);
      loasdBgSound(currentLevelIndex, "yes");
      loadStarCollectionSound("yes");
      loadStarCollectionAwardSound("yes");
    } else if (responseAppConfigState === "false") {
      loasdBgSound(currentLevelIndex, "no");
      loadStarCollectionSound("no");
      loadStarCollectionAwardSound("no");
    }
  }, [responseAppConfigState.responseFlag, currentLevelIndex]);

  useEffect(() => {
    setPrefToken(prefToken);
    setPrefUid(prefUid);
    setPoints(points);
    setPrefUserName(prefUserName);
    // console.log("my uid is : " + prefUid);
    // console.log("my poin is : " + points);
    // console.log("my name is : " + prefUserName);
  }, [prefToken, prefUid, points, prefUserName]);
  useEffect(() => {
    // console.log("user info point : " + responseUserInfoState.point);
    // console.log(
    //   "=================== user info email : " + responseUserInfoState.email
    // );
    setPoints(parseInt(responseUserInfoState.point));
    // dispatch(request_LevelList());
  }, [
    responseUserInfoState.responseFlag === "true" ||
      responseUserInfoState.responseFlag === "false",
  ]);

  useEffect(() => {
    // updatePoints();
    if (responseUpdateUserState.responseFlag === "true") {
      setPoints(parseInt(responseUpdateUserState.point));
    }
  }, [
    responseUpdateUserState.responseFlag === "true" ||
      responseUpdateUserState.responseFlag === "false",
  ]);

  useEffect(() => {
    if (responseVideoDetailsState.responseFlag === "true") {
      // console.log(
      //   "Video length is : " +
      //     responseVideoDetailsState.levelVideoDataArray.length
      // );
      setTaskCountState(responseVideoDetailsState.levelVideoDataArray.length);
      if (responseVideoDetailsState.levelVideoDataArray.length > 0) {
        let tempList = [];
        tempList = [...responseVideoDetailsState.levelVideoDataArray];
        tempList.sort((a, b) =>
          parseInt(a.video_no) > parseInt(b.video_no) ? 1 : -1
        );
        setCurrentLevelVideoList([]);
        // setCurrentLevelVideoList([
        //   ...responseVideoDetailsState.levelVideoDataArray,
        // ].sort((a, b) => (a.video_no > b.video_no) ? 1 : -1));
        setCurrentLevelVideoList([...tempList]);
      }
    }
  }, [responseVideoDetailsState.levelVideoDataArray]);

  useEffect(() => {
    setCurrentLevelVideoList(currentLevelVideoList);
    // console.log("\n\n\n\n");
    // console.log("Sorted Array is : " + JSON.stringify(currentLevelVideoList));
  }, [currentLevelVideoList]);
  useEffect(() => {
    setPoints(points);
  }, [points]);

  useEffect(() => {
    // console.log(
    //   "recieved data : " + responseLevelListState.levelDataArray.length
    // );
    if (responseLevelListState.levelDataArray.length > 0) {
      setLevelList([...responseLevelListState.levelDataArray]);

      setAppLoadingFlag(false);
    } else {
      setAppLoadingFlag(true);
    }
  }, [responseLevelListState.levelDataArray]);

  useEffect(() => {
    // console.log("Level list effect : " + levelList.length);
    if (currentLevelIndex === null) {
      setCurrentLevelIndex(0);
      setCurrentLevelDetail(levelList[0]);
    } else {
      setCurrentLevelIndex(currentLevelIndex);
      setCurrentLevelDetail(levelList[currentLevelIndex]);

      // loasdBgSound(currentLevelIndex,"true")
      // console.log("INDEX list effect : " + currentLevelIndex);
      // getLevelDetails(currentLevelIndex)
    }
  }, [levelList, currentLevelIndex, currentLevelDetail]);

  useEffect(() => {
    if (currentLevelIndex === null) {
    } else {
      // console.log(
      //   "================= Intro url is : " +
      //     JSON.stringify(levelList[currentLevelIndex])
      // );
      setCurrentLevelIndexWord();
      if (currentLevelIndex === 0) {
        setCurrentLevelIndexWord("first");
      } else if (currentLevelIndex === 1) {
        setCurrentLevelIndexWord("second");
      } else if (currentLevelIndex === 2) {
        setCurrentLevelIndexWord("third");
      } else if (currentLevelIndex === 3) {
        setCurrentLevelIndexWord("fourth");
      } else if (currentLevelIndex === 4) {
        setCurrentLevelIndexWord("fifth");
      } else if (currentLevelIndex === 5) {
        setCurrentLevelIndexWord("sixth");
      } else if (currentLevelIndex === 6) {
        setCurrentLevelIndexWord("seventh");
      } else if (currentLevelIndex === 7) {
        setCurrentLevelIndexWord("eighth");
      } else if (currentLevelIndex === 8) {
        setCurrentLevelIndexWord("ninth");
      } else if (currentLevelIndex === 9) {
        setCurrentLevelIndexWord("tenth");
      } else if (currentLevelIndex === 10) {
        setCurrentLevelIndexWord("eleventh");
      } else if (currentLevelIndex === 11) {
        setCurrentLevelIndexWord("twelfth");
      }
      getLevelDetails(currentLevelIndex);
    }
  }, [currentLevelIndex, levelList]);

  useEffect(() => {
    setCurrentLevelIndexWord(currentLevelIndexWord);
  }, [currentLevelIndexWord]);

  useEffect(() => {
    setTaskCountState(taskCountState);
    // console.log("Count state : " + taskCountState);
    // taskCalculation();
  }, [taskCountState]);

  useEffect(() => {
    if (secondImageLoadFlag) {
      // console.log("call inside background");
      //  return backGroundImage();

      tempViews.push(loadSecondImage());
      // console.log("call inside background : " + tempViews.length);
      return tempViews.sort(function (a, b) {
        // setSecondImageLoadFlag(false)
        return a - b;
      });
    }
  }, [secondImageLoadFlag]);

  useEffect(() => {
    if (DEFAUL_BUILD_TYPE === BUILD_TYPE_WEB) {
      setWebOrientation();
    } else {
      setAppOrientation();
    }
  }, []);

  const setWebOrientation = () => {
    const windowWidth = Dimensions.get("window").width;
    const windowHeight = Dimensions.get("window").height;

    // alert("Width : " + windowWidth + " height : "+windowHeight);
    // all calculation base on width :1920 height : 947

    const tempWidthCalculation = parseInt((windowWidth * 388) / 1920);
    const tempHeightCalculation = parseInt((windowHeight * 14.5) / 947);

    // console.log("Device imageWidth new : " + tempWidthCalculation);
    // console.log("Device imageHeight new : " + tempHeightCalculation);

    Device.getDeviceTypeAsync().then((deviceType) => {
      setDetectDeviceType(deviceType);

      if (Device.DeviceType.PHONE === deviceType) {
        setDetectDeviceType(deviceType);
        // setTravelEndx(-2900);
        setTravelEndx(-2330);
        setBottomMargine("14.5%");
        setBottomCharacterMargine("14.5%");
        setBottomMargineStand("12.5%");
        setBottomMargineEndStand("16.5%");
        setLeftMargine("3%");
        setBottomMargineTaskBoardStand("12.5%");
        console.log("Device Type Phone");
        // setimgWdth((detectImageWidth.toString()+"%"));
        setimgWdth("455%");
      } else if (Device.DeviceType.TABLET === deviceType) {
        const detectTravelEndx = parseInt((windowWidth * 4708) / 1024);
        console.log("Device travel : " + -detectTravelEndx);
        if (DEFAUL_BUILD_TYPE === BUILD_TYPE_WEB) {
          setTravelEndx(-5022);
          setimgWdth("600%");
        } else {
          setTravelEndx(-4708);
          setimgWdth("562.5%");
        }

        console.log("Device Type TABLET");
        setBottomMargine("12.5%");
        if (currentLevelIndex === 2) {
          setBottomCharacterMargine("14.5%");
        } else {
          setBottomCharacterMargine("12.5%");
        }

        setBottomMargineEndStand("14.5%");
        setBottomMargineStand("6%");
        setBottomMargineTaskBoardStand("6%");
        setLeftMargine("6%");
      } else if (Device.DeviceType.DESKTOP === deviceType) {
        const detectTravelEndx = parseInt((windowWidth * 5500) / 1920);
        console.log("Device travel : " + -detectTravelEndx);

        setTravelEndx(-5500);
        // setimgWdth("400%");
        setimgWdth("400%");
        setBottomMargine("14.5%");
        if (currentLevelIndex === 2) {
          console.log("reached here : " + currentLevelIndex);
          setBottomCharacterMargine("16.5%");
        } else {
          setBottomCharacterMargine("14.5%");
        }
        setBottomMargineEndStand("16.5%");
        setBottomMargineStand("12.5%");
        setBottomMargineTaskBoardStand("14.5%");
        setLeftMargine("2%");
        console.log("Device Type DESKTOP");
      } else if (Device.DeviceType.TV === deviceType) {
        setTravelEndx(-5500);
        setimgWdth("401%");
        setBottomMargine("14.5%");
        if (currentLevelIndex === 2) {
          setBottomCharacterMargine("16.5%");
        } else {
          setBottomCharacterMargine("14.5%");
        }
        setBottomMargineEndStand("16.5%");
        setBottomMargineStand("12.5%");
        setBottomMargineTaskBoardStand("14.5%");
        setLeftMargine("3%");
        console.log("Device Type TV");
      } else {
        setDetectDeviceType(deviceType);
        setTravelEndx(-2330);
        setBottomMargine("14.5%");
        if (currentLevelIndex === 2) {
          setBottomCharacterMargine("15.5%");
        } else {
          setBottomCharacterMargine("14.5%");
        }
        setBottomMargineEndStand("16.5%");
        setBottomMargineStand("12.5%");
        setBottomMargineTaskBoardStand("14.5%");
        setimgWdth("402%");
        setLeftMargine("3%");
        console.log("Device Type Unknown");
      }
    });

    setLeftMargine("3%");

    setimgWdth(tempWidthCalculation.toString() + "%");
  };

  const setAppOrientation = () => {
    const windowWidth = Dimensions.get("window").width;
    const windowHeight = Dimensions.get("window").height;

    // alert("Width : " + windowWidth + " height : "+windowHeight);
    // all calculation base on width :1920 height : 947

    const tempWidthCalculation = parseInt((windowWidth * 388) / 1920);
    const tempHeightCalculation = parseInt((windowHeight * 14.5) / 947);

    console.log("Device imageWidth new : " + tempWidthCalculation);
    console.log("Device imageHeight new : " + tempHeightCalculation);

    if(Platform.OS==="ios"){

      Device.getDeviceTypeAsync().then((deviceType) => {
        setDetectDeviceType(deviceType);
  
        if (Device.DeviceType.PHONE === deviceType) {
          setDetectDeviceType(deviceType);
          // setTravelEndx(-2900);
          setTravelEndx(-2330);
          setBottomMargine("12%");
          // setBottomCharacterMargine("10.5%");
          setBottomCharacterMargine("7.5%");
          // setBottomMargineStand("10%");
          setBottomMargineStand("7%");
          // setBottomMargineEndStand("16.5%");
          setBottomMargineEndStand("13.5%");
          setLeftMargine("3%");
          // setBottomMargineTaskBoardStand("11%");
          setBottomMargineTaskBoardStand("8%");
          console.log("Device Type Phone");
          // setimgWdth((detectImageWidth.toString()+"%"));
          setimgWdth("455%");
        } else if (Device.DeviceType.TABLET === deviceType) {
          const detectTravelEndx = parseInt((windowWidth * 4708) / 1024);
          console.log("Device travel : " + -detectTravelEndx);
          setTravelEndx(-4708);
          setimgWdth("562.5%");
          console.log("Device Type TABLET");
          setBottomMargine("12.5%");
          if (currentLevelIndex === 2) {
            setBottomCharacterMargine("14.5%");
          } else {
            setBottomCharacterMargine("12.5%");
          }
  
          setBottomMargineEndStand("14.5%");
          setBottomMargineStand("6%");
          setBottomMargineTaskBoardStand("6%");
          setLeftMargine("6%");
        } else if (Device.DeviceType.DESKTOP === deviceType) {
          const detectTravelEndx = parseInt((windowWidth * 5500) / 1920);
          console.log("Device travel : " + -detectTravelEndx);
  
          setTravelEndx(-5500);
          setimgWdth("388%");
          setBottomMargine("14.5%");
          if (currentLevelIndex === 2) {
            console.log("reached here : " + currentLevelIndex);
            setBottomCharacterMargine("16.5%");
          } else {
            setBottomCharacterMargine("14.5%");
          }
          setBottomMargineEndStand("16.5%");
          setBottomMargineStand("12.5%");
          setBottomMargineTaskBoardStand("14.5%");
          setLeftMargine("2%");
          console.log("Device Type DESKTOP");
        } else if (Device.DeviceType.TV === deviceType) {
          setTravelEndx(-5500);
          setimgWdth("401%");
          setBottomMargine("14.5%");
          if (currentLevelIndex === 2) {
            setBottomCharacterMargine("16.5%");
          } else {
            setBottomCharacterMargine("14.5%");
          }
          setBottomMargineEndStand("16.5%");
          setBottomMargineStand("12.5%");
          setBottomMargineTaskBoardStand("14.5%");
          setLeftMargine("3%");
          console.log("Device Type TV");
        } else {
          setDetectDeviceType(deviceType);
          setTravelEndx(-2330);
          setBottomMargine("14.5%");
          if (currentLevelIndex === 2) {
            setBottomCharacterMargine("15.5%");
          } else {
            setBottomCharacterMargine("14.5%");
          }
          setBottomMargineEndStand("16.5%");
          setBottomMargineStand("12.5%");
          setBottomMargineTaskBoardStand("14.5%");
          setimgWdth("402%");
          setLeftMargine("3%");
          console.log("Device Type Unknown");
        }
      });
      setLeftMargine("3%");
      setimgWdth(tempWidthCalculation.toString() + "%");

    }else{
      Device.getDeviceTypeAsync().then((deviceType) => {
        setDetectDeviceType(deviceType);
  
        if (Device.DeviceType.PHONE === deviceType) {
          setDetectDeviceType(deviceType);
          // setTravelEndx(-2900);
          setTravelEndx(-2330);
          setBottomMargine("12%");
          setBottomCharacterMargine("10.5%");
          setBottomMargineStand("10%");
          setBottomMargineEndStand("16.5%");
          setLeftMargine("3%");
          setBottomMargineTaskBoardStand("11%");
          console.log("Device Type Phone");
          // setimgWdth((detectImageWidth.toString()+"%"));
          setimgWdth("455%");
        } else if (Device.DeviceType.TABLET === deviceType) {
          const detectTravelEndx = parseInt((windowWidth * 4708) / 1024);
          console.log("Device travel : " + -detectTravelEndx);
          setTravelEndx(-4708);
          setimgWdth("562.5%");
          console.log("Device Type TABLET");
          setBottomMargine("12.5%");
          if (currentLevelIndex === 2) {
            setBottomCharacterMargine("14.5%");
          } else {
            setBottomCharacterMargine("12.5%");
          }
  
          setBottomMargineEndStand("14.5%");
          setBottomMargineStand("6%");
          setBottomMargineTaskBoardStand("6%");
          setLeftMargine("6%");
        } else if (Device.DeviceType.DESKTOP === deviceType) {
          const detectTravelEndx = parseInt((windowWidth * 5500) / 1920);
          console.log("Device travel : " + -detectTravelEndx);
  
          setTravelEndx(-5500);
          setimgWdth("388%");
          setBottomMargine("14.5%");
          if (currentLevelIndex === 2) {
            console.log("reached here : " + currentLevelIndex);
            setBottomCharacterMargine("16.5%");
          } else {
            setBottomCharacterMargine("14.5%");
          }
          setBottomMargineEndStand("16.5%");
          setBottomMargineStand("12.5%");
          setBottomMargineTaskBoardStand("14.5%");
          setLeftMargine("2%");
          console.log("Device Type DESKTOP");
        } else if (Device.DeviceType.TV === deviceType) {
          setTravelEndx(-5500);
          setimgWdth("401%");
          setBottomMargine("14.5%");
          if (currentLevelIndex === 2) {
            setBottomCharacterMargine("16.5%");
          } else {
            setBottomCharacterMargine("14.5%");
          }
          setBottomMargineEndStand("16.5%");
          setBottomMargineStand("12.5%");
          setBottomMargineTaskBoardStand("14.5%");
          setLeftMargine("3%");
          console.log("Device Type TV");
        } else {
          setDetectDeviceType(deviceType);
          setTravelEndx(-2330);
          setBottomMargine("14.5%");
          if (currentLevelIndex === 2) {
            setBottomCharacterMargine("15.5%");
          } else {
            setBottomCharacterMargine("14.5%");
          }
          setBottomMargineEndStand("16.5%");
          setBottomMargineStand("12.5%");
          setBottomMargineTaskBoardStand("14.5%");
          setimgWdth("402%");
          setLeftMargine("3%");
          console.log("Device Type Unknown");
        }
      });
      setLeftMargine("3%");
      setimgWdth(tempWidthCalculation.toString() + "%");
    }

   


  };

  useEffect(() => {
    setDetectDeviceType(detectDeviceType);
  }, [detectDeviceType]);
  useEffect(() => {
    // taskCalculation();
  }, [travelEndx]);

  useEffect(() => {
    if (valuex <= travelEndx) {
      // levelFinish();
    }

    // for (let i = 1; i <= taskList.length; i++) {
    //   let myindex = "";
    //   let reward = "";
    //   let tempvalue = parseInt(valuex);
    //   let heighvalue = tempvalue - 2;
    //   let lowvalue = tempvalue + 2;

    //   // console.log("heighvalue : " + heighvalue);
    //   // console.log("lowvalue : " + lowvalue);
    //   // console.log("taskList : " + JSON.stringify(taskList));
    //   // console.log("rewardList : " + JSON.stringify(rewardList));

    //   if (taskList.includes(heighvalue) || taskList.includes(lowvalue)) {
    //     try {
    //       myindex = taskList.findIndex((item) => item === heighvalue);
    //     } catch (error) {}

    //     if (myindex === -1) {
    //       try {
    //         myindex = taskList.findIndex((item) => item === lowvalue);
    //       } catch (error) {}
    //     }
    //     // setTaskBoardStandFlag(true);
    //     // // setSpeechActivityFlag(true);

    //     // setCurrentTaskCount(myindex + 1);
    //     // stopBg();
    //     break;
    //   }
    //   //  else if (
    //   //   rewardList.includes(heighvalue) ||
    //   //   rewardList.includes(lowvalue)
    //   // ) {
    //   //   try {
    //   //     reward = rewardList.findIndex((item) => item === heighvalue);
    //   //   } catch (error) {}

    //   //   if (reward === -1) {
    //   //     try {
    //   //       reward = rewardList.findIndex((item) => item === lowvalue);
    //   //     } catch (error) {}
    //   //   }
    //   //   setTaskBoardStandFlag(false);
    //   //   setRewardStandFlag(true);
    //   //   setRewardCollectMsgFlag(true);
    //   //   stopBg();
    //   //   break;
    //   // }
    // }

    // console.log("value x :" + valuex);
  }, [valuex]);

  const [boardStandTaskFinish, setBoardStandTaskFinish] = useState(false);
  const [activityStandTaskFinish, setActivityStandTaskFinish] = useState(false);
  const [funfactStandTaskFinish, setFunFactStandTaskFinish] = useState(false);
  const [pointCollectStandTaskFinish, setPointCollectStandTaskFinish] =
    useState(false);

  useEffect(() => {
    // 3 second logic
    if (bgPlayFlag) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
          // setSecondImageLoadFlag(false)
        }
        console.log("seconds update : " + seconds);
        if (seconds <= 0) {
          console.log("seconds update in : " + seconds);
          setbgPlayFlag(false);
          pinDisplayLogic();
          setSecondImageLoadFlag(true);
          setSeconds(parseInt(g_interval_second));
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [bgPlayFlag, seconds]);

  const endLogic = () => {
    setCharReachEndStopFlag(true);
    moveChar();
    setTimeout(() => {
      stopChar();
      setTimeout(() => {
        setCharReachEndStopDelayFlag(true);
        pauseBgSound();
      }, 1000);
      // setCharReachEndStopDelayFlag(true);
    }, 5000);
  };

  const pinDisplayLogic = () => {
    console.log("TAsk Status : ", boardStandTaskFinish.toString());
    if (!boardStandTaskFinish) {
      setBoardStandTaskFinish(false);
      setActivityStandTaskFinish(false);
      setFunFactStandTaskFinish(false);
      setPointCollectStandTaskFinish(false);
      tempTaskCount = tempTaskCount + 1;
      console.log("Task count is : " + tempTaskCount);
      console.log("Video Length : ", currentLevelVideoList.length);
      console.log("Video Length minus : ", currentLevelVideoList.length - 1);
      if (tempTaskCount > currentLevelVideoList.length) {
        endLogic();
      } else {
        setCurrentTaskCount(tempTaskCount);
        setTaskBoardStandFlag(true);
      }
    }
    // else if (!pointCollectStandTaskFinish) {
    //   setRewardStandFlag(true);
    //   setRewardCollectMsgFlag(true);
    // }
    else {
      if (
        currentLevelVideoList[parseInt(currentTaskCount - 1)]
          .activity_input_flag !== "no"
      ) {
        if (!activityStandTaskFinish) {
          if (
            currentLevelVideoList[currentTaskCount - 1]
              .bmsg_before_actvity_disp !== ""
          ) {
            setBroodyMessageBoxText(
              currentLevelVideoList[currentTaskCount - 1]
                .bmsg_before_actvity_disp
            );
            setBroodyMessageBoxTextAnimFlag(true);
            if (currentLevelIndex === 0) {
              setBroodyMessageBoxTextAnimFlag(true);
            } else {
              setBroodyMessageBoxTextAnimFlag(false);
            }
            setBroodyMessageBox(true);
          } else {
            setBroodyMessageBoxText(String.toast_message_activity);
            setBroodyMessageBoxTextAnimFlag(true);
            setBroodyMessageBox(true);
          }
          setActivityStandPinFlag(true);
          setFunfactStandPinFlag(false);
        } else if (
          currentLevelVideoList[parseInt(currentTaskCount - 1)].funfact_flag ===
          "true"
        ) {
          if (!funfactStandTaskFinish) {
            // setFunfactStandPinFlag(true)
            if (
              currentLevelVideoList[currentTaskCount - 1]
                .bmsg_before_funfact_disp !== ""
            ) {
              setBroodyMessageBoxText(
                currentLevelVideoList[currentTaskCount - 1]
                  .bmsg_before_funfact_disp
              );
              if (currentLevelIndex === 0) {
                setBroodyMessageBoxTextAnimFlag(true);
              } else {
                setBroodyMessageBoxTextAnimFlag(false);
              }
              setBroodyMessageBox(true);
            } else {
              setBroodyMessageBoxText(String.toast_message_funfact);
              if (currentLevelIndex === 0) {
                setBroodyMessageBoxTextAnimFlag(true);
              } else {
                setBroodyMessageBoxTextAnimFlag(false);
              }
              setBroodyMessageBox(true);
            }

            setActivityStandPinFlag(false);
            setFunfactStandPinFlag(true);
          } else {
            console.log("Reach here : ");
            setBroodyMessageBoxText(String.alert_click_play_to_continue);
            if (currentLevelIndex === 0) {
              setBroodyMessageBoxTextAnimFlag(true);
            } else {
              setBroodyMessageBoxTextAnimFlag(false);
            }
            setBroodyMessageBox(true);
          }
        } else {
          // if (
          //   currentLevelVideoList[parseInt(currentTaskCount - 1)]
          //     .message_input_flag !== "no"
          // ) {
          //   // setUserInputMsgFlag(true);
          //   if (
          //     currentLevelVideoList[parseInt(currentTaskCount - 1)]
          //       .message_input_flag === "true"
          //   ) {
          //     setInputMessage(
          //       currentLevelVideoList[parseInt(currentTaskCount - 1)]
          //         .end_message
          //     );
          //     setActivityDispInputFlag(true);
          //   } else {
          //     setBoardStandTaskFinish(false);
          //     setActivityStandTaskFinish(false);
          //     setFunFactStandTaskFinish(false);
          //     setPointCollectStandTaskFinish(false);

          //     setFunfactStandPinFlag(false);
          //     setBroodyMessageBoxText(
          //       currentLevelVideoList[parseInt(currentTaskCount - 1)]
          //         .end_message
          //     );
          //     setBroodyMessageBox(true);
          //   }

          tempTaskCount = tempTaskCount + 1;
          console.log("Task count is : " + tempTaskCount);

          setBoardStandTaskFinish(false);
          setActivityStandTaskFinish(false);
          setFunFactStandTaskFinish(false);
          setPointCollectStandTaskFinish(false);

          setCurrentTaskCount(tempTaskCount);
          setTaskBoardStandFlag(true);
        }
        // else {
        //   tempTaskCount = tempTaskCount + 1;
        //   console.log("Task count is : " + tempTaskCount);

        //   setBoardStandTaskFinish(false);
        //   setActivityStandTaskFinish(false);
        //   setFunFactStandTaskFinish(false);
        //   setPointCollectStandTaskFinish(false);

        //   setCurrentTaskCount(tempTaskCount);
        //   setTaskBoardStandFlag(true);
        // }

        // setBroodyMessageBoxText(String.alert_click_play_to_continue);
        // setBroodyMessageBox(true);
        // }
      } else if (
        currentLevelVideoList[parseInt(currentTaskCount - 1)].funfact_flag ===
        "true"
      ) {
        if (!funfactStandTaskFinish) {
          // setFunfactStandPinFlag(true)
          if (
            currentLevelVideoList[currentTaskCount - 1]
              .bmsg_before_funfact_disp !== ""
          ) {
            setBroodyMessageBoxText(
              currentLevelVideoList[currentTaskCount - 1]
                .bmsg_before_funfact_disp
            );
            if (currentLevelIndex === 0) {
              setBroodyMessageBoxTextAnimFlag(true);
            } else {
              setBroodyMessageBoxTextAnimFlag(false);
            }
            setBroodyMessageBox(true);
          } else {
            setBroodyMessageBoxText(String.toast_message_funfact);
            if (currentLevelIndex === 0) {
              setBroodyMessageBoxTextAnimFlag(true);
            } else {
              setBroodyMessageBoxTextAnimFlag(false);
            }
            setBroodyMessageBox(true);
          }

          setActivityStandPinFlag(false);
          setFunfactStandPinFlag(true);
        } else {
          // setBroodyMessageBoxText(String.alert_click_play_to_continue);
          // setBroodyMessageBox(true);
          if (tempTaskCount >= currentLevelVideoList.length) {
            console.log("Finish level");
            endLogic();
          } else {
            tempTaskCount = tempTaskCount + 1;

            setBoardStandTaskFinish(false);
            setActivityStandTaskFinish(false);
            setFunFactStandTaskFinish(false);
            setPointCollectStandTaskFinish(false);

            console.log("Task count is : " + tempTaskCount);
            setCurrentTaskCount(tempTaskCount);
            setTaskBoardStandFlag(true);
          }
        }
      } else {
        if (tempTaskCount >= currentLevelVideoList.length) {
          console.log("Finish level");
          endLogic();
        } else {
          tempTaskCount = tempTaskCount + 1;
          console.log("Task count is : " + tempTaskCount);

          setBoardStandTaskFinish(false);
          setActivityStandTaskFinish(false);
          setFunFactStandTaskFinish(false);
          setPointCollectStandTaskFinish(false);

          setCurrentTaskCount(tempTaskCount);
          setTaskBoardStandFlag(true);
        }

        // setBroodyMessageBoxText(String.alert_click_play_to_continue);
        // setBroodyMessageBox(true);
      }

      // if(!activityStandTaskFinish){

      // }else if(!funfactStandTaskFinish){

      // }
    }
  };

  useEffect(() => {
    // console.log("valueCharx : " + valueCharx);
    // console.log("my char stop : " + travelEndx);
    // console.log("my char stop : " + parseInt(Math.abs(travelEndx) / 4));
    // if (valueCharx >= parseInt(Math.abs(travelEndx) / 4) - 50) {
    //   stopChar();
    // }
  }, [valueCharx]);

  useEffect(() => {
    // console.log("bottom : " + bottomMargine);
    setBottomMargine(bottomMargine);
  }, [bottomMargine]);

  useEffect(() => {
    // console.log("bottom : " + bottomMargineStand);
    setBottomMargineStand(bottomMargineStand);
  }, [bottomMargineStand]);

  useEffect(() => {
    setCharReachEndFlag(charReachEndFlag);
    if (charReachEndFlag) {
      stopChar();
    }
  }, [charReachEndFlag]);

  useEffect(() => {
    if (bgPlayFlag) {
      if (soundLoadFlag && soundBgPlayFlag) {
        playBgSound();
      }
      playBg();
    } else {
      if (soundLoadFlag && soundBgPlayFlag) {
        // pauseBgSound();
      }
      stopBg();
    }
  }, [bgPlayFlag]);

  useEffect(() => {
    setUserInputData(userInputData);
    setUserInputActivityFlag(userInputActivityFlag);
    setUserInputMsgFlag(userInputMsgFlag);
  }, [userInputData, userInputActivityFlag, userInputMsgFlag]);
  useEffect(() => {
    if (currentTaskCount === 1) {
      setCurrentTaskCountWords("first");
    } else if (currentTaskCount === 2) {
      setCurrentTaskCountWords("second");
    } else if (currentTaskCount === 3) {
      setCurrentTaskCountWords("third");
    } else if (currentTaskCount === 4) {
      setCurrentTaskCountWords("fourth");
    } else if (currentTaskCount === 5) {
      setCurrentTaskCountWords("fifth");
    } else if (currentTaskCount === 6) {
      setCurrentTaskCountWords("sixth");
    } else if (currentTaskCount === 7) {
      setCurrentTaskCountWords("seventh");
    } else if (currentTaskCount === 8) {
      setCurrentTaskCountWords("eighth");
    } else if (currentTaskCount === 9) {
      setCurrentTaskCountWords("ninth");
    } else if (currentTaskCount === 10) {
      setCurrentTaskCountWords("tenth");
    }
  }, [currentTaskCount, currentTaskCountWords]);

  useEffect(() => {
    // setIntroVideoDispFlag(introvideoDispFlag);
    // setIntroVideoUrls(introVideoUrls);
    setSummaryVideoUrls(summaryVideoUrls);
    setSummaryVideoDispFlag(summaryvideoDispFlag);
    setIntroMessageData(introMessageData);
    setSummaryMessageData(summaryMessageData);
    console.log(
      "======================================= reach ========================"
    );
  }, [
    // introvideoDispFlag,
    summaryvideoDispFlag,
    // introVideoUrls,
    summaryVideoUrls,
    introMessageData,
    summaryMessageData,
  ]);
  useEffect(() => {
    // if (toastFlag) {
    //   setTimeout(() => {
    //     setToastFlag(false);
    //   }, 1500);
    // }
    setToastFlag(toastFlag);
  }, [toastFlag]);

  useEffect(() => {
    if (gifPlay) {
      setTimeout(() => {
        // playStarCollectionSound();
        playStarCollectionAwardSound();
      }, 2200);
      setTimeout(() => {
        setGifPlay(false);
        setAnimAwardFlag(true);
        // setCollectStarScreenDispFlag(true);
        setValueX((valuex) => valuex - 6);
        setRewardStandFlag(false);
        setbgPlayFlag(false);
        setLevelExpandFlag(false);

        setRewardStandFlag(false);
        setRewardCollectMsgFlag(false);

        setCollectStarScreenDispFlag(true);

        // setCollectStarScreenDispFlag(true);

        setTimeout(() => {
          pauseStarCollectionSound();
          setTimeout(() => {
            // playStarCollectionSound();
            // playStarCollectionAwardSound();
            if (soundBgPlayFlag) {
              playBgSound();
            }
          }, 1000);
        }, 900);
      }, 2500);
    }
  }, [gifPlay]);

  useEffect(() => {
    if (animAwardFlag) {
      setTimeout(() => {
        setAnimAwardFlag(false);
        // playStarCollectionSound();
        // pauseStarCollectionSound();
        // setCollectStarScreenDispFlag(true);
      }, 2000);
    }
  }, [animAwardFlag]);

  useEffect(() => {
    setCollectStarScreenDispFlag(collectStarScreenDispFlag);
  }, [collectStarScreenDispFlag]);

  useEffect(() => {
    setToastText(toastText);
  }, [toastText]);

  useEffect(() => {
    setIntroVideoDispFlag(introvideoDispFlag);
  }, [introvideoDispFlag]);
  useEffect(() => {
    setIntroVideoUrls(introVideoUrls);
  }, [introVideoUrls]);

  useEffect(() => {
    setIntroBannerDispFlag(introBannerDispFlag);
    setSummaryBannerDispFlag(summaryBannerDispFlag);
  }, [introBannerDispFlag, summaryBannerDispFlag]);
  useEffect(() => {
    setIntroBannerLabel(introBannerLabel);
  }, [introBannerLabel]);

  const clearData = () => {
    setQuestionTitle("");
    setOptionA_origAnsFlag("");
    setOptionB_origAnsFlag("");
    setOptionC_origAnsFlag("");
    setOptionD_origAnsFlag("");
    setOptionAData("");
    setOptionBData("");
    setOptionCData("");
    setOptionDData("");
  };
  useEffect(() => {
    setFunFactCount(funfactTaskCount);
    console.log("Current task effect count is : " + funfactTaskCount);
  }, [funfactTaskCount]);
  useEffect(() => {
    setQuestionTitle(questionTitle);
    setOptionA_origAnsFlag(optionA_origAnsFlag);
    setOptionB_origAnsFlag(optionB_origAnsFlag);
    setOptionC_origAnsFlag(optionC_origAnsFlag);
    setOptionD_origAnsFlag(optionD_origAnsFlag);
    setOptionAData(optionAData);
    setOptionBData(optionBData);
    setOptionCData(optionCData);
    setOptionDData(optionDData);
  }, [
    questionTitle,
    optionA_origAnsFlag,
    optionB_origAnsFlag,
    optionC_origAnsFlag,
    optionD_origAnsFlag,
    optionAData,
    optionBData,
    optionCData,
    optionDData,
  ]);

  useEffect(() => {
    setHelpFlag(helpFlag);
    setSubscriptionMonthly(subscriptionMonthly);
    setSubscriptionYearly(subscriptionYearly);
    setStartNowflag(startNowFlag);
    setUserSubscribed(userSubscribed);
    setRenewPressFlag(renewPressFlag);
    setCancelPressFlag(cancelPressFlag);
  }, [
    helpFlag,
    subscriptionMonthly,
    subscriptionYearly,
    userSubscribed,
    startNowFlag,
    renewPressFlag,
    cancelPressFlag,
  ]);

  useEffect(() => {
    setLevelFinishFlag(levelFinishFlag);
    console.log("level finish flag :" + levelFinishFlag.toString());
  }, [levelFinishFlag]);

  useEffect(() => {
    setFirstVisitFlag(firstVistFlag);
  }, [firstVistFlag]);

  useEffect(() => {
    setCharReachEndStopFlag(charReachEndStopFlag);
  }, [charReachEndStopFlag]);

  useEffect(() => {
    setBroodyMessageBoxText(broodyMessageBoxText);
  }, [broodyMessageBoxText]);

  useEffect(() => {
    setBroodyMessageBox(broodyMessageBox);
  }, [broodyMessageBox]);
  useEffect(() => {
    setInputMessage(inputMessage);
  }, [inputMessage]);

  useEffect(() => {
    if (responseUserAwardListDataState.responseFlag === "true") {
      setAwardsCount(responseUserAwardListDataState.award);
      setAwardsPointCount(responseUserAwardListDataState.point);
    } else if (responseUserAwardListDataState.responseFlag === "false") {
      setAwardsCount("");
      setAwardsPointCount("");
    }
  }, [
    responseUserAwardListDataState.responseFlag === "true" ||
      responseUserAwardListDataState.responseFlag === "false",
  ]);
  // ===== Sound Related ======
  const loadSound = async () => {
    const { sound } = await Audio.Sound.createAsync(
      require("../../../assets/mp3/game_music.mp3")
    );
    setSound(sound);
  };
  const playSound = async () => {
    console.log("Playing Sound");
    if (detectDeviceType === 3 || detectDeviceType === 4) {
      console.log("Library work under progress");
    } else {
      // await sound.playAsync();
    }
  };
  const pauseSoundTemp = async () => {
    console.log("Pause Sound");
    if (
      detectDeviceType === 3 ||
      detectDeviceType === "3" ||
      detectDeviceType === "4" ||
      detectDeviceType === 4
    ) {
      console.log("Library work under progress");
    } else {
      console.log("Library work under progress 233 " + detectDeviceType);
      // await sound.pauseAsync();
    }
  };
  // ===== end Sound Related ======

  // ===== Prefrence Related ======
  async function getValueForPref(key) {
    let result = "";
    try {
      result = await AsyncStorage.getItem(key);

      console.log("Pref Key is : " + key + " : " + result);
      if (key === MUSIC_ON_OFF) {
        // if (result !== "" && result != null) {
        //   setSoundFlag(result);
        //   if (soundFlag === "true") {
        //     playSound();
        //   } else {
        //     if (
        //       detectDeviceType === 3 ||
        //       detectDeviceType === "3" ||
        //       detectDeviceType === 4 ||
        //       detectDeviceType === "4"
        //     ) {
        //       console.log("Library work under progress");
        //     } else {
        //       pauseSoundTemp();
        //     }
        //   }
        // } else {
        //   setSoundFlag("true");
        //   playSound();
        // }
      } else if (key === PREF_USER_TOKEN) {
        if (result !== "" && result != null) {
          g_accessToken = result;
          setPrefToken(result);
        } else {
          setPrefToken("");
        }
      } else if (key === PREF_USER_UID) {
        if (result !== "" && result != null) {
          g_uid = result;
          setPrefUid(result);
        } else {
          setPrefUid("");
        }
      } else if (key === PREF_USER_POINT) {
        if (result !== "" && result !== null && !isNaN(result)) {
          g_point = parseInt(result);
          setPoints(parseInt(result));
          savePref(PREF_USER_POINT, points.toString());
        } else {
          setPoints(0);
          savePref(PREF_USER_POINT, points.toString());
        }
      } else if (key === PREF_FIRST_VISIT) {
        if (result !== "" && result != null) {
          setFirstVisitFlag(result);
        } else {
          setFirstVisitFlag("false");
        }
      } else if (key === PREF_USER_FIRST_NAME) {
        if (result !== "" && result != null) {
          console.log("Check data available");
          g_name = result;
          setPrefUserName(result);
        } else {
          console.log("Check data Not  available");
          setPrefUserName("");
        }
      } else if (key === PREF_INTERVAL_SECONDS) {
        if (result !== "" && result != null) {
          // g_interval_second = result;
        } else {
          // g_interval_second=1;
        }
      } else if (key === PREF_LESSON1) {
        if (result !== "" && result != null) {
          setLesson1(result);
        }
      } else if (key === PREF_LESSON2) {
        if (result !== "" && result != null) {
          setLesson2(result);
        }
      } else if (key === PREF_LESSON3) {
        if (result !== "" && result != null) {
          setLesson3(result);
        }
      } else if (key === PREF_LESSON4) {
        if (result !== "" && result != null) {
          setLesson4(result);
        }
      } else if (key === PREF_LESSON5) {
        if (result !== "" && result != null) {
          setLesson5(result);
        }
      } else if (key === PREF_LESSON6) {
        if (result !== "" && result != null) {
          setLesson6(result);
        }
      } else if (key === PREF_LESSON7) {
        if (result !== "" && result != null) {
          setLesson7(result);
        }
      } else if (key === PREF_LESSON8) {
        if (result !== "" && result != null) {
          setLesson8(result);
        }
      } else if (key === PREF_LESSON9) {
        if (result !== "" && result != null) {
          setLesson9(result);
        }
      } else if (key === PREF_LESSON10) {
        if (result !== "" && result != null) {
          setLesson10(result);
        }
      } else if (key === PREF_LESSON11) {
        if (result !== "" && result != null) {
          setLesson11(result);
        }
      } else if (key === PREF_LESSON12) {
        if (result !== "" && result != null) {
          setLesson12(result);
        }
      }
    } catch (error) {}
  }

  async function savePref(key, value) {
    await AsyncStorage.setItem(key, value);
  }
  // ===== End Prefrence Related ======

  const playBg = () => {
    setbgPlayFlag(true);
    setLevelExpandFlag(false);
    // if (taskBoardStandFlag) {
    //   setValueX((valuex) => valuex - 10);
    //   setTaskBoardStandFlag(false);
    // }
    // if (reawardStandFlag) {
    //   setValueX((valuex) => valuex - 4);
    //   setRewardStandFlag(false);
    //   setRewardCollectMsgFlag(false);
    // }

    interval = setInterval(() => {
      setValueX((valuex) => valuex - 2);
      // setValueX((valuex) => valuex - 12);
    }, 1);
  };
  const stopBg = () => {
    clearInterval(interval);
    setbgPlayFlag(false);
  };
  const moveChar = () => {
    console.log("moveChar");

    intervalChar = setInterval(() => {
      setValueCharX((valueCharx) => valueCharx + 2);
    }, 1);
  };

  const stopChar = () => {
    clearInterval(intervalChar);
    console.log("stop Character : " + valueCharx);
    console.log("=========================");
    setCharReachEndFlag(false);
    setCharReachEndStopFlag(true);
    // Reset all state here

    // resetAllState();
  };

  const resetAllState = () => {
    setCharReachEndStopFlag(false);
    setLevelFinishFlag(false);
    setCharReachEndFlag(false);
    setSummaryVideoEndMessageDispFlag(false);
    setStartTextFlag(true);
    setValueCharX(5);
    setValueX(0);

    setBoardStandTaskFinish(false);
    setActivityStandTaskFinish(false);
    setFunFactStandTaskFinish(false);
    setPointCollectStandTaskFinish(false);
    setBroodyMessageBox(false);

    var tempincrlevelindex = currentLevelIndex;
    tempincrlevelindex = tempincrlevelindex + 1;

    if (tempincrlevelindex > levelList.length - 1) {
      setCurrentLevelIndex(0);
      console.log;
      // setToastText("Congratulation! you finished all lessions.");
      // setToastText(levelList[11].summery_end_message);
      // setToastFlag(true);
      setCourseFinishFlag(true);
    } else {
      setCurrentLevelIndex(currentLevelIndex + 1);
      setLessionScreenDispFlag(true);
    }
  };

  const onPressCourseFinishOk = () => {
    setCourseFinishFlag(false);
  };

  const levelFinish = () => {
    console.log("level finsi");
    stopBg();
    setLevelFinishFlag(true);
    moveChar();
  };
  const taskCalculation = () => {
    // let taskLenghtCalculation = travelEndx - parseInt(travelEndx / 4);
    let taskLenghtCalculation =
      travelEndx - parseInt(travelEndx / taskCountState);
    let taskposition = parseInt(taskLenghtCalculation / taskCountState);
    console.log("Task first calculation : " + taskLenghtCalculation);
    console.log("Task first Position : " + taskposition);
    console.log("Task Count state : " + taskCountState);
    taskList = [];
    rewardList = [];
    for (let i = 1; i <= taskCountState; i++) {
      if ((taskposition * i) % 2 == 0) {
        taskList.push(taskposition * i);
        rewardList.push(taskposition * i - 10);
      } else {
        taskList.push(taskposition * i + 1);
        rewardList.push(taskposition * i + 1 - 10);
      }
    }
    console.log("Task list : " + JSON.stringify(taskList));
    console.log("reward list : " + JSON.stringify(rewardList));
  };

  // ==== Click Event ===
  const onPressRewind = () => {
    setTaskBoardStandFlag(false);
    setRewardStandFlag(false);
    setRewardCollectMsgFlag(false);
    setFunfactStandPinFlag(false);
    setActivityStandPinFlag(false);

    if (charReachEndStopFlag) {
    } else {
      if (valuex >= 0) {
        setValueX(0);
        setBoardStandTaskFinish(false);
        setActivityStandTaskFinish(false);
        setFunFactStandTaskFinish(false);
        setPointCollectStandTaskFinish(false);

        tempTaskCount = 0;
        console.log(" My x is 1");
      } else {
        setBroodyMessageBoxText(String.alert_click_play_to_continue);
        if (currentLevelIndex === 0) {
          setBroodyMessageBoxTextAnimFlag(true);
        } else {
          setBroodyMessageBoxTextAnimFlag(false);
        }
        setBroodyMessageBox(true);
        var tempvaluex = valuex;
        tempvaluex = tempvaluex + 393;
        if (tempvaluex >= 0) {
          setValueX(0);
          setValueX(0);

          setBoardStandTaskFinish(false);
          setActivityStandTaskFinish(false);
          setFunFactStandTaskFinish(false);
          setPointCollectStandTaskFinish(false);
          console.log(" My x is 2");
          tempTaskCount = 0;
        } else {
          tempTaskCount = tempTaskCount - 1;
          if (tempTaskCount < 0) {
            tempTaskCount = 0;
            setBoardStandTaskFinish(false);
            setActivityStandTaskFinish(false);
            setFunFactStandTaskFinish(false);
            setPointCollectStandTaskFinish(false);
            setValueX(0);
            console.log(" My x is 3");
          } else {
            setBoardStandTaskFinish(false);
            setActivityStandTaskFinish(false);
            setFunFactStandTaskFinish(false);
            setPointCollectStandTaskFinish(false);
            setValueX((valuex) => valuex + 393);

            console.log(" My x is 4");
          }
        }
      }
    }

    console.log("Click on Rewind");
  };

  const onPressPlay = () => {
    // setbgPlayFlag(true);
    // setStartTextFlag(false);
    // setCollectStarScreenDispFlag(false);
    if (taskBoardStandFlag === false) {
      if (charReachEndStopFlag) {
        console.log("level finish");
      } else if (reawardStandFlag) {
        setToastText(String.click_tocollect_simple_star);
        setToastFlag(true);
      } else if (collectStarScreenDispFlag === true || animAwardFlag === true) {
      } else if (activityStandPinFlag) {
        setToastText(String.alert_open_activity_time);
        setToastFlag(true);
      } else if (funfactStandPinFlag) {
        setToastText(String.alert_open_funfact_time);
        setToastFlag(true);
      } else {
        setBroodyMessageBox(false);
        if (soundLoadFlag && soundBgPlayFlag) {
          playBgSound();
        }
        setbgPlayFlag(true);
        setStartTextFlag(false);
      }
      // setbgPlayFlag(true);
      // setStartTextFlag(false);
      console.log("Click on onPressPlay");
    } else {
      setToastText(String.alert_incomplete_activity);
      setToastFlag(true);
      console.log("Please firt complete your task");
    }
  };

  const onPressPause = () => {
    if (soundLoadFlag && soundBgPlayFlag) {
      pauseBgSound();
    }
    setbgPlayFlag(false);
    console.log("Click on onPressPause");
  };

  const onPressMusic = () => {
    // if (soundFlag === "true") {
    //   setSoundFlag("false");
    //   savePref(MUSIC_ON_OFF, "false");
    //   pauseSoundTemp();
    // } else {
    //   setSoundFlag("true");
    //   savePref(MUSIC_ON_OFF, "true");
    //   // playSound();
    // }
    if (soundBgPlayFlag) {
      setSoundBgPlayFlag(false);
    } else {
      setSoundBgPlayFlag(true);
    }

    console.log("onPressMusic");
  };

  const onPressReward = () => {
    callAPI_RewardList();
    setAwardScreenDisplayFlag(true);
    console.log("onPressReward");
  };

  const onPressHelp = () => {
    // console.log("onPressHelp");
    if (helpFlag === false) {
      setHelpFlag(true);
    }
  };

  const closeHelpModal = () => {
    setHelpFlag(false);
  };

  const onPressSetting = () => {
    console.log("onPressSetting");
  };
  const onPressCloseVideoView = () => {
    // const [userInputData,setUserInputData] = useState("");
    // const [userInputActivityFlag,setUserInputActivityFlag] = useState(false);
    // const [userInputMsgFlag,setUserInputMsgFlag] = useState(false);
    console.log("Current Video is : " + currentTaskCount);
    console.log("Current Level No is : " + currentLevelIndex);
    console.log(
      "Cuurrent video funfact availabliti : " +
        currentLevelVideoList[parseInt(currentTaskCount - 1)].funfact_flag
    );
    console.log(
      "Cuurrent video funfact  : " +
        JSON.stringify(
          currentLevelVideoList[parseInt(currentTaskCount - 1)].funfacts
        )
    );
    // console.log(
    //   "Current Video end_message  is : " +
    //     currentLevelVideoList[parseInt(currentTaskCount - 1)].end_message
    // );
    // console.log(
    //   "Current Video message_input_flag  is : " +
    //     currentLevelVideoList[parseInt(currentTaskCount - 1)].message_input_flag
    // );
    // console.log(
    //   "Current Video end_activity  is : " +
    //     currentLevelVideoList[parseInt(currentTaskCount - 1)].end_activity
    // );
    // console.log(
    //   "Current Video activity_input_flag  is : " +
    //     currentLevelVideoList[parseInt(currentTaskCount - 1)]
    //       .activity_input_flag
    // );
    if (soundBgPlayFlag) {
      playBgSound();
    }

    setVideoDispFlag(false);
  };
  const onPressCloseIntroVideoView = () => {
    setIntroVideoDispFlag(false);
    setIntroVideoEndMessageDispFlag(true);
    console.log("Intro end Message : " + introMessageData);
  };
  const onPressCloseButtonIntroVideoView = () => {
    setIntroVideoDispFlag(false);
  };
  const onPressCloseSummaryVideoView = () => {
    setSummaryVideoDispFlag(false);
  };

  const onPressStandBoard = () => {
    console.log("Click on board");
    pauseBgSound();
    setVideoDispFlag(true);
  };

  const callAPI_CollectStar = () => {
    console.log("\n\nClick on reward");
    console.log("Points is : " + (parseInt(responseUserInfoState.point) + 1));
    // setValueX((valuex) => valuex - 4);
    // setRewardStandFlag(false);
    // setbgPlayFlag(false);
    // setLevelExpandFlag(false);

    if (reawardStandFlag) {
      console.log("Points is : " + (parseInt(points) + 1));
      console.log("User id is : " + prefUid);

      try {
        var tempPoint = 0;
        if (points === NaN) {
          setPoints(1);
          tempPoint = 1;
        } else {
          tempPoint = parseInt(points) + 1;
        }

        setPoints(tempPoint);
        var payloadCreator = new Object();
        payloadCreator["params"] = {
          usertoken: g_accessToken,
          uid: g_uid,
          point: tempPoint.toString(),
        };

        dispatch(request_UpdateUser(payloadCreator));
      } catch (error) {
        console.log("update user : " + JSON.stringify(error));
      }

      // updatePoints();

      // console.log("Points is after update: "+responseUpdateUserState.point)
    }
  };
  const onPressAndCollectReward = () => {
    pauseBgSound();
    playStarCollectionSound();

    setGifPlay(true);
    setRewardCollectMsgFlag(false);
    setPointCollectStandTaskFinish(true);

    setRewardCollectMsgFlag(false);

    callAPI_CollectStar();
    console.log("click here");
    console.log("click here inside");
  };

  const onPressOnCollectStarOk = () => {
    // setAnimAwardFlag(true);
    // setValueX((valuex) => valuex - 4);
    setCollectStarScreenDispFlag(false);
    // setBoardStandTaskFinish(true);
    setPointCollectStandTaskFinish(true);

    if (
      currentLevelVideoList[currentTaskCount - 1].bmsg_after_star_collection !==
      ""
    ) {
      setBroodyMessageBoxText(
        currentLevelVideoList[currentTaskCount - 1].bmsg_after_star_collection
      );
      if (currentLevelIndex === 0) {
        setBroodyMessageBoxTextAnimFlag(true);
      } else {
        setBroodyMessageBoxTextAnimFlag(false);
      }
      setBroodyMessageBox(true);
    } else {
      setBroodyMessageBoxText(String.alert_click_play_to_continue);
      if (currentLevelIndex === 0) {
        setBroodyMessageBoxTextAnimFlag(true);
      } else {
        setBroodyMessageBoxTextAnimFlag(false);
      }
      setBroodyMessageBox(true);
    }

    // setRewardStandFlag(false);
    // setbgPlayFlag(false);
    // setLevelExpandFlag(false);

    // if (currentLevelVideoList[parseInt(currentTaskCount - 1)] .activity_input_flag !== "no" ) {
    //   setBroodyMessageBoxText(String.toast_message_activity);
    //   setBroodyMessageBox(true);
    //   setActivityStandPinFlag(true);
    //   setFunfactStandPinFlag(false);
    // } else if (currentLevelVideoList[parseInt(currentTaskCount - 1)].funfact_flag === "true") {
    //   setBroodyMessageBoxText(String.toast_message_funfact);
    //   setBroodyMessageBox(true);
    //   setActivityStandPinFlag(false);
    //   setFunfactStandPinFlag(true);
    // } else {
    //   setBroodyMessageBoxText(String.alert_click_play_to_continue);
    //   setBroodyMessageBox(true);
    // }
  };

  const onPressCollectStarAtEnd = () => {
    console.log("level no " + currentLevelIndex);
    setRewardCollectMsgFlag(false);
    setIntroVideoEndMessageDispFlag(false);
    try {
      let tempPoint = parseInt(points) + 1;
      setPoints(tempPoint);
      var payloadCreator = new Object();
      payloadCreator["params"] = {
        usertoken: g_accessToken,
        uid: g_uid,
        point: tempPoint.toString(),
      };

      dispatch(request_UpdateUser(payloadCreator));
    } catch (error) {
      console.log("update user : " + JSON.stringify(error));
    }
  };

  const onPressCollectAward = () => {
    // setToastText(String.tropty_collected);
    // setToastFlag(true);
    // request_CreateUserAward

    console.log("Rq_UserAwardInsert  Award :");
    try {
      var payloadCreator = new Object();
      payloadCreator["params"] = {
        uid: g_uid,
        level_no: currentLevelIndex + 1,
      };

      dispatch(request_CreateUserAward(payloadCreator));
    } catch (error) {}

    updateLevelCompleteFlag(currentLevelIndex);
    resetAllState();
    // setSummaryVideoEndMessageDispFlag(true);
  };

  const updateLevelCompleteFlag = (index) => {
    // console.log("Level Complete : " + index);
    switch (index) {
      case 0:
        setLesson1("true");
        savePref(PREF_LESSON1, "true");
        break;
      case 1:
        setLesson2("true");
        savePref(PREF_LESSON2, "true");
        break;
      case 2:
        setLesson3("true");
        savePref(PREF_LESSON3, "true");
        break;
      case 3:
        setLesson4("true");
        savePref(PREF_LESSON4, "true");
        break;
      case 4:
        setLesson5("true");
        savePref(PREF_LESSON5, "true");
        break;
      case 5:
        setLesson6("true");
        savePref(PREF_LESSON6, "true");
        break;
      case 6:
        setLesson7("true");
        savePref(PREF_LESSON7, "true");
        break;
      case 7:
        setLesson8("true");
        savePref(PREF_LESSON8, "true");
        break;
      case 8:
        setLesson9("true");
        savePref(PREF_LESSON9, "true");
        break;
      case 9:
        setLesson10("true");
        savePref(PREF_LESSON10, "true");
        break;
      case 10:
        setLesson11("true");
        savePref(PREF_LESSON11, "true");
        break;
      case 10:
        setLesson12("true");
        savePref(PREF_LESSON12, "true");
        break;
    }
  };

  const onPressActivityOk = () => {
    setActivityStandPinFlag(false);
    setActivityDispFlag(false);

    setActivityStandTaskFinish(true);

    if (
      currentLevelVideoList[currentTaskCount - 1].bmsg_after_actvity_disp !== ""
    ) {
      setBroodyMessageBoxText(
        currentLevelVideoList[currentTaskCount - 1].bmsg_after_actvity_disp
      );
      if (currentLevelIndex === 0) {
        setBroodyMessageBoxTextAnimFlag(true);
      } else {
        setBroodyMessageBoxTextAnimFlag(false);
      }
      setBroodyMessageBox(true);
    } else {
      setBroodyMessageBoxText(String.alert_hope_you_enjoy_activity);
      if (currentLevelIndex === 0) {
        setBroodyMessageBoxTextAnimFlag(true);
      } else {
        setBroodyMessageBoxTextAnimFlag(false);
      }
      setBroodyMessageBox(true);
    }

    if (
      currentLevelVideoList[parseInt(currentTaskCount - 1)].funfact_flag ===
      "true"
    ) {
      // setBroodyMessageBoxText(String.toast_message_funfact);
      // setBroodyMessageBox(true);
      // setActivityStandPinFlag(false);
      // setFunfactStandPinFlag(true);
    } else {
      if (currentTaskCount - 1 === 0 && currentLevelIndex === 0) {
        setBroodyMessageBoxText(String.alert_click_play_to_continue);
        if (currentLevelIndex === 0) {
          setBroodyMessageBoxTextAnimFlag(true);
        } else {
          setBroodyMessageBoxTextAnimFlag(false);
        }
        setBroodyMessageBox(true);
      } else {
        if (
          currentLevelVideoList[parseInt(currentTaskCount - 1)]
            .message_input_flag !== "no"
        ) {
          // setUserInputMsgFlag(true);
          if (
            currentLevelVideoList[parseInt(currentTaskCount - 1)]
              .message_input_flag === "true"
          ) {
            // setInputMessage(currentLevelVideoList[parseInt(currentTaskCount - 1)].end_message);
            // setActivityDispInputFlag(true);
          } else {
            // setFunfactStandPinFlag(false);
            setBroodyMessageBoxText(
              currentLevelVideoList[parseInt(currentTaskCount - 1)].end_message
            );
            setBroodyMessageBox(true);
            // setVideoDispFlag(false);
          }
        } else {
          setBoardStandTaskFinish(false);
          setActivityStandTaskFinish(false);
          setFunFactStandTaskFinish(false);
          setPointCollectStandTaskFinish(false);
        }
      }
    }
  };

  const onPressFunFactContinue = () => {
    setFunFactFlag(false);
    setFunFactSingleFlag(false);
    setFunFactMultipleFlag(false);
    setFunfactStandPinFlag(false);

    console.log("Current level : " + currentLevelIndex);
    console.log("Current task count : " + currentTaskCount - 1);

    if (
      currentLevelVideoList[parseInt(currentTaskCount - 1)]
        .message_input_flag !== "no"
    ) {
      // setUserInputMsgFlag(true);
      if (
        currentLevelVideoList[parseInt(currentTaskCount - 1)]
          .message_input_flag === "true"
      ) {
        setInputMessage(
          currentLevelVideoList[parseInt(currentTaskCount - 1)].end_message
        );
        setActivityDispInputFlag(true);
      } else {
        setBoardStandTaskFinish(false);
        setActivityStandTaskFinish(false);
        setFunFactStandTaskFinish(false);
        setPointCollectStandTaskFinish(false);

        setFunfactStandPinFlag(false);
        setBroodyMessageBoxText(
          currentLevelVideoList[parseInt(currentTaskCount - 1)].end_message
        );
        if (currentLevelIndex === 0) {
          setBroodyMessageBoxTextAnimFlag(true);
        } else {
          setBroodyMessageBoxTextAnimFlag(false);
        }
        setBroodyMessageBox(true);
        setStartTextFlag(false);
        setTaskFinishFlag(true);
        setVideoDispFlag(false);
      }
    } else {
      setBoardStandTaskFinish(false);
      setActivityStandTaskFinish(false);
      setFunFactStandTaskFinish(false);
      setPointCollectStandTaskFinish(false);

      setVideoDispFlag(false);
      setTaskFinishFlag(true);
      setStartTextFlag(false);
      setbgPlayFlag(true);
    }
  };

  const onPressActiFunFactSubmit = () => {
    setInputSuccessDispFlag(true);
    setActivityDispInputFlag(false);
    setfunfactDispInputFlag(false);
  };
  const onPressActiFunFactClose = () => {
    setBoardStandTaskFinish(false);
    setActivityStandTaskFinish(false);
    setFunFactStandTaskFinish(false);
    setPointCollectStandTaskFinish(false);

    setFunfactStandPinFlag(false);
    setActivityStandPinFlag(false);

    setActivityDispInputFlag(false);
    setfunfactDispInputFlag(false);
  };
  const onPressCloseSuccessDialog = () => {
    setBoardStandTaskFinish(false);
    setActivityStandTaskFinish(false);
    setFunFactStandTaskFinish(false);
    setPointCollectStandTaskFinish(false);

    setFunfactStandPinFlag(false);
    setActivityStandPinFlag(false);
    setInputSuccessDispFlag(false);
    setBroodyMessageBoxText(String.alert_click_play_to_continue);
    if (currentLevelIndex === 0) {
      setBroodyMessageBoxTextAnimFlag(true);
    } else {
      setBroodyMessageBoxTextAnimFlag(false);
    }
    setBroodyMessageBox(true);
  };
  const onPressOkSuccessDialog = () => {
    setBoardStandTaskFinish(false);
    setActivityStandTaskFinish(false);
    setFunFactStandTaskFinish(false);
    setPointCollectStandTaskFinish(false);

    setFunfactStandPinFlag(false);
    setActivityStandPinFlag(false);
    setInputSuccessDispFlag(false);
    setBroodyMessageBoxText(String.alert_click_play_to_continue);
    if (currentLevelIndex === 0) {
      setBroodyMessageBoxTextAnimFlag(true);
    } else {
      setBroodyMessageBoxTextAnimFlag(false);
    }
    setBroodyMessageBox(true);
  };

  const onPressFunFactOption = () => {
    // var tempFuncount = parseInt(funfactTaskCount)
    tempFuncount = tempFuncount + 1;
    // setFunFactCount((state)=>state+1)
    setFunFactMultipleFlag(false);
    // console.log("Current task count normal temp is : " + tempFuncount);
    // console.log("Current task count normal is : " + funfactTaskCount);
    if (
      tempFuncount >=
      currentLevelVideoList[parseInt(currentTaskCount - 1)].funfacts.length
    ) {
      // console.log("Current task count in temp is : " + tempFuncount);
      // console.log("Current task count in is : " + funfactTaskCount);

      setFunFactFlag(false);
      setFunFactSingleFlag(false);
      setFunFactMultipleFlag(false);
      setFunFactCount(0);
      tempFuncount = 0;

      // setFunFactStandTaskFinish(true);

      setBoardStandTaskFinish(false);
      setActivityStandTaskFinish(false);
      setFunFactStandTaskFinish(false);
      setPointCollectStandTaskFinish(false);

      setFunfactStandPinFlag(false);

      if (
        currentLevelVideoList[parseInt(currentTaskCount - 1)]
          .message_input_flag !== "no"
      ) {
        // setUserInputMsgFlag(true);
        if (
          currentLevelVideoList[parseInt(currentTaskCount - 1)]
            .message_input_flag === "true"
        ) {
          setInputMessage(
            currentLevelVideoList[parseInt(currentTaskCount - 1)].end_message
          );
          setActivityDispInputFlag(true);
        } else {
          setBoardStandTaskFinish(false);
          setActivityStandTaskFinish(false);
          setFunFactStandTaskFinish(false);
          setPointCollectStandTaskFinish(false);

          setFunfactStandPinFlag(false);
          setBroodyMessageBoxText(
            currentLevelVideoList[parseInt(currentTaskCount - 1)].end_message
          );
          if (currentLevelIndex === 0) {
            setBroodyMessageBoxTextAnimFlag(true);
          } else {
            setBroodyMessageBoxTextAnimFlag(false);
          }
          setBroodyMessageBox(true);
          setStartTextFlag(false);
          setTaskFinishFlag(true);
          setVideoDispFlag(false);
        }
      } else {
        setBoardStandTaskFinish(false);
        setActivityStandTaskFinish(false);
        setFunFactStandTaskFinish(false);
        setPointCollectStandTaskFinish(false);

        setFunfactStandPinFlag(false);

        setFunfactStandPinFlag(false);
        setVideoDispFlag(false);
        setTaskFinishFlag(true);
        setStartTextFlag(false);
        setBroodyMessageBoxText(String.alert_click_play_to_continue);
        if (currentLevelIndex === 0) {
          setBroodyMessageBoxTextAnimFlag(true);
        } else {
          setBroodyMessageBoxTextAnimFlag(false);
        }
        setBroodyMessageBox(true);
        // setbgPlayFlag(true);
      }
    } else {
      console.log("Current task count is : " + funfactTaskCount);
      setFunFactCount(tempFuncount);
      setFunFactMultipleFlag(true);
    }
  };
  const onPressTaskFinish = () => {
    console.log("Inside onPressTaskFinish");
    if (
      currentLevelVideoList[parseInt(currentTaskCount - 1)]
        .activity_input_flag !== "no"
    ) {
      setUserInputActivityFlag(true);
    } else if (
      currentLevelVideoList[parseInt(currentTaskCount - 1)].funfact_flag ===
      "true"
    ) {
      setFunFactFlag(true);
      if (
        currentLevelVideoList[parseInt(currentTaskCount - 1)].funfact_type ===
        "sing"
      ) {
        setFunFactSingleFlag(true);
      } else if (
        currentLevelVideoList[parseInt(currentTaskCount - 1)].funfact_type ===
        "mul"
      ) {
        setFunFactMultipleFlag(true);
      } else {
        setFunFactFlag(false);
        setFunFactSingleFlag(false);
        setFunFactMultipleFlag(false);
      }
    } else {
      if (
        currentLevelVideoList[parseInt(currentTaskCount - 1)]
          .message_input_flag !== "no"
      ) {
        setUserInputMsgFlag(true);
      } else {
        setVideoDispFlag(false);
        setTaskFinishFlag(true);
        setStartTextFlag(false);
        setbgPlayFlag(true);
      }
    }

    // =================================Old

    // setTaskBoardStandFlag(false);
  };

  const onPressVideoFinish = () => {
    console.log("video finish");
    if (soundBgPlayFlag) {
      playBgSound();
    }
    setVideoDispFlag(false);
    setTaskFinishFlag(true);
    setStartTextFlag(false);

    // New integration
    setTaskBoardStandFlag(false);
    setBoardStandTaskFinish(true);
    setRewardStandFlag(true);
    setRewardCollectMsgFlag(true);
    // setBroodyMessageBoxText(String.alert_click_play_to_continue);
    // setBroodyMessageBox(true);

    stopBg();
    // setbgPlayFlag(true);
  };

  const onPressLevelChange = (index) => {
    pauseBgSound();
    stopBg();
    // if (
    //   levelList[tempindex] !== undefined &&
    //   levelList[tempindex].level_id !== undefined
    // ) {
    //   console.log("Current Index is : " + tempindex);
    //   let levelid = levelList[tempindex].level_id;
    //   console.log("Current level id is : " + levelid);
    //   console.log(
    //     "Current intro video url  : " + levelList[tempindex].intro_video_url
    //   );

    //   if (levelList[tempindex].intro_video_flag === "true") {
    //     // setIntroBannerDispFlag(true)
    //     // setIntroVideoDispFlag(true);
    //     setIntroMessageData(levelList[tempindex].intro_end_message);
    //     setIntroBannerLabel(levelList[tempindex].level_name);
    //     setIntroVideoUrls(levelList[tempindex].intro_video_url);
    //     console.log(
    //       "Current intro video url  : " + levelList[tempindex].intro_video_url
    //     );
    //   } else {
    //     setIntroVideoUrls("");
    //     setIntroBannerLabel("");
    //     setIntroMessageData("");
    //     setIntroVideoDispFlag(false);
    //   }

    //   if (levelList[tempindex].summery_video_flag === "true") {
    //     setSummaryVideoDispFlag(true);
    //     setSummaryVideoUrls(levelList[tempindex].summery_video_url);
    //     setSummaryMessageData(levelList[tempindex].summery_end_message);
    //     console.log(
    //       "Current intro video url  : " + levelList[tempindex].summery_video_url
    //     );
    //   } else {
    //     setSummaryVideoUrls("");
    //     setSummaryMessageData("");
    //     setSummaryVideoDispFlag(false);
    //   }

    //   // Call videolist detail
    //   var payloadCreator = new Object();
    //   payloadCreator["extraParams"] = {
    //     level_id: levelid,
    //     uid: "36921c0c19",
    //   };
    //   dispatch(request_Level_VideoList(payloadCreator));
    // }

    console.log("change" + index);
    setVideoLoadingFlag(false);
    setValueX(0);
    setValueCharX(5);
    setStartTextFlag(true);
    setCharReachEndFlag(false);
    setCharReachEndStopFlag(false);
    setLevelFinishFlag(false);

    setCurrentTaskCount(1);
    setCurrentTaskCountWords("first");
    setTaskBoardStandFlag(false);
    setVideoDispFlag(false);
    setTaskFinishFlag(false);

    setBroodyMessageBox(false)
    setLevelExpandFlag(false);
    setCurrentLevelIndex(index);
    // this one can we set according to task in api
    // setTaskCountState(parseInt(responseLevelListdefault[index].level_breaks));
    setTaskCountState(parseInt(levelList[index].level_breaks));
    // setIntroVideoDispFlag(true);
    setCurrentLevelDetail(levelList[index]);

    if (levelList[index].intro_video_flag === "true") {
      // setIntroVideoDispFlag(true);
      setIntroMessageData(levelList[index].intro_end_message);
      setIntroVideoUrls(levelList[index].intro_video_url);
    } else {
      setIntroVideoUrls("");
      setIntroMessageData("");
      setIntroVideoDispFlag(false);
    }

    if (levelList[index].summery_video_flag === "true") {
      // setSummaryVideoDispFlag(true);
      setSummaryVideoUrls(levelList[index].summery_video_url);
      setSummaryMessageData(levelList[index].summery_end_message);
    } else {
      setSummaryVideoUrls("");
      setSummaryMessageData("");
      setSummaryVideoDispFlag(false);
    }

    console.log("Level id : " + levelList[index].level_id);
    console.log("Level id : " + levelList[index].level_bg_img);
    getLevelDetails(index);
  };
  const onPressNoSummaryVideoMessage = () => {
    setSummaryVideoEndMessageDispFlag(true);
    setCharReachEndStopFlag(false);

    // resetAllState();
  };
  const onPressYesSummaryVideoMessage = () => {
    setSummaryVideoEndMessageDispFlag(true);
    setCharReachEndStopFlag(false);
    // resetAllState();
  };
  // ==== End Click Event ===

  const getLevelDetails = (tempindex) => {
    setCurrentLevelIndex(tempindex);

    if (
      levelList[tempindex] !== undefined &&
      levelList[tempindex].level_id !== undefined
    ) {
      console.log("Current Index is : " + tempindex);
      let levelid = levelList[tempindex].level_id;
      console.log("Current level id is : " + levelid);
      console.log(
        "Current intro video url  : " + levelList[tempindex].intro_video_url
      );

      tempTaskCount = 0;
      setActivityStandTaskFinish(false);
      setFunFactStandTaskFinish(false);
      setBoardStandTaskFinish(false);
      setPointCollectStandTaskFinish(false);
      setCharReachEndStopDelayFlag(false);

      if (levelList[tempindex].intro_video_flag === "true") {
        // setIntroBannerDispFlag(true)
        // setIntroVideoDispFlag(true);
        setIntroMessageData(levelList[tempindex].intro_end_message);
        setIntroBannerLabel(levelList[tempindex].level_name);
        setIntroVideoUrls(levelList[tempindex].intro_video_url);
        console.log(
          "Current intro video url  : " + levelList[tempindex].intro_video_url
        );
      } else {
        setIntroVideoUrls("");
        setIntroBannerLabel("");
        setIntroMessageData("");
        setIntroVideoDispFlag(false);
      }

      if (levelList[tempindex].summery_video_flag === "true") {
        // setSummaryVideoDispFlag(true);
        setSummaryVideoUrls(levelList[tempindex].summery_video_url);
        setSummaryMessageData(levelList[tempindex].summery_end_message);
        console.log(
          "Current intro video url  : " + levelList[tempindex].summery_video_url
        );
      } else {
        setSummaryVideoUrls("");
        setSummaryMessageData("");
        setSummaryVideoDispFlag(false);
      }

      // Call videolist detail
      var payloadCreator = new Object();
      payloadCreator["extraParams"] = {
        level_id: levelid,
        uid: g_uid,
      };
      dispatch(request_Level_VideoList(payloadCreator));
    }
  };

  // ==== Loader ====
  const loader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          position: "absolute",
        }}
      >
        <ActivityIndicator size="large" color="orange" />
      </View>
    );
  };

  const Innerloader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          position: "absolute",
          backgroundColor: "white",
        }}
      >
        <ActivityIndicator size="large" color="orange" />
      </View>
    );
  };

  const displayLevelFinish = () => {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            // width: "96%",
            // height: "96%",
            // margin: "1%",
            width: "85%",
            height: "85%",

            justifyContent: "center",
            alignSelf: "center",
            alignItems: "center",
            position: "absolute",
            backgroundColor: "#5EC8F3",
            borderColor: "white",
            borderWidth: 10,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            // backgroundColor: "black",
          }}
        >
          <ImagePress
            source={IMAGE_TROPHY}
            style={{ width: "30%", height: "30%", resizeMode: "contain" }}
          />
          <Text
            style={{
              fontSize: heightToDp(4),
              color: "black",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: "2%",
            }}
          >
            {levelList[currentLevelIndex].bmsg_end_lvl}
            {/**{String.congratulation_lession}*/}
          </Text>
          {/** <Text
          style={{
            fontSize: heightToDp(4),
            color: "black",
            fontWeight: "bold",
            textAlign: "center",
            marginTop: "2%",
          }}
        >
          {String.congratulation_next_lesson}
          {String.start_quote}
          {levelList[currentLevelIndex+1].level_name.split(":")[1].toUpperCase()}
          {String.start_quote}
        </Text> */}
          <ImagePress
            onPress={onPressCollectAward}
            source={IMAGE_BTN_OK}
            style={{
              width: "25%",
              height: "12%",
              resizeMode: "contain",
              marginTop: "4%",
            }}
          />
        </View>
      </View>
    );
  };
  // ==== End Loader ====

  //   ==== Design ======
  const loadfirstImage = () => {
    return (
      <Image
        ref={imageRef}
        style={{
          width: imgWidth,
          // width:'20000%',
          height: "100%",
          resizeMode: "cover",
          // resizeMode: "repeat",
          transform: [{ translateX: 0 }],
          // position: "relative",
          // position: "absolute",
        }}
        onError={(e) => {
          console.log("Image loading error :  " + JSON.stringify(e));
        }}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          const windowWidth = Dimensions.get("window").width;
          // console.log("Tag Component width is: " + layout.width);
          // console.log("Tag Component height is: " + layout.height);
          // console.log("Tag X offset to page: " + layout.x);

          // console.log("Tag Y offset to page: " + layout.y);
          setTravelEndx(-(layout.width - windowWidth));
          // setimgWdth(layout.width)
          console.log("imgWidth : ", imgWidth);
          console.log("Travel End 1 : " + travelEndx);
        }}
        onLoadStart={() => {
          setInnerLoadingFlag(false);
          // console.log("\n\n\n\n On Load Start =========== \n\n\n");
        }}
        // onLoad={() => {
        //   setInnerLoadingFlag(false);
        //   // console.log("\n\n\n\n On Load =========== \n\n\n");
        // }}
        resizeMethod="resize"
        imageStyle={{ resizeMode: "repeat" }}
        // resizeMode="repeat"
        source={{
          // uri: "https://apils.happigenius.com/uploads/levels/bg/062420210118162454070654694722160d486221967f.jpg",
          // uri: URL_BGIMAGE + apiLevelResponse.result.data[0].level_bg_img,
          // uri: responseLevelListdefault[currentLevelIndex].level_bg_img,
          uri: levelList[currentLevelIndex].level_bg_img,
          cache:'force-cache'
        }}
      />
    );
  };

  const loadSecondImage = () => {
    return (
      <Image
        ref={imageRef}
        style={{
          width: imgWidth,
          // width:'20000%',
          height: "100%",
          // left:(travelEndx),
          resizeMode: "cover",
          // resizeMode: "repeat",
          position: "relative",
          transform: [{ translateX: 0 }],
        }}
        onError={(e) => {
          console.log("Image loading error :  " + JSON.stringify(e));
        }}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          const windowWidth = Dimensions.get("window").width;
          // console.log("Tag Component width is: " + layout.width);
          // console.log("Tag Component image width is: " + imgWidth);
          // console.log("Tag Component height is: " + layout.height);
          // console.log("Tag X offset to page: " + layout.x);
          // console.log("Tag Y offset to page: " + layout.y);
          setTravelEndx(-(layout.width - windowWidth));
          // setimgWdth(layout.width)
          console.log("Travel End 2 : " + travelEndx);
          // console.log("Tag travel Endx : " + travelEndx);
        }}
        onLoadStart={() => {
          setInnerLoadingFlag(false);
          // setSecondImageLoadFlag(false)
          // console.log("\n\n\n\n On Load Start =========== \n\n\n");
        }}
        // onLoad={() => {
        //   setInnerLoadingFlag(false);
        //   // console.log("\n\n\n\n On Load =========== \n\n\n");
        // }}
        resizeMethod="resize"
        imageStyle={{ resizeMode: "repeat" }}
        // resizeMode="repeat"
        source={{
          // uri: "https://apils.happigenius.com/uploads/levels/bg/062420210118162454070654694722160d486221967f.jpg",
          // uri: URL_BGIMAGE + apiLevelResponse.result.data[0].level_bg_img,
          // uri: responseLevelListdefault[currentLevelIndex].level_bg_img,
          uri: levelList[currentLevelIndex].level_bg_img,
          cache:'force-cache'
        }}
      />
    );
  };

  const backGroundImage = () => {
    // console.log("my image task count is : " + tempViews.length);
    // console.log("my image task count is task count : " + taskCountState);

    //  tempViews.push(loadfirstImage());
    // console.log("my image task count is : " + tempViews.length);
    //  tempViews.push(loadfirstImage());
    //  tempViews.push(loadfirstImage());
    //  tempViews.push(loadfirstImage());
    // for (let imginit = 0; imginit < currentLevelVideoList.length; imginit++) {
    console.log("Length is : ", currentLevelVideoList.length);
    for (
      let imginit = 0;
      imginit < currentLevelVideoList.length + 10;
      imginit++
    ) {
      // for (let imginit = 0; imginit < 12; imginit++) {
      // tempViews.push(loadfirstImage());
      if (imginit === 0) {
        tempViews.push(loadfirstImage());
      } else {
        tempViews.push(loadSecondImage());
      }
    }
    return tempViews.sort(function (a, b) {
      return a - b;
    });
  };

  const backGround = () => {
    return (
      <View
        style={{
          position: "absolute",
          flexDirection: "row",
          height: "100%",
          width: "100%",
        }}
      >
        <Animated.View
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            flexDirection: "row",
            transform: [{ translateX: valuex }],
          }}
        >
          {/**backGroundImage()*/}
          {backGroundImage()}
        </Animated.View>
      </View>
    );
  };

  const character = () => {
    return (
      <View
        style={{
          width: "20%",
          bottom: bottomCharacterMargine,
          height: "25%",
          // backgroundColor: "white",
          resizeMode: "contain",
          position: "absolute",
          transform: [{ translateX: valueCharx }],
        }}
      >
        <ImagePressOnLoadAnim
          resizeMethod="resize"
          style={{
            width: "100%",
            // bottom: bottomMargine,
            height: "100%",
            // backgroundColor: "white",
            resizeMode: "contain",
            // position: "absolute",
            // transform: [{ translateX: valueCharx }],
          }}
          source={IMAGE_CHARACTER}
        />
      </View>
    );
  };

  const startText = () => {
    if (levelList[currentLevelIndex].bmsg_start_lvl !== "") {
      return (
        <BroodyMsgBox
          // message={
          //   String.start_text +
          //   prefUserName +
          //   String.start_text_1 +
          //   String.start_play_text
          // }
          // animFlag={currentLevelIndex === 0?true:false}
          animFlag={true}
          message={levelList[currentLevelIndex].bmsg_start_lvl}
          boxwidth="17%"
        />
      );
    } else {
      return (
        <BroodyMsgBox
          // message={
          //   String.start_text +
          //   prefUserName +
          //   String.start_text_1 +
          //   String.start_play_text
          // }
          // animFlag={currentLevelIndex === 0?true:false}
          animFlag={true}
          message={String.start_text + (currentLevelIndex + 1) + "."}
          boxwidth="17%"
        />
      );
    }
  };

  const activityText = (messageDispBroody, animdata) => {
    if (
      messageDispBroody === "It’s Activity Time!" ||
      messageDispBroody === "Time for a few Fun Facts!" ||
      messageDispBroody === "Time for your Fun Facts quiz."||
      messageDispBroody === "Click on the PLAY button to continue."
    ) {
      return (
        <BroodyMsgBox
          message={messageDispBroody}
          boxwidth="14%"
          animFlag={animdata}
        />
      );
    } else {
      return (
        <BroodyMsgBox
          message={messageDispBroody}
          boxwidth="22%"
          animFlag={animdata}
        />
      );
    }
  };

  const taskBoard_Title = () => {
    return (
      <TouchableWithoutFeedback onPress={onPressStandBoard}>
        <View
          style={{
            width: "25%",
            height: "50%",
            position: "absolute",
            bottom: bottomMargineStand,
            resizeMode: "contain",
            transform: [{ translateX: parseInt(Math.abs(travelEndx) / 4) / 2 }],
          }}
        >
          <ImagePress
            onPress={onPressStandBoard}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              // bottom: bottomMargineStand,
              resizeMode: "contain",
              // transform: [{ translateX: parseInt(Math.abs(travelEndx) / 4) / 2 }],
            }}
            source={IMAGE_LEVEL_STAND}
          />
          <Text
            onPress={onPressStandBoard}
            style={{
              width: "100%",
              fontSize: 16,
              color: "white",
              top: 0,
              textAlign: "center",
              padding: "2%",
              position: "absolute",
              // transform: [{ translateX: parseInt(Math.abs(travelEndx) / 4) / 2 }],
            }}
            numberOfLines={1}
          >
            {currentLevelVideoList[currentTaskCount - 1].label}
          </Text>
        </View>
      </TouchableWithoutFeedback>
    );
  };
  const taskBoard = () => {
    return (
      <TouchableWithoutFeedback onPress={onPressStandBoard}>
        <View
          style={{
            width: "25%",
            height: "50%",
            position: "absolute",
            bottom: bottomMargineTaskboardStand,
            resizeMode: "contain",
            left: "42%",
            // transform: [{ translateX: parseInt(Math.abs(travelEndx) / 4) / 2 }],
          }}
        >
          <ImagePressNoAnim
            onPress={onPressStandBoard}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              // bottom: bottomMargineStand,
              resizeMode: "contain",
              // transform: [{ translateX: parseInt(Math.abs(travelEndx) / 4) / 2 }],
            }}
            source={IMAGE_LEVEL_STAND}
          />
          <Text
            onPress={onPressStandBoard}
            style={{
              width: "100%",
              fontSize: 16,
              color: "white",
              top: 0,
              textAlign: "center",
              padding: "2%",
              position: "absolute",
              // transform: [{ translateX: parseInt(Math.abs(travelEndx) / 4) / 2 }],
            }}
            numberOfLines={1}
          ></Text>
        </View>
      </TouchableWithoutFeedback>
    );
  };

  const endBoard = () => {
    return (
      <ImagePressNoAnim
        source={IMAGE_END_FLAG}
        style={{
          width: "25%",
          height: "50%",
          right: "10%",
          position: "absolute",
          bottom: bottomMargineEndStand,
          resizeMode: "contain",
          // transform: [{ translateX: parseInt(Math.abs(travelEndx) / 4) }],
          // transform: [{ translateX: parseInt(Math.abs(travelEndx) / 4) / 2 }],
        }}
      />
    );
  };

  const onPressActivityPinBoard = () => {
    console.log("Click on onPressActivityPinBoard");
    // activityDispFlag
    setBroodyMessageBox(false);
    setActivityDispFlag(true);
  };
  const onPressFunFactPinBoard = () => {
    console.log("Click on onPressFunFactPinBoard");
    setBroodyMessageBox(false);
    if (
      currentLevelVideoList[parseInt(currentTaskCount - 1)].funfact_type ===
      "sing"
    ) {
      setFunFactSingleFlag(true);
    } else if (
      currentLevelVideoList[parseInt(currentTaskCount - 1)].funfact_type ===
      "mul"
    ) {
      setFunFactMultipleFlag(true);
    } else {
      setFunFactFlag(false);
      setFunFactSingleFlag(false);
      setFunFactMultipleFlag(false);
    }
  };

  const rewardBoard = () => {
    if (gifPlay) {
      return (
        <ImagePressNoAnim
          onPress={onPressAndCollectReward}
          style={{
            // width: "15%",
            // height: "35%",
            // position: "absolute",
            // top: bottomMargineStand,
            // resizeMode: "contain",
            // transform: [{ translateX: parseInt(Math.abs(travelEndx) / 4) / 2 }],
            width: "90%",
            height: "58%",
            position: "absolute",
            top: bottomMargineStand,
            resizeMode: "contain",
            // transform: [{ translateX: parseInt(Math.abs(travelEndx) / 4) / 2 - 470 }],
            transform: [{ translateX: parseInt(Math.abs(travelEndx) / 4) / 3 }],
          }}
          source={IMAGE_BLAST}
        />
      );
    } else {
      return (
        <ImagePressNoAnim
          onPress={onPressAndCollectReward}
          style={{
            // width: "15%",
            // height: "35%",
            width: "90%",
            height: "58%",
            position: "absolute",
            top: bottomMargineStand,
            resizeMode: "contain",
            // backgroundColor:'white',
            transform: [{ translateX: parseInt(Math.abs(travelEndx) / 4) / 3 }],
          }}
          source={IMAGE_REWARD_STAND}
        />
      );
    }
  };

  const bgController = () => {
    return (
      <View
        style={{
          height: "15%",
          width: "45%",
          flexDirection: "row",
          bottom: 0,
          marginBottom: "0.2%",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          position: "absolute",
        }}
      >
        <ImagePress
          onPress={onPressRewind}
          source={IMAGE_BT_REWIND}
          style={{
            width: "10%",
            height: "70%",
            resizeMode: "contain",
          }}
        />
        <ImagePressNoAnim
          onPress={onPressPlay}
          source={IMAGE_BT_PLAY}
          style={{
            width: "15%",
            height: "100%",
            marginLeft: "2%",
            marginRight: "2%",
            resizeMode: "contain",
          }}
        />
        <ImagePress
          onPress={onPressPause}
          source={IMAGE_BT_PAUSE}
          style={{
            width: "10%",
            height: "70%",
            resizeMode: "contain",
          }}
        />
      </View>
    );
  };

  const otherConstroller = () => {
    return (
      <View
        style={{
          alignSelf: "flex-end",
          flexDirection: "column",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
          width: "5%",
          height: "100%",
          position: "absolute",
        }}
      >
        <View
          style={{
            backgroundColor: "rgba(94, 200, 243, 0.3)",
            alignSelf: "flex-end",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            width: "100%",
            height: "35%",
            borderTopLeftRadius: 40,
            borderBottomLeftRadius: 40,
            position: "absolute",
          }}
        >
          <ImagePress
            onPress={onPressMusic}
            source={soundBgPlayFlag ? IMAGE_RP_MUSIC_ON : IMAGE_RP_MUSIC_OFF}
            style={{
              width: "60%",
              height: "14%",
              marginBottom: 16,
              resizeMode: "contain",
            }}
          />

          {animAwardFlag ? (
            <ImagePressOnLoadAnim
              onPress={onPressReward}
              source={IMAGE_RP_AWARD_ANIM}
              style={{
                width: "60%",
                height: "14%",
                marginBottom: 16,
                resizeMode: "contain",
              }}
            />
          ) : (
            <ImagePress
              onPress={onPressReward}
              source={IMAGE_RP_AWARD}
              style={{
                width: "60%",
                height: "14%",
                marginBottom: 16,
                resizeMode: "contain",
              }}
            />
          )}

          <ImagePress
            onPress={onPressHelp}
            source={IMAGE_RP_HELP}
            style={{
              width: "60%",
              height: "14%",
              // marginBottom: 16,
              resizeMode: "contain",
            }}
          />
          {/**<ImagePress
            onPress={onPressSetting}
            source={IMAGE_RP_SETTING}
            style={{
              width: "60%",
              height: "14%",
              resizeMode: "contain",
            }}
          />*/}
        </View>
      </View>
    );
  };

  // Level Change click on  level list

  const levelItem_android = (item, index) => {
    return (
      <Text
        onPress={() => {
          onPressLevelChange(index);
        }}
        numberOfLines={1}
        style={{
          backgroundColor: "rgba(94, 200, 243, 1)",
          // width: "80%",
          textAlign: "center",
          // padding: "8%",
          // borderRadius: 60,
          marginTop: "4%",
          color: "white",
          fontWeight: "bold",
          fontSize: 16,
          width: 150,
          height: 30,
          textAlignVertical: "center",
          backgroundColor: "rgba(94, 200, 243, 1)",
          // paddingTop: "4%",
          // paddingBottom: "4%",
          paddingLeft: "6%",
          paddingRight: "6%",
          borderRadius: 20,
        }}
      >
        {item.level_name}
      </Text>
    );
  };

  const getItemColor = (index) => {
    switch (index) {
      case 0:
        return "#5DC8F2";
        break;
      case 1:
        return "#FEA444";
        break;
      case 2:
        return "#7E42E0";
        break;
      case 3:
        return "#5DC8F2";
        break;
      case 4:
        return "#FEA444";
        break;
      case 5:
        return "#7E42E0";
        break;
      case 6:
        return "#5DC8F2";
        break;
      case 7:
        return "#FEA444";
        break;
      case 8:
        return "#7E42E0";
        break;
      case 9:
        return "#5DC8F2";
        break;
      case 10:
        return "#FEA444";
        break;
      case 11:
        return "#7E42E0";
        break;

      default:
        return "#5DC8F2";
        break;
    }
  };

  const detectLevelChangeClick = (currentLevelIndex, index) => {
    if (currentLevelIndex === index) {
      setLevelExpandFlag(false);
    } else {
      detectActualLevelAndClick(index - 1, index);
    }
  };
  const detectActualLevelAndClick = (checkValue, pressableIndex) => {
    console.log("checkValue : " + checkValue);
    console.log("pressableIndex : " + pressableIndex);
    if (checkValue === -1) {
      checkValue = 0;
    }
    switch (checkValue) {
      case 0:
        if (lesson1 === "true") {
          onPressLevelChange(pressableIndex);
        } else {
          commonDisplayToast();
        }
        break;
      case 1:
        if (lesson2 === "true") {
          onPressLevelChange(pressableIndex);
        } else {
          commonDisplayToast();
        }
        break;
      case 2:
        if (lesson3 === "true") {
          onPressLevelChange(pressableIndex);
        } else {
          commonDisplayToast();
        }
        break;

      case 3:
        if (lesson4 === "true") {
          onPressLevelChange(pressableIndex);
        } else {
          commonDisplayToast();
        }
        break;
      case 4:
        if (lesson5 === "true") {
          onPressLevelChange(pressableIndex);
        } else {
          commonDisplayToast();
        }
        break;
      case 5:
        if (lesson6 === "true") {
          onPressLevelChange(pressableIndex);
        } else {
          commonDisplayToast();
        }
        break;
      case 6:
        if (lesson7 === "true") {
          onPressLevelChange(pressableIndex);
        } else {
          commonDisplayToast();
        }
        break;
      case 7:
        if (lesson8 === "true") {
          onPressLevelChange(pressableIndex);
        } else {
          commonDisplayToast();
        }
        break;
      case 8:
        if (lesson9 === "true") {
          onPressLevelChange(pressableIndex);
        } else {
          commonDisplayToast();
        }
        break;
      case 9:
        if (lesson10 === "true") {
          onPressLevelChange(pressableIndex);
        } else {
          commonDisplayToast();
        }
        break;
      case 10:
        if (lesson11 === "true") {
          onPressLevelChange(pressableIndex);
        } else {
          commonDisplayToast();
        }
        break;
      case 11:
        if (lesson12 === "true") {
          onPressLevelChange(pressableIndex);
        } else {
          commonDisplayToast();
        }
        break;
    }
  };
  const commonDisplayToast = () => {
    setToastText(String.toast_message_level_change);
    setToastFlag(true);
  };

  const detectLevelLock = (currentLevelIndex, index) => {
    // console.log("Level Complete :" + index);
    switch (index) {
      case 0:
        if (lesson1 === "true") {
          return setLockOnItem(false);
        } else {
          return setLockOnItem(true);
        }
        break;
      case 1:
        if (lesson2 === "true") {
          return setLockOnItem(false);
        } else {
          return setLockOnItem(true);
        }
        break;
      case 2:
        if (lesson3 === "true") {
          return setLockOnItem(false);
        } else {
          return setLockOnItem(true);
        }
        break;
      case 3:
        if (lesson4 === "true") {
          return setLockOnItem(false);
        } else {
          return setLockOnItem(true);
        }
        break;
      case 4:
        if (lesson5 === "true") {
          return setLockOnItem(false);
        } else {
          return setLockOnItem(true);
        }
        break;
      case 5:
        if (lesson6 === "true") {
          return setLockOnItem(false);
        } else {
          return setLockOnItem(true);
        }
        break;
      case 6:
        if (lesson7 === "true") {
          return setLockOnItem(false);
        } else {
          return setLockOnItem(true);
        }
        break;
      case 7:
        if (lesson8 === "true") {
          return setLockOnItem(false);
        } else {
          return setLockOnItem(true);
        }
        break;
      case 8:
        if (lesson9 === "true") {
          return setLockOnItem(false);
        } else {
          return setLockOnItem(true);
        }
        break;
      case 9:
        if (lesson10 === "true") {
          return setLockOnItem(false);
        } else {
          return setLockOnItem(true);
        }
        break;
      case 10:
        if (lesson11 === "true") {
          return setLockOnItem(false);
        } else {
          return setLockOnItem(true);
        }
        break;
      case 11:
        if (lesson12 === "true") {
          return setLockOnItem(false);
        } else {
          return setLockOnItem(true);
        }
        break;

      default:
        return setLockOnItem(true);
        break;
    }
  };
  const setLockOnItem = (lockFlag) => {
    if (lockFlag) {
      return (
        <Image
          source={IMAGE_LOCK}
          style={{
            height: "100%",
            width: "10%",
            alignSelf: "center",
            resizeMode: "contain",
          }}
        />
      );
    } else {
      return null;
    }
  };

  const levelItemWeb = (item, index) => {
    return (
      <View
        style={{
          width: "100%",
          height: "80%",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "6%",
          borderRadius: 26,
          flexDirection: "row",
          backgroundColor: getItemColor(index),
          padding: "6%",
        }}
      >
        <Text
          onPress={() => {
            // detectLevelChangeClick(currentLevelIndex, index);
            onPressLevelChange(index);
          }}
          style={{
            textAlign: "center",
            height: "100%",
            width: "90%",
            fontSize: heightToDp(2.2),
            fontWeight: "bold",
            color: "white",
            textAlignVertical: "center",
            alignSelf: "center",
          }}
          numberOfLines={1}
        >
          {item.level_name.split(":")[0].toUpperCase()}
        </Text>

        {/**detectLevelLock(currentLevelIndex, index)*/}
      </View>
    );
  };
  const levelItemApp = (item, index) => {
    return (
      <View
        style={{
          width: "100%",
          // height: "30%",
          // width: 150,
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "4%",
          borderRadius: 26,
          flexDirection: "row",
          backgroundColor: getItemColor(index),
          padding: "6%",
        }}
      >
        <Text
          onPress={() => {
            detectLevelChangeClick(currentLevelIndex, index);
            // onPressLevelChange(index);
          }}
          style={{
            textAlign: "center",
            height: "100%",
            width: "90%",
            fontSize: heightToDp(2.2),
            
            fontWeight: "bold",
            color: "white",
            textAlignVertical: "center",
            alignSelf: "center",
          }}
          numberOfLines={1}
        >
          {item.level_name.split(":")[0].toUpperCase()}
        </Text>

        {detectLevelLock(currentLevelIndex, index)}
      </View>
    );
  };
  const levelItem = (item, index) => {
    if (DEFAUL_BUILD_TYPE === BUILD_TYPE_WEB) {
      return levelItemWeb(item, index);
    } else {
      return levelItemApp(item, index);
    }
  };

  const levelUI = () => {
    return (
      <View
        style={{
          width: "12%",
          height: "75%",
          bottom: "3.5%",
          justifyContent: "center",
          position: "absolute",
          right: "10%",
          flex: 1,
        }}
      >
        <ImagePress
          source={IMAGE_QT_CLOSE}
          style={{
            width: "25%",
            height: "25%",
            resizeMode: "contain",
            alignSelf: "center",
          }}
          onPress={() => {
            if (levelExpandFlag) {
              setLevelExpandFlag(false);
            } else {
              setLevelExpandFlag(true);
            }
            console.log("click");
          }}
        />
        <FlatList
          data={levelList}
          renderItem={({ item, index }) => levelItem(item, index)}
          keyExtractor={(item, index) => item.level_id.toString()}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ flexGrow: 1 }}
          style={{ flex: 1 }}
          inverted={true}
        />
      </View>
    );
  };
  const levelSingle = () => {
    return (
      <View
        style={{
          width: "12%",
          height: "6%",
          bottom: "3.5%",
          justifyContent: "center",
          position: "absolute",
          right: "10%",
          borderRadius: 26,
          backgroundColor: "#5DC8F2",
        }}
      >
        <Text
          onPress={() => {
            if (levelExpandFlag) {
              setLevelExpandFlag(false);
            } else {
              setLevelExpandFlag(true);
            }
            console.log("current level");
          }}
          style={{
            textAlign: "center",
            fontSize: heightToDp(2.2),
            fontWeight: "bold",
            color: "white",
            padding: "1%",
          }}
          numberOfLines={1}
        >
          {levelList[currentLevelIndex].level_name.split(":")[0].toUpperCase()}
        </Text>
      </View>
    );
  };

  //End  Level Change click on  level list

  const handleSummaryAndLevelFinish = () => {
    if (charReachEndFlag) {
      if (summaryvideoDispFlag) {
        return (
          <SummaryVideonScreen
            onPress={onPressCloseSummaryVideoView}
            urlSource={summaryVideoUrls}
          />
        );
      } else {
        return displayLevelFinish();
      }
    } else {
      return null;
    }
  };

  const handleIntroAndMessage = () => {
    if (introvideoEndMessageDispFlag) {
      return (
        <VideoEndMessage
          message={introMessageData}
          collectionFlag={true}
          onPress={onPressCollectStarAtEnd}
        />
      );
    } else {
      return null;
    }
  };

  const callAPI_UserInputSubmitInsert = (receiveData) => {
    if (receiveData !== "" && receiveData !== null) {
      try {
        var payloadCreator = new Object();
        if (receiveData === "yes" || receiveData === "no") {
          payloadCreator["params"] = {
            uid: g_uid,
            // point: tempPoint.toString(),
            level_no: (parseInt(currentLevelIndex) + 1).toString(),
            funfact_user_input: receiveData.toString(),
          };
        } else {
          payloadCreator["params"] = {
            uid: g_uid,
            // point: tempPoint.toString(),
            level_no: (parseInt(currentLevelIndex) + 1).toString(),
            user_input: receiveData.toString(),
          };
        }
        dispatch(request_CreateUserInput(payloadCreator));
      } catch (error) {
        console.log("update user : " + JSON.stringify(error));
      }
    }
  };

  const onPressSubmitUserInputActivity = (receiveData) => {
    callAPI_UserInputSubmitInsert(receiveData);

    if (userInputActivityFlag) {
      setUserInputActivityFlag(false);
      if (
        currentLevelVideoList[parseInt(currentTaskCount - 1)].funfact_flag ===
        "true"
      ) {
        if (
          currentLevelVideoList[parseInt(currentTaskCount - 1)].funfact_type ===
          "sing"
        ) {
          setFunFactSingleFlag(true);
        } else if (
          currentLevelVideoList[parseInt(currentTaskCount - 1)].funfact_type ===
          "mul"
        ) {
          setFunFactMultipleFlag(true);
        } else {
          setFunFactFlag(false);
          setFunFactSingleFlag(false);
          setFunFactMultipleFlag(false);
        }
      } else if (
        currentLevelVideoList[parseInt(currentTaskCount - 1)]
          .message_input_flag !== "no"
      ) {
        setUserInputMsgFlag(true);
      } else {
        setVideoDispFlag(false);
        setTaskFinishFlag(true);
        setStartTextFlag(false);
        setbgPlayFlag(true);
      }
    }
  };

  const onPressUserInputYesActivity = () => {
    callAPI_UserInputSubmitInsert("yes");
    if (userInputActivityFlag) {
      setUserInputActivityFlag(false);
      if (
        currentLevelVideoList[parseInt(currentTaskCount - 1)].funfact_flag ===
        "true"
      ) {
        if (
          currentLevelVideoList[parseInt(currentTaskCount - 1)].funfact_type ===
          "sing"
        ) {
          setFunFactSingleFlag(true);
        } else if (
          currentLevelVideoList[parseInt(currentTaskCount - 1)].funfact_type ===
          "mul"
        ) {
          setFunFactMultipleFlag(true);
        } else {
          setFunFactFlag(false);
          setFunFactSingleFlag(false);
          setFunFactMultipleFlag(false);
        }
      } else if (
        currentLevelVideoList[parseInt(currentTaskCount - 1)]
          .message_input_flag !== "no"
      ) {
        setUserInputMsgFlag(true);
      } else {
        setVideoDispFlag(false);
        setTaskFinishFlag(true);
        setStartTextFlag(false);
        setbgPlayFlag(true);
      }
    }
    console.log("onPress UserInput Activity Yes");
  };
  const onPressUserInputNoActivity = () => {
    callAPI_UserInputSubmitInsert("no");
    if (userInputActivityFlag) {
      setUserInputActivityFlag(false);
      if (
        currentLevelVideoList[parseInt(currentTaskCount - 1)].funfact_flag ===
        "true"
      ) {
        if (
          currentLevelVideoList[parseInt(currentTaskCount - 1)].funfact_type ===
          "sing"
        ) {
          setFunFactSingleFlag(true);
        } else if (
          currentLevelVideoList[parseInt(currentTaskCount - 1)].funfact_type ===
          "mul"
        ) {
          setFunFactMultipleFlag(true);
        } else {
          setFunFactFlag(false);
          setFunFactSingleFlag(false);
          setFunFactMultipleFlag(false);
        }
      } else if (
        currentLevelVideoList[parseInt(currentTaskCount - 1)]
          .message_input_flag !== "no"
      ) {
        setUserInputMsgFlag(true);
      } else {
        setVideoDispFlag(false);
        setTaskFinishFlag(true);
        setStartTextFlag(false);
        setbgPlayFlag(true);
      }
    }
    console.log("onPress UserInput Activity No");
  };

  const onPressSubmitUserInputMsg = (receiveData) => {
    callAPI_UserInputSubmitInsert(receiveData);
    setUserInputMsgFlag(false);

    setVideoDispFlag(false);
    setTaskFinishFlag(true);
    setStartTextFlag(false);
    setbgPlayFlag(true);
    console.log("onPress SubmitUser Input : " + receiveData);
  };

  const onPressUserInputYesMsg = () => {
    callAPI_UserInputSubmitInsert("yes");
    setUserInputMsgFlag(false);

    setVideoDispFlag(false);
    setTaskFinishFlag(true);
    setStartTextFlag(false);
    setbgPlayFlag(true);
    console.log("onPress UserInput Msg Yes");
  };
  const onPressUserInputNoMsg = () => {
    callAPI_UserInputSubmitInsert("no");
    setUserInputMsgFlag(false);

    setVideoDispFlag(false);
    setTaskFinishFlag(true);
    setStartTextFlag(false);
    setbgPlayFlag(true);
    console.log("onPress UserInput Msg No");
  };

  const handleEachVideoActivityInput = () => {
    return (
      <VideoEndMessageInput
        detectDeviceType={detectDeviceType}
        message={
          currentLevelVideoList[parseInt(currentTaskCount - 1)].end_activity
        }
        layoutHandleFlag={
          currentLevelVideoList[parseInt(currentTaskCount - 1)]
            .activity_input_flag
        }
        onPressSubmitUserInput={onPressSubmitUserInputActivity}
        onPressNo={onPressUserInputNoActivity}
        onPressYes={onPressUserInputYesActivity}
        isActivity={true}
      />
    );
  };
  const handleEachVideoMessageInput = () => {
    return (
      <VideoEndMessageInput
        detectDeviceType={detectDeviceType}
        message={
          currentLevelVideoList[parseInt(currentTaskCount - 1)].end_message
        }
        layoutHandleFlag={
          currentLevelVideoList[parseInt(currentTaskCount - 1)]
            .message_input_flag
        }
        onPressSubmitUserInput={onPressSubmitUserInputMsg}
        onPressNo={onPressUserInputNoMsg}
        onPressYes={onPressUserInputYesMsg}
        isActivity={false}
      />
    );
  };

  const closeFirstViewBanner = () => {
    console.log("Click here");
    setFirstVisitFlag("true");
    // savePref(PREF_FIRST_VISIT,"true");
  };
  // Question view Funfact Handle
  const questionViewFunFactClose = () => {
    setFunFactMultipleFlag(false);
  };

  const onPressCloseLessionScreen = () => {
    setLessionScreenDispFlag(false);
  };

  const displayToast = () => {
    return (
      <Text
        style={{
          width: "100%",
          backgroundColor: "rgba(94, 490, 243, 1)",
          paddingLeft: heightToDp(8),
          paddingRight: heightToDp(8),
          paddingTop: heightToDp(4),
          paddingBottom: heightToDp(4),
          color: "black",
          top: 0,
          position: "absolute",
          fontSize: heightToDp(4.5),
        }}
      >
        {toastText}
      </Text>
    );
  };

  const onPressCloseToast = () => {
    setToastFlag(false);
  };
  // Award Screen
  const closeAwardScreen = () => {
    setAwardScreenDisplayFlag(false);
  };
  const callAPI_RewardList = () => {
    var payloadCreator = new Object();
    payloadCreator["extraParams"] = {
      uid: g_uid,
    };
    dispatch(request_UserAward_List(payloadCreator));
  };
  // End Award Screen

  const taskBoardMessage = () => {
    // activityText(
    //   String.start_activity_text +
    //     currentTaskCountWords +
    //     String.start_activity_text1
    // )
    if (
      currentLevelVideoList[parseInt(currentTaskCount - 1)]
        .bmsg_before_vdo_play !== ""
    ) {
      console.log(
        "Message : " +
          currentLevelVideoList[parseInt(currentTaskCount - 1)]
            .bmsg_before_vdo_play
      );
      return activityText(
        currentLevelVideoList[parseInt(currentTaskCount - 1)]
          .bmsg_before_vdo_play,
        true
      );
    } else {
      return activityText(
        String.start_activity_text +
          currentTaskCountWords +
          String.start_activity_text1,
        true
      );
    }

    // if(currentTaskCountWords==="first"){
    //   return activityText(String.start_activity_text_welcome+(currentLevelIndex+1))
    // }else{
    //   return activityText(
    //     String.start_activity_text +
    //       currentTaskCountWords +
    //       String.start_activity_text1
    //   )
    // }
  };

  const rewardStopMsg = () => {
    if (
      currentLevelVideoList[parseInt(currentTaskCount - 1)]
        .bmsg_after_vdo_play !== ""
    ) {
      if (currentLevelIndex === 0) {
        return activityText(
          currentLevelVideoList[parseInt(currentTaskCount - 1)]
            .bmsg_after_vdo_play,
          true
        );
      } else {
        if (currentLevelIndex === 0) {
          return activityText(
            currentLevelVideoList[parseInt(currentTaskCount - 1)]
              .bmsg_after_vdo_play,
            true
          );
        } else {
          return activityText(
            currentLevelVideoList[parseInt(currentTaskCount - 1)]
              .bmsg_after_vdo_play,
            false
          );
        }
      }
    } else {
      if (currentLevelIndex === 0) {
        return activityText(String.cometofar_collect_star, true);
      } else {
        return activityText(String.cometofar_collect_star, false);
      }
    }
  };
  const starCollectMsg = () => {
    if (
      currentLevelVideoList[parseInt(currentTaskCount - 1)]
        .bmsg_star_collection !== ""
    ) {
      return currentLevelVideoList[currentTaskCount - 1].bmsg_star_collection;
    } else {
      if (currentLevelVideoList.length > currentTaskCount) {
        var constructMessage =
          String.congratulation_star +
          "\n\n" +
          String.congratulation_next_video +
          "\n" +
          String.con_left_quat +
          currentLevelVideoList[currentTaskCount].label +
          String.con_left_quat;
        return constructMessage;
        // return(currentLevelVideoList[currentTaskCount].label);
      } else {
        return "";
      }
    }
  };
  const childViewAll = () => {
    return (
      <View style={styles.mainContainer}>
        {reawardStandFlag ? rewardBoard() : null}

        {reawardCollectMsgFlag ? rewardStopMsg() : null}
        {charReachEndStopFlag ? endBoard() : null}
        {character()}
        {taskBoardStandFlag ? taskBoard() : null}
        {activityStandPinFlag ? (
          <ActivityPinBoard
            position={[
              { translateX: parseInt(Math.abs(travelEndx) / 3.5) / 2 },
            ]}
            bottomMargine={bottomMargineStand}
            // bottomMargine={bottomMargineTaskboardStand}
            imageSource={IMAGE_STAND_ACTIVITY_PIN}
            onPress={onPressActivityPinBoard}
          />
        ) : null}
        {funfactStandPinFlag ? (
          <ActivityPinBoard
            position={[
              { translateX: parseInt(Math.abs(travelEndx) / 3.5) / 2 },
            ]}
            bottomMargine={bottomMargine}
            imageSource={IMAGE_STAND_FUNFACT_PIN}
            onPress={onPressFunFactPinBoard}
          />
        ) : null}

        {startTextFlag ? startText() : null}
        {taskBoardStandFlag ? taskBoardMessage() : null}
        {bgController()}
        {otherConstroller()}
        {levelExpandFlag ? levelUI() : levelSingle()}
        {introvideoDispFlag ? (
          <IntroVideonScreen
            onPress={onPressCloseIntroVideoView}
            onPressClose={onPressCloseButtonIntroVideoView}
            urlSource={introVideoUrls}
          />
        ) : null}
        {introvideoEndMessageDispFlag ? handleIntroAndMessage() : null}
        {videoDispFlag ? (
          <VideonScreen
            uid={prefUid}
            onPress={onPressCloseVideoView}
            // finishCall={onPressTaskFinish}
            finishCall={onPressVideoFinish}
            count={currentTaskCount}
            videoData={currentLevelVideoList[currentTaskCount - 1]}
          />
        ) : null}
        {userInputActivityFlag ? handleEachVideoActivityInput() : null}
        {userInputMsgFlag ? handleEachVideoMessageInput() : null}

        {charReachEndStopDelayFlag ? displayLevelFinish() : null}

        {collectStarScreenDispFlag ? (
          <CollectStarCmp
            onPress={onPressOnCollectStarOk}
            // message={
            //   currentLevelVideoList.length > currentTaskCount
            //     ? currentLevelVideoList[currentTaskCount].label
            //     : ""
            // }
            message={starCollectMsg()}
          />
        ) : null}
        {funfactSingleFlag ? (
          <FunFactCmp
            message={
              currentLevelVideoList[parseInt(currentTaskCount - 1)].funfacts[
                parseInt(funfactTaskCount)
              ].fun_title
            }
            onPressContinue={onPressFunFactContinue}
          />
        ) : null}
        {funfactMultipleFlag ? (
          <QuestionScreen
            onPressClose={onPressFunFactOption}
            questionTitle={
              currentLevelVideoList[parseInt(currentTaskCount - 1)].funfacts[
                parseInt(funfactTaskCount)
              ].question
            }
            optionA_origAnsFlag={
              currentLevelVideoList[parseInt(currentTaskCount - 1)].funfacts[
                parseInt(funfactTaskCount)
              ].option1_answer_falg
            }
            optionB_origAnsFlag={
              currentLevelVideoList[parseInt(currentTaskCount - 1)].funfacts[
                parseInt(funfactTaskCount)
              ].option2_answer_falg
            }
            optionC_origAnsFlag={
              currentLevelVideoList[parseInt(currentTaskCount - 1)].funfacts[
                parseInt(funfactTaskCount)
              ].option3_answer_falg
            }
            optionD_origAnsFlag={
              currentLevelVideoList[parseInt(currentTaskCount - 1)].funfacts[
                parseInt(funfactTaskCount)
              ].option4_answer_falg
            }
            option1={
              currentLevelVideoList[parseInt(currentTaskCount - 1)].funfacts[
                parseInt(funfactTaskCount)
              ].option1
            }
            option2={
              currentLevelVideoList[parseInt(currentTaskCount - 1)].funfacts[
                parseInt(funfactTaskCount)
              ].option2
            }
            option3={
              currentLevelVideoList[parseInt(currentTaskCount - 1)].funfacts[
                parseInt(funfactTaskCount)
              ].option3
            }
            option4={
              currentLevelVideoList[parseInt(currentTaskCount - 1)].funfacts[
                parseInt(funfactTaskCount)
              ].option4
            }
          />
        ) : null}
        {activityDispFlag ? (
          <ActivityCmp
            onPress={onPressActivityOk}
            message={
              currentLevelVideoList[parseInt(currentTaskCount - 1)].end_activity
            }
          />
        ) : null}
        {activityDispInputFlag ? (
          <ActiFunFactCmp
            message={inputMessage}
            onPressSubmit={onPressActiFunFactSubmit}
            onPressClose={onPressActiFunFactClose}
          />
        ) : null}
        {lessionScreenDispFlag ? (
          <IntroBanner
            title={introBannerLabel}
            onPress={onPressCloseLessionScreen}
          />
        ) : null}
        {broodyMessageBox
          ? activityText(broodyMessageBoxText, broodyMessageBoxTextAnimFlag)
          : null}
        {toastFlag ? (
          <ToastCmp message={toastText} onPress={onPressCloseToast} />
        ) : null}
        {inputSuccessDispFlag ? (
          <InputSuccessCmp
            onPressClose={onPressCloseSuccessDialog}
            onPressOk={onPressOkSuccessDialog}
          />
        ) : null}
        {awardScreenDisplayFlag ? (
          <AwardScreen
            onPress={closeAwardScreen}
            dispUserName={prefUserName}
            dispAward={awardsCount}
            dispAwardPoint={awardsPointCount}
          />
        ) : null}
        {courseFinishFlag ? (
          <FinishCourseBanner
            title={levelList[11].summery_end_message}
            onPress={onPressCourseFinishOk}
          />
        ) : null}
        {helpFlag ? (
          <HowDoesWorkModal
            displayFlag={helpFlag}
            closeModal={closeHelpModal}
            // onPressSignup={}
            dispSignup={false}
          />
        ) : null}
        {firstVistFlag === "false" ?  (
          <FirstViewBanner onPress={closeFirstViewBanner} />
        ):null}
      </View>
    );
  };
  const firstViewLoad = () => {
    return <FirstViewBanner onPress={closeFirstViewBanner} />;
  };
  const loadView = () => {
    // if (firstVistFlag === "false") {
    //   return firstViewLoad();
    // } else {
    //   return AppView();
    // }
    return AppView();
  };
  const AppView = () => {
    return (
      <View style={styles.mainContainer}>
        {backGround()}
        {innerLoadingFlag ? null : childViewAll()}
        {innerLoadingFlag ? Innerloader() : null}
      </View>
    );
  };

  const noInterNet = () => {
    return (
      <View style={{ width: "100%", height: "100%" }}>
        <Text>No Internet</Text>
      </View>
    );
  };

  const mainView = () => {
    if (apploadingFlag) {
      return loader();
    } else {
      return loadView();
    }
  };

  return mainView();

  //   ==== Design end ======
};

export default GameScen;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: "transparent",
    borderStyle: "solid",
  },
  arrowDown: {
    borderTopWidth: 15,
    borderRightWidth: 15,
    borderBottomWidth: 0,
    borderLeftWidth: 15,
    borderTopColor: "rgba(94, 200, 243, 1)",
    borderRightColor: "transparent",
    borderBottomColor: "transparent",
    borderLeftColor: "transparent",
  },
  arrowUp: {
    borderTopWidth: 0,
    borderRightWidth: 15,
    borderBottomWidth: 15,
    borderLeftWidth: 15,
    borderTopColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: "rgba(94, 200, 243, 1)",
    borderLeftColor: "transparent",
  },

  leftArrow: {
    width: 0,
    height: 0,
    backgroundColor: "transparent",
    borderStyle: "solid",
    borderLeftWidth: 20,
    borderRightWidth: 20,
    borderBottomWidth: 40,
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    bottom: "-6%",
    right: 0,
    borderBottomColor: "white",
    transform: [{ rotate: "-120deg" }],
  },
});

import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { heightToDp } from "../utils/Responsive";
const AboutCmp = ({ leftDivider, rightDivider, titleText, descText }) => {
  return (
    <View
      style={{
        flex: 1,
        paddingLeft:'2%',
        paddingRight:'2%',
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={{
          backgroundColor: "yellow",
          paddingTop: "1.5%",
          paddingBottom: "1.5%",
          marginLeft: leftDivider,
          marginRight: rightDivider,
        }}
      >
        <Text
          style={{
            fontSize: heightToDp(4),
            fontWeight: "bold",
            color: "black",
          }}
        >
          {titleText}
        </Text>
        <Text
          style={{
            fontSize: heightToDp(2.9),
            color: "black",
          }}
        >
          {descText}
        </Text>
      </View>
    </View>
  );
};
export default AboutCmp;

import React, { useEffect, useState } from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import ImagePress from "./ImagePress";
import {
  IMAGE_QT_CLOSE,
  IMAGE_BROODY_LESSONS,
  IMAGE_BTN_CONTINUE,
} from "../constants/ImageConst";
import { right } from "inquirer/lib/utils/readline";
import { heightToDp } from "../utils/Responsive";
const WaitingCmp = ({
  waitingMinute,
  waitingSeconds,
  waitingMessage,
  onPressContinue,
  onPressClose,
}) => {
  const [seconds, setSeconds] = useState(waitingSeconds);
  const [minutes, setMinutes] = useState(waitingMinute);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [seconds]);

  return (
    <View style={styles.mainContainer}>
      {minutes >0 ?<Text
        style={{
          fontWeight: "bold",
          fontSize: heightToDp(10),
          textAlign: "center",
        }}
      >
        {minutes + ":" + seconds}
      </Text>:null}
      <Text style={styles.textContainer}>{waitingMessage}</Text>
      <ImagePress
        source={IMAGE_BTN_CONTINUE}
        style={{
          width: "18%",
          height: "8%",
          marginBottom: "2%",
          resizeMode: "contain",
        }}
        onPress={onPressContinue}
      />
      <Image
        source={IMAGE_BROODY_LESSONS}
        style={{ width: "20%", height: "20%", resizeMode: "contain" }}
      />
      <ImagePress
        source={IMAGE_QT_CLOSE}
        style={styles.imageCloseContainer}
        onPress={onPressClose}
      />
    </View>
  );
};
export default WaitingCmp;

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFE353",
    justifyContent: "center",
    position: "absolute",
    alignItems: "center",
    alignContent: "center",
  },
  imageCloseContainer: {
    width: "10%",
    height: "10%",
    position: "absolute",
    alignSelf: "flex-end",
    top: "2%",
    right: "2%",
    resizeMode: "contain",
  },
  textContainer: {
    fontSize: heightToDp(3),
    letterSpacing: 1,
    padding: "2%",
  },
});

import React, { useEffect, useState } from "react";
import { ActivityIndicator, Text, View } from "react-native";
import * as SecureStore from "expo-secure-store";
import * as Device from "expo-device";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { StatusBar } from "expo-status-bar";
import { useDispatch, useSelector } from "react-redux";
import { request_Appconfig } from "../../redux/reducers/Rq_Appconfig";
import { request_UserInfo } from "../../redux/reducers/Rq_UserInfo";
import { request_CreateToken } from "../../redux/reducers/Rq_CreateToken";
import WelcomeScreen from "../startscreens/WelcomeScreen";
import GameScen from "./GameScen";
import {
  PREF_LOGIN,
  PREF_USER_TOKEN,
  PREF_USER_UID,
  PREF_USER_FIRST_NAME,
  PREF_EMAIL,
  PREF_STORE_PREF_NAME,
  PREF_INTERVAL_SECONDS,
} from "../../constants/PrefConst";
import NetInfo from '@react-native-community/netinfo';
import ToastCmp from "../../components/ToastCmp";
import { String } from "../../constants/String";

const MainSplashScen = () => {
  const [detectDeviceType, setDetectDeviceType] = useState();
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [loginFlag, setLoginFlag] = useState("");
  const [userid, setUserId] = useState("");
  const [userTokenData, setUserTokenData] = useState("");

  const [internetFlag, setInternetFlag] = useState("");
  const [toastFlag, setToastFlag] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const dispatch = useDispatch();

  const responseAppConfigState = useSelector((state) => {
    // console.log(" -------Level List -------------  "+ JSON.stringify(state.Rq_Levellist));
    return state.Rq_Appconfig;
  });
  const responseUserInfoState = useSelector((state) => {
    // console.log(" -------User Info -------------  "+ JSON.stringify(state.Rq_UserInfo));
    return state.Rq_UserInfo;
  });
  const responseCreateTokenState = useSelector((state) => {
    // console.log(" -------Token create -------------  "+ JSON.stringify(state.Rq_CreateToken));
    return state.Rq_CreateToken;
  });

  useEffect(() => {
    setToastFlag(toastFlag);
  }, [toastFlag]);
  useEffect(() => {
    setToastMessage(toastMessage);
  }, [toastMessage]);
  const toastOnPressClose = () => {
    setToastFlag(false);
  };

  

  useEffect(()=>{
    const unsubscribe = NetInfo.addEventListener(state => {
      console.log("Connection type", state.type);
      console.log("Is connected?", state.isConnected);
      setInternetFlag(state.isConnected.toString());
    });
    return () => {
      // Unsubscribe to network state updates
      unsubscribe();
    };
    
  },[])

  useEffect(() => {
    Device.getDeviceTypeAsync().then((deviceType) => {
      setDetectDeviceType(deviceType);
      if (Device.DeviceType.PHONE === deviceType) {
        setDetectDeviceType(deviceType);
        console.log("Device Type Phone");
      } else if (Device.DeviceType.TABLET === deviceType) {
        console.log("Device Type TABLET");
      } else if (Device.DeviceType.DESKTOP === deviceType) {
        console.log("Device Type DESKTOP");
      } else if (Device.DeviceType.TV === deviceType) {
        console.log("Device Type TV");
      } else {
        setDetectDeviceType(deviceType);
        console.log("Device Type Unknown");
      }
    });
  }, []);

  useEffect(() => {
    setDetectDeviceType(detectDeviceType);
  }, [detectDeviceType]);

  useEffect(() => {
    // setLoadingFlag(true)
    // dispatch(request_Appconfig());
    // getValueForPref(PREF_LOGIN);
    // getValueForPref(PREF_USER_UID);
    // getValueForPref(PREF_USER_TOKEN);

    if (internetFlag === "true") {
      setLoadingFlag(true);
      dispatch(request_Appconfig());
   
    } else if (internetFlag === "false") {
      setLoadingFlag(false);
      setToastMessage(String.internet_connection_not_avaialable);
      setToastFlag(true);
    }
    getValueForPref(PREF_LOGIN);
    getValueForPref(PREF_USER_UID);
    getValueForPref(PREF_USER_TOKEN);
  }, []);

  useEffect(() => {
    console.log("===token recieved==== : "+responseCreateTokenState.usertoken);
    if (responseCreateTokenState.usertoken !== "") {
      setUserTokenData(responseCreateTokenState.usertoken);
      savePref(PREF_USER_TOKEN, responseCreateTokenState.usertoken);
      setLoadingFlag(false);
    } else {
      setLoadingFlag(false);
    }
  }, [
    responseCreateTokenState.responseFlag
  ]);

  useEffect(() => {
    if (internetFlag === "true") {
      if (
        userTokenData !== null &&
        userTokenData !== "" &&
        userid !== null &&
        userid !== ""
      ) {
        setLoadingFlag(true);
        var payloadCreator = new Object();
        payloadCreator["extraParams"] = {
          usertoken: userTokenData,
          uid: userid,
        };
        dispatch(request_UserInfo(payloadCreator));
        console.log("call user info api ");
      }
    } else if (internetFlag === "false") {
      // setLoadingFlag(false)
      setToastMessage(String.internet_connection_not_avaialable);
      setToastFlag(true);
    }
  }, [userTokenData, userid]);

  useEffect(() => {
    setLoginFlag(loginFlag);
  }, [loginFlag]);

  useEffect(() => {
    if( responseAppConfigState.responseFlag === "true"){
      savePref(PREF_INTERVAL_SECONDS,responseAppConfigState.stop_interval);
    }
  }, [
    responseAppConfigState.responseFlag === "true" ||
      responseAppConfigState.responseFlag === "false",
  ]);

  useEffect(() => {
    if (responseUserInfoState.responseFlag === "true") {
      console.log("user info point : " + responseUserInfoState.point);
      savePref(PREF_USER_UID, responseUserInfoState.uid);
      savePref(PREF_USER_TOKEN, responseUserInfoState.usertoken);
      savePref(PREF_USER_FIRST_NAME, responseUserInfoState.first_name);
      savePref(PREF_EMAIL, responseUserInfoState.email);
      savePref(PREF_LOGIN, "true");
      setLoginFlag("true");
    }
    // setLoginFlag("true");
    setLoadingFlag(false);
  }, [
    responseUserInfoState.responseFlag === "true" ||
      responseUserInfoState.responseFlag === "false",
  ]);

  async function getValueForPref(key) {
    let result = "";
    try {
      try {
        result = await AsyncStorage.getItem(key);
      } catch (error) {
        console.log("Error " + key + " " + JSON.stringify(error));
      }

      // if(detectDeviceType === "3" || detectDeviceType ===3){
      //   result = await AsyncStorage.getItem(key)
      // }else{
      //   result = await SecureStore.getItemAsync(key);
      // }
      console.log("Prefence : " + key + " : " + result);
      if (key === PREF_USER_TOKEN) {
        if (result !== "" && result !== null) {
          setUserTokenData(result);
        } else {
          setUserTokenData("");
          if(internetFlag === "true"){
            setLoadingFlag(true);
            dispatch(request_CreateToken());
          }else if(internetFlag ==="false"){
            setToastMessage(String.internet_connection_not_avaialable)
            setToastFlag(true)
          }
          
        }
      }

      if (key === PREF_USER_UID) {
        if (result !== "" && result !== null) {
          setUserId(result);
        } else {
          setUserId("");
          setLoginFlag("false");
        }
      }

      if (key === PREF_LOGIN) {
        if (result !== "" && result !== null) {
          if (result === "true") {
            setLoginFlag("true");
          } else {
            setLoginFlag("false");
          }
        } else {
          setLoginFlag("false");
        }
      }
      setLoadingFlag(false);
    } catch (error) {
      if (key === PREF_USER_TOKEN) {

        if(internetFlag === "true"){
          setLoadingFlag(true);
          dispatch(request_CreateToken());
        }else if(internetFlag ==="false"){
          setToastMessage(String.internet_connection_not_avaialable)
          setToastFlag(true)
        }

     
      }
      if (key === PREF_LOGIN) {
        setLoginFlag("false");
      }
      setLoadingFlag(false);
      console.log("Pref rence error : " + " " + key + " " + error.message);
    }
  }
  async function savePref(key, value) {
    if (detectDeviceType === "3" || detectDeviceType === 3) {
      await AsyncStorage.setItem(key, value);
    } else {
      await SecureStore.setItemAsync(key, value);
    }
  }

  const changeLoginFalg = () => {
    savePref(PREF_LOGIN, "true");
    setLoginFlag("true");
    console.log("recieve login flag " + loginFlag);
  };
  const loader = () => {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          position: "absolute",
          backgroundColor: "white",
        }}
      >
        <ActivityIndicator size="large" color="orange" />
      </View>
    );
  };
  const loadScreenUsingLogin = () => {
    if (loginFlag === "false") {
      return (
        <WelcomeScreen loginState={changeLoginFalg} token={userTokenData} />
      );
    } else if (loginFlag === "true") {
      return <GameScen />;
    } else {
      return loader();
    }
  };
  return (
    <View style={{ width: "100%", height: "100%" }}>
      {loadScreenUsingLogin()}
      {toastFlag ? (
        <ToastCmp message={toastMessage} onPress={toastOnPressClose} />
      ) : null}
      {loadingFlag ? loader() : null}
    </View>
  );
};
export default MainSplashScen;

import React from "react";
import { View, Image, TouchableWithoutFeedback, Animated } from "react-native";

const ImagePressOnLoadAnim = ({ source, style, onPress }) => {
  const animatedButtonScale = new Animated.Value(1);
  const handlePressIn = () => {
    Animated.spring(animatedButtonScale, {
      toValue: 1.03,
      friction:1.03,
      useNativeDriver: true,
    }).start();
  };
  const handlePressOut = () => {
    Animated.spring(animatedButtonScale, {
      toValue: 1,
      friction:1,
      useNativeDriver: true,
    }).start();
  };
  const animatedScaleStyle = {
    transform: [{scale: animatedButtonScale}]
};
  return (
    <TouchableWithoutFeedback
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      onPress={onPress}
      style={style}
    >
      <Animated.Image source={source} style={[style,animatedScaleStyle]} resizeMethod="resize"   onLoad={handlePressIn}/>
    </TouchableWithoutFeedback>
  );
};
export default ImagePressOnLoadAnim;

import React from "react";
import { View, TextInput, Text, TouchableOpacity, Image } from "react-native";

const CmpTextInput = ({
  sideImageRightFlag,
  sideImageRight,
  onPressImageRight,
  maxLength,
  placeholder,
  autoCapitalize,
  keyboardType,
  returnKeyType,
  secureTextEntry,
  refrensKey,
  onSubmitEditing,
  onBlur,
  onFocus,
  onChangeText,
  multiline,
  onPress,
  errorFlag,
  errorMessage,
  style,
}) => {
  return (
      <TextInput
        numberOfLines={1}
        maxLength={maxLength}
        placeholder={placeholder}
        placeholderTextColor="gray"
        autoCapitalize={autoCapitalize}
        keyboardType={keyboardType}
        returnKeyType={returnKeyType}
        secureTextEntry={secureTextEntry}
        ref={refrensKey}
        onSubmitEditing={onSubmitEditing}
        onBlur={onBlur}
        onFocus={onFocus}
        multiline={multiline}
        onChangeText={onChangeText}
        style={style}
      />
     
  );
};

export default CmpTextInput;

import { Platform } from "react-native";
import Constants from "expo-constants";

export const HEADER = {
  platform: Platform.OS,
  version: Constants.manifest.version,
  "Access-Control-Allow-Origin": "*",
};


export const BUILD_TYPE_WEB = "WEB";
export const BUILD_TYPE_APP = "APP";

export const DEFAUL_BUILD_TYPE = BUILD_TYPE_WEB;

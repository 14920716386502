import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { heightToDp } from "../utils/Responsive";
import { IMAGE_BROODY_ACTIVITY,IMAGE_BTN_OK } from "../constants/ImageConst";
import { String } from "../constants/String";
import ImagePress from "./ImagePress";

const ActivityCmp = ({ message, onPress }) => {
  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View style={styles.mainContainer}>
        <Image
          style={styles.imageContainerLarge}
          source={IMAGE_BROODY_ACTIVITY}
        />
        <Text style={[styles.textSubTitleContainer, { fontStyle: "italic" }]}>
          {String.activity_suggest_title}
        </Text>
        <Text style={[styles.textSubTitleContainer,{width:'45%', marginTop: "0.2%",}]}>
          {message}
        </Text>
        <ImagePress
        style={styles.imageButtonContainer}
        source={IMAGE_BTN_OK}
        onPress={onPress}
      />
      </View>
    </View>
  );
};

export default ActivityCmp;

const styles = StyleSheet.create({
  mainContainer: {
    width: "80%",
    height: "80%",
    borderWidth: 10,
    borderRadius: 10,
    borderColor: "white",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FEE7F1",
  },
  imageContainer: {
    width: "35%",
    height: "35%",
    alignSelf: "center",
    resizeMode: "contain",
  },
  imageButtonContainer: {
    marginTop: "2%",
    marginBottom: "1%",
    width: "26%",
    height: "12%",
    resizeMode: "contain",
    alignSelf: "center",
  },
  imageContainerLarge: {
    width: "35%",
    height: "35%",
    resizeMode: "contain",
  },
  textTitleContainer: {
    fontSize: heightToDp(3),
    color: "black",
    padding: "2%",
    textAlign: "center",
    fontWeight: "bold",
  },
  textSubTitleContainer: {
    fontSize: heightToDp(3),
    color: "black",
    marginTop: "2%",
    textAlign: "center",
  },
});

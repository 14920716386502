import React from "react";
import { View, Text, StyleSheet, Image, FlatList } from "react-native";
import CustomViewBack from "../../../components/CustomViewBack";
import { String } from "../../../constants/String";
import {
  IMAGE_CHILD,
  IMAGE_HOWITS_CHILD,
  IMAGE_HOWITS_ABOUT_2,
  IMAGE_BTN_SIGNUP,
  IMAGE_BTN_BACK,
} from "../../../constants/ImageConst";
import { heightToDp, widthToDp } from "../../../utils/Responsive";
import ImagePress from "../../../components/ImagePress";
import AboutCmp from "../../../components/AboutCmp";
import CustomViewBackTrans from "../../../components/CustomViewBackTrans";

const sections = [
  {
    title: "Made for you",
    horizontal: true,
  },
];

const HowDoesWorkModal = ({ displayFlag, closeModal, onPressSignup,dispSignup }) => {
  const view_1 = () => {
    return (
      <View style={{ width: "100%", alignItems: "center" }}>
        <View
          style={{ width: "95%", flexDirection: "row", alignItems: "center" }}
        >
          <ImagePress
            source={IMAGE_HOWITS_CHILD}
            style={{
              resizeMode: "contain",
              width: "30%",
              height: heightToDp(50),
            }}
          />
          <View style={{ width: "65%", paddingLeft: "2%" }}>
            <Text
              style={[
                styles.titleContainer,
                { paddingTop: "2%", paddingBottom: "2%" },
              ]}
            >
              {String.sidemenu_scr_how_work_abouttitle}
            </Text>
            <Text style={styles.descriptionContainer}>
              {String.sidemenu_scr_how_work_aboutdescription}
            </Text>
          </View>
        </View>
        <View
          style={{
            backgroundColor: "yellow",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Text
            style={[
              styles.titleContainer,
              { paddingTop: "5%", letterSpacing: 1.2 },
            ]}
          >
            {String.sidemenu_scr_how_work_help_yourchild}
          </Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              backgroundColor: "yellow",
              paddingTop: "5%",
              width:'100%',
              flex: 1,
              paddingBottom: "2%",
            }}
          >
            <AboutCmp
              // rightDivider="2%"
              titleText={String.sidemenu_scr_abt_title_1}
              descText={String.sidemenu_scr_abt_subtitle_1}
            />
            <View style={{height:'100%',width:'0.8%',backgroundColor:'white'}} />
            <AboutCmp
              // rightDivider="2%"
              titleText={String.sidemenu_scr_abt_title_2}
              descText={String.sidemenu_scr_abt_subtitle_2}
            />
            <View style={{height:'100%',width:'0.8%',backgroundColor:'white'}} />
            <AboutCmp
              // rightDivider="2%"
              titleText={String.sidemenu_scr_abt_title_3}
              descText={String.sidemenu_scr_abt_subtitle_3}
            />
            <View style={{height:'100%',width:'0.8%',backgroundColor:'white'}} />
            <AboutCmp
              // rightDivider="2%"
              titleText={String.sidemenu_scr_abt_title_4}
              descText={String.sidemenu_scr_abt_subtitle_4}
            />
            <View style={{height:'100%',width:'0.8%',backgroundColor:'white'}} />
            <AboutCmp
              // rightDivider="2%"
              titleText={String.sidemenu_scr_abt_title_5}
              descText={String.sidemenu_scr_abt_subtitle_5}
            />
          </View>
          </View>
      </View>
    );
  };
  const view_2 = () => {
    return (
      <View
        style={{
          width: "100%",
          alignItems: "center",
          backgroundColor: "yellow",
        }}
      >
        <Text style={[styles.titleContainer, { paddingTop: "5%" }]}>
          {String.sidemenu_scr_how_work_tool}
        </Text>
        <Image
          source={IMAGE_HOWITS_ABOUT_2}
          style={{
            width: "100%",
            height: heightToDp(80),
            resizeMode: "contain",
            marginTop:heightToDp(10),
            backgroundColor: "white",
            backgroundColor: "yellow",
          }}
        />
      </View>
    );
  };
  const view_3 = () => {
    return (
      <View
        style={{
          width: "100%",
          alignItems: "center",
          backgroundColor: "#A2D9F8",
        }}
      >
        <Text
          style={[
            styles.titleContainer,
            { paddingTop: "2%", paddingBottom: "2%" },
          ]}
        >
          {String.sidemenu_scr_how_work_tool}
        </Text>
        <View
          style={{
            width: "90%",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <View style={{ width: "70%" }}>
            <Text
              style={[
                styles.descriptionContainer,
                {
                  marginTop: "4%",
                },
              ]}
            >
              {String.sidemenu_scr_how_work_description}
            </Text>
            {dispSignup?<ImagePress
              onPress={onPressSignup}
              source={IMAGE_BTN_SIGNUP}
              style={{
                height: "15%",
                width: "40%",
                marginTop: "5%",
                resizeMode: "contain",
              }}
            />:<View style={{ height: "8%",
            width: "40%",
            marginTop: "5%",}}/>}
          </View>
          <ImagePress
            source={IMAGE_CHILD}
            style={{
              resizeMode: "contain",
              width: "35%",
              marginTop: heightToDp(-5),
            }}
          />
        </View>
      </View>
    );
  };
  const mainView = () => {
    return (
      <View style={{ width: "100%", alignItems: "center" }}>
        <View
          style={{
            width: "100%",
            alignItems: "center",
            backgroundColor: "#A2D9F8",
            paddingTop: "2%",
          }}
        >
          {view_1()}
        </View>
        <View
          style={{
            width: "100%",
            alignItems: "center",
            backgroundColor: "#FDE453",
          }}
        >
          {view_2()}
        </View>

        <View
          style={{
            width: "100%",
            alignItems: "center",
            backgroundColor: "#A2D9F8",
            paddingTop: "2%",
          }}
        >
          {view_3()}
        </View>
      </View>
    );
  };
  return (
    <CustomViewBack  borderColor="white" onPress={closeModal} bgColor="#A2D9F8">
      <FlatList
        data={sections}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item }) => mainView()}
        showsVerticalScrollIndicator={false}
      />
      <ImagePress
        onPress={closeModal}
        source={IMAGE_BTN_BACK}
        style={{
          resizeMode: "contain",
          position:'absolute',
          height: "8%",
          width: "5%",
          margin: "1%",
        }}
      />
    </CustomViewBack>
  );
};
export default HowDoesWorkModal;

const styles = StyleSheet.create({
  titleContainer: {
    fontSize: heightToDp(4.8),
    fontWeight: "bold",
    color: "black",
    letterSpacing: 1.2,
  },
  descriptionContainer: {
    fontSize: heightToDp(3.5),
    lineHeight: heightToDp(6),
    color: "black",
  },
});

import React from "react";
import { View, Image, Text, TouchableWithoutFeedback } from "react-native";
import {
  IMAGE_RADIO_ACTIVE,
  IMAGE_RADIO_INACTIVE,
} from "../constants/ImageConst";
import { heightToDp } from "../utils/Responsive";

const RadioView = ({ style, subTitle, subPrice, subText,subscribeFalg,onPressSubscribe }) => {
  return (
    <View style={style}>
      <TouchableWithoutFeedback onPress={onPressSubscribe}>
        <View
          style={{
            width: "100%",
            height: "33%",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Image
            source={subscribeFalg?IMAGE_RADIO_ACTIVE:IMAGE_RADIO_INACTIVE}
            style={{
              width: "25%",
              height: "80%",
              resizeMode: "contain",
              marginTop: "2%",
            }}
            resizeMethod="resize"
          />

          <View
            style={{
              flexDirection: "column",
            }}
          >
            <Text style={{ fontSize: heightToDp(2.5), fontWeight: "bold" }}>{subTitle}</Text>
            <Text
              style={{ fontSize: heightToDp(2), fontWeight: "normal", marginTop: "1%" }}
            >
              {subPrice}
            </Text>
            <Text
              style={{ fontSize: heightToDp(1.5), fontWeight: "normal", marginTop: "2.5%" }}
            >
              {subText}
            </Text>
          </View>
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
};

export default RadioView;

import React from "react";
import { Platform } from "react-native";
import Constants from "expo-constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isBlank, validateObject, checkKeyAssign } from "../../utils/Utility";
import {
  API_LEVEL_LIST_GET,
  BASE_URL,
  URLs,
  URL_BGIMAGE,
  URL_CHARIMAGE,
} from "../../constants/ApiConst";
import { String } from "../../constants/String";
import Api from "../../utils/Api";
import axios from "axios";
import { HEADER } from "../../constants/DefaultConst";
const TAG = "Rq_Levellist ";
export const request_LevelList = createAsyncThunk(
  "Rq_Levellist/request_LevelList",
  async (payloadCreator, thunkAPI) => {
    const data = {};
    var config = {
      method: "get",
      // url: 'http://apils.happigenius.com/v1.0/levels/levellist',
      url: API_LEVEL_LIST_GET,
      // headers: HEADER,
    };

     const response = axios(config)
      .then(function (response) {
        console.log("Rq_Levellist Response : "+JSON.stringify(response.data));
        return response;
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // Logger(TAG, 'error data1 : ' + JSON.stringify(error.response.data));
          // Logger(TAG, 'error status2 : ' + error.response.status);
          // Logger(
          //   TAG,
          //   'error headers3 : ' + JSON.stringify(error.response.headers),
          // );
          return error.response.data;
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // Logger(TAG, 'error request4 : ' + JSON.stringify(error.request));
        } else {
          // Something happened in setting up the request that triggered an Error
          // Logger(TAG, 'error message5 : ' + error.message);
        }
        // Logger(TAG, 'error config6 : ' + JSON.stringify(error.config));
      });

    // let response = await fetch(API_LEVEL_LIST_GET, {
    //   method:'GET',
    //   mode: 'no-cors',
    //   headers: {
    //     platform: Platform.OS,
    //     version: Constants.manifest.version,
    //     "Access-Control-Allow-Origin": "*",
    //   },
    // }).then((resp)=>{
    //   console.log(TAG + " Response is : " + JSON.stringify(resp.json()));
    // });
    
    return response;
  }
);

const setResponsedata = (state, response) => {
  // console.log(TAG, "data response infun :" + JSON.stringify(response.status));
  if (validateObject(response.data)) {
    state.levelDataArray = [];
    response.data.forEach((item) => {
      let dataitem = null;
      try {
        dataitem = setSingleItemData(state, item);
        return state.levelDataArray.push(dataitem);
      } catch (error) {}
    });
    // console.log("My array is : "+ JSON.stringify(state.levelDataArray));
  }
};

const setSingleItemData = (state, item) => {
  let itemobject = {
    level_id: "",
    level_name: "",
    level_bg_img: "",
    level_character_img: "",
    intro_video_flag:"",
    intro_video_url:"",
    summery_video_flag:"",
    summery_video_url:"",
    intro_end_message:"",
    summery_end_message:"",
    end_activity_task_title:"",
    level_breaks: 0,
    bmsg_end_lvl:"",
    bmsg_start_lvl:"",
    created_at: "",
    updated_at: "",
  };
  itemobject.level_id = checkKeyAssign("level_id", item);
  itemobject.level_name = checkKeyAssign("level_name", item);

  if (checkKeyAssign("level_bg_img", item) !== "") {
    itemobject.level_bg_img =
      URL_BGIMAGE + checkKeyAssign("level_bg_img", item);
  }
  if (checkKeyAssign("level_character_img", item) !== "") {
    itemobject.level_character_img =
      URL_CHARIMAGE + checkKeyAssign("level_character_img", item);
  }
  if (checkKeyAssign("level_breaks", item) !== "") {
    itemobject.level_breaks = checkKeyAssign("level_breaks", item);
  } else {
    itemobject.level_breaks = 0;
  }
  itemobject.created_at = checkKeyAssign("created_at", item);
  itemobject.updated_at = checkKeyAssign("updated_at", item);

  itemobject.intro_video_flag = checkKeyAssign("intro_video_flag", item);
  itemobject.intro_video_url = checkKeyAssign("intro_video_url", item);
  itemobject.summery_video_flag = checkKeyAssign("summery_video_flag", item);
  itemobject.summery_video_url = checkKeyAssign("summery_video_url", item);

  var temp_bmsg_end_lvl = checkKeyAssign("bmsg_end_lvl", item);
  if(temp_bmsg_end_lvl !== ""){
    itemobject.bmsg_end_lvl = temp_bmsg_end_lvl.replace(/\\n/g,'\n');
  }else{
    itemobject.bmsg_end_lvl = temp_bmsg_end_lvl;
  }

  var temp_bmsg_start_lvl = checkKeyAssign("bmsg_start_lvl", item);
  if(temp_bmsg_start_lvl !== ""){
    itemobject.bmsg_start_lvl = temp_bmsg_start_lvl.replace(/\\n/g,'\n');
  }else{
    itemobject.bmsg_start_lvl = temp_bmsg_start_lvl;
  }

  itemobject.intro_end_message = checkKeyAssign("intro_end_message", item);
  itemobject.summery_end_message = checkKeyAssign("summery_end_message", item);
  itemobject.end_activity_task_title = checkKeyAssign("end_activity_task_title", item);

  return itemobject;
};

const Rq_Levellist = createSlice({
  name: "Rq_Levellist",
  initialState: {
    loading: "",
    responseFlag: "",

    levelDataArray: [],
    errorCode: "",
    errorType: "",
    errorMessage: "",
  },
  reducers: {},
  extraReducers: {
    [request_LevelList.pending]: (state, action) => {
      state.loading = "yes";
    },
    [request_LevelList.fulfilled]: (state, action) => {
      if (validateObject(action.payload.data)) {
        if (validateObject(action.payload.data.result)) {
          state.responseFlag = checkKeyAssign(
            "status",
            action.payload.data.result
          );
          if (state.responseFlag === "true") {
            setResponsedata(state, action.payload.data.result);
          } else if (state.responseFlag === "false") {
            if (validateObject(action.payload.data.result)) {
              // setErrorData(state, action.payload.result.error);
              state.errorCode = checkKeyAssign(
                "statusCode",
                action.payload.data.result
              );
              state.errorMessage = checkKeyAssign(
                "statusMessage",
                action.payload.data.result
              );
            } else {
              state.responseFlag = "false";
              state.responseMessage = String.could_not_get_reponse;
            }
          } else {
            state.responseFlag = "false";
            state.responseMessage = String.could_not_get_reponse;
          }
        }
      }
      state.loading = "no";
    },
    [request_LevelList.rejected]: (state, action) => {
      state.loading = "no";
      state.responseFlag = "false";
      state.responseMessage = String.could_not_get_reponse;
    },
  },
});
export default Rq_Levellist;

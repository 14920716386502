import React from "react";
import { View, Text, StyleSheet } from "react-native";
import ImagePress from "../../components/ImagePress";
import RadioView from "../../components/RadioView";
import RoundBackground from "../../components/RoundBackground";
import {
  IMAGE_BTN_CANCEL,
  IMAGE_BTN_RENEW,
  IMAGE_BTN_NO,
  IMAGE_BTN_YES,
  IMAGE_PAYNOW,
} from "../../constants/ImageConst";
import { String } from "../../constants/String";

const AllReadySubscribed = ({
  onPress,
  cancelPress,
  renewPress,
  cancelFlag,
  renewFlag,
  closeCancelView,
  onPressSubscriptionMonthly,
  onPressSubscriptionYearly,
  monthlySubscribeFlag,
  yearlySubscribeFlag,
  onPressPayNow,
}) => {
  const mainView = () => {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={styles.subTitleTextStyle}>
          {String.unsubscribe_endson}
        </Text>
        <Text style={styles.titleTextStyle}>
          {String.unsubscribe_endson_date}
        </Text>
        <View
          style={{
            width: "100%",
            height: "15%",
            flexDirection: "row",
            marginTop: "6%",
            justifyContent: "center",
          }}
        >
          <ImagePress
            onPress={renewPress}
            source={IMAGE_BTN_RENEW}
            style={{
              width: "30%",
              height: "100%",
              resizeMode: "contain",
              marginRight: "10%",
            }}
          />
          <ImagePress
            onPress={cancelPress}
            source={IMAGE_BTN_CANCEL}
            style={{ width: "30%", height: "100%", resizeMode: "contain" }}
          />
        </View>
      </View>
    );
  };
  const renewView = () => {
    return (
      <RoundBackground
        bgcolor="white"
        onPress={onPress}
        closeDisplay={false}
        borderColor="#2DCECB"
        margin="12%"
      >
        <Text
          style={{
            color: "black",
            fontWeight: "bold",
            fontSize: 24,
            marginTop: "1%",
          }}
        >
          {String.register_choose_subscription}
        </Text>
        <View
          style={{
            flexDirection: "row",
            width: "50%",
            height: "20%",
            marginTop: "2%",
          }}
        >
          <RadioView
            subscribeFalg={monthlySubscribeFlag}
            onPressSubscribe={onPressSubscriptionMonthly}
            subTitle={String.register_subscription_monthly}
            subPrice={String.register_subscription_price_monthly}
            subText={String.register_sub_cancel_anytime}
            style={{ width: "50%", height: "100%" }}
          />
          <RadioView
            subscribeFalg={yearlySubscribeFlag}
            onPressSubscribe={onPressSubscriptionYearly}
            subTitle={String.register_subscription_yearly}
            subPrice={String.register_subscription_price_yearly}
            subText={String.register_sub_access_all_levels}
            style={{ width: "50%", height: "100%" }}
          />
        </View>

        <ImagePress
          source={IMAGE_PAYNOW}
          onPress={onPressPayNow}
          style={{
            marginTop: "5%",
            width: "30%",
            height: "14%",
            resizeMode: "contain",
          }}
        />
      </RoundBackground>
    );
  };
  const cancelView = () => {
    return (
      <RoundBackground
        bgcolor="white"
        onPress={onPress}
        closeDisplay={false}
        borderColor="#2DCECB"
        margin="12%"
      >
        <Text
          style={(styles.subTitleTextStyle, { color: "black", fontSize: 16 })}
        >
          {String.unsubscribe_are_cancel_subscription}
        </Text>
        <View
          style={{
            width: "100%",
            height: "15%",
            flexDirection: "row",
            marginTop: "6%",
            justifyContent: "center",
          }}
        >
          <ImagePress
            onPress={renewPress}
            source={IMAGE_BTN_YES}
            style={{
              width: "15%",
              height: "100%",
              resizeMode: "contain",
              marginRight: "5%",
            }}
          />
          <ImagePress
            onPress={closeCancelView}
            source={IMAGE_BTN_NO}
            style={{ width: "15%", height: "100%", resizeMode: "contain" }}
          />
        </View>
      </RoundBackground>
    );
  };
  const selectView = () => {
    if (!renewFlag && !cancelFlag) {
      return mainView();
    } else if (renewFlag) {
      return renewView();
    } else if (cancelFlag) {
      return cancelView();
    }
  };
  return (
    <RoundBackground
      bgcolor="rgba(145, 107, 242, 0.9)"
      onPress={onPress}
      closeDisplay={true}
      borderColor="white"
    >
      {selectView()}
    </RoundBackground>
  );
};
export default AllReadySubscribed;

const styles = StyleSheet.create({
  titleTextStyle: {
    color: "white",
    fontWeight: "bold",
    fontSize: 26,
    marginTop: "1%",
  },
  subTitleTextStyle: {
    color: "white",
    fontWeight: "normal",
    fontSize: 16,
    marginTop: "1%",
  },
});

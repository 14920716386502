import React, { useRef, useEffect, useState } from "react";
import { View, Image, Text, StyleSheet, Alert } from "react-native";
import { useForm, Controller } from "react-hook-form";
import CmpTextInput from "../../components/CmpTextInput";
import RoundBackground from "../../components/RoundBackground";
import { String } from "../../constants/String";
import {
  IMAGE_SEVENDAY_TRIAL,
  IMAGE_PAYNOW,
  IMAGE_SINGLE_CHAR,
  IMAGE_BTN_SIGNUP,
  IMAGE_RADIO_ACTIVE,
  IMAGE_RADIO_INACTIVE,
} from "../../constants/ImageConst";
import ImagePress from "../../components/ImagePress";
import RadioView from "../../components/RadioView";
import * as Device from "expo-device";
import { heightToDp } from "../../utils/Responsive";
import {
  BUILD_TYPE_WEB,
  DEFAUL_BUILD_TYPE,
} from "../../constants/DefaultConst";
import ToastCmp from "../../components/ToastCmp";
import NetInfo from "@react-native-community/netinfo";
import { useDispatch, useSelector } from "react-redux";
import { request_Appconfig } from "../../redux/reducers/Rq_Appconfig";

const RegisterWelcome = ({
  onPressPayNow,
  onPress,
  onPressSubscriptionMonthly,
  onPressSubscriptionYearly,
  monthlySubscribeFlag,
  yearlySubscribeFlag,
  monthPrince,
  yearPrice,
  monthProductId,
  yearProductId,
}) => {
  const ref_Register_name = useRef();
  const ref_Register_email = useRef();
  const ref_Register_password = useRef();
  const ref_Register_coupon = useRef();
  const [detectDeviceType, setDetectDeviceType] = useState();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [coupon, setCoupon] = useState("");
  const [toastFlag, setToastFlag] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [internetFlag, setInternetFlag] = useState("");

  const dispatch = useDispatch();

  const responseAppConfigState = useSelector((state) => {
    console.log(
      " -------Config api on Register -------------  " +
        JSON.stringify(state.Rq_Appconfig)
    );
    return state.Rq_Appconfig;
  });

  useEffect(() => {
    Device.getDeviceTypeAsync().then((deviceType) => {
      setDetectDeviceType(deviceType);
      if (Device.DeviceType.PHONE === deviceType) {
        console.log("Device Type Phone");
      } else if (Device.DeviceType.TABLET === deviceType) {
        console.log("Device Type TABLET");
      } else if (Device.DeviceType.DESKTOP === deviceType) {
        console.log("Device Type DESKTOP");
      } else if (Device.DeviceType.TV === deviceType) {
        console.log("Device Type TV");
      } else {
        console.log("Device Type Unknown");
      }
    });
  }, []);

  useEffect(() => {
    dispatch(request_Appconfig());
  }, []);

  useEffect(() => {
    setDetectDeviceType(detectDeviceType);
  }, [detectDeviceType]);

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      console.log("Connection type", state.type);
      console.log("Is connected?", state.isConnected);
      setInternetFlag(state.isConnected.toString());
    });
    return () => {
      // Unsubscribe to network state updates
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    setEmail(email);
    setName(name);
    setPassword(password);
  }, [email, name, password]);

  useEffect(() => {
    setCoupon(coupon);
  }, [coupon]);

  useEffect(() => {
    setToastFlag(toastFlag);
  }, [toastFlag]);
  useEffect(() => {
    setToastMessage(toastMessage);
  }, [toastMessage]);

  // const { control, handleSubmit, errors, getValues, reset } = useForm();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (internetFlag === "true") {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

      if (responseAppConfigState.configure_coupon === "true") {
        if (coupon.length !== 0) {
          if (coupon === responseAppConfigState.coupon_code) {
            othervalidationCoupon();
          } else {
            setToastFlag(true);
            setToastMessage(String.alert_coupon_code_invalid);
          }
        } else {
          othervalidation();
        }
      } else if (email !== "" && name !== "" && password !== "") {
        if (reg.test(email) !== false) {
          let regname = /([A-Za-z])$/;
          if (regname.test(name)) {
            onPressPayNow(name, email, password);
          } else {
            setToastMessage(
              String.alert_ohh + "\n" + String.alert_register_only_text_allowed
            );
            setToastFlag(true);
          }
        } else {
          setToastMessage(
            String.alert_ohh + "\n" + String.alert_register_invalid_email
          );
          setToastFlag(true);
        }
      } else {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        let regname = /([A-Za-z])$/;
        if (email === "" && name === "" && password === "") {
          setToastMessage(
            String.alert_ohh + "\n" + String.alert_register_name_email_passwrod
          );
          setToastFlag(true);
        } else if (name === "") {
          setToastMessage(String.alert_signup_name_must_required);
          setToastFlag(true);
        } else if (regname.test(name) === false) {
          setToastMessage(
            String.alert_ohh + "\n" + String.alert_register_only_text_allowed
          );
          setToastFlag(true);
        } else if (email === "") {
          setToastMessage(String.alert_signup_email_must_required);
          setToastFlag(true);
        } else if (reg.test(email) === false) {
          setToastMessage(String.alert_register_invalid_email);
          setToastFlag(true);
        } else if (password === "") {
          setToastMessage(String.alert_signup_password_must_required);
          setToastFlag(true);
        }
      }
    } else {
      setToastFlag(true);
      setToastMessage(String.internet_connection_not_avaialable);
    }
  };

  const othervalidation = () => {
    if (email !== "" && name !== "" && password !== "") {
      if (reg.test(email) !== false) {
        let regname = /([A-Za-z])$/;
        if (regname.test(name)) {
          onPressPayNow(name, email, password, "");
        } else {
          setToastMessage(
            String.alert_ohh + "\n" + String.alert_register_only_text_allowed
          );
          setToastFlag(true);
        }
      } else {
        setToastMessage(
          String.alert_ohh + "\n" + String.alert_register_invalid_email
        );
        setToastFlag(true);
      }
    } else {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      let regname = /([A-Za-z])$/;
      if (email === "" && name === "" && password === "") {
        setToastMessage(
          String.alert_ohh + "\n" + String.alert_register_name_email_passwrod
        );
        setToastFlag(true);
      } else if (name === "") {
        setToastMessage(String.alert_signup_name_must_required);
        setToastFlag(true);
      } else if (regname.test(name) === false) {
        setToastMessage(
          String.alert_ohh + "\n" + String.alert_register_only_text_allowed
        );
        setToastFlag(true);
      } else if (email === "") {
        setToastMessage(String.alert_signup_email_must_required);
        setToastFlag(true);
      } else if (reg.test(email) === false) {
        setToastMessage(String.alert_register_invalid_email);
        setToastFlag(true);
      } else if (password === "") {
        setToastMessage(String.alert_signup_password_must_required);
        setToastFlag(true);
      }
    }
  };
  const othervalidationCoupon = () => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (email !== "" && name !== "" && password !== "") {
      if (reg.test(email) !== false) {
        let regname = /([A-Za-z])$/;
        if (regname.test(name)) {
          onPressPayNow(name, email, password, coupon);
        } else {
          setToastMessage(
            String.alert_ohh + "\n" + String.alert_register_only_text_allowed
          );
          setToastFlag(true);
        }
      } else {
        setToastMessage(
          String.alert_ohh + "\n" + String.alert_register_invalid_email
        );
        setToastFlag(true);
      }
    } else {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      let regname = /([A-Za-z])$/;
      if (email === "" && name === "" && password === "") {
        setToastMessage(
          String.alert_ohh + "\n" + String.alert_register_name_email_passwrod
        );
        setToastFlag(true);
      } else if (name === "") {
        setToastMessage(String.alert_signup_name_must_required);
        setToastFlag(true);
      } else if (regname.test(name) === false) {
        setToastMessage(
          String.alert_ohh + "\n" + String.alert_register_only_text_allowed
        );
        setToastFlag(true);
      } else if (email === "") {
        setToastMessage(String.alert_signup_email_must_required);
        setToastFlag(true);
      } else if (reg.test(email) === false) {
        setToastMessage(String.alert_register_invalid_email);
        setToastFlag(true);
      } else if (password === "") {
        setToastMessage(String.alert_signup_password_must_required);
        setToastFlag(true);
      }
    }
  };

  const onChangeSet = () => {
    return (
      <View
        style={{
          position: "absolute",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Text
          style={{
            color: "black",
            fontWeight: "bold",
            fontSize: heightToDp(2.5),
            marginTop:heightToDp(2)
          }}
        >
          {String.register_wcm_happygen}
        </Text>
        <Text
          style={{
            color: "black",
            fontWeight: "normal",
            fontSize: heightToDp(2),
            marginTop: "1%",
            marginBottom: "2%",
          }}
        >
          {String.register_get_started}
        </Text>

        <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: String.register_alert_enter_firstname,
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <CmpTextInput
              style={{
                fontSize: heightToDp(2),
                justifyContent: "center",
                color: "black",
                height: "10%",
                backgroundColor: "white",
                width: "55%",
                marginTop: "2%",
                borderRadius: 6,
                paddingHorizontal: 10,
                // height: heightToDp(5),
              }}
              placeholder={String.register_plc_name}
              autoCapitalize="none"
              keyboardType="default"
              returnKeyType="next"
              maxLength={50}
              secureTextEntry={false}
              refrensKey={ref_Register_name}
              onSubmitEditing={() => ref_Register_email.current.focus()}
              onBlur={onBlur}
              onChangeText={(text) => {
                onChange;
                if (text.length >= 50) {
                  setToastFlag(true);
                  setToastMessage(String.alert_signup_yourname_max_char);
                } else {
                  setName(text);
                }
              }}
              value={value}
            />
          )}
          name="yourname"
          defaultValue=""
        />
        {/*{errors.yourname && (
          <Text style={styles.errorTextStyle}>{errors.yourname.message}</Text>
        )}*/}

        <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: String.register_alert_enter_email,
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "invalid email address",
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <CmpTextInput
              style={{
                fontSize: heightToDp(2),
                justifyContent: "center",
                color: "black",
                height: "10%",
                backgroundColor: "white",
                width: "55%",
                borderRadius: 6,
                marginTop: "1%",
                paddingHorizontal: 10,
              }}
              placeholder={String.register_plc_email}
              autoCapitalize="none"
              keyboardType="email-address"
              returnKeyType="next"
              secureTextEntry={false}
              refrensKey={ref_Register_email}
              // onSubmitEditing={handleSubmit(onPressPayNow("hitesh","hitesh@sociosquares.com"))}
              onSubmitEditing={() => ref_Register_password.current.focus()}
              onBlur={onBlur}
              onChangeText={(text) => {
                onChange;
                setEmail(text);
              }}
              value={value}
            />
          )}
          name="email"
          defaultValue=""
        />
        {/*{errors.email && (
          <Text style={styles.errorTextStyle}>{errors.email.message}</Text>
       )}*/}

        <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: String.register_plc_password,
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <CmpTextInput
              style={{
                fontSize: heightToDp(2),
                justifyContent: "center",
                color: "black",
                height: "10%",
                backgroundColor: "white",
                width: "55%",
                marginTop: "1%",
                borderRadius: 6,
                paddingHorizontal: 10,
                // height: heightToDp(5),
              }}
              placeholder={String.register_plc_password}
              autoCapitalize="none"
              keyboardType="default"
              returnKeyType="done"
              maxLength={15}
              secureTextEntry={true}
              refrensKey={ref_Register_password}
              onSubmitEditing={onSubmit}
              onBlur={onBlur}
              onChangeText={(text) => {
                onChange;
                setPassword(text);
              }}
              value={value}
            />
          )}
          name="pasword"
          defaultValue=""
        />
        {responseAppConfigState.configure_coupon === "true" ? (
          <Controller
            control={control}
            rules={{
              required: {
                value: true,
                message: String.register_plc_coupon,
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <CmpTextInput
                style={{
                  fontSize: heightToDp(2),
                  justifyContent: "center",
                  color: "black",
                  height: "10%",
                  backgroundColor: "white",
                  width: "55%",
                  marginTop: "1%",
                  borderRadius: 6,
                  paddingHorizontal: 10,
                  // height: heightToDp(5),
                }}
                placeholder={String.register_plc_coupon}
                autoCapitalize="none"
                keyboardType="default"
                returnKeyType="done"
                maxLength={10}
                secureTextEntry={false}
                refrensKey={ref_Register_coupon}
                onSubmitEditing={onSubmit}
                onBlur={onBlur}
                onChangeText={(text) => {
                  onChange;
                  setCoupon(text);
                }}
                value={value}
              />
            )}
            name="coupon code"
            defaultValue=""
          />
        ) : null}
        <Text
          style={{
            color: "black",
            fontWeight: "normal",
            fontSize: heightToDp(2),
            marginTop: "1%",
          }}
        >
          {String.register_choose_subscription}
        </Text>
        <Text
          style={{
            color: "black",
            fontWeight: "normal",
            fontSize: heightToDp(2),
            width: "55%",
            textAlign: "center",
          }}
        >
          {String.register_choose_subscription_provide}
        </Text>
        <View
          style={{
            flexDirection: "row",
            width: "50%",
            height: "12%",
            marginTop: "1%",
          }}
        >
          <RadioView
            subscribeFalg={monthlySubscribeFlag}
            onPressSubscribe={onPressSubscriptionMonthly}
            subTitle={String.register_subscription_monthly}
            subPrice={
              monthPrince === ""
                ? String.register_subscription_price_monthly
                : monthPrince
            }
            subText={String.register_sub_cancel_anytime}
            style={{ width: "50%", height: "100%" }}
          />
          <RadioView
            subscribeFalg={yearlySubscribeFlag}
            onPressSubscribe={onPressSubscriptionYearly}
            subTitle={String.register_subscription_yearly}
            subPrice={
              yearPrice === ""
                ? String.register_subscription_price_yearly
                : yearPrice
            }
            subText={String.register_sub_access_all_levels}
            style={{ width: "50%", height: "100%" }}
          />
        </View>

        <Text
        style={{
          color: "black",
          fontWeight: "normal",
          fontSize: heightToDp(2),
          marginBottom:heightToDp(2),
          marginTop: "1%",
        }}
      >
        {String.register_cancel_anytime_subscription}
      </Text>

        <ImagePress
          // source={IMAGE_PAYNOW}
          source={IMAGE_BTN_SIGNUP}
          // onPress={()=>{onPressPayNow("hitesh","hitesh@sociosquares.com")}}
          onPress={onSubmit}
          style={{
            // marginTop: "2%",
            width: "26%",
            height: "10%",
            resizeMode: "contain",
          }}
        />
       
        <View
          style={{
            position: "absolute",
            width: "20%",
            height: "55%",
            right: "2%",
            bottom: "1%",
          }}
        >
          {
            // <Image
            //     // source={IMAGE_SEVENDAY_TRIAL}
            //     style={{ width: "100%", height: "50%", resizeMode: "contain" }}
            //     resizeMethod="resize"
            //   />
          }

          <Image
            source={IMAGE_SINGLE_CHAR}
            style={{ width: "100%", height: "50%", resizeMode: "contain" }}
            resizeMethod="resize"
          />
        </View>
      </View>
    );
  };

  const toastOnPressClose = () => {
    setToastFlag(false);
  };

  return (
    <RoundBackground
      bgcolor="rgba(241, 221, 106, 1)"
      onPress={onPress}
      closeDisplay={true}
      borderColor="white"
    >
      {onChangeSet()}
      {toastFlag ? (
        <ToastCmp message={toastMessage} onPress={toastOnPressClose} />
      ) : null}
    </RoundBackground>
  );
};
export default RegisterWelcome;

const styles = StyleSheet.create({
  errorTextStyle: {
    color: "red",
    justifyContent: "center",
    width: "55%",
    paddingHorizontal: 10,
    fontSize: 10,
  },
});

import React from "react";
import { Platform } from "react-native";
import Constants from "expo-constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  isBlank,
  validateObject,
  checkKeyAssign,
  validateArray,
} from "../../utils/Utility";
import { API_LEVEL_VIDEO_LIST_GET, URL_VIDEO } from "../../constants/ApiConst";
import { String } from "../../constants/String";
import axios from "axios";
import { HEADER } from "../../constants/DefaultConst";
const TAG = "Rq_Level_videolist ";
export const request_Level_VideoList = createAsyncThunk(
  "Rq_Level_videolist/request_Level_VideoList",
  async (payloadCreator, thunkAPI) => {
    const data = {};
    console.log(
      TAG + " Payload levelid : " + payloadCreator.extraParams.level_id
    );
    var config = {
      // '/' +
      // payloadCreator.extraParams.from +
      // '/' +
      // payloadCreator.extraParams.to
      method: "get",
      url:
        API_LEVEL_VIDEO_LIST_GET +
        "?level_id=" +
        payloadCreator.extraParams.level_id +
        "&" +
        "uid=" +
        payloadCreator.extraParams.uid,
      // headers: HEADER,
    };

    const response = axios(config)
      .then(function (response) {
        console.log(
          "Rq_Level_videolist Response : " + JSON.stringify(response.data)
        );
        return response;
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // Logger(TAG, 'error data1 : ' + JSON.stringify(error.response.data));
          // Logger(TAG, 'error status2 : ' + error.response.status);
          // Logger(
          //   TAG,
          //   'error headers3 : ' + JSON.stringify(error.response.headers),
          // );
          return error.response.data;
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // Logger(TAG, 'error request4 : ' + JSON.stringify(error.request));
        } else {
          // Something happened in setting up the request that triggered an Error
          // Logger(TAG, 'error message5 : ' + error.message);
        }
        // Logger(TAG, 'error config6 : ' + JSON.stringify(error.config));
      });
    return response;
  }
);

const setResponsedata = (state, response) => {
  // console.log(TAG, "data response infun :" + JSON.stringify(response.status));
  if (validateObject(response.data)) {
    state.levelVideoDataArray = [];
    response.data.forEach((item) => {
      let dataitem = null;
      try {
        dataitem = setSingleItemData(state, item);
        return state.levelVideoDataArray.push(dataitem);
      } catch (error) {}
    });
    // console.log("My array is : "+ JSON.stringify(state.levelDataArray));
  }
};

const setSingleItemData = (state, item) => {
  let itemobject = {
    vid: "",
    level_id: "",
    label: "",
    video_url: "",
    video_no:"",
    end_message: "",
    message_input_flag: "",
    end_activity: "",
    activity_input_flag: "",
    funfact_flag: "",
    funfact_type: "",

    bmsg_before_vdo_play:"",
    bmsg_after_vdo_play:"",
    bmsg_star_collection:"",
    bmsg_after_star_collection:"",
    bmsg_before_actvity_disp:"",
    bmsg_after_actvity_disp:"",
    bmsg_before_funfact_disp:"",
    bmsg_after_funfact_disp:"",

    created_at: "",
    updated_at: "",
    funfacts: [],
  };
  itemobject.vid = checkKeyAssign("vid", item);
  itemobject.level_id = checkKeyAssign("level_id", item);
  itemobject.label = checkKeyAssign("label", item);

  if (checkKeyAssign("video_url", item) !== "") {
    itemobject.video_url = checkKeyAssign("video_url", item);
  }
  itemobject.end_message = checkKeyAssign("end_message", item);

  // itemobject.end_activity = checkKeyAssign("end_activity", item);
  var temp_end_activity = checkKeyAssign("end_activity", item);
  if(temp_end_activity !== ""){
    console.log("Temp end_activity : "+ temp_end_activity);
    itemobject.end_activity = temp_end_activity.replace(/\\n/g,'\n');
  }else{
    itemobject.end_activity = temp_end_activity;
  }


  itemobject.message_input_flag = checkKeyAssign("message_input_flag", item);
  itemobject.activity_input_flag = checkKeyAssign("activity_input_flag", item);

  itemobject.video_no = checkKeyAssign("video_no", item);

  itemobject.funfact_flag = checkKeyAssign("funfact_flag", item);
  itemobject.funfact_type = checkKeyAssign("funfact_type", item);


  itemobject.bmsg_before_vdo_play = checkKeyAssign("bmsg_before_vdo_play", item);
  itemobject.bmsg_after_vdo_play = checkKeyAssign("bmsg_after_vdo_play", item);
  // itemobject.bmsg_star_collection = checkKeyAssign("bmsg_star_collection", item);

  var temp_bmsg_star_collection = checkKeyAssign("bmsg_star_collection", item);
  if(temp_bmsg_star_collection !== ""){
    console.log("Temp Message : "+ temp_bmsg_star_collection);
    itemobject.bmsg_star_collection = temp_bmsg_star_collection.replace(/\\n/g,'\n');
  }else{
    itemobject.bmsg_star_collection = temp_bmsg_star_collection;
  }

  itemobject.bmsg_after_star_collection = checkKeyAssign("bmsg_after_star_collection", item);
 
 
  itemobject.bmsg_before_actvity_disp = checkKeyAssign("bmsg_before_actvity_disp", item);
  itemobject.bmsg_after_actvity_disp = checkKeyAssign("bmsg_after_actvity_disp", item);
  itemobject.bmsg_before_funfact_disp = checkKeyAssign("bmsg_before_funfact_disp", item);
  itemobject.bmsg_after_funfact_disp = checkKeyAssign("bmsg_after_funfact_disp", item);


  itemobject.created_at = checkKeyAssign("created_at", item);
  itemobject.updated_at = checkKeyAssign("updated_at", item);

  if (validateArray(item.funfacts))
    item.funfacts.forEach((childitem) => {
      let dataitemchild = null;
      try {
        dataitemchild = setFunFactData(state, childitem);
        return itemobject.funfacts.push(dataitemchild);
      } catch (error) {}
    });

  return itemobject;
};

const setFunFactData = (state, item) => {
  let itemChildobject = {
    fid: "",
    vid: "",
    funfact_type: "",
    fun_title: "",
    fun_que_no: "",
    question: "",
    option1: "",
    option1_answer_falg: "",
    option2: "",
    option2_answer_falg: "",
    option3: "",
    option3_answer_falg: "",
    option4: "",
    option4_answer_falg: "",
  };

  itemChildobject.fid = checkKeyAssign("fid", item);
  itemChildobject.vid = checkKeyAssign("vid", item);
  itemChildobject.funfact_type = checkKeyAssign("funfact_type", item);

  itemChildobject.fun_title = checkKeyAssign("fun_title", item);

  var temp_fun_title = checkKeyAssign("fun_title", item);
  if(temp_fun_title !== ""){
    console.log("Temp Message : "+ temp_fun_title);
    itemChildobject.fun_title = temp_fun_title.replace(/\\n/g,'\n');
  }else{
    itemChildobject.fun_title = temp_fun_title;
  }

  itemChildobject.fun_que_no = checkKeyAssign("fun_que_no", item);

  itemChildobject.question = checkKeyAssign("question", item);

  itemChildobject.option1 = checkKeyAssign("option1", item);
  itemChildobject.option1_answer_falg = checkKeyAssign("option1_answer_falg", item);

  itemChildobject.option2 = checkKeyAssign("option2", item);
  itemChildobject.option2_answer_falg = checkKeyAssign("option2_answer_falg", item);

  itemChildobject.option3 = checkKeyAssign("option3", item);
  itemChildobject.option3_answer_falg = checkKeyAssign("option3_answer_falg", item);

  itemChildobject.option4 = checkKeyAssign("option4", item);
  itemChildobject.option4_answer_falg = checkKeyAssign("option4_answer_falg", item);
  
  return itemChildobject;


};

const Rq_Level_videolist = createSlice({
  name: "Rq_Level_videolist",
  initialState: {
    loading: "",
    responseFlag: "",

    levelVideoDataArray: [],
    errorCode: "",
    errorType: "",
    errorMessage: "",
  },
  reducers: {},
  extraReducers: {
    [request_Level_VideoList.pending]: (state, action) => {
      state.loading = "yes";
    },
    [request_Level_VideoList.fulfilled]: (state, action) => {
      if (validateObject(action.payload.data)) {
        if (validateObject(action.payload.data.result)) {
          state.responseFlag = checkKeyAssign(
            "status",
            action.payload.data.result
          );
          if (state.responseFlag === "true") {
            setResponsedata(state, action.payload.data.result);
          } else if (state.responseFlag === "false") {
            if (validateObject(action.payload.data.result)) {
              state.errorCode = checkKeyAssign(
                "statusCode",
                action.payload.data.result
              );
              state.errorMessage = checkKeyAssign(
                "statusMessage",
                action.payload.data.result
              );
              // setErrorData(state, action.payload.result.error);
            } else {
              state.responseFlag = "false";
              state.responseMessage = String.could_not_get_reponse;
            }
          } else {
            state.responseFlag = "false";
            state.responseMessage = String.could_not_get_reponse;
          }
        }
      }
      state.loading = "no";
    },
    [request_Level_VideoList.rejected]: (state, action) => {
      state.loading = "no";
      state.responseFlag = "false";
      state.responseMessage = String.could_not_get_reponse;
    },
  },
});
export default Rq_Level_videolist;

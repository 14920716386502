import React, { useState, useEffect, useRef } from "react";
import { View, Text, Image, StyleSheet } from "react-native";
import ImagePress from "../../components/ImagePress";
import {
  IMAGE_SINGLE_CHAR,
  IMAGE_BROODY_LESSONS,
  IMAGE_PLAY_VIDEO,
  IMAGE_START_SESSONS,
  IMAGE_BROODY_DIALOG,
} from "../../constants/ImageConst";
import { String } from "../../constants/String";
import { heightToDp } from "../../utils/Responsive";
import IntroVideonScreenFull from "./IntroVideonScreenFull";

// Audio test

const FirstViewBanner = ({ onPress }) => {
  const [introvideoDispFlag, setIntroVideoDispFlag] = useState(false);
  const [nextFlag, setNextFlag] = useState(false);

  const onPressCloseButtonIntroVideoView = () => {
    setIntroVideoDispFlag(false);
    setNextFlag(true);
  };
  const onPressPlayVideo = () => {
    setIntroVideoDispFlag(true);
    setNextFlag(false);
  };

  const startScreen = () => {
    return (
      <View style={{ width: "100%", height: "100%", justifyContent: "center" }}>
        <Text style={styles.textTitleContainer}>
          {String.dialog_welcome_tohappygenius}
        </Text>
        <Image source={IMAGE_BROODY_LESSONS} style={styles.imageContainer} />
        <Text style={styles.textSubTitleContainer}>
          {String.dialog_welcome_desc}
        </Text>
        <ImagePress
          source={IMAGE_PLAY_VIDEO}
          onPress={onPressPlayVideo}
          style={styles.imageButtonContainer}
        />
      </View>
    );
  };

  const lessonScreen_start = () => {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          position: "absolute",
          backgroundColor: "#FEE252",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            height: "100%",
          }}
        >
          <View
            style={{
              width: "40%",
              height: "100%",
              alignItems: "flex-end",
              alignContent:'center',
              flexDirection:'column'
            }}
          >
            <Image
              source={IMAGE_BROODY_DIALOG}
              style={[
                styles.imageContainerTop,
                { position: "absolute" },
              ]}
              
            />
            <Image
              source={IMAGE_SINGLE_CHAR}
              style={[
                styles.imageContainerLarge,
                { bottom: "10%", position: "absolute" },
              ]}
            />
          </View>

          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              width: "60%",
              height: "100%",
            }}
          >
            <Text style={[styles.textSubTitleContainer,{letterSpacing: 1.5,lineHeight:heightToDp(4)}]}>
             {String.start_play_text1}
             <Text style={[styles.textSubTitleContainer,{letterSpacing: 1.5,fontWeight:'bold'}]}>{String.start_play_text2}</Text>
             {String.start_play_text3}
             <Text style={[styles.textSubTitleContainer,{letterSpacing: 1.5,fontWeight:'bold'}]}>{String.start_play_text4}</Text>
             {String.start_play_text5}
            </Text>
            <ImagePress
              source={IMAGE_PLAY_VIDEO}
              onPress={onPressPlayVideo}
              style={[styles.imageButtonContainer,{width:'40%',height:'12%'}]}
            />
          </View>
        </View>
      </View>
    );
  };

  const lessonScreen = () => {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          position: "absolute",
          backgroundColor: "#FEA444",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            height: "100%",
          }}
        >
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              width: "60%",
              height: "100%",
            }}
          >
            <Text style={[styles.textSubTitleContainer,{letterSpacing: 1.5}]}>
              <Text
                style={[styles.textSubTitleContainer]}
              >
                {String.dialog_gouri_desc}
              </Text>
              <Text>{String.dialog_gouri_desc1}</Text>
              
            </Text>
            <ImagePress
              source={IMAGE_START_SESSONS}
              onPress={onPress}
              style={[styles.imageButtonContainer, { marginTop: "6%" }]}
            />
          </View>
          <View
            style={{
              width: "40%",
              height: "100%",
            }}
          >
            <Image
              source={IMAGE_SINGLE_CHAR}
              style={[
                styles.imageContainerLarge,
                { bottom: "10%", position: "absolute" },
              ]}
            />
          </View>
        </View>
      </View>
    );
  };
  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View style={styles.mainContainer}>
        {lessonScreen_start()}
        {nextFlag ? lessonScreen() : null}
        {introvideoDispFlag ? (
          <IntroVideonScreenFull
            hideclose={true}
            urlSource="https://happigenius.b-cdn.net/Intro_to_happigenius.mp4"
            onPress={onPressCloseButtonIntroVideoView}
          />
        ) : null}
      </View>
    </View>
  );
};

export default FirstViewBanner;
const styles = StyleSheet.create({
  mainContainer: {
    // flex: 1,
    width: "75%",
    height: "75%",
    borderWidth: 10,
    borderRadius: 10,
    borderColor: "white",
    backgroundColor: "rgba(238, 227, 106, 1)",
    justifyContent: "center",
  },

  imageContainer: {
    width: "35%",
    height: "35%",
    alignSelf: "center",
    resizeMode: "contain",
  },
  imageContainerTop: {
    width: "65%",
    height: "30%",
    marginTop:"10%",
    resizeMode: "contain",
    
  },
  imageContainerLarge: {
    width: "65%",
    height: "50%",
    marginBottom:"6%",
    resizeMode: "contain",
  },
  imageButtonContainer: {
    marginTop: "4%",
    marginBottom: "2%",
    width: "40%",
    height: "12%",
    resizeMode: "contain",
    alignSelf: "center",
  },

  textTitleContainer: {
    fontSize: heightToDp(3),
    color: "black",
    padding: "2%",
    textAlign: "center",
    fontWeight: "bold",
  },
  textSubTitleContainer: {
    fontSize: heightToDp(3),
    color: "black",
    marginTop: "2%",
    textAlign: "center",
  },
  textSubBoldTitleContainer: {
    fontSize: heightToDp(3),
    color: "black",
    marginTop: "2%",
    textAlign: "center",
    fontWeight: "bold",
  },
});

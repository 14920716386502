import React, { Children } from "react";
import { View, Image, ScrollView } from "react-native";
import { IMAGE_QT_CLOSE } from "../constants/ImageConst";
import ImagePress from "./ImagePress";

const RoundBackground = ({ children, onPress, bgcolor,closeDisplay,borderColor,margin }) => {
  return (
      <View
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          justifyContent: "center",
        }}
      >
        <View
          style={{
            width: "95%",
            height: "95%",
            position: "absolute",
            backgroundColor: bgcolor,
            borderColor:borderColor,
            alignSelf: "center",
            borderRadius: 20,
            borderWidth: 10,
            justifyContent: "center",
            alignItems: "center",
            margin:margin
          }}
        >
          {children}
        </View>
        
        {closeDisplay?<ImagePress
          source={IMAGE_QT_CLOSE}
          onPress={onPress}
          style={{
            width: "4%",
            height: "8%",
            margin: "0.5%",
            top: "0.5%",
            resizeMode: "contain",
            right: "1%",
            position: "absolute",
          }}
          resizeMethod="resize"
        />:null}
      </View>
  );
};

export default RoundBackground;

import { Platform } from "react-native";
import Constants from "expo-constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isBlank, validateObject, checkKeyAssign } from "../../utils/Utility";
import { API_APP_CONFIG_GET, URL_MUSIC } from "../../constants/ApiConst";
import axios from "axios";
import { HEADER } from "../../constants/DefaultConst";

const TAG = "Rq_Appconfig ";

export const request_Appconfig = createAsyncThunk(
  "Rq_Appconfig/request_Appconfig",
  async (payloadCreator, thunkAPI) => {
    const data = {};
    var config = {
      method: "get",
      url: API_APP_CONFIG_GET,
      // headers: HEADER,
    };

    const response = axios(config)
      .then(function (response) {
        console.log("Rq_Appconfig Response : "+JSON.stringify(response.data));
        return response;
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // Logger(TAG, 'error data1 : ' + JSON.stringify(error.response.data));
          // Logger(TAG, 'error status2 : ' + error.response.status);
          // Logger(
          //   TAG,
          //   'error headers3 : ' + JSON.stringify(error.response.headers),
          // );
          return error.response.data;
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // Logger(TAG, 'error request4 : ' + JSON.stringify(error.request));
        } else {
          // Something happened in setting up the request that triggered an Error
          // Logger(TAG, 'error message5 : ' + error.message);
        }
        // Logger(TAG, 'error config6 : ' + JSON.stringify(error.config));
      });
    return response;
  }
);

const setResponsedata = (state, response) => {
    // console.log(TAG, "data response infun :" + JSON.stringify(response.status));
    if (validateObject(response.data)) {
        state.android_app_version= checkKeyAssign('android_app_version',response.data);
        state.ios_app_version= checkKeyAssign('ios_app_version',response.data);
        state.monthly_price= checkKeyAssign('monthly_price',response.data);
        state.yearly_price= checkKeyAssign('yearly_price',response.data);
        state.update_optional= checkKeyAssign('update_optional',response.data);
        state.update_date= checkKeyAssign('update_date',response.data);
        state.stop_interval = checkKeyAssign('stop_interval',response.data);
        state.levelMusic=[];
        state.level_1_mp3 = URL_MUSIC+checkKeyAssign('level_1_mp3',response.data);
        state.level_2_mp3 = URL_MUSIC+checkKeyAssign('level_2_mp3',response.data);
        state.level_3_mp3 = URL_MUSIC+checkKeyAssign('level_3_mp3',response.data);
        state.level_4_mp3 = URL_MUSIC+checkKeyAssign('level_4_mp3',response.data);
        state.level_5_mp3 = URL_MUSIC+checkKeyAssign('level_5_mp3',response.data);
        state.level_6_mp3 = URL_MUSIC+checkKeyAssign('level_6_mp3',response.data);
        state.level_7_mp3 = URL_MUSIC+checkKeyAssign('level_7_mp3',response.data);
        state.level_8_mp3 = URL_MUSIC+checkKeyAssign('level_8_mp3',response.data);
        state.level_9_mp3 = URL_MUSIC+checkKeyAssign('level_9_mp3',response.data);
        state.level_10_mp3 = URL_MUSIC+checkKeyAssign('level_10_mp3',response.data);
        state.level_11_mp3 = URL_MUSIC+checkKeyAssign('level_11_mp3',response.data);
        state.level_12_mp3 = URL_MUSIC+checkKeyAssign('level_12_mp3',response.data);

        state.star_collection_mp3 = URL_MUSIC+checkKeyAssign('star_collection_mp3',response.data);
        state.star_collection_award_mp3 = URL_MUSIC+checkKeyAssign('star_collection_award_mp3',response.data);
        state.configure_coupon=checkKeyAssign('configure_coupon',response.data);
        state.coupon_code=checkKeyAssign('coupon_code',response.data);


        state.levelMusic.push(state.level_1_mp3);
        state.levelMusic.push(state.level_2_mp3);
        state.levelMusic.push(state.level_3_mp3);
        state.levelMusic.push(state.level_4_mp3);
        state.levelMusic.push(state.level_5_mp3);
        state.levelMusic.push(state.level_6_mp3);
        state.levelMusic.push(state.level_7_mp3);
        state.levelMusic.push(state.level_8_mp3);
        state.levelMusic.push(state.level_9_mp3);
        state.levelMusic.push(state.level_10_mp3);
        state.levelMusic.push(state.level_11_mp3);
        state.levelMusic.push(state.level_12_mp3);

        return state;


    }
  };


const Rq_Appconfig = createSlice({
    name: "Rq_Appconfig",
    initialState: {
      loading: "",
      responseFlag: "",

      android_app_version:'',
      ios_app_version:'',
      monthly_price:'',
      yearly_price:'',
      update_optional:'',
      update_date:'',
      stop_interval:'',
      level_1_mp3:'',
      level_2_mp3:'',
      level_3_mp3:'',
      level_4_mp3:'',
      level_5_mp3:'',
      level_6_mp3:'',
      level_7_mp3:'',
      level_8_mp3:'',
      level_9_mp3:'',
      level_10_mp3:'',
      level_11_mp3:'',
      level_12_mp3:'',
      star_collection_mp3:'',
      star_collection_award_mp3:'',
      configure_coupon:'',
      coupon_code:'',
      levelMusic: [],

      errorCode: "",
      errorType: "",
      errorMessage: "",
    },
    reducers: {
     
    },
    extraReducers: {
      [request_Appconfig.pending]: (state, action) => {
        state.loading = "yes";
      },
      [request_Appconfig.fulfilled]: (state, action) => {
        
        if (validateObject(action.payload.data)) {
          if (validateObject(action.payload.data.result)) {
            state.responseFlag = checkKeyAssign("status", action.payload.data.result);
            if (state.responseFlag === "true") {
               setResponsedata(state, action.payload.data.result);
            } else if (state.responseFlag === "false") {
              if (validateObject(action.payload.data.result)) {
                state.errorCode= checkKeyAssign('statusCode',action.payload.data.result);
                state.errorMessage= checkKeyAssign('statusMessage',action.payload.data.result);
                // setErrorData(state, action.payload.result.error);
              } else {
                state.responseFlag = "false";
                state.responseMessage = String.could_not_get_reponse;
              }
            } else {
              state.responseFlag = "false";
              state.responseMessage = String.could_not_get_reponse;
            }
          }
        }
        state.loading = "no";
      },
      [request_Appconfig.rejected]: (state, action) => {
        state.loading = "no";
        state.responseFlag = "false";
        state.responseMessage = String.could_not_get_reponse;
      },
    },
  });

export default Rq_Appconfig;

import React, { Children } from "react";
import { View, StyleSheet } from "react-native";
import ImagePress from "./ImagePress";
import { IMAGE_BTN_BACK } from "../constants/ImageConst";
// handleMenu={onPressSideMenuButton}

const CustomViewBackTrans = ({ bgColor, borderColor, children, onPress,dummyStyle }) => {
  return (
    <View
      style={[
        styles.mainContainer,
        {  borderColor: borderColor,backgroundColor:bgColor },
        dummyStyle
      ]}
    >
      <ImagePress
        onPress={onPress}
        source={IMAGE_BTN_BACK}
        style={{
          resizeMode: "contain",
          height: "8%",
          width: "5%",
          margin: "1%",
        }}
      />
      {children}
    </View>
  );
};

export default CustomViewBackTrans;

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
  },
});

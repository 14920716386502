import React, { Children } from "react";
import { View, StyleSheet } from "react-native";
import ImagePress from "./ImagePress";
import { IMAGE_BTN_BACK } from "../constants/ImageConst";
// handleMenu={onPressSideMenuButton}

const CustomViewBack = ({ bgColor, borderColor, children, onPress,dummyStyle }) => {
  return (
    <View
      style={[
        styles.mainContainer,
        { backgroundColor: bgColor, borderColor: borderColor },
        dummyStyle
      ]}
    >
      {children}
    </View>
  );
};

export default CustomViewBack;

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    overflow:'hidden'
  },
});

import React from "react";
import { Platform } from "react-native";
import FormData from "form-data";
import Constants from "expo-constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isBlank, validateObject, checkKeyAssign } from "../../utils/Utility";
import { API_USER_CREATE_POST } from "../../constants/ApiConst";
import { String } from "../../constants/String";
import axios from "axios";
import { HEADER } from "../../constants/DefaultConst";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  PREF_LESSON1,
  PREF_LESSON10,
  PREF_LESSON11,
  PREF_LESSON12,
  PREF_LESSON2,
  PREF_LESSON3,
  PREF_LESSON4,
  PREF_LESSON5,
  PREF_LESSON6,
  PREF_LESSON7,
  PREF_LESSON8,
  PREF_LESSON9,
} from "../../constants/PrefConst";
const TAG = "Rq_CreateUser ";
export const request_CreateUser = createAsyncThunk(
  "Rq_CreateUser/request_CreateUser",
  async (payloadCreator, thunkAPI) => {
    const data = new FormData();

    if (!isBlank(payloadCreator.params["first_name"])) {
      data.append("first_name", payloadCreator.params["first_name"]);
    }
    if (!isBlank(payloadCreator.params["email"])) {
      data.append("email", payloadCreator.params["email"]);
    }
    if (!isBlank(payloadCreator.params["password"])) {
      data.append("password", payloadCreator.params["password"]);
    }
    if (!isBlank(payloadCreator.params["usertoken"])) {
      data.append("usertoken", payloadCreator.params["usertoken"]);
    }
    if (!isBlank(payloadCreator.params["user_type"])) {
      data.append("user_type", payloadCreator.params["user_type"]);
    }

    if (!isBlank(payloadCreator.params["monthly_subscribe_date"])) {
      data.append(
        "monthly_subscribe_date",
        payloadCreator.params["monthly_subscribe_date"]
      );
      console.log(
        TAG +
          " Payload monthly_subscribe_date : " +
          payloadCreator.params.monthly_subscribe_date
      );
    }
    if (!isBlank(payloadCreator.params["yearly_subscribe_date"])) {
      data.append(
        "yearly_subscribe_date",
        payloadCreator.params["yearly_subscribe_date"]
      );
      console.log(
        TAG +
          " Payload yearly_subscribe_date : " +
          payloadCreator.params.yearly_subscribe_date
      );
    }

    // const data = {
    //   'first_name': payloadCreator.params.name,
    //   'email': payloadCreator.params.email,
    //   'usertoken': payloadCreator.params.usertoken,
    // };

    console.log(
      TAG + " Payload first_name : " + payloadCreator.params.first_name
    );
    console.log(TAG + " Payload email : " + payloadCreator.params.email);
    console.log(
      TAG + " Payload usertoken : " + payloadCreator.params.usertoken
    );
    console.log(TAG + " Payload usertype : " + payloadCreator.params.user_type);

    var config = {
      // '/' +
      // payloadCreator.extraParams.from +
      // '/' +
      // payloadCreator.extraParams.to
      method: "post",
      url: API_USER_CREATE_POST,
      // headers: HEADER,
      data: data,
    };

    const response = axios(config)
      .then(function (response) {
        console.log(TAG + " : " + JSON.stringify(response.data));
        return response;
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // Logger(TAG, 'error data1 : ' + JSON.stringify(error.response.data));
          // Logger(TAG, 'error status2 : ' + error.response.status);
          // Logger(
          //   TAG,
          //   'error headers3 : ' + JSON.stringify(error.response.headers),
          // );
          return error.response.data;
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // Logger(TAG, 'error request4 : ' + JSON.stringify(error.request));
        } else {
          // Something happened in setting up the request that triggered an Error
          // Logger(TAG, 'error message5 : ' + error.message);
        }
        // Logger(TAG, 'error config6 : ' + JSON.stringify(error.config));
      });
    return response;
  }
);

const setResponsedata = (state, response) => {
  // console.log(TAG, "data response infun :" + JSON.stringify(response.status));
  if (validateObject(response.data)) {
    state.id = checkKeyAssign("id", response.data);
    state.usertoken = checkKeyAssign("usertoken", response.data);
    state.uid = checkKeyAssign("uid", response.data);
    state.email = checkKeyAssign("email", response.data);
    state.user_type = checkKeyAssign("user_type", response.data);
    state.first_name = checkKeyAssign("first_name", response.data);
    state.last_name = checkKeyAssign("last_name", response.data);
    state.pic = checkKeyAssign("pic", response.data);
    state.age = checkKeyAssign("age", response.data);
    state.monthly_subscribe_date = checkKeyAssign(
      "monthly_subscribe_date",
      response.data
    );
    state.monthly_subscribe_flag = checkKeyAssign(
      "monthly_subscribe_flag",
      response.data
    );
    state.yearly_subscribe_date = checkKeyAssign(
      "yearly_subscribe_date",
      response.data
    );
    state.yearly_subscribe_flag = checkKeyAssign(
      "yearly_subscribe_flag",
      response.data
    );
    state.monthly_subscribe_end_date = checkKeyAssign(
      "monthly_subscribe_end_date",
      response.data
    );
    state.yearly_subscribe_end_date = checkKeyAssign(
      "yearly_subscribe_end_date",
      response.data
    );

    state.lesson1 = checkKeyAssign("lesson1", response.data);
    state.lesson2 = checkKeyAssign("lesson2", response.data);
    state.lesson3 = checkKeyAssign("lesson3", response.data);
    state.lesson4 = checkKeyAssign("lesson4", response.data);
    state.lesson5 = checkKeyAssign("lesson5", response.data);
    state.lesson6 = checkKeyAssign("lesson6", response.data);
    state.lesson7 = checkKeyAssign("lesson7", response.data);
    state.lesson8 = checkKeyAssign("lesson8", response.data);
    state.lesson9 = checkKeyAssign("lesson9", response.data);
    state.lesson10 = checkKeyAssign("lesson10", response.data);
    state.lesson11 = checkKeyAssign("lesson11", response.data);
    state.lesson12 = checkKeyAssign("lesson12", response.data);

    savePref(PREF_LESSON1, state.lesson1);
    savePref(PREF_LESSON2, state.lesson2);
    savePref(PREF_LESSON3, state.lesson3);
    savePref(PREF_LESSON4, state.lesson4);
    savePref(PREF_LESSON5, state.lesson5);
    savePref(PREF_LESSON6, state.lesson6);
    savePref(PREF_LESSON7, state.lesson7);
    savePref(PREF_LESSON8, state.lesson8);
    savePref(PREF_LESSON9, state.lesson9);
    savePref(PREF_LESSON10, state.lesson10);
    savePref(PREF_LESSON11, state.lesson11);
    savePref(PREF_LESSON12, state.lesson12);

    // console.log("My array is : "+ JSON.stringify(state.levelDataArray));
  }
  return state;
};

async function savePref(key, value) {
  try {
    await AsyncStorage.setItem(key, value);
  } catch (error) {}
}

const Rq_CreateUser = createSlice({
  name: "Rq_CreateUser",
  initialState: {
    loading: "",
    responseFlag: "",

    id: "",
    usertoken: "",
    uid: "",
    email: "",
    user_type: "",
    first_name: "",
    last_name: "",
    pic: "",
    age: "",
    point: "",
    monthly_subscribe_date: "",
    monthly_subscribe_flag: "",
    yearly_subscribe_date: "",
    yearly_subscribe_flag: "",
    monthly_subscribe_end_date: "",
    yearly_subscribe_end_date: "",

    lesson1: "",
    lesson2: "",
    lesson3: "",
    lesson4: "",
    lesson5: "",
    lesson6: "",
    lesson7: "",
    lesson8: "",
    lesson9: "",
    lesson10: "",
    lesson11: "",
    lesson12: "",

    errorCode: "",
    errorType: "",
    errorMessage: "",
  },
  reducers: {},
  extraReducers: {
    [request_CreateUser.pending]: (state, action) => {
      state.loading = "yes";
    },
    [request_CreateUser.fulfilled]: (state, action) => {
      if (validateObject(action.payload.data)) {
        if (validateObject(action.payload.data.result)) {
          state.responseFlag = checkKeyAssign(
            "status",
            action.payload.data.result
          );
          if (state.responseFlag === "true") {
            setResponsedata(state, action.payload.data.result);
          } else if (state.responseFlag === "false") {
            if (validateObject(action.payload.data.result)) {
              state.errorCode = checkKeyAssign(
                "statusCode",
                action.payload.data.result
              );
              state.errorMessage = checkKeyAssign(
                "statusMessage",
                action.payload.data.result
              );
              // setErrorData(state, action.payload.result.error);
            } else {
              state.responseFlag = "false";
              state.responseMessage = String.could_not_get_reponse;
              state.errorMessage = String.could_not_get_reponse;
            }
          } else {
            state.responseFlag = "false";
            state.responseMessage = String.could_not_get_reponse;
            state.errorMessage = String.could_not_get_reponse;
          }
        }
      }
      state.loading = "no";
    },
    [request_CreateUser.rejected]: (state, action) => {
      state.loading = "no";
      state.responseFlag = "false";
      state.responseMessage = String.could_not_get_reponse;
    },
  },
});
export default Rq_CreateUser;

import React from "react";
import { View, Text, Image, TouchableWithoutFeedback } from "react-native";

import {
  IMAGE_QT_OPTION_BOX,
  IMAGE_QT_INCORRECT,
  IMAGE_QT_CORRECT,
} from "../constants/ImageConst";
import { heightToDp } from "../utils/Responsive";

const OptionView = ({ onPress, optionAns, userClickOn, optionNumber }) => {
  const answerGivenOrNot = () => {
    if (userClickOn === "") {
      return null;
    } else {
      return correctFlagDisp();
    }
  };
  const correctFlagDisp = () => {
    return (
      <View
        style={{
          justifyContent: "flex-end",
          position: "absolute",
          width: "24%",
          flex: 0.2,
          right: 0,
          height: "35%",
          alignItems: "flex-end",
        }}
      >
        <Image
          source={
            userClickOn === "true" ? IMAGE_QT_CORRECT : IMAGE_QT_INCORRECT
          }
          style={{
            width: "100%",
            height: "100%",
            resizeMode: "contain",
            //   backgroundColor: "white",
          }}
          resizeMethod="resize"
        />
      </View>
    );
  };

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={{ width: "50%", height: "60%", margin: "2%" }}>
        <Image
          resizeMethod="resize"
          style={{ width: "100%", height: "100%", resizeMode: "contain" }}
          source={IMAGE_QT_OPTION_BOX}
        />
        <View
          style={{
            position: "absolute",
            width: "80%",
            marginLeft: "10%",
            marginRight: "8%",
            flex: 1,
            // backgroundColor: "orange",
            alignItems: "center",
            height: "100%",
            flexDirection: "row",
          }}
        >
          <Text
            style={{
              fontWeight: "bold",
              color: "black",
              textAlign: "left",
              width: "2%",
              textAlignVertical:'center',
              flex: 0.1,
              //   backgroundColor: "black",
              fontSize: heightToDp(2.8),
            }}
          >
            {optionNumber}
          </Text>

          <Text
            style={{
              fontWeight: "bold",
              color: "black",
              textAlign: "left",
              width: "98%",
              flex: 0.7,
              //   backgroundColor: "black",
              fontSize: heightToDp(2.8),
            }}
            numberOfLines={2}
          >
            {optionAns}
          </Text>

          {answerGivenOrNot()}
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};
export default OptionView;

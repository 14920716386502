import { StatusBar } from "expo-status-bar";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import GameScen from "./src/screens/scens/GameScen";
import WelcomeScreen from "./src/screens/startscreens/WelcomeScreen";
import { Provider } from "react-redux";
import store from "./src/redux/store/store";
import MainSplashScen from "./src/screens/scens/MainSplashScen";

export default function App() {
  return (
    <Provider store={store}>
      <View style={styles.container}>
        <MainSplashScen />
        <StatusBar style="auto" hidden={true} />
      </View>
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});

import React from "react";
import { View, Text, Image, StyleSheet } from "react-native";
import ImagePress from "../../components/ImagePress";
import RoundBackground from "../../components/RoundBackground";
import {
  IMAGE_START_NOW,
  IMAGE_SINGLE_CHAR,
  IMAGE_WATCHED_TICK,
} from "../../constants/ImageConst";
import { String } from "../../constants/String";

const StartNowScreen = ({ onPress, onPressStart }) => {
  return (
    <RoundBackground
      bgcolor="rgba(241, 221, 106, 0.9)"
      onPress={onPress}
      closeDisplay={false}
      borderColor="white"
    >
      <View style={{ alignItems: "center", width: "100%", height: "100%" }}>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Image
            source={IMAGE_WATCHED_TICK}
            style={{ width: "15%", height: "15%", resizeMode: "contain" }}
          />
          <Text style={styles.titleTextStyle}>{String.startnow_allset}</Text>
          <Text style={styles.subTitleTextStyle}>
            {String.startnow_enjoy_sevenday_free}
          </Text>
          <Text style={styles.normalTextStyle}>
            {String.startnow_other_description}
          </Text>

          <ImagePress
          onPress={onPressStart}
            source={IMAGE_START_NOW}
            style={{
              marginTop: "6%",
              width: "30%",
              height: "14%",
              resizeMode: "contain",
            }}
          />
        </View>
        <Image
          source={IMAGE_SINGLE_CHAR}
          style={{
            position: "absolute",
            width: "20%",
            height: "27%",
            right: "1%",
            bottom: "0%",
            resizeMode: "contain",
          }}
          resizeMethod="resize"
        />
      </View>
    </RoundBackground>
  );
};

export default StartNowScreen;

const styles = StyleSheet.create({
  titleTextStyle: {
    color: "black",
    fontWeight: "bold",
    fontSize: 24,
    marginTop: "1%",
  },
  subTitleTextStyle: {
    color: "black",
    fontWeight: "normal",
    fontWeight: "bold",
    fontSize: 18,
    marginTop: "1%",
  },
  normalTextStyle: {
    color: "black",
    fontWeight: "normal",
    textAlign: "center",
    fontSize: 16,
    marginTop: "3%",
  },
});

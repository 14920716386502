import React, { useEffect,useState } from "react";
import { View, Text, StyleSheet, Image } from "react-native";
import { heightToDp } from "../utils/Responsive";
import ImagePress from "./ImagePress";
import { IMAGE_BTN_PLAY,IMAGE_START_SESSONS,IMAGE_BROODY_LESSONS,IMAGE_BTN_OK } from "../constants/ImageConst";


const FinishCourseBanner = ({ title, welcomeMessage, onPress }) => {
  // sentence.split(" ");
  const [fisrstText,setFirstText] = useState("");
  const [secondText,setSecondText] = useState("");
  // const arratext = title.split(":");
  // useEffect(()=>{
  //   console.log("Title is : " + title)
  //   const arratext = title.split(":")
  //   console.log("Title 1 is : " + arratext[0])
  //   console.log("Title 2 is : " + arratext[1])
  //   setFirstText(arratext[0])
  //   setSecondText(arratext[1])
  // },[])

  // useEffect(()=>{
  //   setFirstText(fisrstText)
  //   setSecondText(secondText)
  // },[fisrstText,secondText])

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View style={styles.mainContainer}>
        <Text style={styles.textTitleContainer}>{title}</Text>
        <Image style={styles.imageContainerLarge} source={IMAGE_BROODY_LESSONS}/>
        <ImagePress
          source={IMAGE_BTN_OK}
          onPress={onPress}
          style={styles.imageButtonContainer}
        />
      </View>
    </View>
  );
};

export default FinishCourseBanner;
const styles = StyleSheet.create({
  mainContainer: {
    width: "90%",
    height: "90%",
    // margin: "2%",
    borderWidth: 10,
    borderRadius: 10,
    justifyContent: "center",
    borderColor: "white",
    backgroundColor: "#FEE252",
  },
  closeButtonView: {
    position: "absolute",
    height: "4%",
    width: "2%",
    // margin: "10%",
    right: "4.5%",
    top: "4.5%",
    resizeMode: "contain",
  },
  imageContainerLarge: {
    width: "30%",
    height: "30%",
    resizeMode: "contain",
    alignSelf: "center",
  },
  imageButtonContainer: {
    marginTop: "2%",
    marginBottom: "1%",
    width: "26%",
    height: "12%",
    resizeMode: "contain",
    alignSelf: "center",
  },
  textTitleContainer: {
    fontSize: heightToDp(4),
    color: "black",
    padding: "2%",
    textAlign: "center",
    fontWeight: "bold",
  },

  textSubTitleContainer: {
    fontSize: heightToDp(3),
    color: "black",
    marginBottom: "2%",
    textAlign: "center",
  },
});

import React from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import ImagePress from './ImagePress';
import ImagePressNoAnim from './ImagePressNoAnim';



const ActivityPinBoard = ({bottomMargine, onPress, position,imageSource})=>{
    return(<TouchableWithoutFeedback onPress={onPress}>
        <View
          style={{
            width: "13%",
            // width: "25%",
            height: "50%",
            position: "absolute",
            // bottom: "14%",
            bottom:bottomMargine,
            resizeMode: "contain",
            // transform:position
            left:'42%'
            // transform: [{ translateX: parseInt(Math.abs(travelEndx) / 3.5) / 2 }],
          }}
        >
          <ImagePressNoAnim
            onPress={onPress}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              // bottom: bottomMargineStand,
              resizeMode: "contain",
              // transform: [{ translateX: parseInt(Math.abs(travelEndx) / 4) / 2 }],
            }}
            source={imageSource}
          />
        </View>
      </TouchableWithoutFeedback>)
}
export default ActivityPinBoard;
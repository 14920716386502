import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { heightToDp } from "../utils/Responsive";
import ImagePress from "./ImagePress";

const TeacherCmp = ({ textCount, textLable, textSubLable, margineFlag }) => {
  return (
    <View
      style={[
        margineFlag ? styles.viewContainer_two : styles.viewContainer_one,
      ]}
    >
      <Text
        style={{
          width: "20%",
          fontWeight: "bold",
          textAlign: "center",
          letterSpacing: 1,
          color: "white",
          fontSize: heightToDp(9),
        }}
      >
        {textCount}
      </Text>
      <View style={{ width: "80%", paddingRight: "1%" }}>
        <Text
          style={{
            width: "100%",
            fontSize: heightToDp(4),
            fontWeight: "bold",
            color: "black",
            lineHeight: heightToDp(5.5),
            letterSpacing: 0.6,
          }}
        >
          {textLable}
        </Text>
        <Text style={{ width: "100%", color: "black", letterSpacing: 0.6,  fontSize: heightToDp(2.8), }}>
          {textSubLable}
        </Text>
      </View>
          
      </View>
  );
};
export default TeacherCmp;

const styles = StyleSheet.create({
  descriptionContainer: {
    fontSize: heightToDp(3.5),
    lineHeight: heightToDp(5),
    color: "black",
  },
  viewContainer_one: {
    width: "100%",
    flexDirection: "row",
    paddingTop: "2%",
    paddingBottom: "4%",
  },
  viewContainer_two: {
    width: "100%",
    flexDirection: "row",
    paddingTop: "2%",
    marginBottom: "10%",
  },
});
